/*
DestinationsPlaylistUtils.js

Functions that are used in DestinationsPlaylist/Stations component.
*/

import {DESTINATIONS_VIDEOS_PER_PAGE} from '../js/Configuration';

import {
  apirequest,
  getAuthData,
  shuffle,
  expandShortYouTubeUrl,
  extractYouTubeIdFromUrl,
  makeYouTubeUrl
} from '../js/Utilities';

import {
  tooManyURLs
} from './DestinationsUtils';

/*export const setStateOnChangePerPage = (state, value) => {
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      perPage: +value
    }
  }
}
*/

export const setStateOnShuffle = (state) => {
  const toshuffle = [...state.list];
  return {
    ...state,
    list: shuffle(toshuffle)
  }
}

export const setStateGroups = (state, data) => {
  return {videoGroups: data};
}

export const setStateOnPreGetVideoListBatch = (state) => {
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      loading: true
    }
  };
}

export const normalizeVids = (videolist) => {
  const callback = ( item, index ) => {
    return {
      name: item.Video_Name,
      ordinal: index,
      id: item.video_id,
      ytID: item.Video_ID,
      thumb: item.videoext_thumbnail,
      duration: +item.Duration,
      is_interstitial: item.is_interstitial,
      crt: item.crt,
      meow: item.meow,
      active: item.active,
      position: +item.order
    };
  };
  return videolist.map(callback);
}

export const setStatePostVideoListBatch = (state, data) => {
  // add a checked property so we don't get undefined errors
  const { videos } = data;  
  videos.map((v) => {
    v.checked = false;
    return v;
  })
  
  const newState = {
    ...state.addBySearch,
    list: videos,
    currentPage: data.current_page,
    totalPages: data.pages,
    numResults: data.num_results,
    loading: false
  };

  return {
    ...state,
    addBySearch: newState
  };
}

export const makeRatingsObjects = (data) => {
  return data.map((item, index) => {
    return {
      value: index,
      label: item.label
    };
  });
}

export const setStatePostRatingsSubmit = (state, data) => {
  return {
    ...state,
    showVideo: false,
    selectedRating: '',
    currentVideoData: {}
  }
}

export const setStateOnRatingsChange = (state, value) => {
  return {
    ...state,
    selectedRating: value,
    ratingsSubmitHandler: ''
  };
}

export const setStateIsDupe = (state, data) => {
  return {
    ...state,
    duplicated: data.is_dupe
  };
}

export const setStateCloseVideoList = (state) => {
  const newState = {
    ...state.addBySearch,
    open: false,
    currentPage: 1,
    totalPages: 0,
    numResults: 0,
    sortBy: 'video_addeddate',
    ascending: false,
    filterKeyword: '',
    filterOn: '',
    loading: true
  };
  return {
    ...state,
    addBySearch: newState
  };
}

export const setStateCloseCheckDupes = (state) => {
  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      open: false,
      loading: true
    }
  }
}

export const setStateShowAddByURLModal = (state) => {
  return {
    ...state,
    addByURL: {
      ...state.addByURL,
      open: true,
      value: '',
      working: false
    }
  }
}

export const setStateOnShowVids = (state) => {
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      open: true
    }
  }
}

export const setStateOnSearchDirectionChange = (state) => {
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      ascending: !state.addBySearch.ascending
    }
  }
}

export const setStateOnSearchFilterClear = (state) => {
  return {
    ...state,
    ...state.addBySearch,
    filterOn: '',
    filterKeyword: ''
  };
}

// Technique for finding duplicates in an array. See: https://stackoverflow.com/a/24968449
const count = (list) => list.reduce((accumulator, currentvalue) => ({ ...accumulator, [currentvalue]: (accumulator[currentvalue] || 0) + 1 }), {});
export const findDupes = (arry) => {
  const counts = count(arry);
  return Object.keys(counts).filter((a) => counts[a] > 1);
}

export const checkDupes = (videolist = []) => {
  const vidIds = videolist.map((video) => {
    if(video.hasOwnProperty('ytID')) {
      return video.ytID;
    }
  });
  return findDupes(vidIds);
}

// Use this instead of checkUrlListForDupes
export const checkUrlsForDupes = (submitted, existing) => {
  const subs = splitURLs(submitted);
  const exist = existing.map((e) => makeYouTubeUrl(e.ytID));
  return exist.filter((f) => (subs.indexOf(f) > -1))
}

export const splitURLs = (input='') => {
  return input
          .split(/[,\s]/)
          .filter((f) => f !== '')
          .map((i) => i.replace('https://youtu.be/','https://www.youtube.com/watch?v='))
          .map((i) => i.replace('https://www.youtube.com/shorts/','https://www.youtube.com/watch?v='));
}

export const indicesOfDupes = (array, value) => {
  const reducer = (acc, current, index) => {
    if(current.ytID === value) {
      acc.push(index);
    }
    return acc;
  }

  // Find the indices of the video in the video list array.
  const indices = array.reduce(reducer, []);
  return {
    id: value,
    indexes: indices,
    checked: true
  };
}

export const excludeUrlFromValue = (urls = '', video = '', checked=true) => {
  // Splits by commas, or whitespace. filters empties.
  const list = urls.split(/[,\s]/).filter(line => line !== '');
  if(!list.length) return;

  if(!checked) {
    include = list.filter(line => line.indexOf(video) === -1); 
  } else {
    include = list;
  }
  
  return include.join('\n');
}

// Called when there's an error adding items to the database.
export const setStateResetModal = ( state ) => {
  return {
    ...state,
    loading: false,
    addBySearch: {
      ...state.addBySearch,
      open: false
    },
    addByURL: {
      ...state.addByURL,
      open: false,
      value: '',
      working: false
    },
    checkForDupes: {
      ...state.checkForDupes,
      open: false,
      working: false
    }
  }
}

export const setStateAfterGrid = ( state, response = {}, closeAfter = false ) => {
  if( !response.hasOwnProperty('videoList') ) return state;

  return {
    ...state,
    loading: false,
    list: normalizeVids( response.videoList.returnData ),
    addBySearch: {
      ...state.addBySearch,
      open: false
    },
    currentVideoBlock: response,
    curated: {
        by: response.Curated_By || '',
        date: response.Curated_Date || false,
        isOkayed: response.Curated === 'y'
      },
    approved: {
      by: response.Approved_By || '',
      date: response.Approved_Date || false,
      isOkayed: response.Approved === 'y'
    },
    proofed: {
      by: response.Proofed_By || '',
      date: response.Proofed_Date || false,
      isOkayed: response.Proofed === 'y'
    },
    addByURL: {
      ...state.addByURL,
      open: false,
      value: '',
      working: false
    },
    checkForDupes: {
      ...state.checkForDupes,
      open: !closeAfter,
      working: false
    }
  }
}

const updateSelectedInList = ( list, target ) => {
  let loadedRows = [...list];
  const update = loadedRows.findIndex((item) => target.value === item.video_youtubeID );
  
  loadedRows[ update ] = {
    ...loadedRows[ update ],
    checked: !loadedRows[ update ].checked,
  };

  return loadedRows;
}

export const setStateOnVideoSelect = (state, target) => {
  const updatedSet = new Set( [...state.addBySearch.selected] );

  if( !target.checked ) {
    updatedSet.delete( target.value )
  } else {
    updatedSet.add( target.value );
  }

  const boo = {
    addBySearch: {
      ...state.addBySearch,
      selected: updatedSet,
      list: [...updateSelectedInList( state.addBySearch.list, target )]
    },
    addByURL: {
      ...state.addByURL,
      value: [...updatedSet].join('\n')
    }
  }

  return boo;
}

export const initAddByUrlState = {
  value: '',
  maxIds: false,
  working: false,
  toggle: {
    options: ['Yes', 'No'],
    selected: 1,
  },
}

export const initAddBySearchState = {
  loading: true,
  currentPage: 1, // old this.state.page
  perPage: DESTINATIONS_VIDEOS_PER_PAGE,
  totalPages: 0,
  numResults: 0,
  sortBy: 'video_addeddate',
  ascending: false,
  filterKeyword: '',
  filterOn: '',
  list: [],
  selected: [], // Videos that are checked
  maxIds: false,
}

export const initCheckForUpdatesState = {
  open: false,
  updates: [],
  loading: true,
  dupes: [],
}

export const initCheckForDupesState = {
  dupes: [],
  mode: 'adding', // Can be 'adding' or 'checking'
  working: false,
  response: {},
  message: '',
}