/**
 * EpisodeMoveModal.js
 * Displays playlist URL and comments form fields.
 */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SelectMenu from '../subcomponents/SelectMenu';
import ModalDialog from '../subcomponents/ModalDialog';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

import './css/EpisodeMoveModal.css';

const EpisodeMoveModal = ({
  cssClass,
  onChange,
  onClose,
  onSubmit,
  series,
  modalRef,
  block=null,
}) => {

  const makeOptions = (item) => {
    return {
      label: item.setName,
      value: item.setID
    }
  }

  const oOnClose = () => {
    if(onClose) {
      onClose();
    }
  }

  const oOnChange = (domEvent) => {
    if(!onChange) return;
    onChange(domEvent);
  }
  
  const oOnSubmit = (domEvent) => {
    domEvent.preventDefault();
    domEvent.persist();
    if(onSubmit) {
      onSubmit(domEvent);
    }
    oOnClose();
  }

  const cssClasses = classNames({
    'stations__move__ep': true,
  }, cssClass);

  return (
    <ModalDialog id="EpisodeMoveModal" onClose={oOnClose} ref={modalRef} className={cssClasses}>
      <h2>Move this episode</h2>
      <form id="EpisodeMoveModal__menu" action="/" method="dialog" onSubmit={oOnSubmit}>
        <div>
          <SelectMenu
            labelText="Select a new series"
            id="stations_move_ep_list"
            name="series"
            onChangeHandler={oOnChange}
            options={series}
            size="20"
            firstEmpty={false}/>
        </div>
        <p>
          <button type="submit" className="btn btn--action">Save</button>
        </p>
      </form>
    </ModalDialog>
  );
}

EpisodeMoveModal.propTypes = {
  modalRef: PropTypes.any,
  block: PropTypes.any, // For now, this can be an object that contains the block ID/vsb_id.
  cssClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EpisodeMoveModal;