/**
 * MeowMenu.jsx
 * Used on the VideosPage modal to set whether a video's MEOW status
 * See PropTypes definitions at the end of this file for parameters
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PleaseAddToAva } from '../../Videos/PleaseAddToAva';

const MeowMenu = ({
  actionURL= 'api/ratings',
  cssClass= null,
  method= 'POST',
  options= [],
  disabled,
  onChange= null,
  onSubmit= null,
  value= null
}) => {

  const makeMenuItems = (arrayOfItems=[]) => {
    const defaultSelect = [<option key='selectrating_rating-1' value="">Select a MEOW Status</option>];
    let menu = null;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={`selectrating_${index.toString()}`} value={index}>{item}</option>
      });
    }
    menu = defaultSelect.concat(menu);

    return menu;
  }

  const cssClasses = classNames(
    cssClass,
    {
      'video__info__meow__select': true,
      'video__info__field': true
    }
  );

  return (
    <form action={actionURL} method={method} className={cssClasses} onChange={onChange} onSubmit={onSubmit}>
      <label htmlFor="video_rating">MEOW Status:</label>
      <PleaseAddToAva hidden={ !disabled } />
      <span className="form__input_button_group">
        <select name="meow_status" id="meow_status" onChange={()=>{}} value={value} disabled={ disabled }>
          {makeMenuItems(options)}
        </select>
        <button type="submit" disabled={disabled} className="btn btn--action">Save</button>
      </span>
    </form>
  );
};

MeowMenu.propTypes = {
  actionURL: PropTypes.string,
  method: PropTypes.string,
  cssClass: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default MeowMenu;