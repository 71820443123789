import React, { useRef } from 'react';
import { CloseButton } from '../subcomponents/CloseButton';
import PropTypes from 'prop-types';

import TextInputControlled from '../subcomponents/TextInputControlled';
import ModalDialog from '../subcomponents/ModalDialog';

import { API_DESTINATIONS_ADDNEW } from '../../js/Configuration';
import { getAuthData } from '../../js/Utilities';

const CreateDestination = ({
  modalRef,
  create,
  destination,
  onChange,
  onClose,
  onSubmit,
  value
}) => {

  return (
    <ModalDialog ref={modalRef} id="DestinationsCreateNew" onClose={onClose}>
      <h2>Create a New Destination</h2>
      <form onSubmit={onSubmit} id="destination_create_new" action={API_DESTINATIONS_ADDNEW} method="POST">
        <input type="hidden" name="token" value={getAuthData('token')} onChange={()=>{}}/>
        <TextInputControlled
          cssClass="destinations__create-new"
          labelText="Enter the name for this destination"
          name="name"
          maxLength="50"
          isRequired={true}
          onInputHandler={onChange}
          value={value}
        />
        <button type="submit" className="btn btn--action">Save</button>
      </form>
    </ModalDialog>
  );
}

export default CreateDestination;
