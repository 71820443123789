/**
 * ToolsSpreadsheetFailed.js
 * 
 */

import React from 'react';

export const ToolsSpreadsheetFailed = () => {
  return (
    <div className="tools__failed">
      <h1>I coud not retrieve those videos</h1>
      <p><b>Things to try:</b></p>
      <ul>
        <li>Check that you've entered the correct page name.</li>
        <li>Check that you've entered the correct URL.</li>
        <li>Check that the page you've submitted isn't empty.</li>
      </ul>
      <p>
        <a href="/tools">Try again</a>
      </p>
    </div>
  )
}

