import React from 'react';
import { Project } from './Login/Project';
import { ProjectIcons } from '../js/ProjectIcons';
import uniqueId from 'lodash.uniqueid';

import {
  getAuthData,
  apirequest
} from '../js/Utilities';

import '../css/UserProjects.css';

const UserProjects = (props) => {
  const [projects, setProjects] = React.useState([]);

  React.useEffect(()=>{
    if(!projects.length) {
      const fd = new FormData();
      fd.set('token', getAuthData('token'));
      fd.set('uuid', getAuthData('uuid'));

      apirequest('api/projectsforuser', {body: fd}, (data) => {
        setProjects(data);
      });
    }
  }, []);

  const makeLinks = () => {
    let links = [];

    if(projects && projects.length) {
      links = projects
      .filter((pj) => pj.name !== 'User Admin') // Removes user admin from the list.
      .map((pj) => {
        const ico = ProjectIcons[pj.icon];
        return <Project key={uniqueId()} path={`/${pj.path}`} name={pj.name} icon={ico} />
      });
    }
    return links;
  }

  return (
    <div className="UserProjects">
      {makeLinks()}
    </div>
  );
}

export default UserProjects;
