/**
 * ChannelListSearch
 * Search form for ChannelsList component.
 * Author: Webinista, Inc 6/11/18.
 */

import React from 'react';
import { DEFAULT_LABEL_TEXT } from '../../js/Configuration';
import TextInput from './TextInput';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ChannelListSearch = ({
  apiURL='',
  labelText=DEFAULT_LABEL_TEXT,
  limit=50,
  cssClass='',
  onInputHandler=null,
  onSubmitHandler=null,
  value='',
  datalist=[]
}) => {

  const cssClasses = classNames(
    cssClass,
    {'channel__list__search': true}
  );

  return (
    <div>
      <form id="channel_list_search" action={apiURL} method="POST" className={cssClasses} onSubmit={onSubmitHandler}>
        <input type="hidden" id="limit" name="limit" value={limit} />
        <TextInput
          labelText={labelText}
          name="channel_filter"
          onInputHandler={onInputHandler}
          value={value}
          datalist={datalist}
          isRequired={true} />
      </form>
    </div>
  )
}

ChannelListSearch.propTypes = {
  apiURL: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  cssClass: PropTypes.string,
  onInputHandler: PropTypes.func,
  onSubmitHandler: PropTypes.func,
  value: PropTypes.string.isRequired,
  datalist: PropTypes.array
};

export default ChannelListSearch;
