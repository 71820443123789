/**
 * EpisodeView.js
 * View a single episode. Page page-level component
 */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';

import AddVideosByURL from '../Destinations/AddVideosByURL';
import Breadcrumb from '../subcomponents/Breadcrumb';
import CheckForDuplicatesModal from '../Destinations/CheckForDuplicatesModal';
import CheckForUpdates from '../Destinations/CheckForUpdates';
import CheckForUpdatesProgress from '../Destinations/CheckForUpdatesProgress';
import CRTStatus from '../Destinations/CRTStatus';
import ModalDialog from '../subcomponents/ModalDialog';

import DragSortList from '../DragSortList';

import { DestinationsToggleView } from '../Destinations/DestinationsToggleView';
import EpisodeViewTitle from './EpisodeViewTitle';
import EpisodeMoveModal from './EpisodeMoveModal';
import ExportCSV from '../Destinations/ExportCSV';
import RuntimeRatings from '../Destinations/RuntimeRatings';
import RunDate from '../Stations/RunDate';
import SeasonsEpsDisplay from './SeasonsEpsDisplay';
import VideoDragItem from '../subcomponents/VideoDragItem';
import VideoModal from '../subcomponents/Videos/VideoModal';
import VideoSetEdit from '../Destinations/VideoSetEdit';
import VideosList from '../VideosList';

import {
  API_CRTAPPROVALS_GETVIDSETSTATUS,
  API_DESTINATIONS_ASSIGNMENTS,
  API_DESTINATIONS_CHECKFORUPDATES,
  API_DESTINATIONS_CHECKFORUPDATES_CSV,
  API_VIDEO_REMOVEFROMSET,
  API_VIDEOSETBLOCK_RATING,
  API_VIDEOSETBLOCK_UPDATE,
  API_VIDEO_GETBATCH,
  API_VIDEO_ADDTOSET,
  API_GROUPS_ALLGROUPS,
  API_RATINGS_ALL,
  API_VIDEO_ADDBATCH,
  API_RATINGS_VIDEOUPDATE,
  API_VIDEO_INFO,
  API_STATIONS_EPISODE_EDIT,
  API_VIDEOSETBLOCK_REORDERED,
  API_VIDEOSETBLOCK_INFO,
  API_VIDEOSETBLOCK_INFO_CSV,
  NULL_EPISODE_TITLE,
  NULL_SET_TITLE
} from '../../js/Configuration';

import {
  apirequest,
  expandShortYouTubeUrl,
  extractYouTubeIdFromUrl,
  getAuthData,
  isAdmin,
  isEmptyObject,
  youtubeURL,
  totalRunTime,
  formatTime,
  extractYouTubeIdFromUrl,
  unique
} from '../../js/Utilities';

import {
  formatVideoList,
  saveSeasonEp
} from '../../actions/StationsUtils';

import {
  excludeUrlFromValue,
  checkDupes,
  checkUrlsForDupes,
  splitURLs
} from '../../actions/DestinationsPlaylistUtils';

import {
  exportVideoData,
  playAllVideos,
  tooManyURLs
} from '../../actions/DestinationsUtils';

import './css/EpisodeView.css';
import '../Destinations/css/VideoDisplay.css';

const EpisodeView = ({
  params,
  project,
}) => {

  const episodeReNameRef = useRef(null);
  const episodeDeleteDialog = useRef(null);
  const addVideosByUrlRef = useRef(null);
  const addVideosFromAvaRef = useRef(null);
  const checkForDupesModal = useRef(null);
  const episodeMoveRef = useRef(null);
  const videoModalRef = useRef(null);
  const cfupRef = useRef(null);

  const {episodeID} = params;

  const canAdmin = isAdmin(project);

  const initEditTitle = {
    meta: {},
    list: [],
    open: false,
    value: '',
    error: null,
    id: 0
  }

  const initAddByUrl = {
    open: false,
    value: '',
    maxIds: false,
    working: false
  }

  const initVideosToAdd = {
    open: false,
    isloading: true,
    available: [],
    page: 1,
    pageCount: 1,
    results: 0,
    perPage: 10,
    sortBy: 'video_addeddate',
    ascending: false,
    filterKeyword: '',
    filterOn: '',
    selected: [],
  }

  const initMeta = {
    se: {
      season: 0,
      episode: 0
    },
    vsb_datetimetorun: null
  }

  const initCheckForUpdates = {
    open: false,
    updates: [],
    loading: true,
    mode: '' // One of 'checking', 'adding'
  }

  const initMoveEp = {
    open: false,
    value: '',
    series: []
  }

  const initCheckForDupes = {
    open: false,
    loading: true,
    dupes: [],
    mode: 'adding', // Can be 'adding', 'checking', or 'checked'
    working: false,
    response: {},
    message: ''
  }

  const [series, setSeries] = useState(0);
  const [list, setList] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([{path: null, label: ''}]);
  const [title, setTitle] = useState('');
  const [editTitle, setEditTitle] = useState(initEditTitle);
  const [addByURL, setAddByURL] = useState(initAddByUrl);
  const [videostoadd, setVideosToAdd] = useState(initVideosToAdd);
  const [videopreview, setVideoPreview] = useState('');
  const [crtstatus, setCRTStatus] = useState({});
  const [meta, setMeta] = useState(initMeta);
  const [rating, setRating] = useState('');
  const [runtime, setRuntime] = useState(0);
  const [checkForUpdates, setCheckForUpdates] = useState(initCheckForUpdates);
  const [moveep, setMoveEp] = useState(initMoveEp);
  const [deleteep, setDeleteEp] = useState({open: false});
  const [useGrid, setUseGrid] = useState(true);
  const [checkForDupes, setCheckForDupes] = useState(initCheckForDupes);
  const [hideSaveStatus, setHideSaveStatus] = useState(true);
  const [submissions, setSubmissions] = useState([]);


  useEffect(() => {
    if(!list.length) {
      getVideosForBlock();
    }
  
    if (isEmptyObject(crtstatus)) {
      getEpisodeCRTStatus();
    }

    if(!rating) {
      getEpisodeRating();
    }

  }, [list, crtstatus]);

  const closeModal = () => {
    episodeDeleteDialog?.current.close();
  }

  const changePerPageHandler = (domEvent) => {
    domEvent.persist();
    getVideoListBatchRequest(domEvent.target.value);
  }

  const getVideosForBlock = () => {
    const fd = new FormData();

    if(!episodeID) {
      alert('ERROR: Try refreshing the page.');
      return;
    }

    fd.set('id', episodeID);
    fd.set('token', getAuthData('token'));

    // Fetch the block's videos.
    apirequest(API_VIDEOSETBLOCK_INFO, {body: fd}, (response) => {
      const {
        videoList,
        vsb_setID,
        title,
        Station,
        station_id,
        Video_Set
      } = response;

      // If this is an empty set don't do anything.
      if(!response.vsb_setID) {
        setList([false]);
        return;
      };

      const {returnData} = videoList;

      const crumb = [{
        path: `/stations/${station_id}`,
        label: response.Station
      },
      {
        path: `/stations/series/${vsb_setID}`,
        label: Video_Set || NULL_SET_TITLE
      },
      {
        path: '',
        label: title || NULL_EPISODE_TITLE
      }];
      setBreadcrumbs(crumb);

      setSeries(vsb_setID);

      if(returnData.length) {
        setList(formatVideoList(returnData));
      } else {
        setList([false]);
      }

      setTitle(response.title);
      setMeta(response);
      setVideosToAdd({...videostoadd, selected: returnData});
      setRuntime(totalRunTime(returnData));
      setEditTitle(title);
      setAddByURL({...addByURL, working: false})
    });
  }

  const getEpisodeCRTStatus = () => {
    if( isEmptyObject(params) || !Object.hasOwn(params,'episodeID') ) {
      throw new Error('Missing the episode ID. Please check the URL.');
    }

    const fd = new FormData();
    fd.set('videoset', episodeID);
    fd.set('token', getAuthData('token'));

    apirequest(API_CRTAPPROVALS_GETVIDSETSTATUS, {body: fd}, (data) => {
      setCRTStatus({
        ...data,
        loading: false
      });
    });
  }

  const getEpisodeRating = () => {
    const fd = new FormData();
    fd.set('videosetblockid', episodeID);
    fd.set('token', getAuthData('token'));

    // Make the network request here.
    apirequest(API_VIDEOSETBLOCK_RATING, { body: fd }, (data) => {
      if(data.message === 'success') {
        setRating(data.rating);
      }
    });
  }

  const makeBreadcrumb = () => {
    return <Breadcrumb items={breadcrumbs} ariaLabel="Destinations menu" />;
  }

  const onCloseAddByUrlModal = () => {
    setAddByURL({
      ...addByURL,
      value: ''
    })
    if(addVideosByUrlRef?.current) {
      addVideosByUrlRef.current.close();
    }
  }

  const makeAddVidsByURL = () => {
    return (
      <AddVideosByURL
        {...addByURL}
        modalRef={addVideosByUrlRef}
        block={episodeID}
        onClose={onCloseAddByUrlModal}
        onSubmit={onAddVideoByUrlSubmit}
        onChange={onAddVideoByUrlChange}
      />
    )
  }

  const onVideoSelected = (domEvent) => {

    const picked = domEvent.target.value.toString();
    let nselected;
    let toAdd;

    if(addByURL.value) {
      toAdd = addByURL.value.split(/[,\s]/);
    } else {
      toAdd = [];
    }

    // If the box is checked and it's not already in the list...
    if(domEvent.target.checked) {
      // Find the item in the videosAvailable list
      const addThis = videostoadd.available.find((item) => item.video_youtubeID === picked);
      nselected = [...videostoadd.selected, addThis];
      toAdd.push(picked);
    } else {
      nselected = videostoadd.selected.filter((item) => item.video_youtubeID !== picked);
      toAdd = toAdd.filter((item) => item !== picked);
    }

    setVideosToAdd({...videostoadd, selected: nselected});
    setAddByURL({
      ...addByUrl,
      value: toAdd.join(',')
    });
  }

  const getVideoListBatchRequest = (
    perPage=10,
    page=1,
    sort='',
    keyword='',
    criteria,
    orderby
  ) => {
    const fd = new FormData();
    fd.set('perpage', perPage);
    fd.set('page', page);
    fd.set('sort_by', sort);
    fd.set('filter_by', keyword);
    fd.set('filter_on', criteria);
    fd.set('order', orderby);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_GETBATCH, {body: fd}, (response) => {
      setVideosToAdd({
        ...videostoadd,
        available: response.videos,
        page: response.current_page,
        pageCount: response.pages,
        results: response.num_results,
        isloading: false,
      });
    });
  }

  const getVideoListBatchSortDir = () => {
    const direction = ['descending', 'ascending'];
    const dirKey = +videostoadd.ascending;
    return direction[dirKey];
  }

  const onSearchFilterChangeHandler = (domEvent) => {
    const keyword = domEvent.target.value;
    const criterion = domEvent.target.form.elements.filterOn.value.split('|')[0];

    setVideosToAdd({
      ...videostoadd,
       filterKeyword: keyword,
      filterOn: criterion,
      page: 1,
      pageCount: 1
    });

    getVideoListBatchRequest(
      undefined,
      undefined,
      videostoadd.sortBy,
      keyword,
      criterion,
      videostoadd.sortBy
    );
  }

  const onVideoInfoClose = () => {
    setVideoPreview('');
    if(videoModalRef.current) {
      videoModalRef.current.close();
    }
  }

  const onSeasonEpChange = (domEvent) => {

    const {id, value} = domEvent.target;

    const newmeta = {
      ...meta,
      se: {
        ...meta.se,
        [id]: value
      }
    }

    setMeta(newmeta);
  }

  const onSeasonEpSave = () => {
    const { vsb_id, se } = meta;
    const { season, episode} = se;

    saveSeasonEp(vsb_id, season, episode, (data) => {
      setHideSaveStatus(false);
      manageSaveAlert();
    });
  }

  const onRunDateChange = (domEvent) => {
    const {value} = domEvent.target;

    setMeta({...meta, vsb_datetimetorun: value});

    const fd = new FormData();
    fd.set('id', meta.vsb_id);
    fd.set('rundate', value);
    fd.set('token', getAuthData('token'));
    apirequest(API_VIDEOSETBLOCK_UPDATE, {body: fd}, (response) => {
      setHideSaveStatus(false);

      setTimeout(() => {
        setHideSaveStatus(true)
      }, 3000);
    });

  }

  /*
    Duplicates DestinationPlaylist methods. Need to normalize the names that the
    API returns. Then we can consolidate these into a Utility module.
  */
  const onCRTsubmit = (data) => {
    if(data.status !== 'success') return;

    const vl = [...list];

    const whichVideo = vl.findIndex((item) => {
      return item.ytID === data.video_ytid;
    });

    vl[whichVideo].crt = data.crt;

    setList(vl);
  }

  const onMeowSave = (data) => {
    if(data.status !== 'success') return;

    const vl = [...list];
    const whichVideo = vl.findIndex((item) => {
      return item.ytID === data.video_ytid;
    });

    vl[whichVideo].meow = data.meow;
    setList(vl);
  }

  const makeSeriesOptions = (item) => {
    return {
      label: item.setName,
      value: item.setID
    }
  }

  const makeVideoList = () => {
    return (
      <VideosList
        modalRef={addVideosFromAvaRef}
        currentSet={list}
        blockId={episodeID}
        onSubmit={() => {
          setList([])
        }}
      />
     );
  }

  // Shows the video modal
  const onAddVideoFromAva = () => {
    if(addVideosByUrlRef?.current) {
      addVideosByUrlRef.current.close();
    }

    if(addVideosFromAvaRef?.current) {
      addVideosFromAvaRef.current.showModal();
    }

    getVideoListBatchRequest();
  }

  const onAddVideoFromAvaClose = () => {
    if(addVideosFromAvaRef?.current) {
      addVideosFromAvaRef.current.close();
    }
    setVideosToAdd(initVideosToAdd);
  };

  const onAddVideoByURLClose = () => {
    if(addVideosByUrlRef?.current) {
      addVideosByUrlRef.current.close();
    }
    setVideosToAdd(initVideosToAdd);
  }

  const onAddVideoByUrlChange = (domEvent) => {
    const {value} = domEvent.target;

    setAddByURL({
      ...addByURL,
      value: value,
      maxIds: tooManyURLs(value)
    });
  }

  const onAddVideoByURLResponse = (response) => {
    const {videoList} = response;
    const {returnData} = videoList

    setList(formatVideoList(returnData));
    setVideosToAdd({
      ...videostoadd,
      selected: returnData,
      open: false
    });

    setAddByURL({
      ...addByURL,
      open: false,
      working: false,
      value: ''
    });

    setRuntime( totalRunTime(returnData) );
  }

  const makeVideos = () => {
    if( !list || list[0] === false ) {
      return <h2>No videos in this episode.</h2>;
    }

    const cssClass = classNames({
      'video__display': true,
      'video__display--grid': useGrid,
      'video__display--listview': !useGrid
    });

    const grid = (
      <section className="draggable__grid episode__videolist">
        <header>
          <h4>Drag videos to reorder. Click thumbnail to view video information.</h4>
          <div className="episode__savestatus" hidden={hideSaveStatus}>Saved</div>
        </header>

        <DestinationsToggleView useGrid={useGrid} onChange={onGridToggleChange} />

        <div className={cssClass}>
          <DragSortList
            items={list}
            onPlayVideo={oOnPlayVideo}
            onDeleteVideo={deleteVideo}
            onReSort={(newOrder) => {
              setList(newOrder)
              gridUpdated(newOrder);
            }}
          />
        </div>
      </section>
    );

    return grid;
  }

  const oOnPlayVideo = (videoId) => {
    setVideoPreview(videoId);

    if(videoModalRef.current) {
      videoModalRef.current.showModal();
    }
  }

  const gridUpdated = (videos) => {
    let ordered;
    if(videos.length) {
      ordered = videos.map((vid) => {
        return vid.ytID;
      });
      // Saves the new order to the server.
      saveNewOrder(ordered);
    }
  }

  const saveNewOrder = (order) => {
    const fd = new FormData();
    const id = meta.vsb_id;

    fd.set('id', id);
    fd.set('videos', order.join(','));
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_REORDERED, {body: fd}, (data) => {
      const {returnData} = data;
      if(!returnData.length) return;

      setList(formatVideoList(returnData));
      setRuntime(totalRunTime(returnData));
    });
  }

  const onEpisodeEdit = () => {
    if(episodeReNameRef?.current) {
      episodeReNameRef?.current.showModal();
    }
  }

  const onEpisodeEditClose = () => {
    if(episodeReNameRef?.current) {
      episodeReNameRef?.current.close();
    }
  }

  const onEpisodeEditSave = (domEvent) => {
    domEvent.preventDefault();
    const {target} = domEvent;

    const fd = new FormData(target);
    fd.set('id', episodeID);
    fd.set('token', getAuthData('token'));

    apirequest(API_STATIONS_EPISODE_EDIT, {body: fd}, (response) => {
      if(response.result === 'success') {
        let bc = [...breadcrumbs];

        // Update the last crumb with the new title.
        bc[breadcrumbs.length - 1] = {...bc[breadcrumbs.length - 1], label: response.title};
        setBreadcrumbs(bc);
        setTitle(response.title);

        if(episodeReNameRef?.current) {
          episodeReNameRef?.current.close();
        }

      } else {
        setEditTitle({
          ...editTitle,
          error: response.message
        });
      }
    });
  }

  const onEpisodeMove = () => {
    const fd = new FormData();
    fd.set('id', +meta.station_id);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_ASSIGNMENTS, {body: fd}, (response) => {
      const options = response.map(makeSeriesOptions);

      setMoveEp({
        ...moveep,
        series: options,
      });

      if(episodeMoveRef?.current) {
        episodeMoveRef?.current.showModal();
      }

    });
  }

  const onEpisodeMoveClose = () => {
    setMoveEp(initMoveEp);
    if(episodeMoveRef?.current) {
      episodeMoveRef?.current.close();
    }
  }

  const onEpisodeMoveSave = (domEvent = null) => {
    if(Object.hasOwn(domEvent, 'preventDefault')) {
      domEvent.preventDefault();
    }

    const fd = new FormData();
    fd.set('id', episodeID);
    fd.set('series', domEvent.target.elements.series.value);
    fd.set('token', getAuthData('token'));

    const callback = (response) => {
      if(response.error) {
        window.alert(response.error);
      }
      if(!response.series) return;

      const loaf = [...breadcrumbs];
      const sliced = {
        label: response.series.Video_Set,
        path: `/stations/series/${response.series.vs_id}`
      };

      // Replaces previous breadcrumb with new one.
      loaf.splice(1, 1, sliced);
      setBreadcrumbs(loaf);
    };

    apirequest(API_STATIONS_EPISODE_EDIT, {body: fd}, callback);
  }

  const onEpisodeMoveChange = (newValue) => {
    setMoveEp({...moveep, value: newValue})
  }


  const onEpisodeDelete = () => {
    episodeDeleteDialog?.current.showModal();
  }

  const onEpisodeDeleteClose = () => {
    episodeDeleteDialog?.current.close();
  }

  const onEpisodeDeleteConfirm = () => {
    const fakeDomEvent = {
      target: {
        elements: {
          series: {
            value: '00000'
          }
        }
      }
    }
    onEpisodeMoveSave(fakeDomEvent);
    episodeDeleteDialog?.current.close();
    window.location = `/stations/series/${series}`;
  }

  const onCheckForUpdates = (domEvent) => {
    setCheckForUpdates({...checkForUpdates, loading: true});
    requestVideoUpdates();
    if(cfupRef.current) {
      cfupRef.current.showModal();
    }
  }

  const requestVideoUpdates = () => {
    const fd = new FormData();
    fd.set('id', meta.vsb_id);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_CHECKFORUPDATES, {body: fd}, (response) => {
      if(!response.changed.length) {
        setCheckForUpdates({...checkForUpdates, loading: false});
      } else {

        const currentlist = [...list];

        // Update the current state for each changed item in the list.
        response.changed.forEach((item) => {
          const updateIt = currentlist.findIndex((vid, index) => vid.ytID === item.YouTube_ID);
          currentlist[updateIt].name = item.Current_Title;
        });

        setList(currentlist);
        setCheckForUpdates({
          ...checkForUpdates,
          updates: response.changed,
          loading: false
        });
      }
    });
  }

  const makeEpisodeEditModal = () => {
    return (
      <VideoSetEdit
        modalRef={episodeReNameRef}
        id="title"
        labelText="Enter a new name for this episode"
        title="Edit episode title"
        value={title}
        onSubmit={onEpisodeEditSave}
        onClose={onEpisodeEditClose}
        error={editTitle?.error} />
    );
  }

  const deleteVideo = (videoObj) => {
    const yes = window.confirm('Are you sure that you want to delete this video?');

    if(yes) {
      const fd = new FormData();
      fd.set('playlist', episodeID);
      fd.set('video', videoObj.id);
      fd.set('order', videoObj.position);
      fd.set('token', getAuthData('token'));

      apirequest(API_VIDEO_REMOVEFROMSET, {body: fd}, (response) => {
        if(response.itemsDeleted) {
          alert('Video deleted.');
          setList([]);
        }
      });
    }
  }

  const onAddVideoByURL = () => {
    if(addVideosByUrlRef?.current) {
      addVideosByUrlRef.current.showModal();
    }
  }

  const onAddVideoByUrlSubmit = (domEvent = null) => {
    domEvent.preventDefault();
    let postdupecheck = false;

    // Videos from the first submission.
    let {add_videos_by_url_urls} = domEvent.target;
    if( add_videos_by_url_urls) {
      setSubmissions(splitURLs(add_videos_by_url_urls.value));
    }

    if (domEvent) {
      postdupecheck = Object.hasOwn(domEvent.target.elements, 'PostDupeCheck');
    }

    // Find duplicates, then get unique values.
    let dupes = checkUrlsForDupes(addByURL.value, list);
    dupes = Array.from(new Set(dupes));

    console.log(dupes)
    /*
      If we have duplicates, and this form isn't being submitted after the duplicate
      checked display the 'Duplicates Found' modal.
    */
    if(dupes.length && !postdupecheck) {
      setTimeout(() => {
        addVideosByUrlRef.current.close();
        checkForDupesModal.current.showModal();
        setCheckForDupes({...checkForDupes, dupes: dupes, loading: false});
      }, 100);
    } else {
        setCheckForDupes({...checkForDupes, loading: true});
        onSubmitUrlPostConfirmation(domEvent.target);
    }
  }

  const onSubmitUrlPostConfirmation = (formElement) => {
    const {duplicates} = formElement.elements;

    // Returned the YouTube IDs for checked boxes only.
    let exclude = [];
    if(duplicates.length) {
      exclude = Array.from(duplicates).filter((d) => !d.checked).map((d => d.value));
    }

    /*
     Filter the excluded values from the submitted ones.
    */

    let submits = submissions.map(expandShortYouTubeUrl)
                    .map(extractYouTubeIdFromUrl)
                    .filter((s) => {return exclude.indexOf(s) == -1})

    if(submits.length) {
      const fd = new FormData();
      fd.set('blockid', episodeID);
      fd.set('userID', getAuthData('uuid'));
      fd.set('token', getAuthData('token'));
      fd.set('videoids', submits.join(','));
      fd.set('prepend', +!addByURL.toggle.selected);

      apirequest(API_VIDEO_ADDBATCH, {body: fd}, (response) => {
        if (
          !response ||
          (Object.hasOwn(response, 'error') && response.error)
        ) {
          window.alert(`ERROR: ${response.message}`);
        } else {

          setCheckForDupes(initCheckForDupesState);
          setAddByURL(initAddByUrlState);
          setList([]);

          if(checkForDupesModal.current) {
            checkForDupesModal.current.close();
          }

          if(addByUrlModal.current) {
            addByUrlModal.current.close();
          }
        }
      });
    } else {
      setAddByURL({...addByURL, maxIds: true});
    }
  }

  const saveVideosToEpisode = () => {
    const fd = new FormData();
    fd.set('blockid', meta.vsb_id);
    fd.set('userID', getAuthData('uuid') );
    fd.set('videoids', addByURL.value);

    saveURLsToBlock(fd, callback, prepend=0)
  }

  const onRatingsSubmit = (response) => {
    const {youtubeid, rating} = response;
    const index = list.findIndex((f) => f.ytID == youtubeid);
    const changedList = [...list];

    const update = {...changedList[index]};
    update.rating = rating;
    changedList[index] = update;

    setList(changedList);
  }

  const manageSaveAlert = () => {
    const disappear = () => {
      setHideSaveStatus(true);
    };
    setTimeout(disappear, 3000);
  }

  const onCheckForUpdatesClose = () => {
    if(cfupRef.current) {
      cfupRef.current.close();
    }
  }

  const makeCheckForUpdatesModal = () => {
    let cfum = null;

    cfum = (
      <CheckForUpdatesProgress
          apiurl={API_DESTINATIONS_CHECKFORUPDATES_CSV}
          modalRef={cfupRef}
          videoset={meta.vsb_id}
          videos={checkForUpdates.updates}
          loading={checkForUpdates.loading}
          onClose={onCheckForUpdatesClose} />
    );

    return cfum;
  }

  const makeMoveEpisodeModal = () => {
    return (
      <EpisodeMoveModal
        {...moveep}
        modalRef={episodeMoveRef}
        station={meta.station_id}
        onChange={onEpisodeMoveChange}
        onSubmit={onEpisodeMoveSave}
        onClose={onEpisodeMoveClose} />
    )
  }

  const makeDeleteEpisodeModal = () => {
    const clickHandler = (domEvent) => {
      switch(domEvent.target.value) {
        case 'Yes':
          onEpisodeDeleteConfirm();
          break;
        default:
          onEpisodeDeleteClose();
      }
    }

    const confirm = (
      <form method="dialog" onClick={clickHandler}>
        <h2>Delete <em>{title}</em> from <em>{meta.Video_Set}</em></h2>
        <h3>Are you sure?</h3>
        <p>
          <button type="button" className="btn btn--action" name="confirm" value="Yes">Yes</button>
          <button type="button" className="btn btn--action" name="confirm" value="No">No</button>
        </p>
      </form>
    );

    return (
      <ModalDialog
        ref={episodeDeleteDialog}
        id="EpisodeViewDeleteModal"
        onClose={onEpisodeDeleteClose}>
        {confirm}
      </ModalDialog>
    );
  }

  const onGridToggleChange = (domEvent) => {
    /*
    `+domEvent.target.value` Converts the value to an integer. !! converts it to a boolean
    */
    setUseGrid(!!+domEvent.target.value);
  }

  const onCheckForDuplicates = (domEvent) => {
    setCheckForDupes({
      ...checkForDupes,
      loading: true,
      dupes: unique(checkDupes([...list])),
      mode: 'checking'
    });

    setTimeout(() => {
      setCheckForDupes({
        ...checkForDupes,
        dupes: unique(checkDupes([...list])),
        loading: false
      });
    }, 1500);

    if(checkForDupesModal.current) {
      checkForDupesModal.current.showModal()
    }
  }

  const onCloseCheckForDupes = () => {
    setCheckForDupes(initCheckForDupes);
    setAddByURL({...addByURL, working: false});

    if(checkForDupesModal.current) {
      checkForDupesModal?.current.close();
    }
  }

  const onCheckDupesChange = (domEvent) => {
    const {value} = domEvent.target;
    const {checked} = domEvent.target;

    setAddByURL({
     ...addByURL,
      working: false,
      value: excludeUrlFromValue(addByURL.value, value, checked)
    });
  }

  const onCheckDupesErrorClose = (domEvent) => {
    setCheckForDupes({
      ...checkForDupes,
      mode: 'checking',
      message: ''
    });
  }

  const onRemoveInstance = (domEvent) => {
    domEvent.preventDefault();
    let confirm = false;
    let video = false;

    const video_id = domEvent.target.elements['video'].value;
    if(video_id) {
      video = list.find((item) => item.ytID === video_id);
    }
    if(!video) return;

    confirm = window.confirm(`Are you sure you want to delete this instance of "${video.name}"?`);
    if(confirm) {
      const form = domEvent.target;

      setCheckForDupes({
        ...checkForDupes,
        working: true,
        message: `I deleted that instance of <q>${title}</q>.`
      });
      onRemoveInstanceSubmit(form)
    }
  }

  const onRemoveInstanceSubmit = (form) => {
    const fd = new FormData(form);
    fd.set('token', getAuthData('token'));
    const ac = new AbortController;
    const {signal} = ac;

    setList([]);

    apirequest(API_VIDEO_REMOVEFROMSET, {body: fd, signal}, (data) => {
      setCheckForDupes({
        ...checkForDupes,
        working: false,
        loading: false,
        mode: 'checked',
        response: data
      });
    });
  }

  let disableButtons;
  if(list) {
    disableButtons = (list[0] === false);
  } else {
    disableButtons = true;
  }

  return (
    <div>
      {makeBreadcrumb()}
      <div className="should__be__acollapsible__panel">
        <EpisodeViewTitle
          title={title}
          id={list.vsb_id}
          onEpisodeEdit={onEpisodeEdit}
          onEpisodeMove={onEpisodeMove}
          onEpisodeDelete={onEpisodeDelete}
          isAdmin={canAdmin} />
        <SeasonsEpsDisplay
          season={meta.se.season || '—'}
          episode={meta.se.episode || '—'}
          onSave={onSeasonEpSave}
          onChange={onSeasonEpChange}
          isAdmin={canAdmin}
        />
        <div id="episode__view__ratingcrt">
          <RuntimeRatings
            runtime={formatTime(runtime)}
            rating={rating}
            videos={list.length}
          />
          <CRTStatus
            approved={crtstatus.approved}
            status={crtstatus.status}
            loading={crtstatus.loading}
          />
        </div>
        <RunDate
          date={meta.vsb_datetimetorun || ''}
          onInputChange={onRunDateChange}
        />
        <div className="episode__view__controls">
          <span>
            <button
              type="button"
              className="btn btn-sm btn--action"
              onClick={onAddVideoByURL}
            >Add Videos by URL</button>
            <button
              type="button"
              className="btn btn-sm btn--action"
              onClick={onAddVideoFromAva}
            >Add Videos from AVA</button>
          </span>

          <div>
            <button
              disabled={disableButtons}
              type='button'
              className='btn btn-sm btn--action playAllBtn playListBtn'
              onClick={() => {playAllVideos(list)} }
            >Play All Videos</button>
            <button
              disabled={disableButtons}
              type='button'
              className='btn btn-sm btn--action exportDataBtn playListBtn'
              onClick={() => {exportVideoData(list)}}
            >Export IDs</button>

            <ExportCSV
              actionURL={API_VIDEOSETBLOCK_INFO_CSV}
              videosetID={+meta.vsb_id}
              disabled={disableButtons}
            />

            <CheckForUpdates
              onClick={onCheckForUpdates}
              disabled={disableButtons}
            />
            <button
              disabled={disableButtons}
              type="button"
              className="btn btn--action btn-sm"
              onClick={onCheckForDuplicates}
            >Check for Duplicate Videos</button>

          </div>
        </div>
      </div>
      {makeVideos()}
      {makeEpisodeEditModal()}
      {makeAddVidsByURL()}
      {makeVideoList()}

      <VideoModal
        modalRef={videoModalRef}
        isAdmin={canAdmin}
        id="DestinationPlaylistVideoModal"
        mode="videos_in_system"
        youtubeId={videopreview}
        onClose={onVideoInfoClose}
        onCRTSave={onCRTsubmit}
        onRatingSave={onRatingsSubmit}
        onMeowSave={onMeowSave}
      />

      {makeCheckForUpdatesModal()}
      {makeMoveEpisodeModal()}
      {makeDeleteEpisodeModal()}

      <CheckForDuplicatesModal
        modalRef={checkForDupesModal}
        onClose={onCloseCheckForDupes}
        inSet={list}
        playlist={episodeID}
        onRemove={onRemoveInstance}
        onSubmit={onAddVideoByUrlSubmit}
        onErrorClose={onCheckDupesErrorClose}
        {...checkForDupes} />
    </div>
  );
}

export default (props) => (
    <EpisodeView
        {...props}
        params={useParams()}
    />
);