/**
 * CloseButton.js
 */
import React from 'react';
import classNames from 'classnames';

import './css/CloseButton.css';

export const CloseButton = ({
  color,
  className,
  title,
  onClick,
  longForm = false
}) => {
  const cssClasses = classNames(
    className,
    {
      'close__button': true,
      'close__button--longform': longForm
    }
  );
  
  let ttle, longform = '';
  
  ttle = title ? <title>{title}</title> : 'Close this modal';

  if(longForm) {
    longform = <span>{title}</span>;
    ttle = '';
  }

  return (
    <button type="button" className={cssClasses} onClick={onClick}>
      <svg viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
        {ttle}
        <polygon fill={color} points="1.61065574 242.514344 93.8196721 150.305328 1 57.7909836 57.7909836 1 150.610656 93.5143443 242.819672 1.30532787 298.084016 56.875 205.875 149.084016 299 242.209016 242.209016 299 149.084016 205.875 56.875 298.084016"></polygon>
      </svg>
      {longform}
    </button>
  );
}



