/**
 * DestinationsSearchResults
 * Display table data for the stations series.
 */
import React from 'react';
import { Link } from 'react-router-dom'
import { DestinationsRestoreSet } from './DestinationsRestoreSet';

import './css/DestinationsSearchResults.css';

const DestinationsSearchResults = ({
  keyword,
  results,
  onDuplicate,
  onRestore,
  isAdmin=false
}) => {

  const makeTitle = () => {
    let tle = null;
    if(keyword) {
      tle = <h3>Video sets matching <q>{keyword}</q></h3>
    }
    return tle;
  }

  const makeLink = (item) => {
    const {
      id,
      video_set_name
    } = item;

    const stateobj = {title: video_set_name};
    return (
      <Link
        to={{
          pathname: `/destinations/rundates/${id}`,
          state: stateobj
        }}
      >{video_set_name}</Link>
    );
  }

  const makeControls = (item) => {
    const {
      id,
      video_set_name,
      isActive
    } = item;
    
    const stateobj = {title: video_set_name};
    let link= (
      <Link
        to={{ 
          pathname: `/destinations/rundates/${item.id}`,
          state: stateobj
        }}
        className="btn btn-sm btn--action"
      >View / Edit</Link>
    );
    
    if(isActive == false && isAdmin) {
      link = <DestinationsRestoreSet id={id} onSubmit={onRestore} />;
    }
    return link;
  }

  const makeResultGroup = () => {
    let groups = null;
    if(!results) return [];
     
    groups = results.map((item, index) => {
      let body = null;
        
     
    
      if(Object.hasOwn(item, 'sets')) {
        const {sets} = item;

        const rows = sets.map((trd, idx) => {
          const {
            id,
            isActive,
            video_set_name
          } = trd;

          const stateobj = {title: video_set_name};
          const showRestore = isActive == false && isAdmin;

          const rows = (
            <tr>
              <td>
                <Link
                  to={{
                    pathname: `/destinations/rundates/${id}`,
                    state: stateobj
                  }}
                >{video_set_name}</Link>
              </td>
              <td>
              <Link
                to={{ 
                  pathname: `/destinations/rundates/${id}`,
                  state: stateobj
                }}
                className="btn btn-sm btn--action"
              >View / Edit</Link>
               
              {showRestore &&
                <DestinationsRestoreSet id={id} onSubmit={onRestore} />
              }
              </td>
            </tr>
          );

          return rows;
        });
       
        body = (
          <table>
            <tbody>
              {rows}
            </tbody>
          </table>
        );

        // Return the group.
        return (
          <section className="destination__search_videosets" key={index}>
            <h4>{item.title}</h4>
            {body}
          </section>
        );
      }
    }); 
    
    //console.log(sets)

    return groups;
  }

  return (
    <div id="DestinationSearchResults">
      {makeTitle()}
      {makeResultGroup()}
    </div>
  );
}

export default DestinationsSearchResults