/**
 * UserAdminHeader.jsx
 * The navigation display for the UserAdmin page(s).
 */

import React from 'react';
import {
  NavLink
} from 'react-router-dom';

import SwitchProjects from './SwitchProjects';
import SwitchProjectsMenu from './SwitchProjectsMenu';

import { checkAccess } from '../../js/Utilities';

const UserAdminHeader = ({
  canswitch=false,
  menuopen=false,
  onSwitch=()=>{}
}) => {
  let switcher = canswitch ? <SwitchProjects onClick={onSwitch} /> : null;

  return (
    <div className="nav__project__wrap">
      <nav id="UserAdminHeader" className="nav__project" aria-label="User Admin Menu">
        <b className="nav__project_label">User Administration</b>
      </nav>
      { switcher }
      <SwitchProjectsMenu open={menuopen} onClose={onSwitch} />
    </div>
  );
}

export default UserAdminHeader;
