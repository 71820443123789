/**
 * StationView.js
 * /stations page-level component
 */
import React, {useState, useEffect, useRef} from 'react';
import { useParams, redirect } from 'react-router-dom';
import Breadcrumb from '../subcomponents/Breadcrumb';
import StationsSeriesTable from './StationsSeriesTable';
import StationsFilter from './StationsFilter';
import EpisodesList from './EpisodesList';
import LoaderSimple from '../subcomponents/LoaderSimple';
import VideoSetEdit from '../Destinations/VideoSetEdit';
import SeriesEdit from '../Destinations/SeriesEdit';
import EditModal from '../subcomponents/EditModal';

import {
  API_VIDEOSET_RENAME,
  API_DESTINATIONS_ASSIGNMENTS,
  API_STATIONS,
  API_STATIONS_SERIES_EDIT,
  API_VIDEOSET_ADDNEW,
  API_VIDEOSETBLOCK_ADDNEW,
  API_VIDEOSET_DUPLICATE,
  API_VIDEOSET_ASSIGNMENTS,
  API_STATIONS_FIND_SERIES
} from '../../js/Configuration';

import {
  apirequest,
  debounce,
  getAuthData,
  isAdmin
} from '../../js/Utilities';

import {
  initAddNewEp,
  initAddNewSeries,
  initEditSeries,
  initEpisodes,
  saveSeasonEp
} from '../../actions/StationsUtils';

import './css/StationView.css';

const StationView = ({params, project}) => {

  const [list, setList] = useState([]);
  const [episodes, setEpisodes] = useState(initEpisodes);
  const [breadcrumbs, setBreadcrumbs] = useState([{
    path: '/stations',
    label: 'Stations'
  }]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [editseries, setEditSeries] = useState(initEditSeries);
  const [addnewseries, setAddNewSeries] = useState(initAddNewSeries);
  const [addNewSeriesTitle, setAddNewSeriesTitle] = useState('');
  const [addnew, setAddNew] = useState(initAddNewEp);

  const addNewModalRef = useRef(null);
  const editSeriesRef  = useRef(null);
  const viewEpisodesRef = useRef(null);
  const episodesListRef = useRef(null);

  const fd = new FormData(); // We'll reuse the FormData object in this component.
  fd.set('token', getAuthData('token')); // Set the user token.

  const {stationID} = params;

  useEffect(() => {
    if(!Object.keys(meta).length) {
      getStationMeta();
    }

    if(!list.length) {
      getSeries();
    }
  }, [meta, list]);

  const getSeries = () => {
    fd.set('id', params.stationID);
    fd.set('isStation', 1);

    apirequest(API_DESTINATIONS_ASSIGNMENTS, {body: fd}, (data) => {
      if(data.length) {
        setList(data);
      } else {
        setList([0])
      }
      setFiltered(data);
      setLoading(false);
    })
  }

  const getStationMeta = () => {
    fd.set('id', stationID);

    apirequest(API_STATIONS, {body: fd}, (data) => {
      if(!data.length) return;
      const slice = [{
        path: `/stations/${data[0].id}`,
        label: data[0].station
      }];

      setBreadcrumbs([...breadcrumbs].concat(slice));
      setMeta(data[0])
    });
  }

  const filterSeries = (domEvent) => {
    if(domEvent.target.value === '') {
      setFiltered([...list]);
      return;
    }

    fd.set('keyword', domEvent.target.value);
    fd.set('station', stationID);

    apirequest(API_STATIONS_FIND_SERIES, {body: fd}, (data) => {
      setFiltered(data);
    });
  }

  const onViewEpisodes = (domEvent) => {
    const epState = {
      ...episodes,
      meta: {
        id: domEvent.target.dataset.episode,
        title: domEvent.target.dataset.setname
      }
    };

    setEpisodes(epState);

    if(episodesListRef?.current) {
      episodesListRef.current.showModal();
    }
  }

  const onEpisodesClose = (domEvent) => {
    if(episodesListRef?.current) {
      episodesListRef?.current.close()
    }
    setEpisodes(initEpisodes);
  }

  const onEpisodeDuplicate = (domEvent) => {
    domEvent.preventDefault();

    fd.set('id', domEvent.target.elements.id.value);

    apirequest(API_VIDEOSET_DUPLICATE, {body: fd}, (data) => {
      if(data) {
        window.location = `/stations/episode/${data}`;
      }
    });
  }

  const onBatchSave = (episodesList) => {
    if(!episodesList.length) return;

    episodesList.forEach((ep) => {
      saveSeasonEp(ep.vsb_id, ep.season, ep.episode, (data) => {
        setEpisodes(initEpisodes);
      });
    });
  }

  const onBatchEdit = (domEvent) => {
    const epState = {...episodes};
    setEpisodes({...episodes, mode: 'edit'});
  }

  const onAddEpisode = (domEvent) => {
    if (viewEpisodesRef?.current) {
      viewEpisodesRef.current.showModal();
    }

    setAddNew({
      ...addnew,
      setId: domEvent.target.dataset.series,
    });
  }

  const onAddEpisodeChange = (domEvent) => {
    setAddNew({...addnew, title: domEvent.target.value});
  }

  const onAddEpisodeSave = (domEvent) => {
    fd.set('setID', addnew.setId);
    fd.set('title', addnew.title);

    apirequest(API_VIDEOSETBLOCK_ADDNEW, {body: fd}, (data) => {
      if(data.result === 'success') {
        setAddNew(initAddNewEp);
        if(viewEpisodesRef?.current) {
          viewEpisodesRef.current.close();
        }
        showCreatedEpisode(data.blockID);
      } else {
        alert(data.result);
      }
    });
  }

  const onAddEpisodeClose = (dialogRef) => {
    console.log(dialogRef)
    setAddNew(initAddNewEp);
    addNewModalRef?.current.close();
  }

  const onAddSeries = (domEvent) => {
    setAddNewSeries({
      ...addnewseries,
      mode: 'series',
      setId: domEvent.target.dataset.series
    });

    if(addNewModalRef?.current) {
      addNewModalRef.current.showModal();
    }
  }

  const onAddSeriesInput = (domEvent) => {
    const {value} = domEvent.target;
    setAddNewSeriesTitle(value);
  }

  const onAddSeriesSave = (domEvent) => {
    domEvent.preventDefault();

    fd.set('name', addNewSeriesTitle);
    fd.set('destination', stationID);

    apirequest(API_VIDEOSET_ADDNEW, {body: fd}, (data) => {
      if(data.result === 'success') {
        setAddNewSeries({error: null});
        setAddNewSeriesTitle('');
        showCreatedSeries(data);
      } else {
        alert(data.result);
      }
    });
  }

  const onAddSeriesClose = () => {
    if(addNewModalRef?.current) {
      addNewModalRef.current.close();
    }

    setAddNewSeries(initAddNewSeries);
  }

  const onEditSeriesTitle = (domEvent) => {
    const {dataset} = domEvent.target;

    setEditSeries({
      ...editseries,
      series_id: dataset.episode,
      value: dataset.setname
    });

    if(editSeriesRef?.current) {
      editSeriesRef.current.showModal();
    }
  }

  const onEditSeriesTitleInput = (domEvent) => {
    setEditSeries({
      ...editseries,
      value: domEvent.target.value
    });
  }

  const onEditSeriesTitleClose = (dialogRef) => {
    
    setState((state) => {
      const combinedstate = {
        ...editseries,
        error: null,
        value: '',
        series_id: null,
        open: false
      };

      return { editseries: combinedstate }
    }, () => { dialogRef.current.close(); } );
  }


  const updateSeriesTitleInList = (id, title) => {
    const newlist = [...list];
    // Find the current object to update...

    const current = newlist.findIndex((item) => item.setID == id);
    newlist[current].setName = title;
 
    return newlist;
  }

  const onEditSeriesTitleSave = () => {

    fd.set('id', editseries.series_id);
    fd.set('title', editseries.value);

    apirequest(API_STATIONS_SERIES_EDIT, {body: fd}, (response) => {
      if(!response.success) {
        const newlist = updateSeriesTitleInList(editseries.series_id, editseries.value);
        setList(newlist);
        setEditSeries(initEditSeries);
      } else {
        throw new Error('Something went wrong. Please contact an administrator.');
      }
    });
  }

  const showCreatedEpisode = (episodeid) => {
    window.location = `/stations/episode/${episodeid}`;
  }

  const showCreatedSeries = (data) => {
    // Instead of redirecting to the /series/:id view, 
    // Let's go directly to the blank episode.
    fd.set('id', data.setID);

    apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd}, (resp) => {
      window.location = `/stations/episode/${resp[0].vsb_id}`;
    });
  }

  const makeEpisodesModal = () => {
    return (
      <EpisodesList
        {...episodes}
        modalRef={episodesListRef}
        onClose={onEpisodesClose}
        breadcrumbs={breadcrumbs}
        onDuplicateEpisode={onEpisodeDuplicate}
        onBatchEdit={onBatchEdit}
        onBatchSave={onBatchSave} />
    );
  }

  const makeAddNewEpisodeModal = () => {
    return (
      <VideoSetEdit
        apiURL='api/videosetrename'
        id="VideoSetEdit"
        modalRef={viewEpisodesRef}
        title={addnew.modalTitle}
        labelText={addnew.labelText}
        open={addnew.open}
        value={addnew.title}
        onInput={onAddEpisodeChange}
        onSubmit={onAddEpisodeSave}
        onClose={onAddEpisodeClose}
        error={addnew.error} />
    );
  }

  const makeAddNewModal = () => {
    return (
      <SeriesEdit
        {...addnewseries}
        modalRef={addNewModalRef}
        id="destinations_new_set_name"
        apiURL={API_VIDEOSET_RENAME}
        onInput={onAddSeriesInput}
        onSubmit={onAddSeriesSave}
        onClose={onAddSeriesClose}
        onReset={onAddSeriesClose}
      />
    );
  }

  const makeEditSeriesModal = () => {
    return (
      <EditModal id="stations-editseries"
        modalRef={editSeriesRef}
        onSubmit={onEditSeriesTitleSave}
        onClose={onEditSeriesTitleClose}
        onInput={onEditSeriesTitleInput}
        apiURL="/"
        labelText="Edit a new name for this series"
        title="Change series title"
        value={editseries.value} />
    );
  }

  const isLoaded = () => {
    if(loading) {
      return <LoaderSimple open={true} />;
    } else {
      return (
        <div>
          <StationsFilter onFilterType={filterSeries} />
          <StationsSeriesTable
            modalRef={editSeriesRef}
            header={['Series', 'Actions']}
            rows={filtered}
            onViewEpisodes={onViewEpisodes}
            onAddEpisode={onAddEpisode}
            onEditSeriesTitle={onEditSeriesTitle}
          />
        </div>
      );
    }
  }

  const makeNewSeriesButton = () => {
    let btn = null;
    if(isAdmin(project)){
      btn = (
        <div>
          <button
            type="button"
            className="btn btn--action"
            onClick={onAddSeries}>Add New Series</button>
        </div>
      );
    }
    return btn;
  }

  return (
    <div className="stations__view stations__view--withbreadcrumb">
      <header>
        <Breadcrumb items={breadcrumbs} ariaLabel="Stations navigation" />
        <div>
          <h1>Series for <b>{meta.station}</b></h1>
          {makeNewSeriesButton()}
        </div>
      </header>
      {isLoaded()}
      {makeEpisodesModal()}
      {makeAddNewEpisodeModal()}
      {makeAddNewModal()}
      {makeEditSeriesModal()}
    </div>
  );
}

export default (props) => (
    <StationView
        {...props}
        params={useParams()}
    />
);

