/**
 * PleaseAddToAva.jsx
 * A no-framework meter input
 */

import React from 'react';
import { MESSAGE_ADD_TO_AVA } from '../../js/Configuration';

export const PleaseAddToAva = (props) => {
  return <p className="form__instructions" hidden={ props.hidden }>{ MESSAGE_ADD_TO_AVA }</p>;
}
