/**
 * DestinationsToggleView.js
 * Toggles between list and grid views on Destinations, Stations
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import './css/DestinationsToggleView.css';

export const DestinationsToggleView = (props) => {
  return (
    <div id="DestinationsToggleView">
      <b>View:</b>
      <form id="DestinationsToggleViewForm">
        <div>
          <input disabled={props.disabled} type="radio" value="1" name="DestinationsToggleViewInput" checked={props.useGrid === true} onChange={props.onChange} id="DestinationsToggleViewInput__grid" />
          <label htmlFor="DestinationsToggleViewInput__grid">Grid</label>
        </div>

        <div>
          <input disabled={props.disabled} type="radio" value="0" name="DestinationsToggleViewInput" checked={props.useGrid === false} onChange={props.onChange} id="DestinationsToggleViewInput__list" />
          <label htmlFor="DestinationsToggleViewInput__list">List</label>
        </div>
      </form>
    </div>
  );
}
