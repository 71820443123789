/**
 * SwitchProjects.jsx
 */
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';

import { CloseButton } from './CloseButton';
import { Project } from '../Login/Project';

import SwitchProjects from './SwitchProjects';
import { ProjectIcons } from '../../js/ProjectIcons';

import { getAuthData } from '../../js/Utilities';

import './css/SwitchProjectsMenu.css';

const SwitchProjectsMenu = ({open, onClose}) => {

  const isOpen = classNames({ 'open': open });

  let menu = null;

  if(getAuthData('permissions').length) {
    const projects = getAuthData('permissions')
      .filter((pj) => pj.name !== 'User Admin')
      .map((pj) => {
      const ico = ProjectIcons[pj.icon];
      return <Project path={`/${pj.path}`} name={pj.name} icon={ico} key={uniqueId()} />
    });

    menu = (
      <nav id="SwitchProjectsMenu" className={ isOpen } hidden={!open} aria-labelledby="SwitchProjectsMenuHeading">
        <CloseButton color="#fff" className="switch__project__ctrl" onClick={onClose} title="Close Projects menu" />
        <h3 id="SwitchProjectsMenuHeading">Switch Projects</h3>
        { projects }
      </nav>
    );
  }
  return menu;
}

export default SwitchProjectsMenu;