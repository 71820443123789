/**
 * VideoSetRundates
 * View run dates or playlists for a particular video set
 */
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { useParams, redirect } from 'react-router-dom';

import Breadcrumb from '../subcomponents/Breadcrumb';
import LoaderSimple from '../subcomponents/LoaderSimple';
import DestinationsPlaylistTable from './DestinationsPlaylistTable';
import { CreateRunDate } from './CreateRunDate';

import {
  API_VIDEOSETBLOCK_ADDNEW
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
} from '../../js/Utilities';

import {
  duplicatePlaylist,
  getRunDates,
  getVideoSetBreadcrumbs
} from '../../actions/DestinationsUtils';

import './css/DestinationVideoSets.css';

const VideoSetRundates = ({
  params,

}) => {
  const initCreateState = {
    open: false,
    date: '',
    mode: 'add',
    playlistID: null,
    title: ''
  }

  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [crumbTrail, setCrumbTrail] = useState([]);
  const [create, setCreate] = useState(initCreateState);

  const createRunDateRef = useRef(null);

  useEffect(() => {

    if(!episodes.length) {
      getTheRunDates();
    }

    if(!crumbTrail.length) {
      getVideoSetBreadcrumbs(params.id, setBreadcrumbs);
    }
  }, [create, params, episodes]);

  const getTheRunDates = () => {
    const {id} = params;

    getRunDates(id, (response) => {
      setCreate({
        ...create,
        title: create.title
      });
      setEpisodes(response);
      setLoading(false);
    });
  }

  const duplicate = (id) => {
    duplicatePlaylist(id, (data) => {
      window.location = `/destinations/playlist/${data}`;
    });
  }

  const onCreateSave = (domEvent = null) => {
    if(domEvent) {
      domEvent.preventDefault();
    }
    const fd = new FormData();
    fd.set('setID', params.id);
    fd.set('date', create.date);
    fd.set('title', '');
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_ADDNEW, {body: fd}, onCreateRundateResponse);
  }

  const onCreateRundateResponse = (response) => {
    const {
      result,
      blockID,
      set_title
    } = response;

    setCreate({
      ...create,
      mode: result,
      playlistID: blockID,
      title: set_title
    });
  }

  const onCreateChange = (domEvent) => {
    const {value} =  domEvent.target;
    setCreate({...create, date: value});

    if(value == '') {
      onCreateSave();
    }
  }

  const onCreateOpen = () => {
    if(createRunDateRef.current) {
      createRunDateRef.current.showModal();
    }
  }

  const onCreateRundateClose = () => {
    setCreate({
      ...create,
      mode: 'add',
      playlistID: null,
      date: ''
    });

    if(createRunDateRef.current) {
      createRunDateRef.current.close();
    }
  }

  const setBreadcrumbs = (response) => {
    const {
      station_id,
      Station,
      Video_Set
    } = response;

    const items = [
      {
        path: '/destinations',
        label: 'Destinations'
      },
      {
        path: `/destinations/videosets/${station_id}`,
        label: Station,
        state: {
          destination: Station
        }
      },
      {
        label: Video_Set
      }
    ];

    setCrumbTrail(items);
    setCreate({ ...create, title: Video_Set });
  }

  const makeBreadcrumbs = () => {
    return <Breadcrumb items={crumbTrail} />;
  }

  let body;
  if(loading) {
    body = <LoaderSimple open={true} />
  } else {
    body = (
      <DestinationsPlaylistTable
        header={['Run Dates', 'Duration', 'Actions']}
        rows={episodes}
        onDuplicate={duplicate} />
    )
  }

  return (
    <div>
      {makeBreadcrumbs()}
      <header id="destination__header">
        <h1>Run dates for <q>{create.title}</q></h1>
        <button type="button"
          className="btn btn--action"
          onClick={onCreateOpen}
        >Create New Playlist</button>
      </header>
      {body}
      <CreateRunDate
        {...create}
        modalRef={createRunDateRef}
        videoset={params.id}
        onClose={onCreateRundateClose}
        onSubmit={onCreateSave}
        onChange={onCreateChange}
        />
    </div>
  );
}

export default (props) => (
    <VideoSetRundates
        {...props}
        params={useParams()}
    />
);

