/**
 * AmzSongAddSongs.js
 */
import React from 'react';
import ModalDialog from '../subcomponents/ModalDialog';

import { AmzSongAddSongsForm } from './AmzSongAddSongsForm';
import AmzSongAddSongsSave from './AmzSongAddSongsSave';
import LoaderSimple from '../subcomponents/LoaderSimple';

const AmzSongAddSongs = (props) => {
  let body = <LoaderSimple open />;
  const {
    working,
    modalRef,
    mode,
    open,
    onClose,
  } = props;

  if (mode === 'save') {
    body = <AmzSongAddSongsSave {...props} />;
  } else {
    body = <AmzSongAddSongsForm {...props} />;
  }

  return (
    <ModalDialog
      ref={modalRef}
      id="AmzSongAddSongs"
      title="Add Songs"
      onClose={onClose}
    >
      <h2>Add songs to cluster</h2>
      {body}
    </ModalDialog>
  );
}

export default AmzSongAddSongs;
