/**
 * Tools.jsx
 * Tools for calculating statistics about lists of video IDs
 */

import React from 'react';
import ImportSpreadsheet from './Import/ImportSpreadsheet';
import { getSheetId } from '../js/Utilities';

import '../css/Tools.css';
import './subcomponents/css/fancy-radiobuttons.css';

const Tools = (props) => {

  const [calcs, setCalcs] = React.useState({
    total: 0,
    median: 0,
    mean: 0,
    mode: [],
    min: 0,
    max: 0
  });
  
  const [sheetObj, setSheetObj] = React.useState({
    url: '',
    sheet: 'Sheet1',
    operation: '' // can be an empty string, 'averages', or 'durations'
  });
  
  const [loading, setLoading] = React.useState(false);

  const onSubmit = (domEvent) => {
    domEvent.preventDefault();

    const {
      operation,
      url,
      sheet
    } = sheetObj;

    const view = operation === 'durations' ? 'durationmode' : 'averages';
    window.location = `/tools/${view}/${getSheetId(url)}/${sheet}`;
  }

  return (
    <div className="tools__start">
      <h1>Video List Calculator</h1>
      <ImportSpreadsheet
        loading={loading}
        onSubmit={onSubmit}
        {...sheetObj} />
    </div>
  );
}

export default Tools;