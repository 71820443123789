/**
 * ToolsAddToGoogleSheet
 */

import React from 'react';
import LoaderSimple from '../subcomponents/LoaderSimple';

export const ToolsAddToGoogleSheet = (props) => {
  const text = props.loading ? <LoaderSimple open={true} /> : 'Add Statistics to Google Sheet';
  return <button type="button" onClick={props.onClick} className="btn btn--action ToolsAddToGoogleSheet">{text}</button>;
}
