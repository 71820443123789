/**
 * RunDate.jsx
 * Display the RunDate on the the Destinations/edit page
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  format,
  isValid
} from 'date-fns';

import {
  NULL_DATE_TEXT
} from '../../js/Configuration';

import './css/RunDate.css';

const RunDate = ({
  date=null,
  className,
  onInputChange
}) => {

  const cssClass = classNames(
    {'destinations__run__date': true},
    className
  );

  let runDateFull = NULL_DATE_TEXT;
  if(date) {
    const input = new Date(date); // Convert the input date to a Date object.

    // Create a new date object
    const d = new Date(
      input.getUTCFullYear(),
      input.getUTCMonth(),
      input.getUTCDate()
    );

    if(isValid( d )) {
      runDateFull = format( d, 'PP' );
    }
  }

  return (
    <div className={cssClass}>
      <p>
        <label htmlFor="destination_rundate">Set or Change Run Date: </label>
        <input
          type="date"
          id="destination_rundate"
          name="destination_rundate"
          defaultValue={date}
          onChange={onInputChange}
        />
      </p>
      <p className="destinations__run__date__full">{runDateFull}</p>
    </div>
  );
}

RunDate.propTypes = {
  date: PropTypes.string,
  onInputChange: PropTypes.func,
  className: PropTypes.string
};

export default RunDate;
