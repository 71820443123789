/**
 * UserAdmin.js
 * Displays the UserAdmin interface.
 */

import React from 'react';
import {
  useEffect,
  useState
} from 'react';

import LoaderSimple from './subcomponents/LoaderSimple';
import AddUserForm from './subcomponents/UserAdmin/AddUserForm';
import UserAdminTable from './subcomponents/UserAdmin/UserAdminTable';
import EditUserModal from './subcomponents/UserAdmin/EditUserModal';
import ModalAlert from './subcomponents/ModalAlert';

import {
  API_USER_PW_RESET,
  API_USERS_ADD,
  API_USERS_DEACTIVATE,
  API_USERS_DELETEUSER,
  API_USERS_GETALL,
  API_USERS_REACTIVATE,
  API_USERS_UPDATEUSER,
  API_PROJECTS,
  USER_PERMISSIONS_LEVELS,
  MESSAGE_USER_DELETE_CONFIRM,
  MESSAGE_ERROR_GENERAL,
  API_LANGUAGES,
  API_REGIONS
} from '../js/Configuration';

import {
  apirequest, 
  getAuthData,
  randomString,
  mapToObject,
  showLoader
} from '../js/Utilities';

import * as Ua from '../actions/UserAdminUtils';

import '../css/UserAdmin.css';

const UserAdmin = (props) => {

  const [ state, setState ] = useState(Ua.initialState);
  const userPermissionLevel = getAuthData('permissions');

  useEffect(() => {
    getProjects();
    getRegions();
    getLanguages();
    getUsers();
  });

  const getProjects = () => {
    if( state.projects.length ) return;

    const fetchCancel = new AbortController();
    const signal = fetchCancel.signal;

    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_PROJECTS, {body: fd, signal}, (data) => {
      setState({
        ...state,
        projects: [...data]
      });
    });
    return () => {
      if( state.projects.length ) {
        fetchCancel.abort();
      }
    }
  }

  const getRegions = () => {
    if( state.regions.length ) return;

    const fetchCancel = new AbortController();
    const signal = fetchCancel.signal;

    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_REGIONS, {body: fd, signal}, (data) => {
      const makeRegions = () => data.map((r) => {
        return { value: r.id, label: r.name };
      });
      setState({...state, regions: makeRegions() });
    });

    return () => {
      if( state.regions.length ) {
        fetchCancel.abort();
      }
    }
  }

  const getLanguages = () => {
    if( state.languages.length ) return;

    const fetchCancel = new AbortController();
    const signal = fetchCancel.signal;

    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_LANGUAGES, {body: fd, signal}, (data) => {
      const makeLanguages = () => data.map((l) => {
        return { value: l.id, label: l.name };
      });

      setState({
        ...state, 
        languages: makeLanguages(),
      });
    });

    return () => {
      if( state.languages.length ) {
        fetchCancel.abort();
      }
    };
  }

  const getUsers = () => {
    if( state.usersList.length ) return;

    const fetchCancel = new AbortController();
    const signal = fetchCancel.signal;

    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_USERS_GETALL, {body: fd, signal}, (data) => {
      setState({
        ...state,
        usersList: [...data],
        loading: false,
      });
    });

    return () => {
      if( state.usersList.length ) {
        fetchCancel.abort();
      }
    }
  }

  const toggleAddUser = () => {
    setState(Ua.setStateOnAddUserToggle(state));
  }

  const deleteUser = (domEvent) => {
    domEvent.preventDefault();

    let isSure = window.confirm(MESSAGE_USER_DELETE_CONFIRM);

    if(isSure) {
      const fd = new FormData(domEvent.currentTarget);
      fd.set('token', getAuthData('token'));

      apirequest(API_USERS_DELETEUSER, {body: fd}, (response) => {
        if(response.success) {
          setState(Ua.setStateAfterDeleteUser(state, response));
        } else {
          alert(MESSAGE_ERROR_GENERAL);
        }
      });
    }

    setState((state) => {
      return {
        ...state,
        editUser: {
          ...state.editUser,
          open: false
        }
      }
    });
  }

  const deactivateUser = (domEvent) => {
    domEvent.preventDefault();

    const uuid = domEvent.currentTarget.uuid.value;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('uuid', uuid);

    apirequest(API_USERS_DEACTIVATE, {body: fd}, (response) => {
      setState(Ua.setStateAfterDeactivateUser(state, uuid, response));
    });
  }

  const reactivateUser = (domEvent) => {
    domEvent.preventDefault();

    const uuid = domEvent.currentTarget.uuid.value;

    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('uuid', uuid);

    apirequest(API_USERS_REACTIVATE, {body: fd}, (response) => {
      setState(Ua.setStateAfterReactivateUser(state, uuid, response));
    });
  }

  const makeEditModal = () => {
    return (
      <EditUserModal
        currentUserName={state.editUser.form.username?.valueOf()}
        {...state.editUser}
        regions={[...state.regions]}
        languages={[...state.languages]}
        onClose={onEditUserClose}
        onSubmit={onEditUserSubmit}
        onChange={onEditUserChange}
        onDeleteUser={ deleteUser }
        onReset={ onEditUserReset }
        onRequestPassword={resetPassword} />
    );
  }

  const onAddUserChange = (domEvent) => {
    const target = domEvent.target
    const field  = target.name;
    const value  = target.value;

    let stateSetter = Ua.setStateOnAddUserTextBoxChange(state, field, value);

    if(['radio','checkbox'].indexOf(target.type) > -1) {
       stateSetter = Ua.setStateOnAddUserToProject(state, target);
    }

    setState(stateSetter);
  }

  const onEditUserOpen = (domEvent) => {
    const uuid = domEvent.target.dataset.uuid;
    const ns = Ua.setStateOnEditUserOpen(state, uuid);
    setState(ns);
  }

  const onEditUserClose = () => {
    setState(Ua.setStateOnEditUserClose(state));
  }

  const onEditUserChange = (domEvent) => {
    const target = domEvent.target;
    let stateSetter;

    if(['user_language','user_region'].indexOf(target.name) > -1) {
      stateSetter = Ua.setStateOnChangeRegionLang(state, target);
    }

    if(['radio','checkbox'].indexOf(target.type) > -1) {
      stateSetter = (state) => Ua.setStateOnChangeUserProjects(state, target);
    }

    if(['text','email'].indexOf(target.type) > -1) {
      stateSetter = (state) => Ua.setStateOnEditUserTextBox(state, target.name, target.value);
    }

    setState(stateSetter);
  }

  const onEditUserReset = (domEvent) => {
    domEvent.preventDefault();
    setState(Ua.resetEditUserState);
  }

  const onEditUserSubmit = (domEvent) => {
    domEvent.preventDefault();
    setState(showLoader(state));
    updateUser();
  }

  const updateUser = () => {
    const fd = new FormData();
    /*
      Convert the project_access Map to an Object since we can't use
      JSON.stringify with a map.
    */
    const projects = mapToObject(state.editUser.form.project_access);

    // The + forces this to be an integer, which we'll treat as a boolean server-side
    fd.set('enabled', +state.editUser.form.enabled);
    fd.set('uuid', state.editUser.form.uuid);
    fd.set('username', state.editUser.form.user_name);
    fd.set('email', state.editUser.form.email);
    fd.set('displayname_first', state.editUser.form.displayname_first);
    fd.set('displayname_second', state.editUser.form.displayname_second || '');
    fd.set('token', getAuthData('token'));
    fd.set('region', state.editUser.form.user_region);
    fd.set('lang', state.editUser.form.user_language || '');
    fd.set('projects', JSON.stringify(projects));

    apirequest(API_USERS_UPDATEUSER, {body: fd}, (response) => {
      let stateSetter;
      if(response.status === 'failed') {
        stateSetter = Ua.setStateOnUpdateError(state,response);
      } else {
        stateSetter = Ua.setStateAfterUserEdit(state, response);
      }
      setState(stateSetter);
    });

  }

  const addUser = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData();

    /*
      Convert the project_access Map to an Object since we can't use
      JSON.stringify with a map.
    */
    const projects = mapToObject(state.addUser.form.project_access);

    fd.set('username', state.addUser.form.username);
    fd.set('email', state.addUser.form.email);
    fd.set('displayname_first', state.addUser.form.displayname_first);
    fd.set('displayname_second', state.addUser.form.displayname_second || '');
    fd.set('token', getAuthData('token'));
    fd.set('region', state.addUser.form.user_region);
    fd.set('lang', state.addUser.form.user_language || '');
    fd.set('projects', JSON.stringify(projects));

    apirequest(API_USERS_ADD, {body: fd}, (response) => {
      if(response.result === 'success') {
        setState((state) => Ua.setStateAfterAddUser(state, response), resetUserStateAfterAdd);
      } else {
        alert(response.message);
      }
    });
  }

  const resetUserStateAfterAdd = () => {
    setState(Ua.reSetFormState, getUsers);
  }

  const closeStatusMessage = () => {
    setState(Ua.setStateCloseStatusMsg);
  }

  const findUserByUUID = (uuid) => {
    return state.usersList.find((item) => item.uuid === uuid);
  }

  const resetPassword = (domEvent) => {
    const uuid = domEvent.target.elements.user_uid.value;
    const userobj = findUserByUUID(uuid);

    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('id', uuid);
    fd.set('tok', getAuthData('token'));

    apirequest(API_USER_PW_RESET, {body: fd}, (response) => {
      setState((state) => Ua.setStateOnPasswordReset(state, response));
    });
  }

  const onCloseAlert = (domEvent) => {
    setState(Ua.setStateOnCloseErrorModal(state))
  }

  const onFilterChange = (domEvent=null) => {
    if(!domEvent) return;

    const field = domEvent.target.name;
    const value = domEvent.target.value;

    setState(Ua.setStateOnFilterChange(state, field, value));
  }

  if(state.loading) {
    return <LoaderSimple open={true} />;
  }

  return (
    <div>
      <AddUserForm
        title="Add a new user"
        onSubmit={addUser}
        onChange={onAddUserChange}
        onToggle={toggleAddUser}
        onMessageClose={closeStatusMessage}
        projects={[...state.projects]}
        languages={[...state.languages]}
        regions={[...state.regions]}
        {...state.addUser}
      />

      <h1>Manage Users</h1>
      <UserAdminTable
        {...state.editUser}
        projects={[...state.projects]}
        filtering={{...state.filtering}}
        userPermissionLevel={userPermissionLevel}
        users={state.usersList}
        onEditUser={onEditUserOpen}
        onDelete={deleteUser}
        onDeactivate={deactivateUser}
        onReactivate={reactivateUser}
        onRequestPassword={resetPassword}
        onFilterChange={onFilterChange} />

        {makeEditModal()}

      <ModalAlert id="UserAdminAlertMessage" open={state.alert.open} title={state.alert.title} {...state.alert} onClose={onCloseAlert}>
        <p style={{'fontSize': '1.8rem'}} dangerouslySetInnerHTML={{__html: state.alert.message}} />
      </ModalAlert>

    </div>
  );
}


export default UserAdmin;