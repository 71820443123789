/**
 * DestinationsVideoSetsToggleSort.jsx
 * Toggles between list and grid views on Destinations, Stations
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import ToggleSwitch from '../subcomponents/ToggleSwitch';

export const DestinationsVideoSetsToggleSort = ({
  selectedIndex,
  disabled,
  onChange
}) => {
  return (
    <ToggleSwitch
      label="Sort video sets: "
      selectedIndex={selectedIndex}
      options={['A - Z', 'Z - A', 'Newest first']}
      disabled={disabled}
      onChange={onChange}
      name="DestinationsVideoSetsToggleSort"
    />
  )
}
