/**
 * AmzSongAddSongs.jsx
 */
import React from 'react';
import { MESSAGE_ERROR_TOO_MANY_URLS } from '../../js/Configuration';

export const AmzSongAddSongsForm = ({
  maxIds,
  value,
  cluster,
  onChange,
  onClose,
  onSubmit
}) => {
  const txtAreaRef = React.useRef(null);

  const submitDisabled = () => {
    let disabled = true;
    if(txtAreaRef.current) {
      disabled = !txtAreaRef.current.value;
    }
    return disabled;
  }

  return (
    <form id="AmzSongAddSongs__form" onSubmit={onSubmit}>
      <p hidden={!maxIds} className="form__instructions form__error">
        <i>
          <b>{MESSAGE_ERROR_TOO_MANY_URLS[0]}</b>
          {` ${MESSAGE_ERROR_TOO_MANY_URLS[1]} `}
          {MESSAGE_ERROR_TOO_MANY_URLS[2]}
        </i>
      </p>
      <p>
        <input
          type="hidden"
          id="clusterid"
          name="clusterid"
          defaultValue={cluster}
          onChange={()=>{}}
        />
        <label htmlFor="name">Amazon ASIN IDs or Song URLs</label>
        <textarea
          id="name"
          name="name"
          className="form-control"
          aria-describedby="cluster_note"
          defaultValue={value}
          onChange={onChange}
          rows="6"
          ref={txtAreaRef}
        ></textarea>
        <span className="form__instructions aria__description" id="cluster_note">
          Use valid Amazon URLs or song ASIN IDs. URLs or IDs should be 
          separated either by a comma (,) or new line. If using URLs, include 
          the protocol for each, e.g.: <code>http://</code> or
          <code>https://</code>. Limit 15.
        </span>
      </p>
      <div className="button__group">
        <button
          type="submit"
          className="btn btn--action"
          disabled={submitDisabled()}
        >Save</button>
        <button type="button"
          className="btn btn--light"
          onClick={onClose}
        >Cancel</button>
      </div>
    </form>
  );
}



    
