/**
 * Search.jsx
 * Search form for Top Channels by Destination, Top Videos by Destination
 * Author: Webinista, Inc 3/24/18.
 */
  
import React from 'react';
import DateInput from '../subcomponents/DateInput';
import SelectMenu from '../subcomponents/SelectMenu';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ReportSearch = ({
  name,
  onChangeHandler,
  onModalClickHandler,
  onSubmitHandler,
  destinations=[],
  cssClass='',
  apiURL='',
  labelText='NEEDS A LABEL',
  value='',
  limit='500'
}) => {

  const defaultDate = () => {
    const theDate = new Date();
    const month = (theDate.getMonth().toString().length < 2) ? `0${theDate.getMonth() + 1}` : theDate.getMonth();
    const date = (theDate.getDate().toString().length < 2) ? `0${theDate.getDate()}` : theDate.getDate();
    return `${theDate.getFullYear()}-${month}-${date}`;
  }

  const makeOptions = (inputArray) => {
    return inputArray.map((item, index) => {
      return {
        label: item.dName,
        value: `${item.dID}`
      };
    });
  }

  const cssClasses = classNames(
    cssClass,
    {'reports__destination__search': true}
  );

  return (
    <div>
      <form id="reports_destination_search" action={apiURL} method="POST" className={cssClasses} onSubmit={onSubmitHandler}>
        <input type="hidden" id="limit" name="limit" value={limit} />
        <SelectMenu
          firstEmpty={true}
          labelText={labelText}
          name={name}
          onChange={onChangeHandler}
          options={makeOptions(destinations)}
          isRequired={true}
          cssClass="destination-select" />
        <DateInput name="start" labelText="Start of date range:" isRequired={false} />
        <DateInput name="end" labelText="End of date range:" isRequired={false} />
        <button type="submit" className="btn btn--action">Search</button>
      </form>
    </div>
  )
}

ReportSearch.propTypes = {
  apiURL: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onChangeHandler: PropTypes.func,
  onSubmitHandler: PropTypes.func,
  destinations: PropTypes.arrayOf(PropTypes.shape({
    dID: PropTypes.string,
    dName: PropTypes.string
  })),
  value: PropTypes.string.isRequired
};

export default ReportSearch;
