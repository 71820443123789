/**
 * ToggleSwitch.js
 * Toggles between two options
 * See PropTypes definitions at the end of this file for parameters
 */

import React, { useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';

import './css/ToggleSwitch.css';


const ToggleSwitch = ({
  className,
  data,
  label='NEEDS LABEL TEXT',
  options = [],
  disabled = false,
  name = '',
  selectedIndex = 0,
  onChange = ()=>{}
}) => {
  // eslint-disable-next-line react/destructuring-assignment

  /* eslint-disable */
  const cssClass = classNames({
    'toggle__switch': true,
  }, className);
  /* eslint-enable */

  const opts = options.map((opt, index) => {
    const fieldId = useId();

    return (
      <div key={uniqueId()}>
        <input
          type="radio"
          disabled={disabled}
          value={+index}
          name={name || uniqueId('ToggleSwitchInput__')}
          onChange={onChange}
          checked={+index == +selectedIndex}
          id={fieldId}
        />
        <label htmlFor={fieldId}>{opt}</label>
      </div>
    );
  });

  return (
    <div id={`${name}Control`} className={cssClass}>
      <form
        id={`${name}Form`}
        className="toggle__switch__form"
        data-data={data}
        onChange={onChange}
      >
        <fieldset>
          <div className="toggle__switch__group">
            <legend>{label}</legend>
            {opts}
          </div>
        </fieldset>
      </form>
    </div>
  );
}

ToggleSwitch.propTypes = {
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export default ToggleSwitch;
