/**
 * SelectMenuControlled.jsx
 * A no-framework dropdown menu. This sends the entire DOMEvent to the onChange
 * instead of just the value. It's also a CONTROLLED rather than an UNCONTROLLED
 * component.
 *
 * Second version is due to some legacy code. Easier to add this component than
 * to rewrite SelectMenu and everywhere it's used to make it controlled.
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import SelectOption from './SelectOption';
import classNames from 'classnames';

import '../../css/SelectMenu.css';

const SelectMenuControlled = ({
  onChange,
  name='',
  cssClass='',
  disabled=false,
  hidden=false,
  labelText='NEEDS LABEL TEXT',
  id=null,
  options=[],
  firstEmpty=true,
  value='',
  isRequired=false,
  size='1',
  multiple=false,
  labelledby=null,
  describedBy=null
  
}) => {

  const makeOptions = (arrayOfObjects) => arrayOfObjects.map((o, x) => (
    <SelectOption value={o.value} key={x.toString()} label={o.label} />
  ));

  const cssClasses = classNames('ava-input-select', cssClass);

  const opts = makeOptions(options);
  
  if(firstEmpty) {
    opts.unshift(<option key="selectmenufirstempty"></option>);
  }

  return (
    <div className={cssClasses}>
      <label htmlFor={id || name}>{labelText}</label>
      <select
        disabled={disabled}
        hidden={hidden}
        required={isRequired}
        defaultValue={value}
        id={id || name}
        name={name}
        onChange={onChange}
        size={size}
        multiple={multiple}
        aria-labelledby={labelledby}
        aria-describedby={describedBy}>
        {opts}
      </select>
    </div>
  );
}

SelectMenuControlled.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  firstEmpty: PropTypes.bool.isRequired,
  size: PropTypes.string,
  multiple: PropTypes.bool
};

export default SelectMenuControlled;
