/**
 * AmzGenreImport.jsx
 */
import React, {
  useState,
  useRef
} from 'react';

import {
  API_IMPORT_EMAIL,
  API_CLUSTERS_GENREIMPORT,
} from '../../js/Configuration';

import ActionMessageDisplay from '../subcomponents/ActionMessageDisplay';
import LoaderSimple from '../subcomponents/LoaderSimple';
import ModalDialog from '../subcomponents/ModalDialog';

import uniqueId from 'lodash.uniqueid';
import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

import './css/AmzGenreAddNew.css';

const AddForm = (props) => {
  let body = <LoaderSimple open = {true} />;

  if( !props.loading ) {
    body = (
      <form onSubmit={ props.onSubmit }>
        <div><label htmlFor="url">Google Sheet URL</label></div>
        <div>
          <input
            type="hidden"
            id="parent"
            name="parent"
            value={props.parent}
            onChange={()=>{}}
          />
          <input
            disabled={!props.email}
            type="url"
            id="url"
            name="url"
            value={props.url}
            aria-describedby="url_note"
            onChange={props.onChange}
            required
          />
          <button type="submit" className="btn btn--action">Import</button>
        </div>
        <p id="url_note">Make sure that you've shared this spreadsheet with{' '}
        the email address <b className="nobreak">{props.email}</b>.</p>
      </form>
    );
  }

  if( !props.loading && props.completed.length ) {
    const li = props.completed.map((line) => <li key={uniqueId()}>{line}</li>);
    body = (
      <ul className="bulleted-disc">
        {li}
      </ul>
    );
  }

  return (
    <ModalDialog id="AmzGenresImport" ref={props.modal} onClose={props.onClose}>
      <h2>Import Genres</h2>
      { body }
    </ModalDialog>
  )
};

const AmzGenreImport = (props) => {
  const modalRef = useRef(null);
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState([]);

  const onOpen = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    
    apirequest(API_IMPORT_EMAIL, { body: fd }, (response) => {
      setEmail(response.email);
      if (modalRef.current) {
        modalRef.current.showModal();
      }
    });
  }

  const onClose = () => {
    if (modalRef.current) {
      modalRef.current.close();
    }
    if(props.onClose) {
      props.onClose()
    }
  }

  const onSubmit = (domEvent) => {
    domEvent.preventDefault();
    const {url} = domEvent.target;

    setLoading(true);
    
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    fd.set('url', url);
    fd.set('parent', props.parent);

    apirequest(API_CLUSTERS_GENREIMPORT, { body: fd }, (response) => {
      if(response.error) {
        window.alert(response.message);
      } else {
        setCompleted(response);
        onClose();
      }
    });
  }


  return (
    <div>
      <div style={{'textAlign':'right', 'marginLeft': '1rem'}}>
        <button
          type="button"
          className="btn btn--action"
          onClick={onOpen}
        >Import Genres</button>
      </div>
      <AddForm
        loading={loading}
        completed={completed}
        email={email}
        onClose={onClose}
        onSubmit={onSubmit}
        modal={modalRef}
      />
    </div>
  );
};

export default AmzGenreImport