/**
 * DestinationsSelect
 * Used on VideoInfo only to select a destination
 */

import React, {useState, useEffect, useRef} from 'react';
import SelectMenu from '../subcomponents/SelectMenu';

import {
  format,
  isValid
} from 'date-fns';

import DatePicker from 'react-datepicker';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

import {
  API_DESTINATIONS_ADDNEW,
  API_DESTINATIONS_ASSIGNMENTS,
  API_DESTINATIONS_LIST,
  API_VIDEOSET_ASSIGNMENTS,
  API_VIDEOSET_ADDNEW,
  API_VIDEO_ADDTOSET,
  API_VIDEOSETBLOCK_ADDNEW,
  API_VIDEOSETBLOCK_INFO,
  NULL_DATE_TEXT,
  INTERSTITIAL_INDICATOR,
  MESSAGE_ADD_TO_AVA
} from '../../js/Configuration';

import '../Destinations/css/DestinationsSelect.css';
import 'react-datepicker/dist/react-datepicker.css';

const DestinationsSelect = ({
  disabled,
  mode,
  videoId,
  onSubmit,
  onVideosLoad
}) => {

  const [destinations, setDestinations] = useState({
    disabled: false,
    options: [],
    mode: 'select',
    value: null,
    name: null
  });

  const [videosets, setVideoSets] = useState({
    options: [],
    mode: 'select',
    value: null,
    disabled: true,
    deleteDisabled: true,
    editDisabled: true,
    name: null
  });

  const [videosetblocks, setVideoSetBlocks] = useState({
    options: [],
    mode: 'select',
    value: null,
    newDate: null,
    datePickerDisabled: false,
    disabled: true,
  });

  const [disableAddVideoToDestination, setDisableAddVideoToDestination] = useState(true);

  const newBlockRef = useRef(null);
  const newDestinationRef = useRef(null);

  useEffect(() => {
    if(!destinations.options.length) {
      getDestinations();
    }
  }, [destinations]);

  const getDestinations = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_DESTINATIONS_LIST, {body: fd}, onDestinationsLoaded);
  }

  const getVideoSets = (id) => {
    const fd = new FormData();

    fd.set('id', id);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_ASSIGNMENTS, {body: fd}, (data) => {
      onVideoSetsLoaded(data);
    });
  }

  const getBlockList = (value) => {
    if ( !value ) return;
    if( videosetblocks.mode === 'create' ) return;

    /* Only make this request if the mode is 'select' */

    const fd= new FormData();
    fd.set('id', value);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSET_ASSIGNMENTS, { body: fd }, ( response ) => {
      onBlockListLoaded( response );
    });
  }

  const getVideosForBlock = () => {
    const fd = new FormData();
    fd.set('id', videosetblocks.value);
    fd.set('token', getAuthData('token'));
    apirequest(API_VIDEOSETBLOCK_INFO, {body: fd}, (response) => {
      setDisableAddVideoToDestination(false);
    });
  }

  const onDestinationsLoaded = (data = []) => {
    const dl = [
      { value: '', label: " " },
      { value: 'create', label: "Create Destination" }
    ];

    const list = data.map((item) => {
      return { value: item.dID, label: item.dName };
    });

    setDestinations({
      ...destinations,
      disabled: false,
      options: dl.concat(list)
    });
  }

  const onVideoSetsLoaded = (data = []) => {
    const vsl = [
      { value: '', label: " " },
      { value: 'create', label: "Create Video Set" }
    ];

    let videoSetList = [];

    if(data.length) {
      videoSetList = data.map((item) => {
        return { value: item.setID, label: item.setName };
      });
    }

    setVideoSets({
      ...videosets,
      options: vsl.concat(videoSetList),
      disabled: false
    });
  }

  const onCreateDestinationCancel = (event) => {
    setDestinations({
      ...destinations,
       mode: 'select',
       value: null,
       name: null
    })
  }

  const onDestinationSelect = (domEvent) => {
    const {value} = domEvent.target;

    setDestinations({
      ...destinations,
      value: value,
      mode: (value === 'create') ? 'create' : 'select'
    });

    if(value === 'create') {
      setVideoSets({
        ...videosets,
        disabled: true
      })
    } else {
      getVideoSets(value);
    }
  }

  const onCreateDestinationChange = (domEvent) => {
    const {value} = domEvent.target;

    setDestinations({
      ...destinations,
      name: value
    });
  }

  const onCreateDestinationSubmit = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData();

    fd.set('name', destinations.name);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_ADDNEW, {body: fd}, (response) => {

      if(response.result === 'success') {
        const newDestination = {
          value: response.d_id,
          label: response.d_name
        };

        setDestinations({
          ...destinations,
          value: response.d_id,
          mode: 'select',
          options: [...destinations.options].concat(newDestination)
        });
        
        getVideoSets(response.d_id);
        
      } else {
        alert(response.message);
      }
    });
  }

  const onCreateVideoSetCancel = () => {
    setVideoSets({
      ...videosets,
      mode: 'select',
      value: null,
      name: null
    });
  }

  const onVideoSetSelect = (domEvent) => {
    const {value} = domEvent.target;

    setVideoSets({
      ...videosets,
      value: value,
      deleteDisabled: value ? false : true,
      mode: (value === 'create') ? 'create' : 'select'
    });

    if(value === 'create') {
      setVideoSetBlocks({
        ...videosetblocks,
        disabled: true
      });
    } else {
      getBlockList(value);
    }
  }

  const onCreateVideoSetChange = (domEvent) => {
    const {value} = domEvent.target;
    
    setVideoSets({
      ...videosets,
      name: value
    });
  }

  const onCreateVideoSetSubmit = (domEvent) => {
    domEvent.preventDefault();
 
    const fd = new FormData();
    fd.set('name', videosets.name);
    fd.set('destination', destinations.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSET_ADDNEW, {body: fd}, (response) => {
      if( response.result !== 'success') {
        alert(response.message);
        return false;
      }

      setVideoSets({
        ...videosets,
        mode: 'select',
        value: response.setID,
        options: [
          ...videosets.options,
          {
            value: `${response.setID}`, // convert to a string for consistency with other objects
            label: response.setName,
          }
        ],
        disabled: false
      });

      getBlockList(response.setID);
    });
  }

  const onVideoBlockSelect = (domEvent) => {
    const {value} = domEvent.target;

    setVideoSetBlocks({
      ...videosetblocks,
      value: value,
      mode: (value === 'create') ? 'create' : 'select'
    });

    getVideosForBlock(value);
  }

  const onBlockListLoaded = (data = []) => {
    const bl = [
      { value: '', label: ' '},
      { value: 'create', label: "Create Instance/Episode" },
    ];

    const blocks = data.map((item) => {
      const {
        vsb_datetimetorun,
        vsb_id,
        contains_interstitial
      } = item;

      let rundate = '';
      let interstitial = '';

      if (
        (vsb_datetimetorun === null) || 
        (vsb_datetimetorun === '0000-00-00')
      ) {
        rundate = NULL_DATE_TEXT;
      } else {
        const date = new Date( vsb_datetimetorun );
        rundate = format(date, 'PP', { timeZone: 'Etc/UTC' });
      }

      if(contains_interstitial) {
        interstitial = ` ${INTERSTITIAL_INDICATOR} `;
      }

      return {
        value: vsb_id,
        label: `${rundate} ${interstitial}`
      };
    });

    setVideoSetBlocks({
      ...videosetblocks,
      options: bl.concat(blocks),
      deleteDisabled: false,
      disabled: false
    });
  }

  const onCreateBlockSubmit = (domEvent) => {
    domEvent.preventDefault();

    const {newDate} = videosetblocks;

    let yyyymmdd = '0000-00-00';
    let dateTxt = NULL_DATE_TEXT;

    if(isValid(newDate)) {
      yyyymmdd = format(newDate, 'yyyy-MM-dd', { timeZone: 'Etc/UTC' });
      dateText = format(newDate, 'PP', { timeZone: 'Etc/UTC' })
    }

    const fd = new FormData();
    fd.set('date', yyyymmdd);
    fd.set('setID', videosets.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_ADDNEW, {body: fd}, (response) => {
      if(response.result === 'success') {

        const newblock = [{
          value: response.blockID,
          label: dateText
        }];

        setVideoSetBlocks({
          ...videosetblocks,
          options: [...videosetblocks.options].concat(newblock),
          mode: 'select',
          value: response.blockID,
          datePickerDisabled: false
        });

      } else {
        alert(response.message);
      }
    });
  }

  const onCreateBlockDateSelect = (value) => {
    setVideoSetBlocks({
      ...videosetblocks,
      newDate: value
    });
  }

  const blockNoDateSelected = (domEvent) => {
    const {checked} = domEvent.target;

    setVideoSetBlocks({
      ...videosetblocks,
      datePickerDisabled: checked,
      newDate: ''
    });
  }

  const onCreateBlockDateReset = () => {
    setVideoSetBlocks({
      ...videosetblocks,
      mode: 'select',
      value: '',
      newDate: null
    });
  }

  const onCloneVideo = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('ytid', videoId);
    fd.set('setid', videosets.value);
    fd.set('blockid', videosetblocks.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_ADDTOSET, { body: fd }, (response) => {
      if(response.success) {
        alert("Video added to destination.")
      }
    });
  }


  const makeDestinations = () => {
    if(destinations.mode === 'create') {
      return (
        <form id="destinations__new_dest"
          className="destinations__create__new"
          onReset={onCreateDestinationCancel}
          onSubmit={onCreateDestinationSubmit}
        >
          <div className="form__label_btn_group">
            <label
              htmlFor="destination_name form__access__label"
            >Enter a name for this destination</label>
            <input type="text" className="form-control"
              onChange={onCreateDestinationChange}
              id="destination_name"
              name="destination_name"
              maxLength="50"
            />
            <button type="submit"
              disabled={!destinations.name}
              className="btn btn--action"
            >Create</button>
            <button type="reset" className="btn btn-light">Cancel</button>
          </div>
        </form>
      );
    }

    return (
      <div>
        <SelectMenu
          cssClass="video__modal__destinations__select"
          name="DestinationsSelect__destination"
          id="DestinationsSelect__destination"
          options={destinations.options}
          labelText="Select Destination"
          value={destinations.value}
          disabled={destinations.disabled || disabled }
          onChangeHandler={onDestinationSelect}
        />
      </div>
    );
  }

  const makeVideoSets = () => {
    if(videosets.mode === 'create') {
      return (
        <form
          id="destinations__new_videoset"
          className="destinations__create__new"
          onReset={onCreateVideoSetCancel}
          onSubmit={onCreateVideoSetSubmit}>
          <div className="form__label_btn_group">
            <label htmlFor="list_name">Enter a name for this video set</label>
            <input
              type="text"
              className="form-control"
              id="list_name"
              name="list_name"
              maxLength="100"
              onChange={onCreateVideoSetChange}
            />
            <button type="reset" className="btn btn-default">Cancel</button>
            <button type="submit"
              disabled={!videosets.name}
              className="btn btn-default"
            >Create</button>
          </div>
        </form>
      );
    }

    return (
      <div>
         <SelectMenu
          cssClass="video__modal__destinations__select"
          name="DestinationsSelect__videoset"
          id="DestinationsSelect__videoset"
          options={videosets.options}
          labelText="Select Video Set"
          value={videosets.value}
          disabled={videosets.disabled}
          onChangeHandler={onVideoSetSelect}
        />
      </div>
    );
  }

  const makeVideoBlocks = () => {
    if( videosetblocks.mode === 'create' ) {
      const {
        newDate,
        datePickerDisabled
      } = videosetblocks;

      return (
        <form
          method="dialog"
          className="dateCreateContainer destinations__date_select"
          onSubmit={onCreateBlockSubmit}
          onReset={onCreateBlockDateReset}>
          <DatePicker
            disabled={datePickerDisabled}
            id="create_date"
            selected={ newDate }
            onChange={onCreateBlockDateSelect}
            dateFormat="PP" />
          <span className="fancy__chex">
            <input
            type="checkbox" id="no_date" name="create_date_none"
            onChange={blockNoDateSelected} />
            <label htmlFor="no_date">Unscheduled (No date)</label>
          </span>
          <span className="button__group">
            <button type="submit" className="btn btn-sm">Create</button>
            <button type="reset" className="btn btn-sm">Cancel</button>
          </span>
        </form>
      );
    }

    return (
      <div>
        <SelectMenu
          cssClass="video__modal__destinations__select"
          name="DestinationsSelect__rundate"
          id="DestinationsSelect__rundate"
          options={videosetblocks.options}
          labelText="Select Video Set Run Date"
          value={videosetblocks.value}
          disabled={!videosets.value || !destinations.value }
          onChangeHandler={onVideoBlockSelect}
          disabled={videosetblocks.disabled}
        />
      </div>
    );
  }

  return (
    <section className="destinations__component destinations__component--videos">
      <h5>Add Video to Destination:</h5>
      <p hidden={ !disabled } className="form__instructions">{ MESSAGE_ADD_TO_AVA }</p>
      {makeDestinations()}
      {makeVideoSets()}
      {makeVideoBlocks()}
      <form
        id="DestinationsSelect"
        action="/"
        method="post"
        onSubmit={onCloneVideo}
      >
        <button
          id="DestinationsSelect"
          type="submit"
          className="btn btn--action"
          disabled={ disableAddVideoToDestination }
        >Add Video To Destination</button>
      </form>
    </section>
  );
}

export default DestinationsSelect;