/**
 * DestinationsPlaylistTitle.js
 * Display title, duplicate, and delete controls for a Destination playlist.
 */
import React from 'react';

import {
  format,
  isValid
} from 'date-fns';

import {
  NULL_DATE_TEXT_STATIONS,
  INTERSTITIAL_INDICATOR,
  INTERSTITIAL_NOTE
} from '../../js/Configuration';

const DestinationsPlaylistTitle = (props) => {
  const {
    contains_interstitial,
    vsb_datetimetorun,
    Video_Set,
    duplicated,
    onDuplicate,
    onSetDelete,
    isAdmin
  } = props;

  let interstit = null;
  if(contains_interstitial) {
    interstit = <b className="icon" title="">{INTERSTITIAL_INDICATOR}</b>;
  }
  
  let dte = NULL_DATE_TEXT_STATIONS;
  if( vsb_datetimetorun && isValid(new Date(vsb_datetimetorun)) ) {
    dte = format(new Date(vsb_datetimetorun), 'PP');
  }

  let deletebutton = null;
  if (isAdmin) {
    deletebutton = (
      <div>
        <button type="button" className="btn btn--action" onClick={onSetDelete}>Delete this playlist</button>
      </div>
    );
  }

  return (
    <div className="playlist__title__bar">
      <h1 className="playlist__title">
        {Video_Set}
        {' '}
        (
        {dte.replace(/[[|\]]/g, '')}
        )
        {interstit}
        { duplicated ? ' [copy] ' : '' }
      </h1>
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <div>
          <button type="button" className="btn btn--action" onClick={onDuplicate}>Duplicate this playlist</button>
          <b hidden={!duplicated} className="action--alert">Playlist duplicated</b>
        </div>
        {deletebutton}
      </div>
    </div>
  );
}

export default DestinationsPlaylistTitle;