/**
 * GroupsChannelInfo.js
 * Displays a model on the GroupsPage info when the user clicks to get more
 * info about the channel.
 * 
 * propsis similar to, but not the same as the ChannelInfo component. Needed to be able to both retain the
 * existing channel group data _and_ update it. Since that was significantly harder to do with a shared component
 * We're using a separate one.
 * 
 * Let's try to combine those components at some point in the future. In the goals of Gittin'er done, we're making two files. 
 * - webinista
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from './ModalDialog';

import { 
  isValid,
  format
} from 'date-fns';

import {
  API_GROUPS_ALLGROUPS,
} from '../../js/Configuration';

import { 
  apirequest,
  getAuthData,
  numberFormat
} from '../../js/Utilities';

import { getGroupNamesFromData } from '../../actions/ChannelsUtils';
import { setStateOnGetGroups } from '../../actions/GroupsUtils';

import '../../css/GroupsChannelInfo.css';


const GroupsChannelInfo = (props) => {
  const DATE_FORMAT = 'PP';

  const [groupList, setGroupList] = useState([])

  useEffect(() => {
    if(!groupList.length) {
      getAllGroups();
    }
  
  }, [groupList]);
 
  const getAllGroups = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, (data) => {
      setGroupList(data)
    });
  }
  
  const makeMenuItems = (arrayOfItems=[]) => {
    let menu = null;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={index.toString()} value={item.group_id}>{item.group_name}</option>
      });
    }
    return menu;
  }

  const showChannelPage = () => {
    const chann_id = props.youtubeid;
    window.open(`https://www.youtube.com/channel/${chann_id}/videos`, '_blank');
  }

  const formatCategoryNames = () => {
    if(props.channel_category_names !== undefined) {
      return props.channel_category_names.sort().join(', ');
    }
  }

  const removeFromGroup = (domEvent) => {
    domEvent.preventDefault();
    if(props.onRemoveFromGroup) {
      props.onRemoveFromGroup(
        domEvent.target.elements.groupID.value,
        domEvent.target.elements.channelID.value,
      )
    }
  }

  const added   = new Date( props.channel_dateaddedtosystem );
  const updated = new Date( props.channel_datelastupdated );

  const hasAdded   = isValid( added )   ? format( added, DATE_FORMAT ) : null;
  const hasUpdated = isValid( updated ) ? format( updated, DATE_FORMAT ) : null;

  return (
    <ModalDialog ref={props.modalRef} id="GroupsChannelInfoModal" onClose={props.onClose}>
      <h2>{props.channel_title}</h2>
      <div className="GroupsChannelInfo__logo">
        <a href={`https://www.youtube.com/channel/${props.channel_youtubechannelid}/videos`} target="_blank" className="channel__thumb" rel="noopener noreferrer">
          <img src={ props.channel_thumbnail } alt={`${props.channel_title} Thumbnail`} />
        </a>
      </div>

      <section className="channel__meta_data">
        <h4>Channel Description:</h4>
        <div className="description" contentEditable="true" dangerouslySetInnerHTML={{ __html: props.channel_description }} />
      </section>
      
      <section className="channel__meta_data">
        <h4>Channel Stats:</h4>
        <ul>
          <li><span>Date Added to System:</span><span className="itemsBadge">{ hasAdded }</span></li>
          <li><span>Date Updated in System:</span><span className="itemsBadge">{ hasUpdated }</span></li>
          <li><span>Subscribers:</span><span className="itemsBadge">{ numberFormat( props.channel_subscribercount )}</span></li>
          <li><span>Video Count:</span><span className="itemsBadge">{ numberFormat( props.channel_videocount )}</span></li>
          <li><span>Curated Video Count:</span><span className="itemsBadge">{ numberFormat(props.curated_video_count )}</span></li>
          <li><span>Categories / Topics:</span><span className="itemsBadge">{ formatCategoryNames() }</span></li>
          <li><span>Tags:</span><div className="tags listItemScrollingTextBox">{ props.channel_customtags }</div></li>
          <li><span>Groups:</span><div className="tags listItemScrollingTextBox">{ getGroupNamesFromData( props ) }</div></li>
          <li><span>Keywords:</span><div className="tags listItemScrollingTextBox">{ props.channel_keywords }</div></li>
        </ul>
      </section>

      <form method="POST" action="api/channeldelete" className="form__action_control" onSubmit={props.onRemoveFromSystem}>
        <input type="hidden" name="id" id="delete_channel" defaultValue={props.channel_youtubechannelid} />
        <button type="submit" className="btn btn--action">Remove Channel From System</button>
      </form>

      <div className="form__action_control">
        <form method="post" onChange={props.onGroupChangeHandler}>
          <label htmlFor="modal_group_select">Remove Channel from Group:</label>
          <div>
            <select className="form-control" name="modal_group_select" id="modal_group_select" disabled value={+props.selectedGroup || ''}>
              {makeMenuItems(groupList)}
            </select>
          </div>
        </form>

        <div id="add_channel_button" className="centerTxt marginTop6px button__group">
          <form action="api/channelremovefromgroup" method="POST" onSubmit={removeFromGroup}>
            <input type="hidden" name="channelID" defaultValue={props.channel_youtubechannelid} />
            <input type="hidden" name="groupID" defaultValue={props.selectedGroup} />
            <button type="submit" className="btn btn--action" name="add_or_remove" value="add">Remove Channel from Group</button>
          </form>
        </div>
      </div>
      
      <form method="post" action="api/channelsettags" onSubmit={props.onUpdateTags} className="form__action_control">
        <div className="form__input_btn">
          <label htmlFor="channel_tags">Add Manual Tags:</label>
          <input id="channel_tags" type="text" name="tags" defaultValue={ props.channel_customtags || '' } placeholder="No Custom Tags, Add Comma Separated Tags" />
          <button type="submit" className="btn btn--action">Add Tags to Channel</button>
        </div>
      </form>
    </ModalDialog>
  );
}


GroupsChannelInfo.propTypes = {
  channelData: PropTypes.any, // TODO: Change propsto a shape
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRemoveFromGroup: PropTypes.func,
  onRemoveFromSystem: PropTypes.func,
  onGroupChangeHandler: PropTypes.func,
  onUpdateTags: PropTypes.func,
  onAddHandler: PropTypes.func,
  selectedGroup: PropTypes.any
};

export default GroupsChannelInfo;