/**
 * AmzReportsDetails.js
 */
import React, {useState, useEffect} from 'react';
import {
  Link,
  Route,
  useParams,
  useLocation
} from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { AmzSongSearch } from './AmzSongSearch';
import { AmzSongAddSongs } from './AmzSongAddSongs';
import { NoSuchReport } from './NoSuchReport';

import {
  API_GENRES_REPORTS,
  API_CLUSTERS_SINGLEGENRE,
  API_GENRES_SONGDUPES,
  API_GENRES_ARTISTDUPES
} from '../../js/Configuration';

import {
  apirequest,
  capitalize,
  getAuthData
} from '../../js/Utilities';

import {
  download
} from '../../actions/ReportsUtils';

import {
  amzUrl,
  setStateOnClusterTitle,
  setStateOnReportResponse,
  setStateOnSongTitleDupes,
  setStateOnArtistNameDupes
} from '../../actions/AmzUtils';

import './css/AmzReports.css';

const AmzReportsDetails = ({params}) => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [meta, setMeta] = useState({});

  useEffect(() => {
    if(params.report) {
      resetStateOnSongTitleDupes();
    }

  }, [params]);

  const resetStateOnSongTitleDupes = () => {
    const {report} = params;
   
    setLoading(true);
    
    if (report === 'title') {
      getSongTitleDupes();
    } else {
      getArtistNameDupes();
    }
  }

  const getSongTitleDupes = ()  => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', params.id);

    if(Object.hasOwn(params, 'genre')) {
      fd.set('genre', params.genre);
    }

    apirequest(API_GENRES_SONGDUPES, {body: fd}, (response) => {
      setLoading(false);
      setMeta({ name: response.title });
      setResults(response.genres);
    });
  }

  const getArtistNameDupes = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', params.id);

    apirequest(API_GENRES_ARTISTDUPES, {body: fd}, (response) => {
      setLoading(false);
      setMeta({ name: response.name });
      setResults(response.genres);
    });
  }

  const makeBackLink = () => {
    let link = null;

    const {report} = params;
    
    if(
      Object.hasOwn(location, 'state')
    ) {
      link = (
        <p>
          <Link
            to={`/music/reports/${report}/`}
            className="amz__reports_backlink"
          >Return to report</Link>
        </p>
      );
    }
    return link;
  }

  const makeTable = () => { 
    let body = <LoaderSimple open={true} />;
    if(results.length) {
      body = (
        <table className="reports__table__results">
          {makeTableHeader()}
          {makeTableRows()}
        </table>
      )
    }
    return body;
  }

  const makeTableHeader = () => {
    if(!results.length) return null;
    const fields = Object.keys(results[0]);
    const th = fields
              .filter((f) => ['sASIN','aASIN','gid'].indexOf(f) < 0)
              .map((f) => <th key={uniqueId()}>{capitalize(f)}</th>);

    return (
      <thead>
        <tr>
          {th}
        </tr>
      </thead>
    );
  }

  const makeTableRows = () => {
    if(!results.length) return null;
    const results = results;

    const rows = results.map((f, index, results) => {
      const cols = Object.keys(f).filter((f) => ['gid'].indexOf(f) < 0);

      const td = cols
                 .map((c) => {
                    let cell = f[c];

                    if(c === 'Sub-genre' && f.hasOwnProperty('gid')) {
                      cell = <Link to={{pathname: `/music/songs/${f.gid}`}}>{f['Sub-genre']}</Link>
                    }

                    return <td key={uniqueId()}>{cell}</td>
                  });

      return (
        <tr key={uniqueId()}>
          {td}
        </tr>
      );
    });

    return (
      <tbody>
        {rows}
      </tbody>
    );
  }

  const makeDisplay = () => {
    return (
      <div>
        <header>
          <h1>Details for <em>{meta.name}</em></h1>
          {makeBackLink()}
          <p className="button__group" style={{'textAlign': 'right'}}>
            <button
              type="button"
              className="btn btn--action"
              onClick={makeCSV}
              disabled={!results.length}
            >Export CSV</button>
          </p>
        </header>
        {makeTable()}
      </div>
    )
  }

  const makeCSV = () => {
    if(!results.length) return;

    const exclude = ['gid','sASIN','aASIN'];
    let csv = [];

    const headers = Object.keys(results[0]);
    csv[0] = headers
            .filter((h) => exclude.indexOf(h) < 0)
            .map((h) => `"${capitalize(h)}"`);

    const rws = results.map((r) => {
      const fields = Object.keys(r).filter((r) => exclude.indexOf(r) < 0);
      return fields.map((f) => `"${r[f]}"`);
    });

    const filename = `${capitalize(params.report)}_Report_for_${meta.name}`;
    download(csv.concat(rws), filename, 'Duplicate', '');
  } 

  return makeDisplay();
}

export default (props) => (
  <AmzReportsDetails
    {...props}
    params={useParams()}
  />
);
