import React from 'react';
import classNames from 'classnames';

import ChannelsList from '../ChannelsList';
import SearchDropDown from './SearchDropDown';
import SearchInChannels from './SearchInChannels';
import SearchMoreOptions from './SearchMoreOptions';
import SearchDateRange from './SearchDateRange';
import RegionLangDisplay from '../subcomponents/RegionLangDisplay';

import { format } from 'date-fns';
import './css/SearchForm.css';

import { API_SEARCH } from '../../js/Configuration';

import {
  ORDER_BY,
  TOPIC_ID,
  VIDEO_CAT,
  EVENT_TYPE,
  VIDEO_DEF,
  VIDEO_DIM,
  VIDEO_DUR,
  VIDEO_TYPE,
  SAFE_SEARCH,
  VIDEO_LIC,
  LANGUAGES,
  REGIONS,
  VIDEO_EMBEDDABLE
} from '../../js/SearchConfiguration';

import { removeAuthData } from '../../js/Utilities';


import {
  initChannelsList,
  initCriteria,
  initGroups,
  initLanguage,
  getChannels,
  getGroups,
  getRegions,
  getLanguages,
} from '../../actions/SearchUtils';

const SearchForm = ({onSubmit, onChannelSubmit, onReset}) => {

  const [groups, setGroups] = React.useState(initGroups);
  const [advancedOpen, setAdvancedOpen] = React.useState(false);

  const [criteria, setCriteria] = React.useState(initCriteria);

  const [language, setLanguage] = React.useState(initLanguage);

  const [languages, setLanguages] = React.useState([]); 
  const [regions, setRegions] = React.useState([]);
  const [international, setInternational] = React.useState({
    code: 'YTE',
    name: 'All Regions',
    flag: {
      flag: '🌐',
      region: ''
    }
  });
  
  const [channelpicks, setChannelPicks] = React.useState([]);

  const modalRef = React.useRef(null);

  React.useEffect(() => {
    if(!(groups.length > 1)) {
      getGroups((response) => {
        const grps = response.map((g) => {
          return {
            label: g.group_name,
            value: g.group_id,
            channelList: g.channelList
          }
        });
        setGroups([...initGroups].concat(grps));
      });
    }

    if(!regions.length) {
      getRegions((response) => {
        const reg = response.map((r) => {
          return {
            label: r.name,
            value: r.code
          };
        });
        setRegions(reg);
      });
    }

    if(!languages.length) {
      getLanguages((response) => {
        const langs = response.map((l) => {
          return {
            label: l.name,
            value: l.code
          };
        });
        setLanguages(langs);
      });
    }
  }, [groups]);

  const advanced = classNames({
    'search__form__advanced': true,
    'search__form__advanced--open': advancedOpen
  });

  const {
    publishedBefore,
    publishedAfter,
    regionCode, 
    relevanceLanguage,
    videoCategoryId,
    channelSearchGroup='', 
    channelsearch=[],
    order='',
    topicId='',
    videoDefinition = '',
    videoDimension='',
    videoDuration='', 
    videoType='', 
    videoEmbeddable='',
    videoLicense='',
    eventType='',
    safeSearch='',
  } = criteria;

  // FIX THESE
  const before = new Date(publishedBefore);
  const after  = new Date(publishedAfter);

  const onShowOptions = () => {
    setAdvancedOpen(true);
  }

  const onToggleOptions = () => {
    setAdvancedOpen(!advancedOpen);
  }

  const onChange = (domEvent) => {
    const {target} = domEvent;
    const {name, value} = target;

    setCriteria({
      ...criteria,
      [name]: value
    });

    if(name === 'searchGroup') {
      const channelGroup = groups.find((g) => +g.value === +value);
      if(Object.hasOwn(channelGroup, 'channelList')) {
        const channels = channelGroup.channelList.map((c) => c.youtubeid);
        setChannelPicks(channels);
        onChannelSubmit(channels);
      } else {
        setChannelPicks([]);
        onChannelSubmit([]);
      }
    }
  }

  const onResetter = (domEvent) => {
    removeAuthData('userSearch');
    setLanguage(initLanguage);
    setCriteria(initCriteria);
    setChannelPicks([]);
    onChannelSubmit([]);

    if(onReset) {
      onReset();
    }
  }

  const onShowChannels = () => {
    if(modalRef.current) {
      modalRef.current.showModal();
    }
  }

  const onHideChannels = () => {
    if(modalRef.current) {
      modalRef.current.close();
    }
  }

  const onChannelSearchSubmit = (domEvent) => {
    domEvent.preventDefault();

    const {elements} = domEvent.target;

    const picks = Array.from(elements)
                    .filter((p) => p.type === 'checkbox')
                    .filter((p) => p.checked)
                    .map((p) => p.value);

    setChannelPicks(picks);
    onChannelSubmit(picks);
    onHideChannels();
  }

  let rangeStart = '';
  if( !isNaN( after.getTime() )) {
    rangeStart = format( after.getTime(), 'yyyy-MM-dd' );
  }

  let rangeEnd = '';
  if( !isNaN( before.getTime() )) {
    rangeEnd = format( before.getTime(), 'yyyy-MM-dd' ) ;
  }

  return (
    <>
      <RegionLangDisplay
        {...international}
        language={language}
        onClick={onShowOptions}
      />
      <form
        action={API_SEARCH}
        method="POST"
        id="search_form"
        onSubmit={onSubmit}
        onReset={onResetter}
      >
        <div className="form-group form__input_button_group">
          <label htmlFor="q">Enter Search Keywords</label>
          <span>
            <input
              type="search"
              className="bigSearchInputBox"
              name="q"
              id="q"
              defaultValue={criteria.q}
              onChange={onChange} />
          </span>
        </div>

        <div className="search__form__shelf">
          <SearchDropDown
            label="Search in Group"
            options={groups}
            onChange={onChange}
            id="searchGroup"
            value={criteria.searchGroup}
          />
          <SearchInChannels onShowChannels={onShowChannels} />
          <SearchDropDown label="Order By" options={ORDER_BY} onChange={onChange} id="order" value={ order || ''} />
          <SearchDateRange onChange={onChange} afterDate={ rangeStart } beforeDate={ rangeEnd } />
          <SearchDropDown label="Topic ID" options={TOPIC_ID} onChange={onChange} id="topicId" value={ topicId || ''} />
         </div>

        <p
          className="search__form__chancount"
        >Searching in <b>{channelpicks.length}</b> channels
        </p>

        <div className="search__form__shelf show__more">
          <SearchMoreOptions
            open={advancedOpen}
            onClick={onToggleOptions}
          />
        </div>
        <div className={advanced}>
          <SearchDropDown label="Category Search" options={VIDEO_CAT} onChange={onChange} id="videoCategoryId" value={videoCategoryId || ''} />
          <SearchDropDown label="Video Definition" options={VIDEO_DEF} onChange={onChange} id="videoDefinition" value={videoDefinition || ''} />
          <SearchDropDown label="Video Dimensions" options={VIDEO_DIM} onChange={onChange} id="videoDimension" value={videoDimension || ''} />
          <SearchDropDown label="Video Duration" options={VIDEO_DUR} onChange={onChange} id="videoDuration" value={videoDuration || ''} />
          <SearchDropDown label="Video Type" options={VIDEO_TYPE} onChange={onChange} id="videoType" value={videoType || ''} />
          <SearchDropDown label="Video Embeddable" options={VIDEO_EMBEDDABLE} onChange={onChange} id="videoEmbeddable" value={videoEmbeddable || ''}/>
          <SearchDropDown label="Video License" options={VIDEO_LIC} onChange={onChange} id="videoLicense" value={videoLicense || ''}/>
          <SearchDropDown label="Live Event Type" options={EVENT_TYPE} onChange={onChange} id="eventType" value={ eventType || ''}/>
          <SearchDropDown label="Regions" options={regions} onChange={onChange} id="regionCode"  value={regionCode} />
          <SearchDropDown label="Language" options={languages} onChange={onChange} id="relevanceLanguage" value={relevanceLanguage} />
          <SearchDropDown label="Safe Search" options={SAFE_SEARCH} onChange={onChange} id="safeSearch" value={ safeSearch || ''}/>
        </div>
        <span className="search__form__shelf search__form__controls">
          <button type="reset" className="btn">Reset search</button>     
          <button type='submit' id="search_btn" className='btn btn--action'>Search</button>
        </span>
      </form>

      <ChannelsList
        modalRef={modalRef}
        onClose={onHideChannels}
        onSubmit={onChannelSearchSubmit}
      />
    </>
  );
}

export default SearchForm;