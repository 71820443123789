/**
 * ReportsVidSetDestinations
 * View for /reports/display/videosetdestinations
 */

import React from 'react';
import {
  useEffect,
  useState
} from 'react';

import SearchText from './SearchText';
import ReportsResults from './ReportsResults';
import LoaderSimple from '../subcomponents/LoaderSimple';
import { useLocation } from 'react-router-dom';

import {
  API_REPORTS_DESTINATIONSBYVIDSET,
  API_REPORTS_VIDSET_TYPEAHEAD
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

const ReportsVidSetDestinations = (props) => {

  const [videoset, setVideoSet]   = useState('');
  const [videosets, setVideoSets] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(0);

  useEffect(() => {
    window.scrollTo(0,0);
  });

  function inputHandler(domEvent){
    const fd = new FormData();
    fd.set('search', domEvent.target.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_REPORTS_VIDSET_TYPEAHEAD, {body: fd}, (data) => {
      setVideoSets(data.data);
      setResults(data.data.length);
    });
  }

  function searchSubmitHandler(domEvent) {
    domEvent.preventDefault();
    setLoading(true);

    const videoset = videosets.find((vid) => {
      return vid.setName.toLowerCase().includes(domEvent.target.videoset.value.toLowerCase());
    });
  
    const fd = new FormData(domEvent.target);
    fd.set('videoset', videoset.setId);
    fd.set('token', getAuthData('token'));

    apirequest(API_REPORTS_DESTINATIONSBYVIDSET, {body: fd}, (data) => {
      setDestinations(data.data);
      setResults(data.data.length);
      setLoading(false);
    });
  }

  function massageResults(results){
    const res = results.map( (result) => {
      return {
        title: result.setName,
        id: result.setId,
        frequency: result.frequency
      }
    });
    return res;
  }

  function makeDataListInput(results = []){
    if(!results.length) return;
    const res = results.map( (result) => {
      return {
        title: result.setName,
        id: result.setId
      }
    });
    return res;
  }

  if(loading) {
    display = <LoaderSimple open={true} />
  } else {
     display = (
      <ReportsResults
        columnHeadings={['Video set title', 'Frequency']}
        results={massageResults(destinations)}
        count={results}
        downloadURL={`${API_REPORTS_DESTINATIONSBYVIDSET}/csv`}
        videoset={videoset} />
      );
  }

  return (
    <div className="reports__page">
      <h1>{props.params.state.title}</h1>
      <SearchText
        apiURL={API_REPORTS_DESTINATIONSBYVIDSET}
        datalist={makeDataListInput(videosets)}
        id="videoset"
        labelText="Enter a video set or series title:"
        name="videoset"
        onSubmit={searchSubmitHandler}
        onInput={inputHandler}
        limit="50" />
      {display}
    </div>
  );
}

export default (props) => (
  <ReportsVidSetDestinations
      {...props}
      params={useLocation()}
  />
);