/**
 * ChannelsRemoveFromGroup.js
 * /channel/:channelID single channel, page-level component
 */
import React from 'react';

import ChannelGroupDelete from './ChannelGroupDelete';

import './css/ChannelsRemoveFromGroup.css';

const ChannelsRemoveFromGroup = ({
  inGroups=[],
  group_id,
  onGroupDelete,
  channel
}) => {

  const makeBadges = () => {
    if(!inGroups.length) return;

    const badges = inGroups.map((g) => {
      return (
        <ChannelGroupDelete
          key={g.group_id}
          onSubmit={onGroupDelete}
          group={g.group_id}
          channel={channel}
          name={g.group_name} />
      );
    });
    
    return badges;
  }

  return (
    <section id="ChannelsManageGroups__remove">
      <h5>Remove Channel From Groups</h5>
      <div>
        {makeBadges()}
      </div>
    </section>
  );
}

export default ChannelsRemoveFromGroup;
