import React from 'react';

export const NoImageImg = (props) => { 
  const hw = {
    height: '300px',
    width: '300px'
  };
  
  const prps = Object.assign(hw, props);

  return (
    <svg {...prps} viewBox="0 0 300 300" className="NoImageImg">
      <title>No image available</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fillOpacity="0.2" fill="#98C3DD" x="0" y="0" width="300" height="300"></rect>
        <path d="M149.52381,290 C168.417659,290 186.493986,286.305429 203.75279,278.916288 C221.011595,271.527147 235.848111,261.594203 248.262339,249.117456 C260.676567,236.640709 270.57767,221.741294 277.96565,204.419208 C285.353629,187.097123 289.047619,168.927104 289.047619,149.90915 C289.047619,130.891196 285.353629,112.781743 277.96565,95.5807917 C270.57767,78.2587065 260.676567,63.3592905 248.262339,50.8825438 C235.848111,38.4057971 221.011595,28.4728531 203.75279,21.0837119 C186.493986,13.6945706 168.417659,10 149.52381,10 C130.62996,10 112.553633,13.6945706 95.2948289,21.0837119 C78.0360243,28.4728531 63.1995081,38.4057971 50.7852803,50.8825438 C38.3710524,63.3592905 28.4699487,78.2284231 21.0819692,95.4899416 C13.6939897,112.75146 10,130.891196 10,149.90915 C10,168.927104 13.6939897,187.097123 21.0819692,204.419208 C28.4699487,221.741294 38.3710524,236.640709 50.7852803,249.117456 C63.1995081,261.594203 78.0360243,271.527147 95.2948289,278.916288 C112.553633,286.305429 130.62996,290 149.52381,290 Z M67.1214173,205.238095 C56.3420597,188.561848 50.952381,170.364046 50.952381,150.644689 C50.952381,132.629473 55.3731287,115.953226 64.2146242,100.615948 C73.0561198,85.27867 85.0466411,73.1670893 100.186188,64.281206 C115.325735,55.3953226 131.858121,50.952381 149.783345,50.952381 C169.767547,50.952381 187.935003,56.4908425 204.285714,67.5677656 L204.285714,67.5677656 L67.1214173,205.238095 Z M148.911726,250 C129.463979,250 111.414038,244.597519 94.7619048,233.792557 L94.7619048,233.792557 L232.233169,96.6666667 C242.807882,112.934811 248.095238,130.841911 248.095238,150.387965 C248.095238,163.863816 245.481947,176.762998 240.255365,189.085511 C235.028783,201.408023 227.978974,212.000528 219.10594,220.863025 C210.232905,229.725521 199.688579,236.797308 187.472963,242.078385 C175.257347,247.359462 162.403601,250 148.911726,250 Z" fill="#98C3DD" fillRule="nonzero"></path>
      </g>
    </svg>
  )
}
