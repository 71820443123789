/** *
 * Group.js
 * Page component for single group page
 */

import React, {useState, useEffect, useRef} from 'react';
import { Link, useParams } from 'react-router-dom';
import GroupsChannelInfo from './subcomponents/GroupsChannelInfo';
import ChannelsList from './ChannelsList';

import {
  API_CHANNEL_INFO,
  API_CHANNEL_REMOVE,
  API_CHANNEL_SETTAGS,
  API_GROUPS_ADD_CHANNEL_TO_GROUP,
  API_GROUPS_DELETE,
  API_GROUPS_REMOVE_CHANNEL,
  API_GROUPS_CHANNELS_IN_GROUP
} from '../js/Configuration';


import {
  apirequest,
  getAuthData,
} from '../js/Utilities';

import {
  initAllChannels
} from '../actions/GroupsUtils';

const Group = (props) => {
  const {
    params,
    onAddChannelToGroup,
    onGroupChangeHandler
  } = props;

  const {groupId} = params;

  const oneChannelModalRef = useRef(null);
  const channelsListModalRef = useRef(null);

  const [channelList, setChannelList] = useState([]);
  const [channelModal, setChannelModal] = useState({open: false, loading: true})
  const [allChannels, setAllChannels] = useState(initAllChannels);
  const [groupActive, setGroupActive] = useState('n');
  const [groupAdded, setGroupAdded] = useState('');
  const [groupUpdated, setGroupUpdated] = useState('');
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    if(!channelList.length) {
      getChannelsInGroup();
    }
  }, [channelList, allChannels]);

  const onUpdateTags = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));
    fd.set('id', +channelModal.channel_id);

    apirequest(API_CHANNEL_SETTAGS, {body: fd}, (data) => {
      if(oneChannelModalRef?.current) {
        oneChannelModalRef.current.close()
      }
    });
  }

  const getChannelsInGroup = () => {
    if( !Object.hasOwn(params, 'groupId') ) {
      alert('No group ID passed');
      return;
    }

    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', +groupId)
    apirequest(API_GROUPS_CHANNELS_IN_GROUP, {body: fd}, (data) => {
      setGroupActive(data.group_active);
      setGroupAdded(data.group_dateadded);
      setGroupUpdated(data.group_datelastupdated);
      setGroupName(data.group_name);
      setChannelList(data.channelList);
     });
  }

  const getChannelInfo = (domEvent=null) => {
    const {dataset} = domEvent.target;
    const {channelid} = dataset;

    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', channelid)
    apirequest(API_CHANNEL_INFO, {body: fd}, (data) => {
      setChannelModal({
        ...channelModal,
        ...data
      });

      if(oneChannelModalRef?.current) {
        oneChannelModalRef.current.showModal()
      }
    });
  }

  const getChannelBatch = () => {
    if(Object.hasOwn(channelsListModalRef, 'current') && channelsListModalRef.current) {
      channelsListModalRef.current.showModal();
    }
  }

  const onCloseChannelsList = () => {
    if(channelsListModalRef?.current) {
      channelsListModalRef.current.close();
    }
  }

  const onCloseChannelInfo = () => {
    if(oneChannelModalRef?.current) {
      oneChannelModalRef.current.close();
    }
  }

  const onCheckChange = (domEvent) => {
    const {target} = domEvent;
    const {value}  = target;

    if(target?.checked) {
      setAllChannels({
        ...allChannels,
        toAdd: [...allChannels.toAdd, value]
      });
    } else {
      setAllChannels({
        ...allChannels,
        toAdd: allChannels.toAdd.filter(ch => ch !== value )
      });
    } 
  }

  const onChannelFromRemoveGroup = (groupId, channelId) => {
    const {channel_title} = channelList.filter((ch) => ch.youtubeid == channelId)[0];
    const confirmed = window.confirm(`Are you sure that you want to remove ${channel_title} from the group?`);
    if(!confirmed) return;

    const fd = new FormData();
    fd.set('group', groupId);
    fd.set('channel', channelId);
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_REMOVE_CHANNEL, {body: fd}, (response) => {
      if(response.success) {
        const cl = channelList.filter((ch) => ch.youtubeid !== response.channel);
        setChannelList(cl);
      }

      if(oneChannelModalRef?.current) {
        oneChannelModalRef.current.close();
      }
    });
  }

  const onShowChannels = () => {
    getChannelBatch();
  }

  const onChannelAddToGroup = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('group', groupId);
    fd.set('channels', allChannels.toAdd.join(','));
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ADD_CHANNEL_TO_GROUP, {body: fd}, (data) => { 
      setChannelList(data.list);

      if(channelsListModalRef?.current) {
        channelsListModalRef.current.close();
      }
    });
  }

  const onDeleteGroup = () => {
    const confirm = window.confirm(`Confirm that you want to delete "${groupName}" by clicking OK.`);

    if(!confirm) return;

    const fd = new FormData();
    fd.set('id', +groupId);
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_DELETE, {body: fd}, (response) => {
      if(response.success) {
        window.alert(`Group "${groupName}" was deleted.`);
        window.location = `/groups`;
      } else {
        window.alert(`Could not delete ${groupName}.`);
      }
    });
  }

  const onChannelRemoveSystem = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_CHANNEL_REMOVE, {body: fd}, (data) => {
      const deleted_channel = domEvent.target.delete_channel.value;       
  
      const deleted_object = [...channelList].filter((ch) => ch.youtubeid === deleted_channel);

      if( data.status === 'success' ) {
        this.setState((state) => {
          return {
            channelList: [...state.channelList].filter((ch) => ch.youtubeid !== deleted_channel),
            channelModal: {
              ...state.channelModal,
              open: false,
            }
          }
        }, () => {
          alert(`${deleted_object[0].channel_title} was deleted from AVA.`);
        });
      }
    });
  }

  const makeNoChannels = () => {
    return (
      <>
        <h2>No channels in this group.</h2>
        <p><Link to="/groups">Return to Groups page</Link></p>
      </>
    )
  }

  const makeTable = () => {
    const rows = channelList.map((ch, idx) => {
      const onRemove = (domEvent) => {
        const c = domEvent.target.dataset.channelid;
        onChannelFromRemoveGroup(groupId, c);
      }

      return (
        <tr key={idx}>
          <td>{ch.channel_title}</td>
          <td>
            <div className="button__group">
              <button type="button" className="btn btn-sm btn--action" onClick={getChannelInfo} data-channelid={ch.youtubeid}>Show Channel Info</button>
              <button type="button" className="btn btn-sm btn--action" onClick={onRemove} data-channelid={ch.youtubeid}>Remove</button>
            </div>
          </td>
        </tr>
      )
    });

    return (
      <table className="groups__table" style={{marginTop: '4rem'}}>
        <thead>
          <tr>
            <th>Channel Name</th>
            <th style={{width: '15%'}}>Edit Group</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <article>
        <h2>Channels for Group <q>{groupName}</q></h2>

        <p className="button__group">
          <button onClick={onShowChannels} type="button" className="btn btn-sm btn--action">Add Channel To Group</button>
          <button onClick={onDeleteGroup} type="button" className="btn btn-sm btn--action">Delete Group</button>
        </p>

        { channelList.length ? makeTable() : makeNoChannels() }
      </article>

      <GroupsChannelInfo
        {...channelModal}
        modalRef={oneChannelModalRef}
        selectedGroup={groupId}
        onAddHandler={onAddChannelToGroup}
        onClose={onCloseChannelInfo}
        onRemoveFromSystem={onChannelRemoveSystem}
        onUpdateTags={onUpdateTags}
        onRemoveFromGroup={onChannelFromRemoveGroup}
        onGroupChangeHandler={onGroupChangeHandler} />

      <ChannelsList
        group={groupId}
        modalRef={channelsListModalRef}
        onClose={onCloseChannelsList}
        groupsInChannel={channelList}
        onSubmit={onChannelAddToGroup}
        onCheckChange={onCheckChange}
      />
    </>
  )
}

// eslint-disable-next-line react/display-name
export default (props) => (
  <Group
      {...props}
      params={useParams()}
  />
);

