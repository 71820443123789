/**
 * VideoSetEdit.jsx
 * Allows for renaming of video sets.
 */
import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../subcomponents/ModalDialog';

import '../../css/VideoSetEdit.css';

const VideoSetEdit = React.forwardRef(function VideoSetEdit(props, ref) {
  const {
    open,
    onSubmit,
    onClose,
    onReset,
    modalRef,
    id,
    value,
    apiURL,
    labelText,
    error,
    title
  } = props;

  return (
    <ModalDialog ref={modalRef} id="VideoSetEdit" title="Add Songs" onClose={onClose}>
      <h1>{title}</h1>
      <form action={apiURL} onSubmit={onSubmit} className="video__set__edit__form">
        <div>
          <label htmlFor="destinations_new_set_name">{labelText}</label>
          <div>
            <input
              required
              type="text"
              ref={ref}
              name={id}
              id={id}
              defaultValue={value}
              aria-describedby="video_set_edit_error"
            />
            <button type="submit" className="btn btn--action">Save</button>
          </div>
          <p id="video_set_edit_error" className="video__set__edit__error" hidden={!error}>{error}</p>
        </div>
      </form>
    </ModalDialog>
  )
});

VideoSetEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  apiURL: PropTypes.string,
  value: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  error: PropTypes.any,
};

export default VideoSetEdit