/**
 * ImportSpreadsheet
 * Enables users to import data into AVA from GoogleSheet.
 */

import React from 'react';
import classNames from 'classnames';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { ImportSpreadsheetFields } from './ImportSpreadsheetFields';

import {
  API_IMPORT_EMAIL
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  getSheetId
}  from '../../js/Utilities';

import './css/ImportSpreadsheet.css';


const ImportSpreadsheet = ({
  url,
  sheet,
  operation,
  className
}) => {
  const fetchController = new AbortController();
  
  const [loading, setLoading] = React.useState(true);
  const [email, setEmail] = React.useState('');
  
  const [formInput, setFormInput] = React.useState({
    url: url,
    sheet: sheet,
    operation: ''
  });

  React.useEffect(() => {
    if(!email) {
      getEmail();
    }
  
    return () => (fetchController.abort());
    
  }, [fetchController]);
  
  const getEmail = () => {
    const fd = new FormData();
    fd.append('token', getAuthData('token'));

    const {signal} = fetchController;

    apirequest(API_IMPORT_EMAIL, {body: fd, signal}, (response) => {
      setEmail(response.email);
      setLoading(false);
    });
  }

  const onChange = (domEvent) => {
    const {name, value} = domEvent.target;
    setFormInput({
      ...formInput,
      [name]: value
    });
  }

  const onSubmit = (domEvent) => {
    domEvent.preventDefault();

    const {
      operation,
      url,
      sheet
    } = sheetObj;

    const view = operation === 'durations' ? 'durationmode' : 'averages';
    window.location = `/tools/${view}/${getSheetId(url)}/${sheet}`;
  }

  /* Render the component */
  let body;
  const cssClasses = classNames({
    'spreadsheet__import': true,
    'spreadsheet__import__disabled': !email
  }, className);

  if(loading) {
    body = <LoaderSimple open={true} />;
  } else {

    body = (
      <form
        method="post"
        id="tools_import_sheet"
        onSubmit={onSubmit}
        className={cssClasses}
      >
        <ImportSpreadsheetFields
          email={email}
          {...formInput}
          onChange={onChange}
        />
        <fieldset hidden={false} disabled={!email} onChange={onChange}>
          <div>
            <legend>What do you want to do?</legend>
              <p className="fancy__radio">
                <input
                  type="radio"
                  name="operation"
                  id="averages"
                  value="averages"
                  selected={formInput.operation === 'averages'}
                />
                <label htmlFor="averages">Calculate average and total duration</label>
              </p>
              <p className="fancy__radio">
                <input type="radio"
                  name="operation"
                  id="durations"
                  value="durations"
                  selected={formInput.operation === 'durations'}
                />
                <label htmlFor="durations">Count videos by duration</label>
              </p>
          </div>
        </fieldset>
        <button
          disabled={!email || !formInput.operation || !formInput.url }
          type="submit"
          className="btn btn--action"
        >Import</button>
      </form>
    );
  }
  return body;
}

export default ImportSpreadsheet;
