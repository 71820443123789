import React from 'react';
import PropTypes from 'prop-types';

import {
  pushDownload
} from '../../js/Utilities';

import { DownloadCSV } from '../subcomponents/DownloadCSV';

const ReportsResults = ({
  results=[],
  columnHeadings=[],
  destination='0',
  cssClass='',
  limit=500
}) =>  {

  const makeColumnHeadings = (headingsArray) => {
    const ths = headingsArray.map((h, index) => {
      return <th key={index.toString()}>{h}</th>;
    });

    return (
      <thead>
        <tr>
          {ths}
        </tr>
      </thead>
    );
  }

  const makeCells = (rowObject) => {
    const fields = Object.keys(rowObject).filter((key) => {
      return key !== 'url';
    });

    const tds = fields.map((f, index) => {
      let cell;

      if(f === 'title'){ 
        if(rowObject.hasOwnProperty('url')) {
          cell = <a href={rowObject.url} target="_blank" rel="noopener noreferrer">{rowObject.title}</a>;
        } else {
          cell = <b>{rowObject.title}</b>;
        }
      } else {
        cell = rowObject[f];
      }

      return <td key={`${f}_${index.toString()}`}>{cell}</td>;
    });

    return tds;
  }

  const makeRows = (rowsArray) => {
    return rowsArray.map((item, index) => {
      const cells = makeCells(item);

      return (
        <tr key={index.toString()}>
          {cells}
        </tr>
      );
    });
  }

  const makeTable = (headerArray, rowsArray) => {
    const rows = makeRows(rowsArray);
    const header = makeColumnHeadings(headerArray);
    const css = `reports__table__results reports__results__viddest ${cssClass}`;

    return (
      <table className={css}>
        {header}
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

  const onDownload = (domEvent) => {
    domEvent.preventDefault();
    const csvHeaders =  Object.keys(results[0])
                        .map((c) => (`${c.charAt(0).toUpperCase()}${c.slice(1)}`));

    const csv = [
      [csvHeaders],
      results.map((r) => ([`"${r.title}"`, `"${r.url}"`, `"${r.frequency}"`]))
    ].reduce((accumulator, value) => accumulator.concat(value), []);

    pushDownload(csv);
  }

  // Will stop executing if we don't have a results count.
  if(!results.length) {
    return <h2>No results for this report.</h2>
  };
  return (
    <div>
      <DownloadCSV
        count={results.length}
        destination={destination}
        onSubmit={onDownload}
      />
      <div className="reports__table__overflow">
        {makeTable(columnHeadings, results)}
      </div>
    </div>
  );
}

export default ReportsResults;
