import React from 'react';
import PropTypes from 'prop-types';


import '../../css/SelectMenu.css';

export const SearchDropDown = ({
  value,
  label= 'NEEDS A LABEL',
  onChange= '',
  id= '',
  options=[],
  note='',
  defaultValue= ''
}) => {

  const makeMenuItems = (arrayOfItems=[]) => {
    let menu;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={index.toString()} value={item.value}>{item.label}</option>
      });
    } else {
      menu = null;
    }

    return menu;
  }
  
  let theNote = null;
  if(note) {
    theNote = (<div>{note}</div>);
  }

  return (
    <div className="search__form__item ava-input-select">
      <label htmlFor={id}>{label}</label>
      <select id={id} name={id} onChange={onChange} defaultValue={defaultValue}>
        { makeMenuItems(options) }
      </select>
      {note}
    </div>
  )
}

SearchDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any
  })),
  note: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.any
};

export default SearchDropDown;