/**
 * SeriesEdit.js
 * Allows for renaming of series. Mostly the same as VideoSetEdit.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from '../subcomponents/CloseButton';
import ModalDialog from '../subcomponents/ModalDialog';

import '../../css/VideoSetEdit.css';

const SeriesEdit = ({
  error,
  modalRef,
  title,
  open,
  onClose,
  onInput,
  onSubmit,
  onReset,
  value,
  apiURL,
  id,
  labelText = 'Enter a name for this video set',
}) => {

  return (
    <ModalDialog
      ref={modalRef}
      id="SeriesEditModal"
      className="edit__modal"
      onClose={onClose}
    >
      <h2>{title}</h2>
      <form
        action={apiURL}
        method="dialog"
        onSubmit={onSubmit}
        onReset={onReset}
        className="video__set__edit__form"
      >
        <div>
          <label htmlFor="destinations_new_set_name">{labelText}</label>
          <div>
            <input
              type="text"
              onChange={onInput}
              name={id}
              id={id}
              defaultValue={value}
              aria-describedby="video_set_edit_error"required
            />
            <button type="submit" className="btn btn--action">Save</button>
            <button type="reset" className="btn">Cancel</button>
          </div>
          <p
            id="video_set_edit_error"
            className="video__set__edit__error"
            hidden={!error}>{error}</p>
        </div>
      </form>
    </ModalDialog>
  )
}

SeriesEdit.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  apiURL: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string.isRequired
};

export default SeriesEdit;