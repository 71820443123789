/**
 * VideoNote.js
 * Add notes for a particular video.
 */

import React from 'react';
import { PleaseAddToAva } from '../../Videos/PleaseAddToAva';

import './css/VideoNote.css';

export const VideoNote = (props) => {
  return (
    <form className="video__info__note video__info__field" onSubmit={props.onSubmit}>
      <p>
        <label htmlFor="video_note">Notes</label>
      </p>
      <PleaseAddToAva hidden={ !props.disabled } />
      <p>
        <textarea id="video_note" name="video_note" disabled={props.disabled} defaultValue={props.value} className="form-control" rows="5" onChange={props.onChange}></textarea>
      </p>
      <p><button type="submit" disabled={props.disabled} className="btn btn--action">Save Note</button></p>
    </form>
  );
}
