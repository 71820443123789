/**
 * AmzGenreRemove.jsx
 * A form for removing songs from a cluster.
 */
import React from 'react';
import uniqueId from 'lodash.uniqueid';

const AmzGenreRemove = (props) => {
  const { onSubmit, id } = props;

  return (
    <form className="AmzGenreRemove" id={uniqueId('AmzGenreRemove_')} onSubmit={onSubmit}>
      <input type="hidden" name="id" value={id} onChange={() => {}} />
      <button type="submit" className="btn btn--action">Remove</button>
    </form>
  );
}

export default AmzGenreRemove;
