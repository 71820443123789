/**
 * Stations.js
 * /stations page-level component
 */
import React, {useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import VideoSetEdit from './Destinations/VideoSetEdit';
import LoaderSimple from './subcomponents/LoaderSimple';

import {
  API_STATIONS,
  API_DESTINATIONS_ADDNEW
} from '../js/Configuration';

import {
  apirequest,
  getAuthData,
  isAdmin
} from '../js/Utilities';

import '../css/Stations.css';

const Stations = (props) => {
  const { project } = props;
  const [stations, setStations] = useState([]);
  const [addNew, setAddNew] = useState({open: false, title: ''});

  useEffect(() => {
    if(!stations.length) {
      const fd = new FormData();
      fd.set('token', getAuthData('token'));
      apirequest(API_STATIONS, {body: fd}, (data) => {
        setStations(data);
      });
    }
  }, []);

 const videoSetTitleRef = useRef(null);
 const videoSetModalRef = useRef(null);

  const closeModal = () => {
    if(Object.hasOwn(videoSetModalRef, 'current')) {
      videoSetModalRef.current?.close();
    }
  }

  const onAddNewStation = () => {
    setAddNew({...addNew, open: true})
    if(Object.hasOwn(videoSetModalRef, 'current')) {
      videoSetModalRef.current?.showModal();
    }
  }

  const onAddNewStationInput = () => {
    if(Object.hasOwn(videoSetTitleRef, 'current')) {
      setAddNew({...addNew, title: videoSetTitleRef.current.value});
    }
  }

  const onAddNewStationSave = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('name', addNew.title.trim());
    fd.set('isstation', 1);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_ADDNEW, {body: fd}, (data) => {
      if(data.result === 'success') {
        const newStation = [...stations];

        newStation.unshift({
          id: data.d_id,
          station: data.d_name
        });

        setStations(newStation);
        setAddNew({...addNew, open: false});
        closeModal();

      } else {
        alert(data.message);
      }
    });
  }

  const makeNewStationButton = () => {
    let btn;
    if(isAdmin(project)){
      btn = (
        <div>
          <button type="button" className="btn btn--action" onClick={onAddNewStation}>Add New Station</button>
        </div>
      );
    } else {
      btn = null;
    }
    return btn;
  }

  const buildLinks = () => {
    let links = <LoaderSimple open={true} />;

    if(stations.length) {
      links = stations.map((item) => {
        return (
          <li key={`station_${item.id}`}>
            <Link to={item.id} state={{station: item.station }}>{item.station}</Link>
          </li>
        );
      });
    }
    return links;
  }

  const makeAddNewStationModal = () => {
    return (
      <VideoSetEdit
        modalRef={videoSetModalRef}
        ref={videoSetTitleRef}
        apiURL={API_DESTINATIONS_ADDNEW}
        id="VideoSetEdit"
        title="Add a New Station"
        labelText="Enter a name for this station"
        open={addNew.open}
        value={addNew.title}
        onInput={onAddNewStationInput}
        onSubmit={onAddNewStationSave}
        onClose={closeModal}
        error={addNew.error}
      />
    );
  }

  return (
    <article className="stations__view">
      <header>
        <div>
          <h1>Stations</h1>
          <p>Click a station name to view series</p>
        </div>
        {makeNewStationButton()}
      </header>

      <ul className="stations__list">
        {buildLinks()}
      </ul>

      {makeAddNewStationModal()}
    </article>
  );
}

export default Stations;