/**
 * PaginationBar.js
 * A no-framework pagination component
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import classNames from 'classnames';

import { arraychunk, range } from '../../js/Utilities';

import '../../css/pagination.css';

const PaginationBar = ({
  data,
  navSize = 10,
  onClickHandler,
  page,
  pages
}) => {

  const onClick = (domEvent = null) => {
    domEvent.persist();
    if(onClickHandler) {
      onClickHandler(domEvent);
    }
  }
  
  const batches = () => {
    let max = 1;

    if(pages) {
      max = pages;
    }

    const pgs = range(1, max);
    const batches = arraychunk(pgs, navSize);
    return batches;
  }

  const makeButton = (pg) => {
    const cssClass = classNames({
      'pagination__pg': true,
      'pagination__pg__current': pg === page
    });
    return <button key={`pagination__pg--${pg}`} className={cssClass} type="button" value={pg} onClick={onClick} data-data={data}>{pg}</button>
  }

  const makeFirstLast = (last = false, disabled = false) => {
    if(pages <= navSize) { return null; }
 
    let text = 'first';
    if(last) {
      text = 'last';
    }
    return (
      <button
        className={`pagination__${text} pagination__pg`}
        type="button"
        onClick={onClick}
        data-data={data}
        value={text}
        disabled={disabled}><span className="form__access__label">{text.charAt(0).toUpperCase()} Page</span></button>
    );
  }

  const makeNav = () => {
    // Break the number of pages into chunks of 10.
    const btches = batches();
    
    // Finds the index of the batch that contains the currently-requested page
    const batch = btches.findIndex((b) => b.indexOf(page) > -1);
    
    /*
     If we change the number of pages available and our new requested page is no
     longer in range, reset the page batch to 0
    */
    const index = (batch > -1) ? batch : 0;
    
    // Return a set of buttons for the current chunk
    return btches[index].map(makeButton);
  }
  
  const isFirstPage = (pages === 1) || (page === 1);

  let isLastPage = false;
  if(page >= pages) {
    isLastPage = true;
  }

  return (
    <div className="pagination__component--bar">
      {makeFirstLast(false, isFirstPage)}
      <button
        data-data={data}
        className="pagination__previous pagination__pg"
        type="button"
        onClick={onClick}
        value="previous"
        disabled={isFirstPage}><span className="form__access__label">Previous</span></button>
      {makeNav()}
      <button
        data-data={data}
        className="pagination__next pagination__pg"
        type="button"
        onClick={onClick}
        value="next"
        disabled={isLastPage}><span className="form__access__label">Next</span></button>
      {makeFirstLast(true, isLastPage)}
    </div>
  )
}

export default PaginationBar;
