/**
 * ChannelsManageGroups.js
 * Panel for managing the channel-group relationship.
 */
import React from 'react';

import {
  API_CHANNEL_INFO,
  MESSAGE_NO_TAGS,
  API_CHANNEL_DELETE
} from '../../js/Configuration';

import SelectMenu from '../subcomponents/SelectMenu';
import ChannelsRemoveFromGroup from './ChannelsRemoveFromGroup';

import './css/ChannelsManageGroups.css';

import {
  apirequest,
  debounce,
  getAuthData,
  numberFormat
} from '../../js/Utilities';

const ChannelsManageGroups = ({
  channel,
  inGroups,
  groups,
  onSubmit,
  onGroupDelete,
  onChannelDelete
}) => {

  const makeMenu = () => {
    if(!groups.length) return;
    
    let selects = [{value: '', label: 'Select Group'}];
    const grps = groups.map((item) => {
      return {
        value: item.group_id,
        label: item.group_name
      };
    });
    return selects.concat(grps);
  }

  return (
      <details id="ChannelsManageGroups" className="channel__singlepg__panel" open>
        <summary>
          <h2>Manage Channel Groups</h2>
        </summary>
        <div id="ChannelsManageGroups__body">
          <form id="ChannelsManageGroups__addtogroup" onSubmit={onSubmit}>
            <input type="hidden" id="channelID" name="channelID" value={channel} />
            <SelectMenu labelText="Add Channel To Group" options={makeMenu()} onChangeHandler={()=>{}} name="groupID" firstEmpty={false} required />
            <button type="submit" className="btn btn--action">Save</button>
          </form>
          
          <ChannelsRemoveFromGroup channel={channel} onDelete={onGroupDelete} onGroupDelete={onGroupDelete} inGroups={inGroups} />

          <p>
            <button type="button" className="btn btn--destructive" onClick={onChannelDelete}>Delete Channel From System</button>
          </p>        
        </div>
      </details>
  );
}

export default ChannelsManageGroups;
