import React from 'react';
import './css/RegionLangDisplay.css';

const RegionLangDisplay = ({
  name,
  flag,
  language,
  onClick
}) => {
  return (
      <div id="RegionLangDisplay">
        <b>Region: </b>
        <b>{name}</b>
        <i>{flag.flag}</i>
        <b>Language:  </b>
        <b>{language.name}</b>
        <button className="btn__link" onClick={onClick}>Change</button>
      </div>
    );
}

export default RegionLangDisplay;
