/**
 * ToolsSpreadsheetUpdated.js
 * 
 */

import React from 'react';
import { ToolsImportAnother } from './ToolsImportAnother';

export const ToolsSpreadsheetUpdated = (props) => {
  return (
    <div className="tools__pushed">
      <h1>Video List Calculator</h1>
      <p className="alert alert-success"><b>Success!</b> Your spreadsheet was updated.</p>
      <p>
        <a className="tools__pushed__link" href={`https://docs.google.com/spreadsheets/d/${props.spreadsheetID}/`} target="_blank" rel="noopener noreferrer">Visit your spreadsheet</a>
        <b>or</b>
        <ToolsImportAnother />
      </p>
    </div>
  )
}

