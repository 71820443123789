/**
 * DashboardCounts.jsx
 * Displays dashboard videos, channels, and destinations counts.
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import { numberFormat } from '../../js/Utilities';

const DashboardCounts = ({ videos=0, destinations=0, channels=0, stations=0 }) => {
  return (
    <p className="reports__dashboard__count">
      Cataloguing <span className="reports__dashboard__count__num">{numberFormat(videos)}</span> videos
      and <span className="reports__dashboard__count__num">{numberFormat(channels)}</span> channels for 
        <span className="reports__dashboard__count__num">{numberFormat(destinations)}</span> destinations
        and <span className="reports__dashboard__count__num">{numberFormat(stations)}</span> stations.
    </p>
  );
}

DashboardCounts.propTypes = {
  videos: PropTypes.number.isRequired,
  destinations: PropTypes.number.isRequired,
  channels: PropTypes.number.isRequired,
  stations: PropTypes.number.isRequired
};

export default DashboardCounts;
