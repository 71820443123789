/**
 * ActionMessageDisplay.js
 * Displays a success or failure message.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/ActionMessageDisplay.css';

const ActionMessageDisplay = ({
  mode,
  hidden = true,
  message = null,
  onClose = null,
  className = null
}) => {

  const onCloseHandler = () => {
    if(onClose) {
      onClose();
    }
  }

  const htmlOrObject = () => {
    let msg;
    if(typeof message === 'string') {
      msg = <span dangerouslySetInnerHTML={{ __html: message }}></span>;
    } else {
      msg = message;
    }
    return msg;
  }

  const cssClass = classNames({
    'action__message': true,
    'action__message--success': mode === 'success',
    'action__message--failure': mode === 'failed',
  }, className);

  return (
    <p className={cssClass} hidden={hidden}>
      {htmlOrObject()}
      <button type="button" onClick={onCloseHandler} hidden={!onClose}>&times;</button>
    </p>
  );
}

ActionMessageDisplay.propTypes = {
  mode: PropTypes.oneOf(['success', 'failed']),
  hidden: PropTypes.bool.isRequired,
  message: PropTypes.any,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default ActionMessageDisplay;
