/**
 * Created by Webinista, Inc 3/23/18.
 */

import React, { useEffect, useState } from 'react';
import ListDisplay from './Reports/ListDisplay';
import LoaderSimple from './subcomponents/LoaderSimple';
import DashboardCounts from './Reports/DashboardCounts';
import { useLocation } from 'react-router-dom';

import {
  apirequest,
  getAuthData
} from '../js/Utilities';

import {
  API_REPORTS
} from '../js/Configuration';


import '../css/reportsPage.css';

const ReportsDashboardPage = () => {  

  const fetchCancel = new AbortController();
  const [channels, setChannels] = useState(0);
  const [destinations, setDestinations] = useState(0);
  const [reports, setReports] = useState([]); 
  const [stations, setStations] = useState(0);
  const [videos, setVideos] = useState(0);

  useEffect(() => {
    const signal =  fetchCancel.signal;

    if(!reports.length) {
      const fd = new FormData();
      fd.set('token', getAuthData('token'));

      apirequest(API_REPORTS, {body: fd, signal}, (data) => {
        setChannels(+data.count_channels);
        setDestinations(+data.count_destinations);
        setReports(data.reports);        
        setVideos(+data.count_videos);
        setStations(+data.count_stations);
      });
    }
    
    return () => fetchCancel.abort();
  }, [fetchCancel]);

  let display = <LoaderSimple open={true} />;

  if(reports.length) {
    display = <ListDisplay items={reports} />;
  }

  return (
    <div className="reports__page">
      <h1>Reports</h1>
      <DashboardCounts
        videos={videos}
        destinations={destinations}
        channels={channels}
        stations={stations} />
      {display}
    </div>
  );
}

// eslint-disable-next-line react/display-name
export default (props) => (
  <ReportsDashboardPage
      {...props}
      params={useLocation()}
  />
);