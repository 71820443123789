/**
 * SeasonsEpsInput.jsx
 * Simple number input component
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/SeasonsEpsInput.css';

const SeasonsEpsInput = ({
  id,
  label,
  onChange,
  form,
  value
}) =>  {
  return (
    <p className="seasons__ep__input">
      <label htmlFor={id} className="form__access__label">{label}</label>
      <input type="number"
        name={id}
        id={id}
        onChange={onChange}
        form={form}
        value={value}
        step="1"
        min="0"
        size="3" />       
    </p>
  );
}

SeasonsEpsInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

export default SeasonsEpsInput;