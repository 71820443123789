import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'

import Error404 from './Error404';

import Group from './Group';
import Groups from './Groups';
import Destinations from './Destinations';
import DestinationVideoSets from './Destinations/DestinationVideoSets';
import DestinationPlaylist from './Destinations/DestinationPlaylist';
import VideoSetRundates from './Destinations/VideoSetRundates';
import SearchMultiChannel from './SearchMultiChannel';
import Videos from './Videos';
import Channels from './Channels';
import Channel from './Channels/Channel';
import UserAdmin from './UserAdmin';
import UserProjects from './UserProjects';
import UserNoProjects from './UserNoProjects';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import UpdateAccountPage from './UpdateAccountPage';
import ReportsDashboard from './ReportsDashboard';
import ReportsVideoDestinations from './Reports/ReportsVideoDestinations';
import ReportsChannels from './Reports/ReportsChannels';
import ReportsVidSetDestinations from './Reports/ReportsVidSetDestinations';
import ReportsChannelsDestinations from './Reports/ReportsChannelsDestinations';
import ReportsChannelsUse from './Reports/ReportsChannelsUse';
import ReportsVideoUse from './Reports/ReportsVideoUse';
import ReportsVideoDropOffs from './Reports/ReportsVideoDropOffs';
import Stations from './Stations';
import StationView from './Stations/StationView';
import EpisodeView from './Stations/EpisodeView';
import SeriesView from './Stations/SeriesView';
import ResetPassword from './ResetPassword';
import { ResetPasswordNoToken } from './ResetPasswordNoToken';
import { ResetPasswordEmailSent } from './ResetPasswordEmailSent';
import Tools from './Tools';
import ToolsDurationMode from './Tools/ToolsDurationMode';
import ToolsAverages from './Tools/ToolsAverages';
import AmzGenres from './AmzGenres';
import AmzClusters from './Music/AmzClusters';
import AmzSongs from './Music/AmzSongs';
import AmzReports from './Music/AmzReports';
import AmzReportsDetails from './Music/AmzReportsDetails';

import ProjectWrapper from './ProjectWrapper';

// CSS // imports
import '../css/Variables.css';
import '../css/App.css';
import '../css/videosPage.css';
import '../css/videoShared.css';
import '../css/searchPage.css';
import '../css/destinationsPage.css';
import '../css/reportsPage.css';

const SearchMultiChannelView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', SearchMultiChannel);
const GroupView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', Group);
const GroupsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', Groups);
const DestinationsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', Destinations);
const DestinationVideoSetsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', DestinationVideoSets);
const VideoSetRundatesView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', VideoSetRundates);
const DestinationPlaylistView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', DestinationPlaylist);
const VideosView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', Videos);
const StationsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', Stations);
const StationViewView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', StationView);
const EpisodeViewView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', EpisodeView);
const SeriesViewView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', SeriesView);
const ChannelsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', Channels);
const ChannelView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', Channel);
const ReportsDashboardView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ReportsDashboard);
const ReportsVideoDestinationsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ReportsVideoDestinations);
const ReportsChannelsDestinationsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ReportsChannelsDestinations);
const ReportsChannelsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ReportsChannels);
const ReportsChannelsUseView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ReportsChannelsUse);
const ReportsVidSetDestinationsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ReportsVidSetDestinations);
const ReportsVideoUseView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ReportsVideoUse);
const ReportsVideoDropOffsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ReportsVideoDropOffs);
const ToolsView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', Tools);
const ToolsDurationModeView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ToolsDurationMode);
const ToolsAveragesView = ProjectWrapper('d157f6f8-43b7-11ea-b50b-1902bdae', ToolsAverages);

const UserAdminView = ProjectWrapper('ea9298b7-6ddd-11ea-b7f4-008cfa070708', UserAdmin);

const UpdateAccountPageView = ProjectWrapper('999999', UpdateAccountPage);
const UserProjectsView = ProjectWrapper('000000', UserProjects);
const UserNoProjectsView = ProjectWrapper('000000', UserNoProjects);

const AmzGenreView = ProjectWrapper('dec4be2a-43b7-11ea-b50b-1902bdae', AmzGenres);
const AmzClustersView = ProjectWrapper('dec4be2a-43b7-11ea-b50b-1902bdae', AmzClusters);
const AmzSongsView = ProjectWrapper('dec4be2a-43b7-11ea-b50b-1902bdae', AmzSongs);
const AmzReportsView = ProjectWrapper('dec4be2a-43b7-11ea-b50b-1902bdae', AmzReports);
const AmzReportsDetailsView = ProjectWrapper('dec4be2a-43b7-11ea-b50b-1902bdae', AmzReportsDetails);

export default class App extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      user: {}
    };
  }

  render() {
    return (
      <BrowserRouter>    
        <Routes>
          <Route path="/" element={<LoginPage {...this.state} />}  />
          <Route path="/projects" element={<UserProjectsView {...this.state} />}  />
          <Route path="/notassigned" element={<UserNoProjectsView {...this.state} />}  />

          <Route path="/search" element={<SearchMultiChannelView {...this.state} />}  />
          <Route path="/videos" element={<VideosView {...this.state} />}  />
          <Route path="/stations" element={<StationsView {...this.state} />}  />
          <Route path="/stations/:stationID" element={<StationViewView {...this.state} />}  />
          <Route path="/stations/episode/:episodeID" element={<EpisodeViewView {...this.state} />}  />
          <Route path="/stations/series/:seriesID" element={<SeriesViewView {...this.state} />}  />

          <Route path="/destinations" element={<DestinationsView {...this.state} />}  />
          <Route path="/destinations/videosets/:id" element={<DestinationVideoSetsView {...this.state} />}  />
          <Route path="/destinations/rundates/:id" element={<VideoSetRundatesView {...this.state} />}  />
          <Route path="/destinations/playlist/:vsbID" element={<DestinationPlaylistView {...this.state} />}  />

          <Route path="/groups" element={<GroupsView {...this.state} />}  />
          <Route path="/group/:groupId" element={<GroupView {...this.state} />}  />

          <Route path="/channels" element={<ChannelsView {...this.state} />}  />
          <Route path="/channels/:channelID" element={<ChannelView {...this.state} />}  />
          <Route path="/reports" element={<ReportsDashboardView {...this.state} />}  />
          <Route path="/reports/display/videosdestinations" element={<ReportsVideoDestinationsView {...this.state} />}  />
          <Route path="/reports/display/channelsdestinations" element={<ReportsChannelsDestinationsView {...this.state} />}  />
          <Route path="/reports/display/channels" element={<ReportsChannelsView {...this.state} />}  />
          <Route path="/reports/display/channelsusage" element={<ReportsChannelsUseView {...this.state} />}  />
          <Route path="/reports/display/videosetdestinations" element={<ReportsVidSetDestinationsView {...this.state} />}  />
          <Route path="/reports/display/videosusage" element={<ReportsVideoUseView {...this.state} />}  />
          <Route path="/reports/display/videosinactive" element={<ReportsVideoDropOffsView {...this.state} />}  />
          <Route path="/admin" element={<UserAdminView {...this.state} />}  />
          <Route path="/update_account" element={<UpdateAccountPageView {...this.state} />}  />
          <Route path="/tools" element={<ToolsView {...this.state} />}  />
          <Route path="/tools/durationmode/:spreadsheetID/:sheetName" element={<ToolsDurationModeView {...this.state} />}  />
          <Route path="/tools/averages/:spreadsheetID/:sheetName" element={<ToolsAveragesView {...this.state} />}  />
          <Route path="/music" element={<AmzGenreView {...this.state} />}  />
          <Route path="/music/clusters/:id" element={<AmzClustersView {...this.state} />}  />
          <Route path="/music/songs/:id" element={<AmzSongsView {...this.state} />}  />
          <Route path="/music/reports/details/:report/:id/" element={<AmzReportsDetailsView {...this.state} />}  />
          <Route path="/music/reports/details/:report/:id/:genre" element={<AmzReportsDetailsView {...this.state} />}  />
          <Route path="/music/reports/:report/:id" element={<AmzReportsView {...this.state} />}  />
          <Route path="/setpassword/" element={<ResetPasswordNoToken {...this.state} />}  />
          <Route path="/setpassword/:pwordReqID" element={<ResetPassword {...this.state} />}  />
          <Route path="/emailsent" element={<ResetPasswordEmailSent {...this.props} />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route component={Error404} />
        </Routes>
      </BrowserRouter>
    );
  }
}


