/**
 * DownloadCSV.jsx
 */
import React from 'react';
import LoaderSimple from './LoaderSimple';

import './css/DownloadCSV.css';

export const DownloadCSV = ({
  count=0,
  onSubmit,
  disabled=false,
  working=false,
  destination='0'
}) => {

  const countString = `${count} results`;
  return (
    <form className="reports__result_count" onSubmit={onSubmit}>
      <input type="hidden" name="destination" value={destination} onChange={()=>{}} />
      <span>{countString}</span>
      <button type="submit" className="download" disabled={disabled || working}>
        <b>Download CSV</b>
        <LoaderSimple
          open={working}
          cssClass="downloading__indicator"
          title="Downloading data"
        />
      </button>
    </form>
  )
}
