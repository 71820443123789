/*
 eslint "react/react/destructuring-assignment": 0
*/

/**
 * DestinationPlaylist
 */

import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  format,
  isValid
} from 'date-fns';

import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import { CloseButton } from '../subcomponents/CloseButton';

import { DestinationsToggleView } from './DestinationsToggleView';
import DragSortList from '../DragSortList';

import Breadcrumb from '../subcomponents/Breadcrumb';
import VideosList from '../VideosList';
import VideoModal from '../subcomponents/Videos/VideoModal';
import AddVideosByURL from './AddVideosByURL';
import CheckForUpdates from './CheckForUpdates';
import CheckForUpdatesProgress from './CheckForUpdatesProgress';
import CheckForDuplicatesModal from './CheckForDuplicatesModal';
import DeleteSetModal from './DeleteSetModal';
import VideoDragItem from '../subcomponents/VideoDragItem';
import Reviewed from './Reviewed';
import PlaylistMeta from './PlaylistMeta';
import CRTStatus from './CRTStatus';
import DestinationsPlaylistTitle from './DestinationsPlaylistTitle';
import DestinationsPlaylistRunDateModal from './DestinationsPlaylistRunDateModal';
import DestinationsPlaylistHeader from './DestinationsPlaylistHeader';
import LoaderSimple from '../subcomponents/LoaderSimple';
import RunDate from '../Stations/RunDate';
import ImportSpreadsheetToPlaylist from '../Import/ImportSpreadsheetToPlaylist';

import {
  API_CRTAPPROVALS_GETVIDSETSTATUS,
  API_DESTINATIONS_CHECKFORUPDATES,
  API_STATIONS_EPISODE_EDIT,
  API_RATINGS_ALL,
  API_VIDEOSETBLOCK_INFO,
  API_VIDEOSETBLOCK_RATING,
  API_VIDEOSETBLOCK_REORDERED,
  API_VIDEOSETBLOCK_REVIEWED,
  API_VIDEOSETBLOCK_UPDATE,
  NULL_DATE_TEXT_STATIONS,
  API_VIDEO_ADDBATCH,
  API_VIDEO_REMOVEFROMSET
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  debounce,
  totalRunTime,
  formatTime,
  isAdmin,
  shuffle,
  expandShortYouTubeUrl,
  extractYouTubeIdFromUrl,
  getUTCNormalizedDate,
  stringToArray
} from '../../js/Utilities';

import {
  tooManyURLs,
  exportVideoData,
  playAllVideos,
  duplicatePlaylist
} from '../../actions/DestinationsUtils';

import {
  initAddByUrlState,
  initAddBySearchState,
  initCheckForDupesState,
  initCheckForUpdatesState,
  checkDupes,
  checkUrlsForDupes,
  excludeUrlFromValue,
  makeRatingsObjects,
  normalizeVids,
  splitURLs
} from '../../actions/DestinationsPlaylistUtils';

import {
  setStateOnVideoBlockSelect
} from '../../actions/StationsUtils';

import '../../css/destinationsPage.css';
import './css/VideoDisplay.css';

const DestinationPlaylist = (props) => {
  const { project, params } = props;
  const { vsbID } = params;

  const canAdmin = isAdmin(project);
  const fetchCancel = new AbortController();
  const duplicateRunDate = useRef(null);
  const importModal = useRef(null);
  const addByUrlModal = useRef(null);
  const addBySearchModal = useRef(null);
  const checkForDupesModal = useRef(null);
  const checkForUpdatesModal = useRef(null);
  const videoModal = useRef(null);
  const deleteSetRef = useRef(null);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [runTime, setRunTime] = useState('00:00:00');
  const [useGrid, setUseGrid] = useState(true);

  const [ratings, setRatings] = useState([]);
  const [playlistRating, setPlaylistRating] = useState('\u2014');

  const [currentVideoBlock, setCurrentVideoBlock] = useState({
    Station: '',
    Video_Set: '',
    vsb_datetimetorun: '',
    vsb_tags: '',
    vsb_proofCopy: '',
    vsb_playlistURL: '',
    title: '',
    vsb_id: 0,
  });

  const [duplicated, setDuplicated] = useState(false);
  const [duplicatePL, setDuplicatePL] = useState({id: null, rundate: ''});
  const [crtstatus, setCrtStatus] = useState({
    approved: false,
    loading: false,
    status: 'Not Submitted'
  });

  const [addByURL, setAddByURL] = useState(initAddByUrlState);
  const [addBySearch, setAddBySearch] = useState(initAddBySearchState);
  const [checkForUpdates, setCheckForUpdates] = useState(initCheckForUpdatesState);
  const [videoToPlay, setVideoToPlay] = useState('');
  const [checkForDupes, setCheckForDupes] = useState([]);

  const [submissions, setSubmissions] = useState([]);

  /* If we haven't yet retrieved the block info, do so now. */
  useEffect(() => {
    if(!list.length) {
      getVideosForBlock();
    }

    if(!ratings.length) {
      getRatings();
    }

    if(!playlistRating) {
      getVideoSetRating();
    }

    return () => fetchCancel.abort()

  }, [currentVideoBlock, list, ratings, playlistRating]);

  const getVideosForBlock = () => {
    const fd = new FormData();
    fd.set('id', vsbID);
    fd.set('token', getAuthData('token'));

    const { signal } = fetchCancel;

    apirequest(API_VIDEOSETBLOCK_INFO, { body: fd, signal }, (response) => {
      const {returnData} = response.videoList;
      if(returnData.length) {
        setList( normalizeVids(returnData) );
      } else {
        setList([false]);
      }

      setCurrentVideoBlock({...currentVideoBlock, ...response});
    });
  }

  const getRatings = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_ALL, { body: fd }, (data) => {
      setRatings(makeRatingsObjects(data));
    });
  }

  const getVideoSetRating = () => {
    const fd = new FormData();
    fd.set('videosetblockid', +currentVideoBlock.vsb_id);
    fd.set('token', getAuthData('token'));

    // Make the network request here.
    apirequest(API_VIDEOSETBLOCK_RATING, { body: fd }, (data) => {
      if (data.message === 'success') {
        setPlaylistRating(data.rating);
      }
    });
  }

  const toNextPage = (blockID) => {
    window.location = `/destinations/playlist/${blockID}`;
  }

  const onShuffle = () => {
    const shuffled = shuffle(list);
    const ordered = shuffled.map((vid) => vid.ytID);
    saveNewOrder(ordered)
  }

  const onRunDateChange = (domEvent) => {
    const {value} = domEvent.target;

    setCurrentVideoBlock({
      ...currentVideoBlock,
      vsb_datetimetorun: value
    });

    const fd = new FormData();
    fd.set('id', currentVideoBlock.vsb_id);
    fd.set('rundate', value);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_UPDATE, { body: fd }, (response) => {});
  }

  const onCloseRunDateModal = () => {
    if(duplicateRunDate.current) {
      duplicateRunDate.current.close()
    }
  }

  const onDuplicate = () => {
    duplicatePlaylist(vsbID, (data) => {
      setDuplicatePL({...duplicatePL, id: data});

      if(duplicateRunDate.current) {
        duplicateRunDate.current.showModal();
      }
    });
  }

  const onDuplicateSubmit = (domEvent) => {
    domEvent.preventDefault();

    const {id, rundate} = duplicatePL;

    const fd = new FormData();
    fd.set('id', id);
    fd.set('rundate', rundate);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_UPDATE, { body: fd }, (response) => {
      if(response.result === 'success') {
        onDuplicateSkip();
      }
    });
  }

  const onDuplicateSkip = () => {
    const {id} = duplicatePL;
    toNextPage(id);
  }

  const onDuplicateRunDateChange = (domEvent) => {
    const {value} = domEvent.target;
    setDuplicatePL({...duplicatePL, rundate: value});
  }

  const getVideoSetCRTStatus = () => {
    setCrtStatus({...crtstatus, loading: true})

    const fd = new FormData();
    fd.set('videoset', currentVideoBlock.vsb_id || 0);
    fd.set('token', getAuthData('token'));

    apirequest(API_CRTAPPROVALS_GETVIDSETSTATUS, { body: fd }, (data) => {
      setCrtStatus({
        approved: data.approved,
        status: data.status,
        loading: false,
      });
    });
  }

  const playVideo = (youtubeId) => {
    setVideoToPlay(youtubeId);
    videoModal.current.showModal();
  }

  
  const onPlayVideoClose = () => {
    if(videoModal.current) {
      videoModal.current.close();
    }
  }

  const deleteVideo = (data) => {

    const {id, position} = data;
    const {params} = props;

    const yes = window.confirm('Are you sure that you want to delete this video?');
    if (yes) {
      const fd = new FormData();
      fd.set('playlist', vsbID);
      fd.set('video', id);
      fd.set('order', position);
      fd.set('token', getAuthData('token'));
      apirequest(API_VIDEO_REMOVEFROMSET, { body: fd }, (response) => {
        setList([]);
      });
    }
    
    
  }

  const makeBreadcrumbs = () => {
    let crumbs = [];
    const loaf = [{
      label: 'Destinations',
      path: '/destinations',
    }];

    const {
      Station,
      station_id,
      vsb_setID,
      Video_Set,
      vsb_datetimetorun
    } = currentVideoBlock;

    let lastCrumb = NULL_DATE_TEXT_STATIONS;
    if(vsb_datetimetorun && isValid(new Date(vsb_datetimetorun)) ) {
      lastCrumb = format(new Date(vsb_datetimetorun), 'PP', { timeZone: 'Etc/UTC' });
    }

    if(Station) {
      crumbs = [{
        path: `/destinations/videosets/${station_id}`,
        label: Station
      },
      {
        path: `/destinations/rundates/${vsb_setID}`,
        label: Video_Set
      },
      {
        label: lastCrumb,
      }];
    }

    return loaf.concat(crumbs);
  }

  const makeVideos = () => {
    let body;

    if(!list.length || list[0] === false) {
      return <p style={{'fontSize': '3rem','marginBlock':'2rem 30rem'}}>This playlist does not contain any videos.</p>
    }

    body = <LoaderSimple open className="playlist__global" />;

    if (currentVideoBlock.Approved ==='y') {
      body = (
        <div className="List destinations__disabled__list">
        {
          list.map((i) => (
            <VideoDragItem
              key={uniqueId(`${i.ytID}_`)}
              interstitial={i.is_interstitial}
              data={i}
              playVideo={playVideo}
              disabled
              deleteVideo={() => {}}
              position={i.position}
              ordinal={i.ordinal}
            />
          ))
        }
        </div>
      );
    } else {
      body = (
        <DragSortList
          items={list}
          onReSort={(newOrder) => { setList(newOrder) }}
          onPlayVideo={playVideo}
          onDeleteVideo={deleteVideo}
        />
      )
    }
    return body;
  }

  const onPlaylistSave = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('id', currentVideoBlock.vsb_id);
    fd.set('playlisturl', currentVideoBlock.vsb_playlistURL);
    fd.set('proofcopy', currentVideoBlock.vsb_proofCopy);
    fd.set('tags', currentVideoBlock.vsb_tags);
    fd.set('title', currentVideoBlock.title);
    fd.set('rundate', currentVideoBlock.vsb_datetimetorun);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_UPDATE, { body: fd }, (data) => {
      alert(data.message);
    })
  }

  const onPlaylistMetaChange = (domEvent) => {
    const {name, value} = domEvent.target;
    setCurrentVideoBlock({
      ...currentVideoBlock,
      [name]: value,
    });
  }

  const onApprovedProofedCuratedChange = (domEvent) => {
    const fd = new FormData();
    fd.set('field', domEvent.target.name);
    fd.set('by', getAuthData('uuid'));
    fd.set('id', currentVideoBlock.vsb_id);
    fd.set('isOkayed', +domEvent.target.checked); // The + converts the true/false to an integer
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_REVIEWED, { body: fd }, (data) => {
      switch (data.field) {
        case 'proofed':
          setCurrentVideoBlock({
            ...currentVideoBlock,
            Proofed_By: data.by,
            Proofed_Date: data.date,
            Proofed: data.okayed
          });
          break;
        case 'curated':
          setCurrentVideoBlock({
            ...currentVideoBlock,
            Curated_By: data.by,
            Curated_Date: data.date,
            Curated: data.okayed
          });
          break;
        default:
          setCurrentVideoBlock({
            ...currentVideoBlock,
            Approved_By: data.by,
            Approved_Date: data.date,
            Approved: data.okayed
          });
      }
    });
  }

  const onCommentsChange = (domEvent) => {
    setCurrentVideoBlock({
      ...currentVideoBlock,
      vsb_tags: domEvent.target.value
    });
  }

  const onShowImport = () => {
    if(importModal?.current) {
      importModal.current.showModal();
    }
  }

  const onCloseImport = () => {
    if(importModal?.current) {
      importModal.current.close();
    }
  }

  const onShowVideos = () => {
    if(addBySearchModal.current) {
      addBySearchModal.current.showModal();
    }
  }

  const onCheckForDuplicates = () => {
    setCheckForDupes({
      ...checkForDupes,
      dupes: checkDupes([...list])
    });

    if(checkForDupesModal.current) {
      checkForDupesModal.current.showModal();
    }
  }


  const onShowAddByUrlModal = () => {
    if(addByUrlModal.current) {
      addByUrlModal.current.showModal();
    }
  }

  const onCloseAddByUrlModal = () => {
    setAddByURL({
      ...addByURL,
      value: ''
    })
    if(addByUrlModal?.current) {
      addByUrlModal.current.close();
    }
  }

  const onAddVideoByUrlSubmit = (domEvent = null) => {
    domEvent.preventDefault();
    let postdupecheck = false;

    // Videos from the first submission.
    let {add_videos_by_url_urls} = domEvent.target;
    if( add_videos_by_url_urls) {
      setSubmissions(splitURLs(add_videos_by_url_urls.value));
    }

    if (domEvent) {
      postdupecheck = Object.hasOwn(domEvent.target.elements, 'PostDupeCheck');
    }

    // Find duplicates, then get unique values.
    let dupes = checkUrlsForDupes(addByURL.value, list);
    dupes = Array.from(new Set(dupes));

    /*
      If we have duplicates, and this form isn't being submitted after the duplicate
      checked display the 'Duplicates Found' modal.
    */
    if(dupes.length && !postdupecheck) {
      setTimeout(() => {
        addByUrlModal.current.close();
        checkForDupesModal.current.showModal()
        setCheckForDupes({...checkForDupes, dupes: dupes, loading: false});
      }, 100);
    } else {
        setCheckForDupes({...checkForDupes, loading: true});
        onSubmitUrlPostConfirmation(domEvent.target);
    }
  }

  const onSubmitUrlPostConfirmation = (formElement) => {
    const {duplicates} = formElement.elements;

    // Returned the YouTube IDs for checked boxes only.
    let exclude = [];
    if(duplicates.length) {
      exclude = Array.from(duplicates).filter((d) => !d.checked).map((d => d.value));
    }

    /*
     Filter the excluded values from the submitted ones.
    */
    
    let submits = submissions.map(expandShortYouTubeUrl)
                    .map(extractYouTubeIdFromUrl)
                    .filter((s) => {return exclude.indexOf(s) == -1})

    if(submits.length) {
      const fd = new FormData();
      fd.set('blockid', currentVideoBlock.vsb_id);
      fd.set('userID', getAuthData('uuid'));
      fd.set('token', getAuthData('token'));
      fd.set('videoids', submits.join(','));
      fd.set('prepend', +!addByURL.toggle.selected);

      apirequest(API_VIDEO_ADDBATCH, {body: fd}, (response) => {
        if (
          !response ||
          (Object.hasOwn(response, 'error') && response.error)
        ) {
          window.alert(`ERROR: ${response.message}`);
        } else {

          setCheckForDupes(initCheckForDupesState);
          setAddByURL(initAddByUrlState);
          setList([]);

          if(checkForDupesModal.current) {
            checkForDupesModal.current.close();
          }

          if(addByUrlModal.current) {
            addByUrlModal.current.close();
          }
        }
      });
    } else {
      setAddByURL({...addByURL, maxIds: true});
    }
  }

  const onAddByUrlChange = (domEvent) => {
    const { value } = domEvent.target;

    setAddByURL({
      ...addByURL,
      value: value,
      maxIds: stringToArray(value).filter(l => l != '').length > 50
    });
  }

  const onPrependChange = (domEvent) => {
    const {value} = domEvent.target;
    const { toggle } = addByURL;

    setAddByURL({
      ...addByURL,
      'toggle': {
        ...toggle,
        selected: +value
      }
    });
  }

  const onVideoSelected = (domEvent) => {
    alert('update state with the video!')
  }

  const onCheckDupesClose = () => {
    setCheckForDupes(initCheckForDupesState);
    if(checkForDupesModal.current) {
      checkForDupesModal.current.close();
    }
  }

  const onAddBySearchClose = () => {
    if(addBySearchModal.current) {
      addBySearchModal.current.close();
    }
    setAddBySearch(initAddBySearchState);
  }

  const onCheckForUpdates = () => {
    if(checkForUpdatesModal.current) {
      checkForUpdatesModal.current.showModal();
    }

    const fd = new FormData();
    fd.set('id', vsbID);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_CHECKFORUPDATES, { body: fd }, (response) => {
      // Passes response back up to the parent for processing.
      onCheckForUpdatesResponse(response);
    });
  }

  const onCheckForUpdatesResponse = (response) => {
    if(!response) return;
    const {changed, videoList} = response;

    if (!changed.length) {
      setCheckForUpdates({
        ...checkForUpdates,
        loading: false,
      });
    } else {

      const updates = [...list];

      changed.forEach((vid) => {
        const idx = updates.findIndex((t) => t.ytID === vid.YouTube_ID);

        let upv;
        if (vid.Current_Title) {
          upv = { ...updates[idx], name: vid.Current_Title };
        }
        updates[idx] = upv;
      });

      setCheckForUpdates({
        ...checkForUpdates,
        loading: false,
        updates: changed
      });
      setList(normalizeVids(videoList.returnData));
    }
  }

  const onCheckForUpdatesClose = () => {
    if(checkForUpdatesModal.current) {
      checkForUpdatesModal.current.close();
    }
    setCheckForUpdates(initCheckForUpdatesState);
  }

  const onCheckDupesErrorClose = () => {
    setCheckForDupes({
      ...checkForDupes,
      mode: 'checking',
      message: ''
    });
  }

  const saveNewOrder = (ordered) => {
    const fd = new FormData();
    const {vsb_id} = currentVideoBlock;

    fd.set('id', vsb_id);
    fd.set('videos', ordered.join(','));
    fd.set('token', getAuthData('token'));
    fd.set('prepend', +!addByURL.toggle.selected);

    apirequest(API_VIDEOSETBLOCK_REORDERED, { body: fd }, (response) => {
      const {returnData} = response;
      setList(normalizeVids(returnData));
    });
  }

  const onRemoveInstance = (domEvent) => {
    domEvent.preventDefault();
    let confirm = false;
    let vid = false;

    const {video} = domEvent.target.elements;

    if (video) {
      vid = list.find((item) => item.ytID === video.value);
    }
    if (!vid) return;

    // eslint-disable-next-line no-alert
    confirm = window.confirm(`Are you sure you want to delete this instance of "${vid.name}"?`);
    if (confirm) {

      const fd = new FormData(domEvent.target);
      fd.set('token', getAuthData('token'));

      const { signal } = fetchCancel;
      apirequest(API_VIDEO_REMOVEFROMSET, { body: fd, signal }, (response) => {
        const {returnData} = response.videoList;
        setList(normalizeVids(returnData));
      });
    }
  }

  const onCRTSubmitted = (response) => {

    const {youtubeid, crt} = response;
    const index = list.findIndex((f) => f.ytID == youtubeid);
    const changedList = [...list];

    const update = {...changedList[index]};
    update.crt = crt;
    changedList[index] = update;

    setList(changedList);
  }

  const onRatingSubmitted = (response) => {
    const {youtubeid, rating} = response;
    const index = list.findIndex((f) => f.ytID == youtubeid);
    const changedList = [...list];

    const update = {...changedList[index]};
    update.rating = rating;
    changedList[index] = update;

    setList(changedList);
  }

  const onMeowSubmitted = (response) => {
    const {youtubeid, meow} = response;
    const index = list.findIndex((f) => f.ytID == youtubeid);
    const changedList = [...list];

    const update = {...changedList[index]};
    update.meow = meow;
    changedList[index] = update;

    setList(changedList);
  }

  const onSetDelete = () => {
    if(deleteSetRef.current) {
      deleteSetRef.current.showModal()
    }
  }

  const onSetDeleteClose = () => {
    if(deleteSetRef.current) {
      deleteSetRef.current.close();
    }
  }

  const cssClass =  classNames({
    video__display: true,
    'video__display--grid': useGrid,
    'video__display--listview': !useGrid,
  });

  return (
    <div id="destinations__view">
      <Breadcrumb
        ariaLabel="Destinations navigation"
        items={makeBreadcrumbs()}
      />

      <DestinationsPlaylistTitle
        {...currentVideoBlock}
        duplicated={duplicated}
        isAdmin={canAdmin}
        onDuplicate={onDuplicate}
        onSetDelete={onSetDelete}
      />

      <RunDate
        date={currentVideoBlock.vsb_datetimetorun}
        onInputChange={onRunDateChange}
        className="playlist__rundate"
      />

      <DestinationsPlaylistHeader
        runtime={runTime}
        rating={playlistRating}
        count={list.length}
        crtstatus={crtstatus}
        videosetid={currentVideoBlock.vsb_id}
        onSubmit={onPlaylistSave}
        onPlaylistMetaChange={onPlaylistMetaChange}
        onApprovedProofedCuratedChange={onApprovedProofedCuratedChange}
        playlist={...currentVideoBlock}
        onCommentsChange={onCommentsChange}
        onCheckForDuplicates={onCheckForDuplicates}
        onCheckForUpdates={onCheckForUpdates}
        onAddBySearch={onShowVideos}
        onAddByUrl={onShowAddByUrlModal}
        onExport={() => { exportVideoData(list) }}
        onPlay={() => { playAllVideos(list) }}
        onImport={onShowImport}
        onShuffle={onShuffle}
      />

      <div className={cssClass} style={{'marginBlock':'2rem'}}>
        { makeVideos() }
      </div>

      <VideosList
        modalRef={addBySearchModal}
        currentSet={list}
        blockId={vsbID}
        onSubmit={() => setList([])}
      />

      <AddVideosByURL
        {...addByURL}
        modalRef={addByUrlModal}
        block={...currentVideoBlock}
        onClose={onCloseAddByUrlModal}
        onSubmit={onAddVideoByUrlSubmit}
        onChange={onAddByUrlChange}
        onPrependChange={onPrependChange}
      />

      <DestinationsPlaylistRunDateModal
        rundate={duplicatePL.rundate}
        modalRef={duplicateRunDate}
        onClose={onCloseRunDateModal}
        onSubmit={onDuplicateSubmit}
        onChange={onDuplicateRunDateChange}
        onDuplicateSkip={onDuplicateSkip}
      />

      <ImportSpreadsheetToPlaylist
        modalRef={importModal}
        onSubmit={() => setList([])}
        {...currentVideoBlock}
        onClose={onCloseImport}
      />

      <CheckForUpdatesProgress
        {...checkForUpdates}
        modalRef={checkForUpdatesModal}
        videoset={vsbID}
        onClose={onCheckForUpdatesClose}
        onServerResponse={onCheckForUpdatesResponse}
      />

      <CheckForDuplicatesModal
        {...checkForDupes}
        modalRef={checkForDupesModal}
        onClose={onCheckDupesClose}
        inSet={list}
        playlist={vsbID}
        onRemove={onRemoveInstance}
        onSubmit={onAddVideoByUrlSubmit}
        onErrorClose={onCheckDupesErrorClose}
      />

      <VideoModal
        modalRef={videoModal}
        isAdmin={canAdmin}
        id="DestinationPlaylistVideoModal"
        mode="videos_in_system"
        youtubeId={videoToPlay}
        onClose={onPlayVideoClose}
        onCRTSave={onCRTSubmitted}
        onRatingSave={onRatingSubmitted}
        onMeowSave={onMeowSubmitted}
        />

        <DeleteSetModal
          modalRef={deleteSetRef}
          blockId={vsbID}
          title={currentVideoBlock.Video_Set}
          stationId={currentVideoBlock.station_id}
          setId={currentVideoBlock.vsb_setID}
        />
    </div>
  );
}

export default (props) => (
  <DestinationPlaylist
    {...props}
    params={useParams()}
  />
);
