/**
 * StationsSeriesTable
 * Display table data for the stations series.
 */
import React, {useState} from 'react';
import { Link } from 'react-router-dom'

import './css/StationsSeriesTable.css';

const StationsSeriesTable = ({
  onEditSeriesTitle,
  onAddEpisode,
  onViewEpisodes,
  headers = [],
  rows = []

}) => {

  const [stations, setStations] = useState([]);

  const makeHeader = () => {
    const ths = headers.map((thd, idx) => {
      return <th key={idx}>{thd}</th>;
    });
    return <thead><tr>{ths}</tr></thead>;
  }

  const makeLink = (item) => {
    let link;

    if(
      !Object.hasOwn(item, 'isSeries') ||
      (Object.hasOwn(item, 'isSeries') && item?.isSeries === true)
    ) {
      link = <Link to={`/stations/series/${item.setID}`}>{item.setName}</Link>;
    } else {
      link = <Link to={`/stations/episode/${item.setID}`}>{item.setName}</Link>;
    }
    return link;
  }

  const makeControls = (item) => {
    let controls;

    if(
      !Object.hasOwn(item, 'isSeries') ||
      (Object.hasOwn(item, 'isSeries') && item?.isSeries === true)) {

      controls = (
        <span>
          <button
            type="button"
            className="btn btn-sm btn-light stations__btn__editseries" 
            data-episode={item.setID}
            data-setname={item.setName}
            onClick={onEditSeriesTitle}
          >Edit Series Title</button>

          <button
            type="button"
            className="btn btn-sm btn--action"
            data-episode={item.setID}
            data-setname={item.setName}
            onClick={onViewEpisodes}
          >View Episodes</button>

          <button
            type="button"
            className="btn btn-sm btn--action"
            data-series={item.setID}
            onClick={onAddEpisode}
          >Add Episode</button>
        </span>
      );
    } else {
      controls = <Link to={`/stations/episode/${item.setID}`}>View episode</Link>
    }
    return controls;
  }

  const makeRows = () => {
    const trs = rows.map((trd, idx) => {
      return (
        <tr key={`row_${idx}`}>
          <td>{makeLink(trd)}</td>
          <td>
            {makeControls(trd)}
          </td>
        </tr>
      );
    });

    return <tbody>{trs}</tbody>;
  }

  return (
    <table className="stations__series__table">
      {makeHeader()}
      {makeRows()}
    </table>
  );

}

export default StationsSeriesTable;
