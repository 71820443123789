import React from 'react';
import PropTypes from 'prop-types';

const SearchMoreOptions = ({open, onClick}) => {
  const txt = open ? 'Hide advanced options' : 'Show advanced options';
  return <button onClick={onClick} type="button" className="trigger__fauxlink">{txt}</button>;
}

SearchMoreOptions.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default SearchMoreOptions;