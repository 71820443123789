import React from 'react';

import PropTypes from 'prop-types';
import { CloseButton } from '../subcomponents/CloseButton';

import TextInputControlled from '../subcomponents/TextInputControlled';
import ModalDialog from '../subcomponents/ModalDialog';

import { API_VIDEOSET_ADDNEW } from '../../js/Configuration';
import { getAuthData } from '../../js/Utilities';

const DestinationsRename = ({
  modalRef,
  destination,
  value,
  onChange,
  onSubmit,
  onClose
}) => {
  const {dID, dName} = destination;

  return (
    <ModalDialog ref={modalRef} id="DestinationsRename" onClose={onClose}>
      <h2>Rename <q>{dName}</q></h2>
      <form
        onSubmit={onSubmit}
        id="destination_create_vidset"
        action={API_VIDEOSET_ADDNEW}
        method="POST"
      >
        <input type="hidden" name="token" value={getAuthData('token')} />
        <input
          type="hidden"
          name="destination"
          value={dID || ''}
          onChange={() => {}}
        />
        <div className="form__label_btn_group">
          <TextInputControlled
            cssClass="destinations__create-new"
            labelText="Enter the new name for this destination"
            name="name"
            id="create"
            maxLength="50"
            onInputHandler={onChange}
            value={value || ''}
          />
          <button type="submit" className="btn btn--action">Save</button>
        </div>
      </form>
    </ModalDialog>
  );
}

export default DestinationsRename;