/**
 * AppleLogo.js
 * Apple shortened logo in SVG for crispness.
 **/

import React from 'react';
import classNames from 'classnames';

const AppleLogo = ({
  cssClass='',
  title='Apple',
  fill='#000',
  width=200,
  height=200
}) => {
  const cssClasses = classNames('apple__logo', cssClass);
  
  return (
    <svg style={{aspectRation: '1'}} className={cssClasses} width={width} height={height} viewBox="0 0 1250 1250" version="1.1">
        <title>{title}</title>
      <rect x="0" y="0" width="1250" height="1250" style={{'fill': 'none'}}/>
      <clipPath id="_clip1">
        <rect id="Artboard11" x="0" y="0" width="1250" height="1250" />
      </clipPath>
      <g clipPath="url(#_clip1)">
        <path fill={fill} d="M1148.33,912.776c-19.536,62.615 -50.342,125.229 -92.419,187.843c-64.618,98.18 -128.986,147.27 -193.103,147.27c-24.545,0 -59.609,-8.015 -105.193,-24.044c-43.078,-16.03 -80.897,-24.044 -113.457,-24.044c-30.556,0 -66.121,8.265 -106.695,24.795c-40.574,17.031 -73.635,25.547 -99.181,25.547c-76.14,0 -151.527,-64.869 -226.164,-194.606c-73.634,-130.739 -110.452,-256.719 -110.452,-377.941c0,-114.209 28.302,-207.88 84.906,-281.014c56.102,-72.131 127.232,-108.197 213.39,-108.197c36.066,0 80.397,7.513 132.993,22.541c52.095,15.027 86.658,22.541 103.689,22.541c22.541,0 58.357,-8.516 107.447,-25.547c51.093,-17.031 94.422,-25.546 129.987,-25.546c59.609,0 112.957,16.279 160.043,48.839c26.048,18.033 52.095,43.079 78.143,75.137c-39.573,33.562 -68.125,63.116 -85.657,88.662c-32.559,47.086 -48.839,98.931 -48.839,155.535c0,62.113 17.281,117.965 51.845,167.556c34.563,49.591 74.135,81.148 118.717,94.673Zm-282.517,-881.361c0,30.556 -7.263,64.618 -21.79,102.187c-15.027,37.568 -38.32,72.132 -69.877,103.689c-27.05,27.05 -54.099,45.083 -81.149,54.099c-18.534,5.51 -44.581,9.768 -78.143,12.773c1.503,-74.636 21.039,-139.004 58.608,-193.103c37.067,-53.598 99.682,-90.665 187.843,-111.203l3.757,16.53l0.751,15.028Z" style={{'fillRule': 'nonZero'}} />
      </g>
    </svg>
  );
}

export default AppleLogo;