import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  removeAuthData,
  apirequest
} from '../js/Utilities';

import '../css/logoutPage.css';

const LogoutPage = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    apirequest('api/logout', {method: 'GET'}, () => {
      removeAuthData();
      setUser(null)
    });
  
  });

  return (
    <div id="content" className="logout__page">
      <div>
        <h1>You're now logged out.</h1>
        <Link to="/">Log in again</Link>
      </div>
    </div>
  );
}

export default LogoutPage;
