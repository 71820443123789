/**
 * TextInput.jsx
 * A no-framework text input 
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TextInput = ({
  onInputHandler,
  value,
  name,
  id,
  cssClass = '',
  datalist=[],
  isRequired=false,
  labelText="Add label text",
  ariaDescribedBy=''
}) => {

  const cssClasses = classNames('input-text', cssClass);
  const fieldId = id || name;

  let dataList = null, dataListAttr = null;

  const makeDataList = (arrayOfOptions) => {
    return arrayOfOptions.map( (item, index) => {
      return <option value={item.title} key={index.toString()} />
    });
  }

  if(datalist?.length) {
    dataListAttr = `${id}_dl`;
    dataList = <datalist id={dataListAttr}>{makeDataList(datalist)}</datalist>;
  }

  return (
    <div className={cssClasses}>
      <label htmlFor={id}>{labelText}</label>
      <input type="text"
        name={name}
        id={fieldId}
        list={dataListAttr}
        onChange={onInputHandler}
        value={value}
        required={isRequired}
        aria-describedby={ariaDescribedBy} />
      {dataList}
    </div>
  );
}

export default TextInput;
