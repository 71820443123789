/**
 * RatingsMenu.jsx
 * Displays individual channels. Used on Videos page for individual video items.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PleaseAddToAva } from '../Videos/PleaseAddToAva';

const RatingsMenu = ({
  onChangeHandler=()=>{},
  onSubmitHandler=()=>{},
  actionURL='api/ratings',
  cssClass='',
  method='POST',
  options=[],
  value=null,
  disabled=false
}) => {

  const makeMenuItems = (arrayOfItems=[]) => {
    const defaultSelect = [<option key='selectrating_rating-1' value="">Select Rating</option>];
    let menu = null;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={`selectrating_${index.toString()}`} value={index}>{item.label}</option>
      });
    }
    menu = defaultSelect.concat(menu);

    return menu;
  }

  const cssClasses = classNames(
    cssClass,
    {
      'video__form__ratings': true,
      'video__info__field': true
    }
  );

  return (
    <form action={actionURL} method={method} className={cssClasses} onSubmit={onSubmitHandler}>
      <label htmlFor="video_rating">Assign a rating to this video:</label>
      <PleaseAddToAva hidden={ !disabled } />
      <span className="form__input_button_group">
        <select name="video_rating" id="video_rating" onChange={onChangeHandler} value={value} disabled={disabled}>
          {makeMenuItems(options)}
        </select>
        <button type="submit" disabled={disabled} className="btn btn--action">Save</button>
      </span>
    </form>
  );
};

RatingsMenu.propTypes = {
  actionURL: PropTypes.string,
  method: PropTypes.string,
  cssClass: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  })).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};

export default RatingsMenu;