/**
 * ReportsVideoUse.js
 * 
 */

import React from 'react';
import {
  useEffect,
  useState
} from 'react';

import { useLocation } from 'react-router-dom';
import SearchText from './SearchText';
import ReportsResults from './ReportsResults';
import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  apirequest,
  debounce,
  getAuthData
} from '../../js/Utilities';

import {
  API_REPORTS_VIDEOS_TYPEAHEAD,
  API_REPORTS_VIDEO_USAGE
} from '../../js/Configuration';

import '../../css/ReportsVideoUse.css';

const ReportsVideoUse = ({params=null}) => {
  let display;
  const limit = 50;

  const [ videos, setVideos ] = useState([]);
  const [ video, setVideo ] = useState('');
  const [ destinations, setDestinations ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ results, setResults ] = useState(null);

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  const inputHandler = (domEvent) => {
    domEvent.persist(); // Necessary for React to persist the event.
    debouncedInputHandler(domEvent.target.value);
  }

  const debouncedInputHandler = (value = '') => {
    if(!value.length) return;
    const fd = new FormData();
    fd.set('search', value);
    fd.set('token', getAuthData('token'));

    apirequest(API_REPORTS_VIDEOS_TYPEAHEAD, {body: fd}, (data) => {
      setVideos(data.data);
    });
  }

  const onSearchSubmit = (domEvent) => {
    domEvent.preventDefault();

    setLoading(true);
    setVideo(domEvent.target.video.value)

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_REPORTS_VIDEO_USAGE, {body: fd}, (data) => {
      setDestinations(data.data);
      setResults(data.data.length);
      setLoading(false);
    });
  }

  const massageDataListResults = (results) => {
    if(!results) return;
    const res = results.map( (result) => {
      return {
        title: result.video_title,
        id: result.youtubeID,
      }
    });
    return res;
  }

  const massageResults = (results) => {
    const res = results.map( (result) => {
      const setname = result['Video Set / Series'] ? `: ${result['Video Set / Series']}` : '';
      const eptitle = result['Episode Title'] ? ` (${result['Episode Title'].trim()})` : '';
      return {
        title: `${result['Destination or Station']}${setname}${eptitle}`,
        frequency: result.Frequency
      }
    });
    return res;
  }

  if(loading) {
    display = <LoaderSimple open={true} />
  } else {
     display = (
      <ReportsResults
        columnHeadings={['Destination or Station Title: Series Name (Episode Title)', 'Frequency']}
        results={massageResults(destinations)}
        count={results}
        downloadURL={`${API_REPORTS_VIDEO_USAGE}/csv`}
        limit={limit}
        video={video} />
      );
  }

  return (
    <div className="reports__page">
      <h1>{params.state.title}</h1>
      <SearchText
        apiURL={API_REPORTS_VIDEO_USAGE}
        datalist={massageDataListResults(videos)}
        id="video"
        labelText="Enter a video title, URL or YouTube ID:"
        name="video"
        onInput={inputHandler}
        onSubmit={onSearchSubmit}
        limit={limit} />
      {display}
    </div>
  );
}

export default () => <ReportsVideoUse params={useLocation()} />
