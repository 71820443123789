/**
 * Created by Webinista, Inc 9/04/18.
 */

import React from 'react';
import SearchDates from './SearchDates';
import ReportsResults from './ReportsResults';
import LoaderSimple from '../subcomponents/LoaderSimple';
import { useLocation } from 'react-router-dom';
import {format, subDays} from 'date-fns';

import {
  apirequest,
  getAuthData,
  yyyymmdd
} from '../../js/Utilities';

import {
  API_REPORTS_DROPOFFS,
  API_REPORTS_DROPOFFS_CSV,
  NULL_DATE_TEXT,
  NULL_VIDEOSET_TITLE_TEXT
} from '../../js/Configuration';

import '../../css/ReportsVideoDropOffs.css';

const ReportsVideoDropOffs = (props) => {

  const today = new Date();
  const daysago7 = subDays(today, 7);

  const [destination, setDestination] = React.useState('');
  const [destinations, setDestinations] = React.useState([]);
  const [range, setRange] = React.useState({
    start: format(daysago7, 'yyyy-MM-dd'),
    end: format(today, 'yyyy-MM-dd')
  });
  const [videos, setVideos] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState(null); // Number of results returned
  const [limit, setLimit] = React.useState(500);

  const {params} = props;

  const massageResults = (results) => {
    let res = [false];
    
    res = results.data.map((item) => {
      return {
        title: item['Video Title'],
        url: item.url,
        vs_title: item['Video Set'],
        episode: item.Episode  || NULL_VIDEOSET_TITLE_TEXT,
        rundate: item['Run Date'] || NULL_DATE_TEXT,
        frequency: yyyymmdd(new Date(item.video_datelastupdated))
      }
    });

    setVideos(res);
    setResults(res.length);
    setLoading(false);
  }

  const doSearch = () => {
    const fd = new FormData();
    fd.set('start', range.start);
    fd.set('end', range.end);
    fd.set('limit', limit);
    fd.set('token', getAuthData('token'));

    apirequest(API_REPORTS_DROPOFFS, {body: fd}, massageResults);
  }

  const onChange = (domEvent) => {
    const {name, value} = domEvent.target;

    setRange({
      [name]: value
    });
  }

  const onSubmit = (domEvent) => {
    domEvent.preventDefault();
    setLoading(true);
    doSearch();
  }

  const onComponentMount = () => {
    setLoading(true);
    doSearch();
  }
  
  React.useEffect(() => {
    if(!videos.length) {
      onComponentMount()
    }
  }, [results]);

  let display;
  if(loading) {
    display = <LoaderSimple open={true} />
  } else {
     display = (
      <ReportsResults
        columnHeadings={[
          'Video Title',
          'Video Set',
          'Video Set Title',
          'Run Date',
          'Date Found Inactive'
        ]}
        results={videos}
        count={results}
        destination={destination}
        downloadURL={API_REPORTS_DROPOFFS_CSV}
        start={range.start}
        end={range.end}
        limit={limit}
        cssClass="reports__table__videodropoffs" />
      );
  }

  return (
    <div className="reports__page">
      <h1>{params.state.title}</h1>
      <SearchDates
        apiURL={API_REPORTS_DROPOFFS}
        cssClass="reports__dropoffs__searchdates"
        destinations={destinations}
        id="destination"
        start={range.start}
        end={range.end}
        labelText="Select a destination:"
        limit={limit}
        name="destination"
        onChange={onChange}
        onSubmit={onSubmit} />
      {display}
    </div>
  );
}

export default (props) => (
  <ReportsVideoDropOffs
      {...props}
      params={useLocation()}
  />
);