/**
 * CheckForDuplicatesRemove.jsx
 */

import React from 'react';

export const CheckForDuplicatesRemove = (props) => {
  return (
    <form onSubmit={props.onSubmit} id="CheckForDuplicatesRemove">
      <div>
        <input type="hidden" name="video" value={props.id} onChange={()=>{}} />
        <input type="hidden" name="order" value={props.order} onChange={()=>{}} />
        <input type="hidden" name="playlist" value={props.playlist} onChange={()=>{}} />
        <button type="submit" className="btn btn--action">Remove this instance</button>
      </div>
    </form>
  );
}
