import React, { useState, useRef, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import uniqueId from 'lodash.uniqueId';
import SearchForm from './Search/SearchForm';
import SearchResultsPanel from './Search/SearchResultsPanel';
import ChannelsList from './ChannelsList';
import VideoModal from './subcomponents/Videos/VideoModal';
import LoaderSimple from './subcomponents/LoaderSimple';

import {
  API_SEARCH,
  API_VIDEO_INFO,
  API_VIDEO_ADDTOSET,
  VIDEOS_PER_PAGE
} from '../js/Configuration';

import {
  apirequest,
  getAuthData,
  removeAuthData,
  getSortDirection,
  isAdmin,
  showLoader,
  yyyymmdd,
  isEmptyObject,
  slugify
} from '../js/Utilities';

import {
  initCriteria,
  initGroups,
  initLanguage,
  getChannels,
  getGroups,
  getRegions,
  getLanguages,
  search,
  addVideo,
} from '../actions/SearchUtils';

import '../css/SearchMultiChannel.css';

const SearchMultiChannel = ({project}) => {

  // Prevents API requests from clogging up and blocking the UI.
  const fetchcancelqueue = new Map();

  const [language, setLanguage] = useState(initLanguage);

  const [results, setResults] = useState({});
  const [loading, setLoading] = useState({'All Channels': false});

  const [video, setVideo] = useState('');

  /*
   Even though the ChannelsList form is a subcomponent to SearchForm,
   it's a separate form element.

   Since ChannelsList can be submitted separately from SearchForm, we'll pass its
   submit value (an array of channel IDs) up to this component and make the search
   request from here instead of handling it all in SearchForm.

   The results will be displayed here, so I ~think~ this makes sense.
   */
  const [channels, setChannels] = useState([]);

  const modalRef = useRef(null);

  const panels = [];
  const canAdmin = isAdmin(project);

  const makeFormData = (formObject) => {
    const fd = new FormData(formObject);
    fd.set('token', getAuthData('token'));
    fd.delete('searchGroup');

    /* If the region code value is all, then unset it. */
    if(fd.get('regionCode') === 'all' || fd.get('regionCode') === 'YTE') {
      fd.delete('regionCode');
    }

    if(fd.get('relevanceLanguage') === 'all' || fd.get('relevanceLanguage') === 'YTE') {
      fd.delete('relevanceLanguage');
    }

    return fd;
  }

  const onServerResponse = (channel, response) => {
    setResults((prev) => {
      return {
        ...prev,
        [channel]: {
          ...prev[channel],
          ...response
        }
      };
    });
    setLoading((prev) => {
      return {
        ...prev,
        [channel]: false
      }
    });
  }

  const onRequestPage = (domEvent) => {
    domEvent.preventDefault()
    const {form} = domEvent.target;
    const {page, channel} = domEvent.target.dataset;

    setLoading((prev) => {
      return {
        ...prev,
        [channel]: true
      }
    });

    const fd = makeFormData(form);
    fd.set('pageToken', page);

    if(channel === 'All Channels') {
      fd.set('maxResults', VIDEOS_PER_PAGE * 2);
    } else {
      fd.set('channelId', channel);
      fd.set('maxResults', VIDEOS_PER_PAGE);
    }

    const ac = new AbortController();
    const {signal} = ac;

    search({body: fd, signal}, (response) => {
      onServerResponse(channel, response);
    });
  }

  const onCloseVideo = () => {
    if(modalRef.current) {
      modalRef.current.close()
    }
    setVideo(null);
  }

  const onPlayVideo = (videodata) => {
    const {video_yt_id} = videodata;
    setVideo(video_yt_id);

    if(modalRef.current) {
      modalRef.current.showModal()
    }
  }

  const onSubmit = (domEvent) => {
    setLoading(true)
    setResults({});
    domEvent.preventDefault();

    const fd = makeFormData(domEvent.target);

    if(channels.length) {
      channels.forEach((c) => {
        const ac = new AbortController();
        const {signal} = ac;

        fd.set('channelId', c);
        fd.set('maxResults', VIDEOS_PER_PAGE);
        search({body: fd, signal}, (response) => {
          const {channel_ytid} = response;

          setResults((prev) => {
            return {
              ...prev,
              [channel_ytid]: response
            }
          });

          setLoading(() => {
            return {
              ...loading,
              [channel_ytid]: false
            }
          });

        });
      });
    } else {

      const ac = new AbortController();
      const {signal} = ac;
      fd.set('maxResults', VIDEOS_PER_PAGE * 2);
      search({body: fd, signal}, (response) => {
        setLoading(false);
        setResults((prev) => {
          return {
            ...prev,
            ['All Channels']: response
          }
        });
      });
    }
  }

  const onChannelSearchSubmit = (channelList) => {
    setChannels(channelList);
  }

  const channelIds = Object.keys(results);

  return (
      <div style={{minHeight: '70rem'}}>
        <SearchForm
          onSubmit={onSubmit}
          onChannelSubmit={onChannelSearchSubmit}
          onReset={() => setResults({})}
        />

       { channelIds.length > 0 &&
          channelIds.map((c) => {
            return (
              <SearchResultsPanel
                id={c}
                {...results[c]}
                key={uniqueId()}
                onRequestPage={onRequestPage}
                onPlayVideo={onPlayVideo}
                loading={loading[c]}
              />
            );
          })
       }

       <VideoModal
          modalRef={modalRef}
          isAdmin={canAdmin}
          id="SearchResultsModal"
          youtubeId={video}
          onClose={onCloseVideo}
        />

      </div>
    );

}

export default (props) => (
    <SearchMultiChannel
        {...props}
        params={useParams()}
    />
);
