import React from 'react';
import PropTypes from 'prop-types';

import './css/SearchInChannels.css';

const SearchInChannels = ({onShowChannels}) => {
  return (
    <div className="search__form__item form-group search__inchannels">
      <b>Search in Channels:</b>
      <button type="button" className="btn btn-sm btn--action" onClick={onShowChannels}>Show Channel List</button>
    </div>
  )
}

SearchInChannels.propTypes = {
  onShowChannels: PropTypes.func.isRequired
};

export default SearchInChannels;

