/**
 * Videos
 * Top-level component that displays the
 */

import React from 'react';
import VideoModal from './subcomponents/Videos/VideoModal';

import FilterComponent from './Videos/FilterComponent';
import NextPrevious from './Search/NextPrevious';
import LoaderSimple from './subcomponents/LoaderSimple';

import AddVideosField from './Videos/AddVideosField';
import VideoItem from './subcomponents/VideoItem';

import {
  apirequest,
  getAuthData,
  getSortDirection,
  getVideoData,
  isAdmin
} from '../js/Utilities';

import {
  API_CATEGORIES,
  API_VIDEO_ADDTOSET,
  API_RATINGS_VIDEOUPDATE,
  API_RATINGS_ALL,
  API_VIDEO_GETBATCH,
  API_VIDEO_ADDNEW,
  VIDEOS_PER_PAGE,
} from '../js/Configuration';

import '../css/videosPage.css';
import './Destinations/css/VideoDisplay.css';

const Videos = (props) => {
  const initFilterState = {
    searchValue: '',
    filterOn: 'video_title',
    categories: [],
    hideCategories: true,
    category: '',
    showHint: false
  };

  const [videoList, setVideoList] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [videoToPlay, setVideoToPlay] = React.useState('');
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(VIDEOS_PER_PAGE);
  const [pageCount, setPageCount] = React.useState(1);
  const [numResults, setNumResults] = React.useState(0);
  const [ratings, setRatings] = React.useState([]);
  const [selectedRating, setSelectedRating] = React.useState('');

  const [videoinfo, setVideoInfo] = React.useState({
    open: false,
    videoData: {},
    rating: '',
    selectedSet: {
      videosetid: '-1',
      videoblockid: '-1'
    },
    groups: []
  });

  const [filter, setFilter] = React.useState({
    categories: [],
    category: null,
    filterOn: 'video_title',
    hideCategories: true,
    itemsName: 'Videos',
    searchValue: '',
    showHint: false
  });

  const [sortBy, setSortBy] = React.useState('video_setscount');
  const [ascending, setAscending] = React.useState('');

  const fetchcancelqueue = new Map();

  const videoModalRef = React.useRef(null);

  const canAdmin = isAdmin(props.project)

  React.useEffect(() => {
    if(!videoList.length) {
      getPage();
    }

    return () => {
      fetchcancelqueue.forEach((value, key) => {
        value.abort();
      });
    }
  }, [videoList]);

  const addVideoToSet = (domEvent) => {
    domEvent.preventDefault();
    domEvent.persist();

    const fd = new FormData();
    fd.set('videoid', videoinfo.videoData.video_ava_id);
    fd.set('ytid', videoinfo.videoData.video_yt_id);
    fd.set('setid', videoinfo.selectedSet.videosetid);
    fd.set('blockid', videoinfo.selectedSet.videoblockid);
    fd.set('token', getAuthData('token'));


    const ac = new AbortController();
    const signal = ac.signal;
    fetchcancelqueue['addVideoToSet'] = ac;

    apirequest(API_VIDEO_ADDTOSET, {body: fd, signal }, (data) => {
      // TODO: Should probably handle errors too.
      window.alert('Video added to set.');
    });
  }

  const getPage = () => {
    setLoading(true);

    const fd = new FormData();
    fd.set('perpage', perPage);
    fd.set('page', page);
    fd.set('sort_by', sortBy);
    fd.set('filter_by', filter.searchValue);
    fd.set('filter_on', filter.filterOn);
    fd.set('order', getSortDirection(ascending));
    fd.set('token', getAuthData('token'));
    fd.set('category', filter.category);

    const ac = new AbortController();
    const {signal} = ac;
    fetchcancelqueue.set('getPage', ac );

    apirequest(API_VIDEO_GETBATCH, {body: fd, signal}, (response) => {
      let list = [false];
      if(response.videos.length) {
        list = response.videos;
      }

      setFilter({
        ...filter,
        hideCategories: filter.filterOn !== 'video_category'
      });

      let { num_results } = response;
      if(!num_results) {
        num_results = response.videos.length;
      }

      setVideoList(list);
      setPageCount(response.pages);
      setNumResults(+num_results);
      setLoading(false);
    });
  }

  const getNextPage = () => {
     setPage((prev) => {
      let nextPage;
      if(prev > pageCount) {
        nextPage = pageCount;
      } else {
        nextPage = ++prev;
      }
      return nextPage;
    });
    setVideoList([]);
  }

  const getPrevPage = () => {
    setPage((prev) => {
      let prevPage;
      if(prev <= 0) {
        prevPage = 1;
      } else {
        prevPage = prev - 1;
      }
      return prevPage;
    });
    setVideoList([]);
  }

  const onFilterChangeHandler = (domEvent) => {
    const {id, value} = domEvent.target;
    setFilter({
      ...filter,
      [id]: value
    });
    setVideoList([]);
  }

  const onSortChangeHandler = (domEvent) => {
    const {value} = domEvent.target;
    setSortBy(value);
    setVideoList([])
  }

  const onDirectionChangeHandler = (domEvent) => {
    setLoading(true);
    setAscending(!ascending);
    setVideoList([])
  }

  const onFilterClearHandler = (domEvent) => {
    setVideoList([]);
    domEvent.preventDefault();
    setFilter(initFilterState);
    
  }

  const getContent = () => {
    let videos;
    let pagingComponent;
    let list;

    if( loading ) {
      videos = <div className="search__results__results"><LoaderSimple open={true} /></div>;
      pagingComponent = null;

    } else {

      let list = ''
      if(videoList[0] === false) {
        list = <h3>No videos match your criteria</h3>;
      } else {
        const lst = videoList.map( ( _video, index ) => {
          return (
            <VideoItem
              key={ index.toString() }
              videoData={_video}
              id={ _video.video_youtubeID }
              onPlayVideo={playVideo}
            />
          );
        });
        list = <div className="List">{lst}</div>
      }
      videos = <div className="search__results__results video__display video__display--grid">{list}</div>;

      /*
        NextPrevious activates the previous button based on the PRESENCE of a
        prev_page prop, NOT its value. So we're going to conditionally add
        that prop based on the value of page.
      */
      let pgeprops = {
        next: getNextPage,
        prev: getPrevPage,
        result_count: videoList.length,
        per_page: perPage,
      };
      if(page > 1) { pgeprops.prev_page = true; }

      pagingComponent = <NextPrevious {...pgeprops} />;
    }

    let pgCount;
    if(pageCount) {
      if(videoList.length) {
        pgCount = `Page ${page} of ${pageCount} (${numResults} results)`;
      } else {
        pgCount = `Page – of – (0 results)`;
      }
    }

    return (
      <div className="VideoListGroup search__results__panel--open">
        <div className="videos__pagination">
          <span className="videos__pgcount">{pgCount}</span>
          {pagingComponent}
        </div>

        {videos}

        <div className="videos__pagination">
          <span className="videos__pgcount">{pgCount}</span>
          {pagingComponent}
        </div>
      </div>
    );
  }

  const playVideo = (id) => {
    setVideoToPlay(id);
    if(videoModalRef.current) {
      videoModalRef.current.showModal();
    }
  }

  const onPlayVideoClose = () => {
    if(videoModalRef.current) {
      videoModalRef.current.close();
    }
  }

  const addVideo = (domEvent) => {
    domEvent.preventDefault();

    const {elements} = domEvent.target;

    const fd = new FormData();
    fd.set('videoids', elements.video_ids.value);
    fd.set('setID', '-1');
    fd.set('blockID', '-1');
    fd.set('uuid', getAuthData('uuid'));
    fd.set('userName', getAuthData('user'));
    fd.set('token', getAuthData('token'));

    const ac = new AbortController();
    const signal = ac.signal;
    fetchcancelqueue.set('addVideo', ac);

    apirequest(API_VIDEO_ADDNEW, {body: fd, signal}, (data) => {
      if (data.success) {
        setSortBy('video_addeddate');
        setLoading(false);
        setAscending(false);
        setVideoList([]);
        domEvent.target.reset();
      } else {
        alert( 'Something went wrong while adding the video. Please tell an administrator.' );
      }
    });
  }

  const videoSetSelected = (_data) => {
    let value = _data ? _data.value : null;
    setState((state) => {
      return {
        videoinfo: {
          ...state.videoinfo,
          selectedSet: value
        }
      }
    });
  }

  const importGoogleSheets = () => {
    importModal.open(0);
  }

  // After the user changes the CRT value, we should update it in state with
  // method.
  const postCRTHandler = (data) => {
    if(data.status !== 'success') return;

    const vl = [...videoList];
    const whichVideo = vl.findIndex((item) => {
      return item.video_youtubeID === data.video_ytid;
    });

    vl[whichVideo].video_crt = data.video_crt;

    setVideoList(vl);
  }

  const postMeowHandler = (data) => {
    if(data.status !== 'success') return;

    const vl = [...videoList];
    const whichVideo = vl.findIndex((item) => {
      return item.video_youtubeID === data.video_ytid;
    });

    vl[whichVideo].meow = data.meow;

    setVideoList(vl);
  }

  const postRatingHandler = (data) => {
    if( data.error === true ) return;

    const vl = [...videoList];
    const whichVideo = vl.findIndex((item) => {
      return item.video_youtubeID === data.youtubeid;
    });

    vl[whichVideo].rating = data.rating;

    setVideoList(vl);
  }

  const postTagsHandler = (data) => {
    let result;

    if( data.error ) {

      result = data.message;

    } else {

      setState((state) => {
        return {
          videoinfo: {
            ...state.videoinfo,
            videoData: {
              ...state.videoinfo.videoData,
              video_customtags: data.customTags
            }
          }
        }
      }, ( data ) => {
        result = `Updated tags for "${videoinfo.videoData.video_title}" (YouTube ID: ${ videoinfo.videoData.video_yt_id }).`;
      });
    }
    window.alert( result );
  }

  const onDeleteVideo = (data, modalRef=null) => {
    if(!data.videosDeleted.length) return;
    const list = [...videoList];

    // Removes deleted videos from the displayed list.
    const removevid = (id) => {
      const remove = list.findIndex((item) => { return id == item.video_youtubeID; });
      list.splice(remove, 1);
    }
    
    data.videosDeleted.forEach(removevid);

    setState((state) => {
      return {
        videoList: list,
        videoinfo: {
          ...state.videoinfo,
          open: false
        }
      }
    }, () => {
      if (modalRef && Object.hasOwn(modalRef, 'current') ) {
        modalRef.current.close();
      }
    });
  }

  const onDeleteFromBlock = ( data ) => {
    if(!data.videosDeleted.length) {
      window.alert('I could not remove the video from the set. may be a bug. Please tell an administrator.');
      return;
    }

    let remove;
    if( videoinfo.videoData.hasOwnProperty('dest_set_block_assignments') ) {
      const blocks = [...videoinfo.videoData.dest_set_block_assignments];
      remove = blocks.findIndex( (block) => block.block_id === data.deletedFrom );

      const block_title = blocks[remove].vs_name;

      blocks.slice(remove, 1); // Remove that block from the list.

      setState({
        videoinfo: {
          ...videoinfo,
          videoData: {
            ...videoinfo.videoData,
            dest_set_block_assignments: blocks
          }
        }
      }, () => window.alert(`I removed "${ videoinfo.videoData.video_title }" from "${ block_title }"`))
    }
  }

  return (
    <div className="videoListHolder">
      <div id="videoHeaderParent">
          <div id="videoHeader">
            <AddVideosField action={API_VIDEO_ADDNEW} onSubmit={addVideo} />
            <FilterComponent
              { ...filter }
              filterData={[
                  { value:'video_title', name:'Video Title'},
                  { value:'video_title_tags', name:'Video Tags & Title'},
                  { value:'video_description', name:'Video Description'},
                  { value:'video_channel_title', name:'Channel Title'},
                  { value:'video_addedbyname', name:'User Name'},
                  { value:'video_category', name:'Category'},
              ]}
              sortDefault={ sortBy }
              sortData={[
                  { value:'video_title', name:'Video Title'},
                  { value:'video_updateddate', name:'Video Last Updated'},
                  { value:'video_addeddate', name:'Date Video Added to System'},
                  { value:'video_channel_title', name:'Channel Title'},
                  { value:'video_category', name:'Category'},
                  { value:'video_duration_seconds', name:'Video Duration'},
                  { value:'video_viewcount', name:'Video View Count',},
                  { value:'video_likecount', name:'Video Likes Count',},
                  { value:'video_setscount', name:'Number of Video Sets',},
                  { value:'video_publishdate', name:'Publish Date',},
                  { value:'video_channel_publishdate', name:'Channel Date'},
                  { value:'video_rating', name:'Rating'},
                  { value:'meow', name: 'MEOW Status' }
              ]}
              ascending={ascending}
              onSortChange={onSortChangeHandler}
              onFilterChange={onFilterChangeHandler}
              onFilterClear={onFilterClearHandler}
              onDirectionChange={onDirectionChangeHandler}
            />
          </div>
        </div>

      { getContent() }

      <VideoModal
        modalRef={videoModalRef}
        isAdmin={canAdmin}
        id="VideoInfo"
        mode="videos_in_system"
        youtubeId={videoToPlay}
        
        onClose={onPlayVideoClose}
        onTagsSave={() => alert('NEED A TAG SAVE HANDLER! GET FROM DESTINATIONPLAYLIST')}
        onCRTSave={postCRTHandler}
        onRatingSave={postRatingHandler}
        onMeowSave={postMeowHandler}
      />

    </div>
  );
}

export default Videos;