/**
 * PasswordResetShowHide.js
 * Toggle between type="password" and type="text"
 */
import React, {forwardRef} from 'react';
import './css/PasswordResetShowHide.css';
import PropTypes from 'prop-types';

const PasswordResetShowHide = forwardRef(function PasswordResetShowHide(props, ref) {
  const {
    ariaDescribedBy,
    defaultValue,
    disabled,
    id,
    type,
    onClick,
    onChange,
    minLength
  } = props;

  return (
    <span className="PasswordResetShowHide__item">
      <input
        id={id}
        name={id}
        type={type}
        onChange={onChange}
        ref={ref}
        disabled={disabled}
        minLength={minLength}
        aria-describedby={ariaDescribedBy}
        defaultValue={defaultValue}
      />
      <button
        data-controls={id}
        className="btn btn--action"
        type="button"
        hidden={type === 'text'}
        onClick={onClick}
      >
        Show?
      </button>
      <button
        data-controls={id}
        className="btn btn--action"
        type="button"
        hidden={type === 'password'}
        onClick={onClick}
      >
        Hide
      </button>
    </span>
  );
});

PasswordResetShowHide.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PasswordResetShowHide;
