import {
  getAuthData,
  setAuthData
} from '../js/Utilities';

export const setStateOnLoadUser = (state, data) => {
  return {
    ...state,
    user: data.username,
    first_name: data.displayname_first || '',
    second_name: data.displayname_second || '',
    email: data.email,
    uuid: data.uuid,
    uid: data.id
  }
}

export const setStateOnPasswordChange = (state, field, input) => {
  let updates = {
    [field]: {
      ...state[field],
      value: input
    }
  }

  if(field === 'password1' && input === '') {
    updates.password2 = {
      ...state.password2,
      value: ''
    };
  }
  return updates;
}

export const setStateUpdateField = (state, field, input) => {
  return {
    ...state,
    [field]: input
  }
}

export const updateOnPasswordSuggestion = (state, suggestionObj) => {
  return {
    password1: {
      value: suggestionObj.suggestion,
      type: 'text'
    },
    canSave: false
  };
}

export const setStateAfterUpdate = (state, response) => {
  /* let updates = {
    action_mode: response.status,
    update_status: response.message
  };

  let userUpdates = {}
  if(response.hasOwnProperty('data')) {
    userUpdates = {
      ...getAuthData(),
      display_name: response.data.displayname,
      email: response.data.email,
      user: response.data.username
    }
    setAuthData(userUpdates);
  }
  return Object.assign(updates, userUpdates); */
  
  console.log(response)
}

export const setStateOnFormFocus = (state) => {
  return {
    update_status: null,
    action_mode: null
  }
}

export const formFocusInitState = {
  update_status: null,
  action_mode: null,
}
