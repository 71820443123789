import {
  apirequest,
  getAuthData,
} from '../js/Utilities';

import {
  tooManyURLs,
} from './DestinationsUtils';

import {
  API_CLUSTERS_GENREREMOVE,
} from '../js/Configuration';

export const amzUrl = (asin) => `https://amazon.com/dp/${asin}`

// TODO: Delete me after removing from AmzReports
export const setStateOnClusterTitle = (state, response) => {
  let rmeta;

  if (Object.hasOwn(response, 'id')) {
    rmeta = {
      meta: response,
    };
  } else {
    rmeta = {
      meta: {
        error: true,
      },
    };
  }
  return { ...state, ...rmeta };
}

export const makeSaveRequest = (cluster, data, callback) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  fd.set('uuid', getAuthData('uuid'));
  fd.set('cluster', cluster);
  fd.set('data', JSON.stringify(data));
  apirequest('api/importasin', { body: fd }, callback)
}

export const setStateOnAddNew = (state, response) => ({
  ...state,
  addcluster: {
    ...state.addcluster,
    open: true,
    menu: response,
  },
});

export const setStateOnAddNewChange = (state, field, value) => {
  const setError = (field === 'size') ? value === '0' : state.addcluster.error;

  return {
    ...state,
    addcluster: {
      ...state.addcluster,
      [field]: value,
      error: setError,
    },
  }
};

export const setStateOnAddNewSubmit = (state, field, value) => ({
  addcluster: {
    ...state.addcluster,
    [field]: value,
  },
});

export const setStateOnAddNewSubmitResponse = (state, response) => {
  const hasError = Object.hasOwn(response, 'error');
  return {
    ...state,
    addcluster: {
      ...state.addcluster,
      open: hasError,
      error: hasError ? response.error : '',
      message: hasError ? response.message : '',
    },
    genres: Array.from(response).concat([...state.genres]),
  }
}

export const setStateOnReportResponse = (state, response) => ({
  ...state,
  loading: false,
  results: response.results,
});

export const setStateAddGenreOpen = (state) => ({
  ...state,
  addNew: {
    ...state.addNew,
    open: true,
  },
});

export const setStateAddGenreClose = (state) => ({
  ...state,
  addNew: {
    ...state.addNew,
    open: false,
  },
});

export const setStateAddGenreChange = (state, field, value) => ({
  ...state,
  addNew: {
    ...state.addNew,
    [field]: value,
  },
});

export const setStateAddGenrePostSubmit = (state, response) => {
  const updated = [...state.genres];
  updated.unshift(response);

  return {
    ...state,
    genres: updated,
    addNew: {
      ...state.addNew,
      open: false,
    },
  }
}

export const genreRemove = (form, responseCallback = null) => {
  const fd = new FormData(form);
  fd.set('token', getAuthData('token'));
  apirequest(API_CLUSTERS_GENREREMOVE, { body: fd }, responseCallback);
}

export const setStateAfterGenreRemoval = (state, response) => {
  const currentgenres = [...state.genres];
  const newgenres = currentgenres.filter((g) => +response.genre !== +g.id);

  return {
    ...state,
    genres: newgenres,
    loading: false,
  }
}

export const filterGenres = (query, genres) => {
  if (!query) return genres;

  const regexp = new RegExp(query, 'i');

  return genres.filter((g) => regexp.test(g.name));
}
