/**
 * ExportCSV.js
 * Check for updates button.
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/ExportCSV.css';

import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  csvrequest,
  getAuthData,
  forceDownload
} from '../../js/Utilities';

import {
  API_VIDEOSETBLOCK_INFO_CSV
} from '../../js/Configuration';

const ExportCSV = ({
  disabled=false,
  actionURL=API_VIDEOSETBLOCK_INFO_CSV,
  videosetID=0
}) => {

  const [working, setWorking] = React.useState(false);
  
  const onSubmit = (domEvent) => {
    domEvent.preventDefault();
    
    setWorking(true);
    
    if(!videosetID) return;
    
    const fd = new FormData();
    fd.set('id', videosetID);
    fd.set('token', getAuthData('token'));

    csvrequest(API_VIDEOSETBLOCK_INFO_CSV, {body: fd}, (response) => {
      forceDownload(response);
      setWorking(false);
    });
    
  }
  
  return (
    <form action={actionURL} method="POST" target="_blank" className="export__csv" onSubmit={onSubmit}>
      <input type="hidden" id="id" name="id" value={`${videosetID}`} onChange={()=>{}} />
      <input type="hidden" id="token" name="token" value={getAuthData('token')} onChange={()=>{}} />
      <button disabled={disabled || working} type="submit" className="btn btn-sm btn--action saveChangesBtn playListBtn">
        Export CSV
        <LoaderSimple cssClass="export__csv__loader" open={working} />
      </button>       
    </form>
  );
}

ExportCSV.propTypes = {
  actionURL: PropTypes.string.isRequired,
  videosetID: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default ExportCSV;