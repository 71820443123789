/**
 * DragSortList.js
 *
 */
import React, {
  useRef
} from 'react';

import uniqueId from 'lodash.uniqueid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VideoDragItem from './subcomponents/VideoDragItem';

import '../css/DragSortList.css';

const DragSortList = (props) => {
  var dragged = null;
  const wrapper = useRef();

  const dragStart = (domEvent) => {
    domEvent.stopPropagation();

    dragged = domEvent.currentTarget;
    if(dragged.parentElement) {
      dragged.parentElement.classList.add('dragsortlist__is_dragging');
    }

    dragged.classList.add('dragsortlist__dragged_item');

    domEvent.dataTransfer.effectAllowed = 'move';
    domEvent.dataTransfer.setData('text/html', dragged.cloneNode(true));
  }

  const dropped = (domEvent) => {
    if( dragged != domEvent.currentTarget) {
      const wrapper = domEvent.currentTarget.parentElement;

      domEvent.dataTransfer.dropEffect = 'move';

      var before = null;
      if( domEvent.target.previousElementSibling ) {
        before = domEvent.currentTarget.nextSibling;
      } else {
        before = wrapper.firstElementChild;
      }

      wrapper.insertBefore(dragged, before);
    }

    dragged.classList.remove('dragsortlist__dragged_item');
    return false;
  }

  const dragOverEnter = (domEvent) => {
    if (domEvent.preventDefault) {
      domEvent.preventDefault();
    }

    domEvent.dataTransfer.dropEffect = 'move';

    return false;
  }

  const dragEndLeave = (domEvent) => {
    if(dragged?.parentElement) {
      dragged.parentElement.classList.remove('dragsortlist__is_dragging');
    }
  }

  const reSort = (domEvent) => {
    /**
     * Not sure this is the best way to get the new order, but
     * it's what we're doing.
     */
    const newOrder =  [];
    const origOrder = [...props.items];
    const reOrdered = Array.from(wrapper.current.children)
                      .forEach((item, index) => {
                         let ordinal = origOrder.filter((oo) => (item.dataset.dragid == oo.ytID))[0];
                         ordinal = {...ordinal};
                         ordinal.ordinal = index;

                         newOrder[index] = ordinal;
                      });

      // Pass it back up to the parent
      if(Object.hasOwn(props, 'onReSort')) {
        props.onReSort( newOrder )
      }

  }

  const classes = classNames({
    'DragSortList': true,
    ...props.classNames
  });

  const draggables = props.items.map((d, index) => {
    return (
      <li
        data-dragid={d.ytID}
        key={uniqueId()}
        onDragStart={dragStart}
        onDragEnd={dragEndLeave}
        onDrop={dropped}
        onDragOver={dragOverEnter}
        onDragEnter={dragOverEnter}
        onDragLeave={dragEndLeave}
        draggable={true}
      ><VideoDragItem
        key={uniqueId(`${d.ytID}_`)}
        interstitial={ d.is_interstitial }
        data={ d }
        playVideo={props.onPlayVideo}
        deleteVideo={props.onDeleteVideo}
        position={d.position}
        ordinal={d.ordinal} /></li>
    )
  });

  return (
    <ul className={classes} onDrop={reSort} ref={wrapper}>
      {draggables}
    </ul>
  )
  
}

export default DragSortList;
