/**
 * UpdateAccountHeader.jsx
 * Manages the navigation display for the Update Account page
 */

import React from 'react';
import {
  NavLink
} from 'react-router-dom';

const UpdateAccountHeader = () => {
  return (
    <div className="nav__project__wrap">
      <nav id="UserAdminHeader" className="nav__project">
        <b className="nav__project_label">Update Your Account</b>
        <NavLink to="/search" key="search">Search</NavLink>
        <NavLink to="/videos" key="videos">Videos</NavLink>
        <NavLink to="/stations" key="stations">Stations</NavLink>
        <NavLink to="/destinations" key="linear">Destinations</NavLink>
        <NavLink to="/channels" key="channels">Channels</NavLink>
        <NavLink to="/groups" key="groups">Groups</NavLink>
        <NavLink to="/reports" key="reports">Reports</NavLink>
        <NavLink to="/tools" key="tools">Tools</NavLink>
      </nav>
    </div>
  );
}

export default UpdateAccountHeader; 
