import React from 'react';
import PropTypes from 'prop-types';

import PaginationBar from '../subcomponents/PaginationBar';
import SelectPerPage from '../subcomponents/SelectPerPage';
import LoaderSimple from '../subcomponents/LoaderSimple';
import ToggleSwitch from '../subcomponents/ToggleSwitch';

import './css/ReportsChannelUsageResultPanel.css';

import {
  youtubeURL,
  youtubeThumbnailURL
} from '../../js/Utilities';

const ReportsChannelUsageResultPanel = (props) => {

  const makePagingComp = () => {
    let props = {};
    if(!Object.hasOwn(props, 'pages')) return;

    return (
      <PaginationBar
        className="reports__channel_use--pag"
        data={JSON.stringify({'destination': props.destination})}
        pages={+props.pages}
        page={props.page}
        onClickHandler={props.onPageClick}
        navSize={5} />
    );
  }

  const makeToggle = () => {
    let toggle = null;
    let supplementalData = JSON.stringify({destination: +props.destination});

    if(Object.hasOwn(props, 'toggle')) {
      toggle = (
        <ToggleSwitch
          onChange={props.onToggleChange}
          data={supplementalData}
          label="Show:"
          className="reports__channel_use"
          options={['List Each Occurrence','List Videos by Frequency']}
          name="view"
          selectedIndex={props.toggle.selected} />
      );
    }
    return toggle;
  }

  const singlerows = () => {
    let body = null;
    if(!Object.hasOwn(props, 'results') ) {
      body = <h3>No results found.</h3>;
    }

    body = props.results.map((item, index) => {
      return (
        <tr key={`ReportsChannelUsageResultPanel__tbl_row_${item.ytid}_${index}`}>
          <td>{item['Series or Video Set']}</td>
          <td>{item['Episode Title']}</td>
          <td>{item['Run Date']}</td>
          <td><a href={youtubeURL(item.ytid)} target="_blank">{item.video_title}</a></td>
        </tr>
      );
    });
    return body;
  }

  const singles = () => {
    if(!Object.hasOwn(props, 'results')) return null;

    return (
      <div id="ReportsChannelUsageResultPanel">
        <div className="l__flex__sb">
          {makeToggle()}
          {makePagingComp()}
        </div>
        <table className="ReportsChannelUsageResultPanel__tbl">
          <thead>
            <tr>
              <td>Series or Video Set</td>
              <td>Episode Title</td>
              <td>Run Date</td>
              <td>Video</td>
            </tr>
          </thead>

          <tbody>
            {singlerows()}
          </tbody>
        </table>
      </div>
    );
  }

  const groupedrows = () => {
    let body = null;
    if(!Object.hasOwn(props, 'detail')) {
      body = <h3>No results found.</h3>;
    }

    body = props.results.map((item, index) => {
      return (
        <tr key={`ReportsChannelUsageResultPanel__tbl_row_${item.ytid}_${index}`}>
          <td>
            <a href={youtubeURL(item.ytid)} target="_blank" className="vidthumb__and__title">
              <img src={youtubeThumbnailURL(item.ytid)} className="ReportsChannelUsageResultPanel__thumb" alt="" width="75" />
              {item.video_title}
            </a>
          </td>
          <td>{item['Frequency']}</td>
        </tr>
      );
    });
    return body;
  }

  const grouped = () => {
    if(!Object.hasOwn(props, 'results')) return;

    let body;
    if(props.loading) {
      body = <LoaderSimple open={true} />
    } else {
      body = (
        <div id="ReportsChannelUsageResultPanel">
          <div className="l__flex__sb">
            {makeToggle()}
            {makePagingComp()}
          </div>
          <table className="ReportsChannelUsageResultPanel__tbl">
            <thead>
              <tr>
                <td>Video</td>
                <td>Frequency</td>
              </tr>
            </thead>

            <tbody>
              {groupedrows()}
            </tbody>
          </table>
        </div>
      );
    }
    return body;
  }

  let body = null;
  if(!!props.toggle.selected) {
    body = grouped();
  } else {
    body = singles();
  }

  return body;
}

export default ReportsChannelUsageResultPanel;