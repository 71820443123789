/**
 * SeasonsEpsDisplay.jsx
 * Show the season and episode for a video set block.
 * See PropTypes definitions at the end of this file for parameters
 */  
import React from 'react';
import PropTypes from 'prop-types';

import {
  API_SAVE_SEASONEPS
} from '../../js/Configuration';

import './css/SeasonsEpsDisplay.css';

const SeasonsEpsDisplay = (props) => {
  const {
    onChange,
    isAdmin,
    onSave
  } = props;
  
  const [mode, setMode] = React.useState('display');

  const oOnEdit = () => {
    setMode('edit');
  }

  const makeInput = (id='season') => {
    let inp;

    if(mode !== 'edit') {
      inp = props[id];
    } else {
      inp = <input type="number" id={id} name={id} defaultValue={props[id]} onChange={onChange}  />
    }
    return inp;
  }

  const makeLabel = (text = "season") => {
    let lbl;
    if(mode !== 'edit') {
      lbl = text;
    } else {
      lbl = <label htmlFor={text}>{text}</label>
    }
    return lbl;
  }

  const onSaver = (domEvent) => {
    domEvent.preventDefault();
    domEvent.persist();
    setMode('display');
    props.onSave();
  }

  const onCancel = (domEvent) => {
    setMode('display');
  }

  return (
    <form action={API_SAVE_SEASONEPS} method="post" className="s__e__display" onSubmit={onSaver}>
      <span>{makeLabel('season')}</span> {makeInput('season')}
      <span>{makeLabel('episode')}</span> {makeInput('episode')}
      <button type="button" className="btn__link btn" onClick={oOnEdit} hidden={!isAdmin || (mode === 'edit')}>Change</button>
      <button type="submit" className="btn btn--action" hidden={mode !== 'edit'}>Save</button>
      <button type="button" className="btn__link btn" onClick={onCancel} hidden={!isAdmin || (mode !== 'edit')}>Cancel</button>
    </form>
  );
}

SeasonsEpsDisplay.propTypes = {
  season: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  episode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSave: PropTypes.func,
  onChange: PropTypes.func
};

export default SeasonsEpsDisplay;
