/**
 * ChannelVideos.js
 */
import React from 'react';
import { Link } from 'react-router-dom'
import { format } from 'date-fns';

import './css/ChannelVideos.css';

import PaginationBar from '../subcomponents/PaginationBar';
import SelectPerPage from '../subcomponents/SelectPerPage';
import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  youtubeURL
} from '../../js/Utilities';

import { NULL_DATE_TEXT_STATIONS } from '../../js/Configuration.js';

const links = (s) => {
  /*
    The + forces s.isStation to be an integer (0/1) that will be treated as
    a boolean
  */
  const path = +s.isStation ? `/stations/series/${s.videoset}` : `/destinations/playlist/${s.playlist_id}`;
  return (<li key={s.videoset}><Link className="channel__videos__link"to={{ pathname: `${path}` }}>{make_link_text(s)}</Link></li>);
}

const make_link_text = (obj) => {
  let tlt = obj.title ? obj.title : '';
  let rndte;

  switch( obj.rundate ) {
    case '':
    case '0000-00-00':
      rndte = NULL_DATE_TEXT_STATIONS;
      break;
    default:
      rndte = format( new Date( obj.rundate ), '[ PP ]' );
  }
 
  return `${obj.destination_name}: ${obj.set_name} ${tlt} ${rndte}`;
}

const make_sets = (sets_array) => {
  let result;
  if(sets_array.length) {
    result = <ul>{sets_array.map(links)}</ul>;
  } else {
    result = 'None';
  }
  return result; 
}

const ChannelVideos = (props) => {
  let body;
  let tablerows;

  if(props.loading) {
    body = <LoaderSimple open={true} />
  }
  else if(!props.list.length) {
    body = 'No videos in AVA.';
  }
  else {
    tablerows = props.list.map((item) => {

      const isactive = (item.active === 'y') ? '' : '*';

      const sts = make_sets(item.sets);
      
      return (
        <tr key={item.youtube_id}>
          <td className='channel__videos__active'>{isactive}</td>
          <td>
            <a href={youtubeURL(item.youtube_id)} target="_blank" rel="noopener">
              <img src={item.thumbnail} alt='' />
            </a>
          </td>
          <td>
            <a href={youtubeURL(item.youtube_id)} target="_blank" rel="noopener" className="channel__videos__link">
              {item.title}
            </a>
          </td>
          <td className="text__tbl__align_duration">
            {item.duration}
          </td>
          <td>
            {sts}
          </td>      
        </tr>
      )
    });  
    body = (
      <table>
        <thead>
          <tr>
            <th>Inactive?</th>
            <th colSpan="2">Title</th>
            <th className='text__center'>Duration</th>
            <th>Used in</th>
          </tr>
        </thead>
        <tbody>
          {tablerows}
        </tbody>
      </table>   
    );
  }
  return (
    <details id="ChannelsVideosInAVA" className="channel__singlepg__panel" open>
      <summary>
        <h2>Videos in AVA ({props.total || 0})</h2>
      </summary>
      <div className="pagination__selpages">
        <SelectPerPage id="ChannelsVideosInAVAPerPage" value={props.perPage} onChangeHandler={props.onPerPageSelect} options={['View All|-1',10, 25, 50, 75, 100]} replace={true} cssClass="ChannelVideos__select ava-input-select" />
        <PaginationBar {...props} onClickHandler={props.onPaginationClick} />
      </div>
      {body}
      <div className="pagination__selpages">
        <SelectPerPage id="ChannelsVideosInAVAPerPage_b" value={props.perPage} onChangeHandler={props.onPerPageSelect} options={['View All|-1',10, 25, 50, 75, 100]} replace={true} cssClass="ChannelVideos__select ava-input-select" />
        <PaginationBar {...props} onClickHandler={props.onPaginationClick} />
      </div>
    </details>
  );
}

export default ChannelVideos;