/**
 * SearchDates.jsx
 * Search values that are between a start and an end date
 * Author: Webinista, Inc 2018-09-04.
 */
  
import React from 'react';
import DateInput from '../subcomponents/DateInput';
import PropTypes from 'prop-types';

const SearchDates = ({
  start,
  end,
  apiURL = '',
  labelText = 'NEEDS LABEL TEXT',
  name = '',
  cssClass = '',
  onChange=null,
  onSubmit=null,
  value='',
  limit=50
}) => (
  <div>
    <form id="reports_dropoff_search" action={apiURL} method="POST" className={cssClass} onSubmit={onSubmit}>
      <input type="hidden" id="limit" name="limit" value={limit} />
      <DateInput name="start" labelText="Start of date range:" isRequired={false} onChangeHandler={onChange} value={start} />
      <DateInput name="end" labelText="End of date range:" isRequired={false} onChangeHandler={onChange} value={end} />
      <button type="submit" className="btn btn--action">Search</button>
    </form>
  </div>
)

SearchDates.propTypes = {
  apiURL: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default SearchDates;
