/**
 * StationsFilter.js
 * Simple form + button component
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/StationsFilter.css';

const StationsFilter = ({onFilterType}) => {
  return (
    <form method="dialog" className="stations__filter" onChange={onFilterType}>
      <label htmlFor="filter_eps">Find series or episodes</label>
      <input type="search" name="filter_eps" id="filter_eps" aria-describedby="filter_eps_describe" />
      <span id="filter_eps_describe" className="aria__description">Enter a keyword or partial title. Limited to the current station.</span>   
    </form>
  );
}

StationsFilter.propTypes = {
  onFilterType: PropTypes.func.isRequired
}

export default StationsFilter;