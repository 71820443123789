import React from 'react';
import { UserAdminActivate } from './UserAdminActivate';
import {
  ButtonEditUser,
  ButtonResetPassword
} from './UserAdminButtons';

import SelectMenuControlled from '../SelectMenuControlled';

import uniqueId from 'lodash.uniqueid';

import './css/UserAdminTable.css';

export default class UserAdminTable extends React.Component {
  constructor(props) {
    super(props);

    this.makeTable = this.makeTable.bind(this);
    this.makeTableHeader = this.makeTableHeader.bind(this);
    this.makeTableRows = this.makeTableRows.bind(this);
    this.makeProjectList = this.makeProjectList.bind(this);
    this.findProjectById = this.findProjectById.bind(this);
    this.getData = this.getData.bind(this);
    this.disable = this.disable.bind(this);
    this.sortProjects = this.sortProjects.bind(this);
  }

  findProjectById(seek = '') {
    let value;
    
    if(seek) {;
      value =  this.props.projects.find((p) => p.pid === seek) || {};
    }
    return value;
  }

  makeProjectList(uProjects) {
    let listItems;
    if(!uProjects) {
      listItems = '––';
    } else {
      const li = Object.keys(uProjects).map((key) => {
          return <li key={uniqueId()}>{this.findProjectById(key).name}</li>;
      });
      listItems = <ul>{li}</ul>;
    }
    return listItems;
  }

  disable(field) {
    if(!field) return;
    // If the list is being filtered, props.filtering will have multiple
    // properties.
    const isFiltering = Object.keys(this.props.filtering);
 
    // Exclude the 'filtered' key
    const fields = isFiltering.filter((item) => item !== 'filtered');
    return fields.length && fields.indexOf(field) == -1;
  }

  sortProjects() {
    const ps = this.props.projects
              .sort((a,b) => a.name > b.name)
              .map((p) => { return {label: p.name, value: p.pid }});
    
    return [{label: '—',value: ''}].concat( ps );
  }
  
  makeTableHeader() {
    return (
      <thead>
        <tr>
          <th>
            Display name
            <input type="text" id="UserAdminTableDisplayName" name="displayname" onChange={this.props.onFilterChange} value={this.props.filtering.displayname || ''} disabled={this.disable('displayname')} className="useradmin__filter"  />
          </th>
          <th>
            Login name
            <input type="text" id="UserAdminTableLoginName" name="username" onChange={this.props.onFilterChange} value={this.props.filtering.username || ''} disabled={this.disable('username')} className="useradmin__filter"  />
          </th>
          <th>
            Project           
            <SelectMenuControlled
              labelText="Filter users by project"
              name="project"
              onChange={this.props.onFilterChange}
              options={this.sortProjects()}
              isRequired={false}
              cssClass="useradmin__filter"
              firstEmpty={false}
              value={this.props.filtering.project || '—'} />
          </th>
          <th>
            Tools
          </th>
        </tr>
      </thead>
    );
  }

  /*
    Decides whether to use this.props.users, this.props.filtering.filtered or 
    an empty array.
  */
  getData() {
    let data = [];
    if(this.props.filtering.hasOwnProperty('filtered')) {
      data = this.props.filtering.filtered;
    } else if (this.props.users.length){
      data = this.props.users;
    }
    return data;
  }

  makeTableRows() {
    const data = this.getData();
    const rows = data.map((r) => {
      return (
        <tr key={uniqueId()}>
          <td>{r.displayname_first} {r.displayname_second || ''}</td>
          <td>{r.username}</td>
          <td>{this.makeProjectList(r.permissions)}</td>
          <td>
            <span style={{'display':'flex','gap':'1rem','justifyContent': 'space-between'}}>
              <ButtonEditUser data-uuid={r.uuid} onClick={this.props.onEditUser} />
              <UserAdminActivate {...r} onDeactivate={this.props.onDeactivate} onReactivate={this.props.onReactivate} />
              <ButtonResetPassword {...r} onClick={this.props.onRequestPassword} />
            </span>
          </td>
        </tr>
      );
    });

    return <tbody>{rows}</tbody>;

  }

  makeTable() {
    return (
      <div className="useradmin__table__wrap">
        <table className="useradmin__table">
          {this.makeTableHeader()}
          {this.makeTableRows()}
        </table>
      </div>
    );
  }

  render() {
    return this.makeTable();
  }
}
