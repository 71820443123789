/**
 * SearchResultItem.js
 * An item in the search page results.
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import Logo1021 from '../subcomponents/Logo1021';
import YTPlayIcon from '../subcomponents/YTPlayIcon';

import './css/SearchResultItem.css';

import {
  truncateString,
  numberFormat,
  formatTime
} from '../../js/Utilities';

import { addVideo } from '../../actions/SearchUtils';


const SearchResultItem = (props) => {

  const {
    id,
    video_title,
    video_channel_title,
    video_thumb,
    video_alreadyprocessed,
    onPlay,
    video_viewcount,
    video_publishdate,
    video_duration,
    video_likecount,
    video_dislikecount
  } = props;

  const [inSystem, setInSystem] = React.useState(video_alreadyprocessed);

  const onAdd = (domEvent) => {
    const {target} = domEvent;
    const {videoid} = target.dataset;

    addVideo(videoid, (response) => {
      const {success} = response;

      if(success) {
        setInSystem(true);
      }
    });
  }
  
  /*
  * Displays 'Add Video To System' if it's not already there, or
  * 'Video Added to System' if it is
  */
  const makeInSystemIndicator = () => {
    let status;

    const {
      id,
      video_alreadyprocessed,
    } = props;

    if(inSystem === true) {
      status = <div className="result__item__is-in-system">Video Added to System</div>;
    } else {
      status = (
        <button
          type="button"
          className="result__item__add_to_system btn"
          data-videoid={id}
          onClick={onAdd}
        >Add to System</button>
      );
    }
    return status;
  }

  return (
    <div className="results__list__item">
      <div className="searchListItem">
        <button
          type="button"
          className="show__video__title results__list__item__title"
          onClick={ onPlay.bind( null, props)}
        >
          <h5 className="videoResultItemHeader divTransition">{video_title}</h5>
      </button>

        <button type="button" className="show__video__trigger videoThumb" onClick={ onPlay.bind( null, props) } >
          <img src={video_thumb} alt={`Watch ${video_title}`} />
          <YTPlayIcon />
        </button>

        <div className="centerTxt">{ makeInSystemIndicator() }</div>
        {video_alreadyprocessed ? <Logo1021 className="video__in__system__icon" /> : null }
        <ul>
          <li><span className="listText">Channel Title:</span> <span className="itemsBadge">{truncateString(video_channel_title, 25)}</span></li>
          <li><span className="listText">Views:</span><span className="itemsBadge">{numberFormat(video_viewcount)}</span></li>
          <li><span className="listText">Video Date:</span><span className="itemsBadge">{ format(video_publishdate * 1000, 'PP') }</span></li>
          <li><span className="listText">Duration:</span><span className="itemsBadge">{formatTime(video_duration)}</span></li>
          <li><span className="listText">Likes:</span><span className="itemsBadge">{numberFormat(video_likecount)}</span></li>
          <li><span className="listText">Dislikes:</span><span className="itemsBadge">{numberFormat(video_dislikecount)}</span></li>
        </ul>
      </div>
    </div>
  )
}

export default SearchResultItem;
