/*
ToolsUtils.js

Functions for the Tools page.
*/

import {
  apirequest, 
  getAuthData
} from '../js/Utilities';

import {
  API_EXPORT_DURATIONS_TO_GOOG,
} from '../js/Configuration';


export const download = (data, sheetID, sheetName) => {
  const blob = new Blob([convertArrayToCSV(data)], {type: 'text/csv'});
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");

  a.style = "display: none";
  document.body.appendChild(a);
  a.href = url;
  a.download = `${sheetID}_${sheetName}.csv`;
  a.click(); // programatically click the link to trigger the download

  //release the reference to the file by revoking the Object URL
  window.URL.revokeObjectURL(url);

  // Delete the link.
  document.body.removeChild(a);
}

export const convertArrayToCSV = (data) => {
  return data.join("\n");
}

export const setSpreadsheetData = (data, callback, sheetID, sheetName = 'Sheet1', signal = null, operation = 'averages') => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  fd.set('url', `https://docs.google.com/spreadsheets/d/${sheetID}/`);
  fd.set('sheet', sheetName);
  fd.set('values', JSON.stringify(data));
  fd.set('operation', operation);
 
  apirequest(API_EXPORT_DURATIONS_TO_GOOG, {body: fd, signal}, callback);
}
