import React, { useRef } from 'react';
import ModalDialog from '../subcomponents/ModalDialog';

import { Link } from 'react-router-dom';

import { API_VIDEOSET_ADDNEW } from '../../js/Configuration';
import { getAuthData } from '../../js/Utilities';

export const CreateRunDate = ({
  mode,
  date,
  title,
  videoset,
  playlistID,
  modalRef,
  onSubmit,
  onChange,
  onClose
}) => {

  let body;
  if(mode === 'add') {
    body = (
      <section id="DestinationsCreateNewRunDate__add">
        <h2>Create a new playlist in <q>{title}</q></h2>
        <form
          onSubmit={onSubmit}
          id="destination_create_rundate"
          className="destination_create_rundate--modal"
          action={API_VIDEOSET_ADDNEW}
          method="POST"
        >
          <input type="hidden" name="token" value={getAuthData('token')} />
          <input type="hidden" name="videoset" value={videoset} />
          <p>
            <label htmlFor="destination_rundate">Set run date:</label>
            <span className="playlist__dupemod_btns">
              <input
                type="date"
                id="destination_rundate"
                name="destination_rundate"
                value={date}
                onChange={onChange}
              />
              <button
                type="submit"
                className="btn btn--action"
                disabled={!date}
              >Save</button>
              <button
                type="button"
                className="btn btn-light"
                onClick={onChange} value=""
              >I'll set one later</button>
            </span>
          </p>
        </form>
      </section>
    );
  } else if(mode === 'success') {
    body = (
      <section id="DestinationsCreateNewRunDate__success">
        <h2>Success!</h2> 
        <p>I've added a new playlist to the videoset, <q>{title}</q>.</p>
        <div>
          <Link to={`/destinations/playlist/${playlistID}`} className="btn btn--action">Edit this playlist</Link>
        </div>
      </section>
    );
  } else {
      body = (
        <section id="DestinationsCreateNewRunDate__error">
          <h2>Error! Something went wrong</h2>
          <p>Sorry. I could not add a new play list to the videoset,
          <q>{title}</q>. This is probably a bug. Please report it to an
          administrator.</p> 
        </section>
      )
  }

  return (
    <ModalDialog
      ref={modalRef}
      id="DestinationsCreateNewRunDate"
      onClose={onClose}
    >     
      {body}
    </ModalDialog>
  );
}
