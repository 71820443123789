/**
 * SearchChannelUsage
 * Search form for Top Channels by Destination, Top Videos by Destination reports
 * Author: Webinista, Inc 3/24/18.
 */
   
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SelectMenu from '../subcomponents/SelectMenu';

const SearchChannelUsage = ({
  apiURL,
  cssClass,
  onSubmit,
  onReset,
  onSelectChange,
  onInput,
  labelText='NEEDS A LABEL',
  limit=50,
  datalist=[],
  options=[],
}) => {

  const makeDataList = (arrayOfOptions) => {
    return arrayOfOptions.map( (item, index) => {
      return <option value={item.title} key={index.toString()} />
    });
  }

  const cssClasses = classNames(
    cssClass,
    {'reports__channeluse__search': true}
  );
  
  let dataList = null;
  let dataListAttr = null;
  
  const id = 'reports_videoset_search_channel';

  if(datalist) {
    dataListAttr = `${id}_dl`;
    dataList = <datalist id={dataListAttr}>{makeDataList(datalist)}</datalist>;
  }
  
  return (
    <form id="reports_videoset_search" action={apiURL} method="POST" className={cssClasses} onSubmit={onSubmit} onReset={onReset}>
      <input type="hidden" id="limit" name="limit" value={limit} />
      <div>
        <label htmlFor={id}>{labelText}</label>
        <input
          type="text"
          id={id}
          name="channel"
          onChange={onInput}
          list={dataListAttr}
          required={true}
          autoComplete="off" />
          {dataList}
      </div>
      <SelectMenu
        name="destination"
        labelText="Select a Destination or Station"
        onChangeHandler={onSelectChange}
        options={options} />
      <button type="submit" className="btn btn--action">Search</button>
      <button type="reset" className="btn">Reset</button>
    </form>
  )
}

SearchChannelUsage.propTypes = {
  apiURL: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  limit: PropTypes.number,
  cssClass: PropTypes.string,
  onSelectChange: PropTypes.func,
  onChangeHandler: PropTypes.func,
  onInput: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  options: PropTypes.array,  
  destinations: PropTypes.arrayOf(PropTypes.shape({
    dID: PropTypes.string,
    dName: PropTypes.string
  })),
  datalist: PropTypes.array
};

export default SearchChannelUsage;