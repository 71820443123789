/**
 * DestinationsRenameTrigger.jsx
 */

import React from 'react';

const DestinationsRenameTrigger = (props) => {
  return <button type="button" className="btn btn--action" data-action="videoset" data-destinationid={props.dID} onClick={props.onClick}>Rename <span className="form__access__label">to <q>{props.dName}</q></span></button>
}

export default DestinationsRenameTrigger