export const REGION_FLAGS = [
  {
    flag: "🌐",
    region: "All Regions"
  },
  {
    flag: "🇦🇨",
    region: "Ascension Island"
  },
  {
    flag: "🇦🇩",
    region: "Andorra"
  },
  {
    flag: "🇦🇪",
    region: "United Arab Emirates"
  },
  {
    flag: "🇦🇫",
    region: "Afghanistan"
  },
  {
    flag: "🇦🇬",
    region: "Antigua & Barbuda"
  },
  {
    flag: "🇦🇮",
    region: "Anguilla"
  },
  {
    flag: "🇦🇱",
    region: "Albania"
  },
  {
    flag: "🇦🇲",
    region: "Armenia"
  },
  {
    flag: "🇦🇴",
    region: "Angola"
  },
  {
    flag: "🇦🇶",
    region: "Antarctica"
  },
  {
    flag: "🇦🇷",
    region: "Argentina"
  },
  {
    flag: "🇦🇸",
    region: "American Samoa"
  },
  {
    flag: "🇦🇹",
    region: "Austria"
  },
  {
    flag: "🇦🇺",
    region: "Australia"
  },
  {
    flag: "🇦🇼",
    region: "Aruba"
  },
  {
    flag: "🇦🇽",
    region: "Åland Islands"
  },
  {
    flag: "🇦🇿",
    region: "Azerbaijan"
  },
  {
    flag: "🇧🇦",
    region: "Bosnia & Herzegovina"
  },
  {
    flag: "🇧🇧",
    region: "Barbados"
  },
  {
    flag: "🇧🇩",
    region: "Bangladesh"
  },
  {
    flag: "🇧🇪",
    region: "Belgium"
  },
  {
    flag: "🇧🇫",
    region: "Burkina Faso"
  },
  {
    flag: "🇧🇬",
    region: "Bulgaria"
  },
  {
    flag: "🇧🇭",
    region: "Bahrain"
  },
  {
    flag: "🇧🇮",
    region: "Burundi"
  },
  {
    flag: "🇧🇯",
    region: "Benin"
  },
  {
    flag: "🇧🇱",
    region: "St. Barthélemy"
  },
  {
    flag: "🇧🇲",
    region: "Bermuda"
  },
  {
    flag: "🇧🇳",
    region: "Brunei"
  },
  {
    flag: "🇧🇴",
    region: "Bolivia"
  },
  {
    flag: "🇧🇶",
    region: "Caribbean Netherlands"
  },
  {
    flag: "🇧🇷",
    region: "Brazil"
  },
  {
    flag: "🇧🇸",
    region: "Bahamas"
  },
  {
    flag: "🇧🇹",
    region: "Bhutan"
  },
  {
    flag: "🇧🇻",
    region: "Bouvet Island"
  },
  {
    flag: "🇧🇼",
    region: "Botswana"
  },
  {
    flag: "🇧🇾",
    region: "Belarus"
  },
  {
    flag: "🇧🇿",
    region: "Belize"
  },
  {
    flag: "🇨🇦",
    region: "Canada"
  },
  {
    flag: "🇨🇨",
    region: "Cocos (Keeling) Islands"
  },
  {
    flag: "🇨🇩",
    region: "Congo - Kinshasa"
  },
  {
    flag: "🇨🇫",
    region: "Central African Republic"
  },
  {
    flag: "🇨🇬",
    region: "Congo - Brazzaville"
  },
  {
    flag: "🇨🇭",
    region: "Switzerland"
  },
  {
    flag: "🇨🇮",
    region: "Côte d'Ivoire"
  },
  {
    flag: "🇨🇰",
    region: "Cook Islands"
  },
  {
    flag: "🇨🇱",
    region: "Chile"
  },
  {
    flag: "🇨🇲",
    region: "Cameroon"
  },
  {
    flag: "🇨🇳",
    region: "China"
  },
  {
    flag: "🇨🇴",
    region: "Colombia"
  },
  {
    flag: "🇨🇵",
    region: "Clipperton Island"
  },
  {
    flag: "🇨🇷",
    region: "Costa Rica"
  },
  {
    flag: "🇨🇺",
    region: "Cuba"
  },
  {
    flag: "🇨🇻",
    region: "Cape Verde"
  },
  {
    flag: "🇨🇼",
    region: "Curaçao"
  },
  {
    flag: "🇨🇽",
    region: "Christmas Island"
  },
  {
    flag: "🇨🇾",
    region: "Cyprus"
  },
  {
    flag: "🇨🇿",
    region: "Czechia"
  },
  {
    flag: "🇩🇪",
    region: "Germany"
  },
  {
    flag: "🇩🇬",
    region: "Diego Garcia"
  },
  {
    flag: "🇩🇯",
    region: "Djibouti"
  },
  {
    flag: "🇩🇰",
    region: "Denmark"
  },
  {
    flag: "🇩🇲",
    region: "Dominica"
  },
  {
    flag: "🇩🇴",
    region: "Dominican Republic"
  },
  {
    flag: "🇩🇿",
    region: "Algeria"
  },
  {
    flag: "🇪🇦",
    region: "Ceuta & Melilla"
  },
  {
    flag: "🇪🇨",
    region: "Ecuador"
  },
  {
    flag: "🇪🇪",
    region: "Estonia"
  },
  {
    flag: "🇪🇬",
    region: "Egypt"
  },
  {
    flag: "🇪🇭",
    region: "Western Sahara"
  },
  {
    flag: "🇪🇷",
    region: "Eritrea"
  },
  {
    flag: "🇪🇸",
    region: "Spain"
  },
  {
    flag: "🇪🇹",
    region: "Ethiopia"
  },
  {
    flag: "🇪🇺",
    region: "European Union"
  },
  {
    flag: "🇫🇮",
    region: "Finland"
  },
  {
    flag: "🇫🇯",
    region: "Fiji"
  },
  {
    flag: "🇫🇰",
    region: "Falkland Islands"
  },
  {
    flag: "🇫🇲",
    region: "Micronesia"
  },
  {
    flag: "🇫🇴",
    region: "Faroe Islands"
  },
  {
    flag: "🇫🇷",
    region: "France"
  },
  {
    flag: "🇬🇦",
    region: "Gabon"
  },
  {
    flag: "🇬🇧",
    region: "United Kingdom"
  },
  {
    flag: "🇬🇩",
    region: "Grenada"
  },
  {
    flag: "🇬🇪",
    region: "Georgia"
  },
  {
    flag: "🇬🇫",
    region: "French Guiana"
  },
  {
    flag: "🇬🇬",
    region: "Guernsey"
  },
  {
    flag: "🇬🇭",
    region: "Ghana"
  },
  {
    flag: "🇬🇮",
    region: "Gibraltar"
  },
  {
    flag: "🇬🇱",
    region: "Greenland"
  },
  {
    flag: "🇬🇲",
    region: "Gambia"
  },
  {
    flag: "🇬🇳",
    region: "Guinea"
  },
  {
    flag: "🇬🇵",
    region: "Guadeloupe"
  },
  {
    flag: "🇬🇶",
    region: "Equatorial Guinea"
  },
  {
    flag: "🇬🇷",
    region: "Greece"
  },
  {
    flag: "🇬🇸",
    region: "South Georgia & South Sandwich Islands"
  },
  {
    flag: "🇬🇹",
    region: "Guatemala"
  },
  {
    flag: "🇬🇺",
    region: "Guam"
  },
  {
    flag: "🇬🇼",
    region: "Guinea-Bissau"
  },
  {
    flag: "🇬🇾",
    region: "Guyana"
  },
  {
    flag: "🇭🇰",
    region: "Hong Kong SAR China"
  },
  {
    flag: "🇭🇲",
    region: "Heard & McDonald Islands"
  },
  {
    flag: "🇭🇳",
    region: "Honduras"
  },
  {
    flag: "🇭🇷",
    region: "Croatia"
  },
  {
    flag: "🇭🇹",
    region: "Haiti"
  },
  {
    flag: "🇭🇺",
    region: "Hungary"
  },
  {
    flag: "🇮🇨",
    region: "Canary Islands"
  },
  {
    flag: "🇮🇩",
    region: "Indonesia"
  },
  {
    flag: "🇮🇪",
    region: "Ireland"
  },
  {
    flag: "🇮🇱",
    region: "Israel"
  },
  {
    flag: "🇮🇲",
    region: "Isle of Man"
  },
  {
    flag: "🇮🇳",
    region: "India"
  },
  {
    flag: "🇮🇴",
    region: "British Indian Ocean Territory"
  },
  {
    flag: "🇮🇶",
    region: "Iraq"
  },
  {
    flag: "🇮🇷",
    region: "Iran"
  },
  {
    flag: "🇮🇸",
    region: "Iceland"
  },
  {
    flag: "🇮🇹",
    region: "Italy"
  },
  {
    flag: "🇯🇪",
    region: "Jersey"
  },
  {
    flag: "🇯🇲",
    region: "Jamaica"
  },
  {
    flag: "🇯🇴",
    region: "Jordan"
  },
  {
    flag: "🇯🇵",
    region: "Japan"
  },
  {
    flag: "🇰🇪",
    region: "Kenya"
  },
  {
    flag: "🇰🇬",
    region: "Kyrgyzstan"
  },
  {
    flag: "🇰🇭",
    region: "Cambodia"
  },
  {
    flag: "🇰🇮",
    region: "Kiribati"
  },
  {
    flag: "🇰🇲",
    region: "Comoros"
  },
  {
    flag: "🇰🇳",
    region: "St. Kitts & Nevis"
  },
  {
    flag: "🇰🇵",
    region: "North Korea"
  },
  {
    flag: "🇰🇷",
    region: "South Korea"
  },
  {
    flag: "🇰🇼",
    region: "Kuwait"
  },
  {
    flag: "🇰🇾",
    region: "Cayman Islands"
  },
  {
    flag: "🇰🇿",
    region: "Kazakhstan"
  },
  {
    flag: "🇱🇦",
    region: "Laos"
  },
  {
    flag: "🇱🇧",
    region: "Lebanon"
  },
  {
    flag: "🇱🇨",
    region: "St. Lucia"
  },
  {
    flag: "🇱🇮",
    region: "Liechtenstein"
  },
  {
    flag: "🇱🇰",
    region: "Sri Lanka"
  },
  {
    flag: "🇱🇷",
    region: "Liberia"
  },
  {
    flag: "🇱🇸",
    region: "Lesotho"
  },
  {
    flag: "🇱🇹",
    region: "Lithuania"
  },
  {
    flag: "🇱🇺",
    region: "Luxembourg"
  },
  {
    flag: "🇱🇻",
    region: "Latvia"
  },
  {
    flag: "🇱🇾",
    region: "Libya"
  },
  {
    flag: "🇲🇦",
    region: "Morocco"
  },
  {
    flag: "🇲🇨",
    region: "Monaco"
  },
  {
    flag: "🇲🇩",
    region: "Moldova"
  },
  {
    flag: "🇲🇪",
    region: "Montenegro"
  },
  {
    flag: "🇲🇫",
    region: "St. Martin"
  },
  {
    flag: "🇲🇬",
    region: "Madagascar"
  },
  {
    flag: "🇲🇭",
    region: "Marshall Islands"
  },
  {
    flag: "🇲🇰",
    region: "North Macedonia"
  },
  {
    flag: "🇲🇱",
    region: "Mali"
  },
  {
    flag: "🇲🇲",
    region: "Myanmar (Burma)"
  },
  {
    flag: "🇲🇳",
    region: "Mongolia"
  },
  {
    flag: "🇲🇴",
    region: "Macao Sar China"
  },
  {
    flag: "🇲🇵",
    region: "Northern Mariana Islands"
  },
  {
    flag: "🇲🇶",
    region: "Martinique"
  },
  {
    flag: "🇲🇷",
    region: "Mauritania"
  },
  {
    flag: "🇲🇸",
    region: "Montserrat"
  },
  {
    flag: "🇲🇹",
    region: "Malta"
  },
  {
    flag: "🇲🇺",
    region: "Mauritius"
  },
  {
    flag: "🇲🇻",
    region: "Maldives"
  },
  {
    flag: "🇲🇼",
    region: "Malawi"
  },
  {
    flag: "🇲🇽",
    region: "Mexico"
  },
  {
    flag: "🇲🇾",
    region: "Malaysia"
  },
  {
    flag: "🇲🇿",
    region: "Mozambique"
  },
  {
    flag: "🇳🇦",
    region: "Namibia"
  },
  {
    flag: "🇳🇨",
    region: "New Caledonia"
  },
  {
    flag: "🇳🇪",
    region: "Niger"
  },
  {
    flag: "🇳🇫",
    region: "Norfolk Island"
  },
  {
    flag: "🇳🇬",
    region: "Nigeria"
  },
  {
    flag: "🇳🇮",
    region: "Nicaragua"
  },
  {
    flag: "🇳🇱",
    region: "Netherlands"
  },
  {
    flag: "🇳🇴",
    region: "Norway"
  },
  {
    flag: "🇳🇵",
    region: "Nepal"
  },
  {
    flag: "🇳🇷",
    region: "Nauru"
  },
  {
    flag: "🇳🇺",
    region: "Niue"
  },
  {
    flag: "🇳🇿",
    region: "New Zealand"
  },
  {
    flag: "🇴🇲",
    region: "Oman"
  },
  {
    flag: "🇵🇦",
    region: "Panama"
  },
  {
    flag: "🇵🇪",
    region: "Peru"
  },
  {
    flag: "🇵🇫",
    region: "French Polynesia"
  },
  {
    flag: "🇵🇬",
    region: "Papua New Guinea"
  },
  {
    flag: "🇵🇭",
    region: "Philippines"
  },
  {
    flag: "🇵🇰",
    region: "Pakistan"
  },
  {
    flag: "🇵🇱",
    region: "Poland"
  },
  {
    flag: "🇵🇲",
    region: "St. Pierre & Miquelon"
  },
  {
    flag: "🇵🇳",
    region: "Pitcairn Islands"
  },
  {
    flag: "🇵🇷",
    region: "Puerto Rico"
  },
  {
    flag: "🇵🇸",
    region: "Palestinian Territories"
  },
  {
    flag: "🇵🇹",
    region: "Portugal"
  },
  {
    flag: "🇵🇼",
    region: "Palau"
  },
  {
    flag: "🇵🇾",
    region: "Paraguay"
  },
  {
    flag: "🇶🇦",
    region: "Qatar"
  },
  {
    flag: "🇷🇪",
    region: "Réunion"
  },
  {
    flag: "🇷🇴",
    region: "Romania"
  },
  {
    flag: "🇷🇸",
    region: "Serbia"
  },
  {
    flag: "🇷🇺",
    region: "Russia"
  },
  {
    flag: "🇷🇼",
    region: "Rwanda"
  },
  {
    flag: "🇸🇦",
    region: "Saudi Arabia"
  },
  {
    flag: "🇸🇧",
    region: "Solomon Islands"
  },
  {
    flag: "🇸🇨",
    region: "Seychelles"
  },
  {
    flag: "🇸🇩",
    region: "Sudan"
  },
  {
    flag: "🇸🇪",
    region: "Sweden"
  },
  {
    flag: "🇸🇬",
    region: "Singapore"
  },
  {
    flag: "🇸🇭",
    region: "St. Helena"
  },
  {
    flag: "🇸🇮",
    region: "Slovenia"
  },
  {
    flag: "🇸🇯",
    region: "Svalbard & Jan Mayen"
  },
  {
    flag: "🇸🇰",
    region: "Slovakia"
  },
  {
    flag: "🇸🇱",
    region: "Sierra Leone"
  },
  {
    flag: "🇸🇲",
    region: "San Marino"
  },
  {
    flag: "🇸🇳",
    region: "Senegal"
  },
  {
    flag: "🇸🇴",
    region: "Somalia"
  },
  {
    flag: "🇸🇷",
    region: "Suriname"
  },
  {
    flag: "🇸🇸",
    region: "South Sudan"
  },
  {
    flag: "🇸🇹",
    region: "São Tomé & Príncipe"
  },
  {
    flag: "🇸🇻",
    region: "El Salvador"
  },
  {
    flag: "🇸🇽",
    region: "Sint Maarten"
  },
  {
    flag: "🇸🇾",
    region: "Syria"
  },
  {
    flag: "🇸🇿",
    region: "Eswatini"
  },
  {
    flag: "🇹🇦",
    region: "Tristan Da Cunha"
  },
  {
    flag: "🇹🇨",
    region: "Turks & Caicos Islands"
  },
  {
    flag: "🇹🇩",
    region: "Chad"
  },
  {
    flag: "🇹🇫",
    region: "French Southern Territories"
  },
  {
    flag: "🇹🇬",
    region: "Togo"
  },
  {
    flag: "🇹🇭",
    region: "Thailand"
  },
  {
    flag: "🇹🇯",
    region: "Tajikistan"
  },
  {
    flag: "🇹🇰",
    region: "Tokelau"
  },
  {
    flag: "🇹🇱",
    region: "Timor-Leste"
  },
  {
    flag: "🇹🇲",
    region: "Turkmenistan"
  },
  {
    flag: "🇹🇳",
    region: "Tunisia"
  },
  {
    flag: "🇹🇴",
    region: "Tonga"
  },
  {
    flag: "🇹🇷",
    region: "Turkey"
  },
  {
    flag: "🇹🇹",
    region: "Trinidad & Tobago"
  },
  {
    flag: "🇹🇻",
    region: "Tuvalu"
  },
  {
    flag: "🇹🇼",
    region: "Taiwan"
  },
  {
    flag: "🇹🇿",
    region: "Tanzania"
  },
  {
    flag: "🇺🇦",
    region: "Ukraine"
  },
  {
    flag: "🇺🇬",
    region: "Uganda"
  },
  {
    flag: "🇺🇲",
    region: "U.S. Outlying Islands"
  },
  {
    flag: "🇺🇳",
    region: "United Nations"
  },
  {
    flag: "🇺🇸",
    region: "United States"
  },
  {
    flag: "🇺🇾",
    region: "Uruguay"
  },
  {
    flag: "🇺🇿",
    region: "Uzbekistan"
  },
  {
    flag: "🇻🇦",
    region: "Vatican City"
  },
  {
    flag: "🇻🇨",
    region: "St. Vincent & Grenadines"
  },
  {
    flag: "🇻🇪",
    region: "Venezuela"
  },
  {
    flag: "🇻🇬",
    region: "British Virgin Islands"
  },
  {
    flag: "🇻🇮",
    region: "U.S. Virgin Islands"
  },
  {
    flag: "🇻🇳",
    region: "Vietnam"
  },
  {
    flag: "🇻🇺",
    region: "Vanuatu"
  },
  {
    flag: "🇼🇫",
    region: "Wallis & Futuna"
  },
  {
    flag: "🇼🇸",
    region: "Samoa"
  },
  {
    flag: "🇽🇰",
    region: "Kosovo"
  },
  {
    flag: "🇾🇪",
    region: "Yemen"
  },
  {
    flag: "🇾🇹",
    region: "Mayotte"
  },
  {
    flag: "🇿🇦",
    region: "South Africa"
  },
  {
    flag: "🇿🇲",
    region: "Zambia"
  },
  {
    flag: "🇿🇼",
    region: "Zimbabwe"
  },
  {
    flag: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    region: "England"
  },
  {
    flag: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    region: "Scotland"
  },
  {
    flag: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    region: "Wales"
  }
];
