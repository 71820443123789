/**
 * SelectMenu.js
 * A no-framework dropdown menu. This functions as an UNCONTROLLED component and
 * returns the selected VALUE to the onChangeHandler, property rather than the 
 * entire DOMEvent.
 *
 * To return the entire DOMEvent and have a CONTROLLED component, use the
 * SelectMenuControlled component.
 * 
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import SelectOption from './SelectOption';
import classNames from 'classnames';

import '../../css/SelectMenu.css';

const SelectMenu = ({
  onChangeHandler,
  cssClass,
  id,
  name,
  size,
  multiple=false,
  isRequired=false,
  value='',
  firstEmpty=false,
  labelledby=null,
  describedBy=null,
  labelText='NEEDS A LABEL',
  options=[{ label: 'Empty Menu', value: '' }],
  disabled=null
}) => {

  const makeOptions = (arrayOfObjects) => {
    return arrayOfObjects.map((o, x) => {
      return (
        <SelectOption
          value={o.value}
          key={x.toString()}
          label={o.label} />
      );
    });
  }

  const cssClasses = classNames('ava-input-select', cssClass);

  const opts = makeOptions(options);
  if(firstEmpty) {
    opts.unshift(<option key="selectmenufirstempty"></option>);
  }

  return (
    <div className={cssClasses}>
      <label htmlFor={id}>{labelText}</label>
      <select
        required={isRequired}
        defaultValue={value}
        id={id || name}
        name={name}
        onChange={onChangeHandler}
        size={size}
        multiple={multiple}
        aria-labelledby={labelledby}
        aria-describedby={describedBy}
        disabled={disabled}
      >
        {opts}
      </select>
    </div>
  );
}

export default SelectMenu;
