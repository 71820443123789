/**
 * DestinationsPlaylistHeader
 * 
 */
import React from 'react';

import {
  format,
  isValid
} from 'date-fns';

import {
  NULL_DATE_TEXT_STATIONS,
  INTERSTITIAL_INDICATOR,
  INTERSTITIAL_NOTE,
  API_VIDEOSETBLOCK_INFO_CSV,
  API_VIDEOSETBLOCK_UPDATE
} from '../../js/Configuration';

import Reviewed from './Reviewed';
import PlaylistMeta from './PlaylistMeta';
import CRTStatus from './CRTStatus';
import RuntimeRatings from './RuntimeRatings';
import ExportCSV from './ExportCSV';
import CheckForUpdates from './CheckForUpdates';
import ShuffleVideos from './ShuffleVideos';

const DestinationsPlaylistHeader = (props) => {
  const {
    runtime,
    rating,
    count,
    crtstatus,
    videosetid=-1,
    playlist,
    onSubmit,
    onExport,
    onCheckForUpdates,
    onPlay,
    onShuffle,
    onPlaylistMetaChange,
    onApprovedProofedCuratedChange,
    onCommentsChange,
    onImport,
    onAddBySearch,
    onAddByUrl,
    onCheckForDuplicates
  } = props;

  const {
    vsb_playlistURL,
    vsb_proofCopy
  } = playlist;
  
  let proofedDate = null;
  let curatedDate = null;
  let approveDate = null;
  
  if( playlist.Proofed_Date && isValid(new Date(playlist.Proofed_Date)) ) {
    proofedDate = format(new Date(playlist.Proofed_Date), 'PP')
  }
  if( playlist.Curated_Date && isValid(new Date(playlist.Curated_Date)) ) {
    curatedDate = format(new Date(playlist.Curated_Date), 'PP')
  }
  if( playlist.Approved_Date && isValid(new Date(playlist.Approved_Date)) ) {
    approveDate = format(new Date(playlist.Approved_Date), 'PP')
  }

  return (
    <div id="blockInfo">
      <div className="destinations__metadata_and_status">
        <div className="destinations__meta_controls">
          <RuntimeRatings
            runtime={runtime}
            rating={rating}
            videos={count}
          />

          <CRTStatus
            approved={crtstatus.approved}
            status={crtstatus.status}
            loading={crtstatus.loading}
          />

          <div className="destinations__meta_controls__controls">
            <button
              disabled={!count}
              type="button"
              className="btn btn-sm btn--action playAllBtn playListBtn"
              onClick={onPlay}
            >
              Play All Videos
            </button>

            <button
              disabled={!count}
              type="button"
              className="btn btn-sm btn--action exportDataBtn playListBtn"
              onClick={onExport}
            >
              Export IDs
            </button>

            <ExportCSV
              actionURL={API_VIDEOSETBLOCK_INFO_CSV}
              videosetID={videosetid}
              disabled={false}
            />

            <form className="destinations__save" id="destinations_save_playlist" name="destinations_save_playlist" method="POST" action={API_VIDEOSETBLOCK_UPDATE} onSubmit={onSubmit} onChange={() => {}}>
              <button disabled={false} type="submit" className="btn btn-sm btn--action saveChangesBtn playListBtn">Save Playlist Changes</button>
            </form>

            <CheckForUpdates onClick={onCheckForUpdates} disabled={!count} />
            <ShuffleVideos onClick={onShuffle} disabled={!count} />
          </div>
        </div>

        <PlaylistMeta
          url={vsb_playlistURL}
          copy={vsb_proofCopy}
          onChange={onPlaylistMetaChange}
          disabled={playlist.Proofed === 'y'}
          form="destinations_save_playlist"
        />

        <Reviewed
          checked={playlist.Proofed === 'y'}
          cssClass="destinations__proofed"
          labelText="PROOFED: "
          id="proofed"
          by={playlist.Proofed_By || '\u0020'}
          date={proofedDate}
          onChange={onApprovedProofedCuratedChange}
        />
      </div>

      <div className="destinations__comments_reviewed">
        <Reviewed
          checked={playlist.Curated === 'y'}
          cssClass="destinations__curated"
          labelText="CURATED: "
          id="curated"
          by={playlist.Curated_By || '\u0020'}
          date={curatedDate}
          onChange={onApprovedProofedCuratedChange}
        />

        <div className="destinations__comments">
          <label htmlFor="playlist_comments">Enter Playlist Comments</label>
          <textarea
            className="commentsTxt form-control"
            type="textarea"
            name="playlist_comments"
            id="playlist_comments"
            value={playlist.vsb_tags}
            onChange={onCommentsChange}
          />
          <div className="destinations__add__video__controls">
            <button type="button" className="btn btn--action" onClick={onImport} disabled={playlist.Approved === 'y'}>Import Videos from Google Sheet</button>
            <button type="button" className="btn btn--action" onClick={onAddBySearch} disabled={playlist.Approved === 'y'}>Add Videos by Search</button>
            <button type="button" className="btn btn--action" onClick={onAddByUrl} disabled={playlist.Approved === 'y'}>Add Videos by URL</button>
            <button type="button" className="btn btn--action" onClick={onCheckForDuplicates}>Check for Duplicate Videos</button>
          </div>
        </div>

        <Reviewed
          checked={playlist.Approved === 'y'}
          cssClass="destinations__approved"
          labelText="APPROVED: "
          id="approved"
          by={playlist.Approved_By}
          date={approveDate}
          onChange={onApprovedProofedCuratedChange}
        />
      </div>
    </div>
  );
}

export default DestinationsPlaylistHeader;