/**
 * DestinationsPlaylistTable
 * Display table data for the stations series.
 */
import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom'

import {
  NULL_DATE_TEXT_STATIONS,
  INTERSTITIAL_INDICATOR,
  INTERSTITIAL_NOTE
} from '../../js/Configuration';

import {
  formatTime
} from '../../js/Utilities';

import '../Stations/css/StationsSeriesTable.css';
import './css/DestinationsPlaylistTable.css';

const DestinationsPlaylistTable = ({
  header,
  rows,
  onDuplicate
}) => {

  const makeHeader = () => {
    const ths = header.map((thd, idx) => {
      return <th key={idx}>{thd}</th>;
    });
    return <thead><tr>{ths}</tr></thead>;
  }

  const makeLink = (item) => {
    const {
      vsb_datetimetorun,
      vsb_id,
      contains_interstitial
    } = item;

    let datelabel = NULL_DATE_TEXT_STATIONS;

    if(vsb_datetimetorun && vsb_datetimetorun !== '0000-00-00') {
      datelabel = format(new Date(vsb_datetimetorun), 'PP');
    }

    let interstit = null;
    if(contains_interstitial) {
      interstit = (
        <b className="icon">
          <span aria-hidden="true">{INTERSTITIAL_INDICATOR}</span> {' '}
          <span className="form__access__label">Contains Interstitial</span>
        </b>
      )
    }

    return (
      <span>
        {interstit}
        <Link to={`/destinations/playlist/${vsb_id}`}>{datelabel}</Link>
      </span>
    );
  }

  const makeControls = (item) => {
    const {vsb_id} = item;

    return (
      <div>
        <Link
          to={`/destinations/playlist/${vsb_id}`}
          className="btn btn-sm btn--action"
        >View / Edit</Link>
        <form action="/"
          onSubmit={oOnDuplicate}
          className="playlist__edit"
        >
          <input
            type="hidden"
            name="block"
            id="block_id"
            value={vsb_id}
          />
          <button
            type="submit"
            className="btn btn-sm btn--action"
          >Duplicate</button>
        </form>
      </div>
    );
  }

  const makeRows = () => {
    const trs = rows.map((trd, idx) => {
      return (
        <tr key={`set_${trd.vsb_id}`}>
          <td>{makeLink(trd)}</td>
          <td className="duration">{formatTime(trd.duration)}</td>
          <td>
            {makeControls(trd)}
          </td>
        </tr>
      );
    });

    return <tbody>{trs}</tbody>;
  }

  const oOnDuplicate = (domEvent) => {
    domEvent.preventDefault();
    const {block} = domEvent.target;
    if(onDuplicate) {
      onDuplicate(block.value);
    }
  }

  return (
    <div className="destinations__playlist">
      <p className="destinations__component__note">{INTERSTITIAL_NOTE}</p>

      <table className="stations__series__table destinations__playlist__table">
        {makeHeader()}
        {makeRows()}
      </table>

    </div>
  );
}

export default DestinationsPlaylistTable;