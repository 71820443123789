import React from 'react';
import { CloseButton } from '../subcomponents/CloseButton';
import PropTypes from 'prop-types';
import TextInput from '../subcomponents/TextInput';
import ModalDialog from '../subcomponents/ModalDialog';
 
import {
  API_VIDEOSET_RENAME
} from '../../js/Configuration';

import { getAuthData } from '../../js/Utilities';

const EditVideoSet = (props) => {

  const onClose = () => {
    if(props.modalRef.current) {
      props.modalRef.current.close()
    }
  }

  return (
    <ModalDialog ref={props.modalRef} id="DestinationsEditVidset" onClose={onClose}>
      <h2>Rename this video set</h2>
      <form onSubmit={props.onSubmit} id="destination_create_vidset" action={API_VIDEOSET_RENAME} method="POST">
        <input type="hidden" name="token" value={getAuthData('token')} />
        <input type="hidden" name="id" value={props.item} onChange={() => {}} />
        <div className="form__label_btn_group">
        <TextInput
          cssClass="destinations__create-new"
          labelText="Enter a new name for this video set"
          name="title"
          id="edit"
          maxLength="50"
          onInputHandler={props.onChange}
          value={props.value} />
          <button type="submit" className="btn btn--action">Save</button>
        </div>
      </form>
    </ModalDialog>
  );
}

export default EditVideoSet;