/**
 * CRTApprovalsMenu.js
 * Used on the VideosPage modal to set whether a video has been approved,
 * rejected, etc.
 * See PropTypes definitions at the end of this file for parameters
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PleaseAddToAva } from '../../Videos/PleaseAddToAva';

class CRTApprovalsMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: []
    };

    this.makeMenuItems = this.makeMenuItems.bind(this);
  }

  makeMenuItems(arrayOfItems=[]) {
    let menu = null;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map(( item ) => {
        return <option key={`selectrating_${item.value.toString()}`} value={item.value}>{item.label}</option>
      });
    }

    return menu;
  }

  render() {
    const cssClasses = classNames(
      this.props.cssClass,
      {
        'video__info__crt'   : true,
        'video__info__field' : true
      }
    );

    return (
      <form action={this.props.apiurl} method={this.props.method} className={cssClasses} onChange={this.props.onChange} onSubmit={this.props.onSubmit}>
        <label htmlFor="review_status">Content Review Team:</label>
        <PleaseAddToAva hidden={ !this.props.disabled } />
        <span className="form__input_button_group">
          <select name="review_status" id="review_status" onChange={()=>{}}  disabled={this.props.disabled} value={ this.props.value }>
            {this.makeMenuItems(this.props.options)}
          </select>
          <button type="submit" disabled={this.props.disabled} className="btn btn--action">Save</button>
        </span>
      </form>
    );
  }
}

CRTApprovalsMenu.propTypes = {
  apiurl: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CRTApprovalsMenu;
