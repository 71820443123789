/*
Search Field Options used on SearchPage.js

*/

export const ORDER_BY = [
  { value:'', label: "Choose one" },
  { value:'date', label: "Date" },
  { value:'rating', label: "Rating" },
  { value:'relevance', label: "Relevance" },
  { value:'title', label: "Title" },
  { value:'viewCount', label: "View Count" }
];

export const TOPIC_ID = [
  { value:'', label: "Choose one" },
  { value:"/m/025zzc", label:"Action game" },
  { value:"/m/02ntfj", label:"Action-adventure game" },
  { value:"/m/0jm_", label:"American football" },
  { value:"/m/095bb", label:"Animated cartoon" },
  { value:"/m/018jz", label:"Baseball" },
  { value:"/m/018w8", label:"Basketball" },
  { value:"/m/01cgz", label:"Boxing" },
  { value:"/m/0b1vjn", label:"Casual game" },
  { value:"/m/05fw6t", label:"Children's music" },
  { value:"/m/02mscn", label:"Christian music" },
  { value:"/m/0ggq0m", label:"Classical music" },
  { value:"/m/01lyv", label:"Country music" },
  { value:"/m/09xp_", label:"Cricket" },
  { value:"/m/02lkt", label:"Electronic music" },
  { value:"/m/02jjt", label:"Entertainment" },
  { value:"/m/032tl", label:"Fashion" },
  { value:"/m/027x7n", label:"Fitness" },
  { value:"/m/02wbm", label:"Food" },
  { value:"/m/02vx4", label:"Football" },
  { value:"/m/0bzvm2", label:"Gaming" },
  { value:"/m/037hz", label:"Golf" },
  { value:"/m/0glt670", label:"Hip hop music" },
  { value:"/m/03glg", label:"Hobby" },
  { value:"/m/09kqc", label:"Humor" },
  { value:"/m/03tmr", label:"Ice hockey" },
  { value:"/m/05rwpb", label:"Independent music" },
  { value:"/m/03_d0", label:"Jazz music" },
  { value:"/m/01k8wb", label:"Knowledge" },
  { value:"/m/019_rr", label:"Lifestyle" },
  { value:"/m/01h7lh", label:"Mixed martial arts" },
  { value:"/m/0410tth", label:"Motorsport" },
  { value:"/m/02vxn", label:"Movies" },
  { value:"/m/04rlf", label:"Music" },
  { value:"/m/028sqc", label:"Music of Asia" },
  { value:"/m/0g293", label:"Music of Latin America" },
  { value:"/m/02hygl", label:"Music video game" },
  { value:"/m/05qjc", label:"Performing arts" },
  { value:"/m/068hy", label:"Pets" },
  { value:"/m/041xxh", label:"Physical attractiveness (Beauty)" },
  { value:"/m/064t9", label:"Pop music" },
  { value:"/m/066wd", label:"Professional wrestling" },
  { value:"/m/04q1x3q", label:"Puzzle video game" },
  { value:"/m/01sjng", label:"Racing video game" },
  { value:"/m/06cqb", label:"Reggae music" },
  { value:"/m/06j6l", label:"Rhythm and blues music" },
  { value:"/m/06by7", label:"Rock music" },
  { value:"/m/0403l3g", label:"Role-playing video game" },
  { value:"/m/021bp2", label:"Simulation video game" },
  { value:"/m/098wr", label:"Society" },
  { value:"/m/0gywn", label:"Soul music" },
  { value:"/m/06ntj", label:"Sports" },
  { value:"/m/022dc6", label:"Sports game" },
  { value:"/m/03hf_rm", label:"Strategy video game" },
  { value:"/m/07c1v", label:"Technology" },
  { value:"/m/07bs0", label:"Tennis" },
  { value:"/m/07bxq", label:"Tourism" },
  { value:"/m/0f2f9", label:"TV shows" },
  { value:"/m/07yv9", label:"Vehicles" },
  { value:"/m/07_53", label:"Volleyball" }
];

export const VIDEO_CAT = [
  { value:'', label: "Choose one" },
  { value:'1', label: "Film & Animation" },
  { value:'2', label: "Autos & Vehicles" },
  { value:'10', label: "Music" },
  { value:'15', label: "Pets & Animals" },
  { value:'17', label: "Sports" },
  { value:'19', label: "Travel & Events" },
  { value:'20', label: "Gaming" },
  { value:'22', label: "People & Blogs" },
  { value:'23', label: "Comedy" },
  { value:'24', label: "Entertainment" },
  { value:'25', label: "News & Politics" },
  { value:'26', label: "How to & Style" },
  { value:'27', label: "Education" },
  { value:'28', label: "Science & Technology" },
  { value:'29', label: "Nonprofits & Activism" },
];

export const EVENT_TYPE = [
  { value:'', label: "Choose one" },
  { value:'completed', label: "Completed" },
  { value:'live', label: "Live" },
  { value:'upcoming', label: "Upcoming" }
];

export const VIDEO_DEF = [
  { value:'any', label: "Any" },
  { value:'standard', label: "SD" },
  { value:'high', label: "HD" },
];

export const VIDEO_DIM = [
  { value:'any', label: "Any" },
  { value:'2d', label: "2D" },
  { value:'3d', label: "3D" }
];

export const VIDEO_DUR = [
  { value:'any', label: "Any" },
  { value:'short', label: "Short ( 0min - 4min )" },
  { value:'medium', label: "Medium ( 4min - 20min )" },
  { value:'long', label: "Long( 20min+ )" }
];

export const VIDEO_TYPE = [
  { value:'any', label: "Any" },
  { value:'episode', label: "Episode" },
  { value:'movie', label: "Movie" }
];

export const ANY_TRUE = [
  { value:'any', label: "Any" },
  { value:'true', label: "True" }
];

export const VIDEO_EMBEDDABLE = [
  { value:'any', label: "All videos" },
  { value:'true', label: "Embeddable only" }
];

export const SAFE_SEARCH = [
  { value:'none', label: "None" },
  { value:'moderate', label: "Moderate" },
  { value:'strict', label: "Strict" }
];

export const VIDEO_LIC = [
  { value:'any', label: "Any" },
  { value:'creativeCommon', label: "Creative Common" },
  { value:'youtube', label: "YouTube" }
];

export const VIDEO_SYNDICATED = [
  { value: 'any', label: 'All'},
  { value: 'true', label: 'Syndicated only'},  
]

// Moved REGIONS and LANGUAGES constants to the API codebase.
