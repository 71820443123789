export const initialState = {
  loading: true,
  usersList: [],
  addUser: {
    title: 'Add new user',
    open: true,
    mode: {
      mode: null,
      status: null
    },
    form: {
      /* 
       * This map should be in the form:
       * project_id : { checked: <boolean>, role: <integer|numeric string> }
       */
      project_access: new Map(),
      user_language: 0,
      user_region: 0,
      username: '',
      displayname_first: '',
      email: ''
    },
  },
  projects: [],
  languages: [],
  regions: [],
  editUser: {
    open: false,
    mode: {
      mode: null,
      status: null
    },
    form: {
      project_access: new Map(),
      user_language: 0,
      user_region: 0,
      user_name: '',
      displayname_first: '',
      email: '',
      uuid: ''
    },
  },
  alert: {
    open: false,
    title: '',
    message: ''
  },
  filtering: {}
};


export const reSetFormState = (state) => {
  const updates = {
    addUser: {
      ...state.addUser,
      form: {
        project_access: new Map(),
        user_language: '0',
        user_region: '0'
      }
    }
  };
  return updates;
}

export const resetEditUserState = (state) => {
  return {
    ...state,
    editUser: {
      ...state.editUser,
      open: false,
      form: {
        ...state.editUser.form,
        project_access: new Map(),
        user_language: '0',
        user_region: '0',
        user_name: '',
        displayname_first: '',
        email: ''
      }
    }
  }
}

export const setStateCloseStatusMsg = (state) => {
  return {
    ...state,
    addUser: {
      ...state.addUser,
      mode: {
        ...state.addUser.mode,
        mode: null,
        status: null
      }
    }
  }
}

export const setStateOnEditUserClose = (state) => {
  return {
    ...state,
    editUser: {
      ...state.editUser,
      form: {
        ...initialState.editUser.form,
      },
      open: false
    }
  }
}

/* 
Converts project object from { projectId: roleId }
to a map in the form: { projectId: { checked: true, role: roleId }}
*/
const projectObjectToMap = (po) => {
  const keys = Object.keys(po);
  const projectMap = new Map();
  
  keys.forEach((k) => {
    const startState = {
      checked: true,
      role: po[k]
    }
    projectMap.set(k, startState)
  });

  return projectMap;
}

export const setStateOnEditUserOpen = (state, uuid) => {
  const currentUser = state.usersList.find((u) => u.uuid === uuid);
  return {
    ...state,
    editUser: {
      ...state.editUser,
      mode: {
          mode: null,
          status: null
      },
      form: {
        ...state.editUser.form,
        user_language: currentUser.user_language,
        user_region: currentUser.user_region,
        user_name: currentUser.username,
        displayname_first: currentUser.displayname_first,
        displayname_second: currentUser.displayname_second || '',
        email: currentUser.email,
        enabled: +currentUser.enabled, // + Force integer
        missing_pass: currentUser.missing_pass,
        uuid: currentUser.uuid,
        project_access: projectObjectToMap(currentUser.permissions)
      },
      open: true
    },
    alert: {
      ...initialState.alert
    }
  }
}


export const setStateOnEditUserTextBox = (state, field, value) => {
  return {
    ...state,
    editUser: {
      ...state.editUser,
      open: true,
      form: {
        ...state.editUser.form,
        [field]: value
      }
    }
  }
}

export const setStateOnAddUserToggle = (state) => {
  return {
    ...state,
    addUser: {
      ...state.addUser,
      open: !state.addUser.open
    }
  }
}

export const setStateAfterAddUser = (state, data) => {
  return {
    ...state,
    addUser: {
      ...state.addUser,
      mode: {
        ...state.addUser.mode,
        mode: data.result,
        status: data.message
      }
    },
  }
}

const roleInfo = (valueJSON) => {
  return JSON.parse(valueJSON);
}

const isUserAdmin = (projectList, projectId) => {
  const project = projectList.find((p) => p.pid === projectId);
  let returnvalue = false;

  if(project) {
    returnvalue = (project.name === 'User Admin');
  };
  return returnvalue
}

export const setStateOnAddUserTextBoxChange = (state, field, value) => {
  return {
    ...state,
    addUser: {
      ...state.addUser,
      form: {
        ...state.addUser.form,
        [field]: value
      }
    }
  }
}

/*
Extracts the project ID from the form field name. Form field must be in the form
project_role[project_identifier] or project_access[project_identifier]
*/
const getProjectIdForRole = (targetName) => {
  let returnValue = false;
  const id = targetName.match(/project_(role|access)\[([-a-z0-9]{1,})\]/i);
  if(id.length) {
    returnValue = id[2];
  }
  return returnValue;
}

export const setStateOnAddUserToProject = (state, eventTarget) => {
 
  let projectId = getProjectIdForRole(eventTarget.name);
  // Copy existing state into a new Map.
  const changedAccess = new Map(state.addUser.form.project_access); 
  let details = changedAccess.get(projectId);
 
  if(eventTarget.type === 'checkbox') {
    // If it's not checked, delete it.
    if(!eventTarget.checked && changedAccess.has(projectId)) {
      changedAccess.delete(projectId);
    }  else {
      changedAccess.set(projectId, {...details, checked: eventTarget.checked});
    }    
  }

  else {
    changedAccess.set(projectId, {...details, role: +eventTarget.value});   
  }

  return {
    ...state,
    addUser: {
      ...state.addUser,
      form: {
        ...state.addUser.form,
        project_access: changedAccess
      }
    }
  }
}

export const setStateOnChangeUserProjects = (state, eventTarget) => {
  let projectId = getProjectIdForRole(eventTarget.name);

  // Copy existing state into a new Map.
  const changedAccess = new Map(state.editUser.form.project_access); 
  let details = changedAccess.get(projectId);

  if(eventTarget.type === 'checkbox') {
    // If it's not checked, delete it.
    if(!eventTarget.checked && changedAccess.has(projectId)) {
      changedAccess.delete(projectId);
    }  else {
      changedAccess.set(projectId, {...details, checked: eventTarget.checked});
    }
  }
  else {
      // If it's a radio button, update the role property instead.
    changedAccess.set(projectId, {...details, role: +eventTarget.value});
  }

  return {
    ...state,
    editUser: {
      ...state.editUser,
      form: {
        ...state.editUser.form,
        project_access: changedAccess
      }
    }
  }
}

export const setStateOnChangeRegionLang = (state, eventTarget) => {
  return {
    ...state,
    editUser: {
      ...state.editUser,
      form: {
        ...state.editUser.form,
        [eventTarget.name]: eventTarget.value
      }
    }
  }
}

const updateUsersList = (ul, data) => {
  const idx = ul.findIndex((x) => data.uuid === x.uuid);
  ul[idx] = Object.assign({...ul[idx]}, data);
  return ul;
}

export const setStateAfterUserEdit = (state, response) => {
  /*
   * Find the last updated user and update their region and language data
   */
  const uIndex = state.usersList.findIndex((u) => u.uuid === state.editUser.form.uuid);

  const uList = [...state.usersList];
  uList[uIndex] = {
    ...uList[uIndex],
    ...response.data,
    user_language: response.data.lang,
    user_region: response.data.region,
  };

  /* Delete these extraneous properties returned by the API */
  delete uList[uIndex].region;
  delete uList[uIndex].lang;

  return {
    ...state,
    usersList: uList,
    loading: false,
    editUser: {
      ...state.editUser,
      open: false
    }
  }
}

export const setStateOnUpdateError = (state, response) => {
  return {
    ...state,
    alert: {
      ...state.alert,
      open: true,
      message: response.message,
      title: 'Error 😞'
    },
    loading: false,
    editUser: {
      ...state.editUser,
      open: false
    }
  };
}

export const setStateOnCloseErrorModal = (state) => {
  return {
    ...state,
    alert: {
      ...state.alert,
      open: false,
      message: '',
      title: ''
    }
  }
}

export const setStateAfterDeleteUser = (state, response) => {
  return {
    ...state,
    alert: {
      ...state.alert,
      open: true,
      title: response.success ? 'Success 😁' : 'Error 😞',
      message: response.message
    },
    editUser: {
      ...state.editUser,
      open: false
    }
  }
}

export const setStateAfterDeactivateUser = (state, uuid, response) => {
  const userIndex = state.usersList.findIndex((u) => u.uuid === uuid);

  const uList = [...state.usersList];
  uList[userIndex] = {...uList[userIndex], enabled: false}

  return {
    ...state,
    usersList: uList,
    alert: {
      ...state.alert,
      open: true,
      title: response.result === 'success' ? 'Success 😁' : 'Error 😞',
      message: response.message
    },
    editUser: {
      ...state.editUser,
      open: false
    }
  }
}

export const setStateAfterReactivateUser = (state, uuid, response) => {
  const userIndex = state.usersList.findIndex((u) => u.uuid === uuid);

  const uList = [...state.usersList];
  uList[userIndex] = {...uList[userIndex], enabled: true}

  return {
    ...state,
    usersList: uList,
    alert: {
      ...state.alert,
      open: true,
      title: response.result === 'success' ? 'Success 😁' : 'Error 😞',
      message: response.message
    },
    editUser: {
      ...state.editUser,
      open: false
    }
  }
}

export const setStateOnPasswordReset = (state, response) => {
  return {
    ...state,
    alert: {
      ...state.alert,
      open: true,
      title: response.result === 'success' ? 'Success 😁' : 'Error 😞',
      message: response.message
    },
    editUser: {
      ...state.editUser,
      open: false
    }
  }
}


export const setStateOnFilterChange = (state, field, value) => {
  let returnValue, matching;

  // If there is no value, clear the filtered array and remove other properties.
  if(value.length == 0) {
    returnValue = {
      ...state,
      filtering: {}
    };
  } else {

    if(field === 'project') {
      matching = state.usersList.filter((user) => Object.keys(user.permissions).indexOf(value) > -1);
    } else {
      matching = state.usersList.filter((user) => { const regexp = new RegExp(value, 'ig'); return regexp.test(user[field]); });
    }

    returnValue = {
      ...state,
      filtering: {
        ...state.filtering,
        [field]: value,
        filtered: matching
      }
    }
  }
  return returnValue;
}
