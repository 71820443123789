/**
 * UpdateAccountPage.jsx
 * Enables users to update their own account page.
 */

import {useState, useEffect, useRef} from 'react';
import isNull from 'lodash.isnull';

import ActionMessageDisplay from './subcomponents/ActionMessageDisplay';
import PasswordResetShowHide from './UpdateAccount/PasswordResetShowHide';
import { IconApprovedRejected } from './subcomponents/IconApprovedRejected';
import { Meter } from './subcomponents/Meter';

import {
  API_USERS_GETUSERINFO,
  API_USERS_UPDATEUSER,
  API_USER_TEST_PASS,
  SET_PASS_RULES,
  SET_PASS_RULES2,
} from '../js/Configuration';

import {
  getAuthData,
  apirequest
}  from '../js/Utilities';

import {
  resetPasswordState,
  requestPasswordSuggestion,
  setStatePasswordResetShowHide,
} from '../actions/LoginUtils';

import {
  formFocusInitState,
  setStateOnLoadUser,
  setStateUpdateField
} from '../actions/UserUtils';

import './subcomponents/css/fancy-checkboxes.css';
import './UpdateAccount/css/UpdateAccountPage.css';

const UpdateAccountPage = () => {
  const [userData, setUserData] = useState({
    user: '',
    uuid: '',
    email: '',
  });
  const [passwordData, setPasswordData] = useState(resetPasswordState);
  const [updateStatus, setUpdateStatus] = useState(formFocusInitState);

  const pw1ref = useRef(null);
  const pw2ref = useRef(null);

  useEffect(() => {
    const fd = new FormData();
    fd.set('id', getAuthData('uid'));
    fd.set('uid', getAuthData('uuid'));
    fd.set('token', getAuthData('token'));

    apirequest(API_USERS_GETUSERINFO, { body: fd }, (data) => {
      setUserData(setStateOnLoadUser(userData, data));
    });
  }, []);

  const onChange = (domEvent) => {
    const {name, value} = domEvent.target;
    const data = setStateUpdateField(userData, name, value)
    setUserData(data)
  }

  const onChangePass = () => {
    checkPassStrength();
    checkPassMatch();
  }

  const updateAccount = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData();

    const {
      user,
      first_name,
      second_name,
      email,
      uuid,
      uid,
    } = userData;

    fd.set('username', user);
    fd.set('displayname', `${first_name}|${second_name}`);
    fd.set('name1', first_name);
    fd.set('name2', second_name);
    fd.set('email', email);
    fd.set('id', uid);
    fd.set('uuid', uuid);
    fd.set('token', getAuthData('token'));

    if(passwordData.password1.value && passwordData.canSave) {
      fd.set('password', passwordData.password1.value);
    }

    apirequest(API_USERS_UPDATEUSER, { body: fd }, (response) => {
      if(response.status === 'failed') {
        alert(response.message);
      } else {
        alert('Changes saved.');
      }
    });
  }

  const onSuggestionRequest = () => {
    requestPasswordSuggestion(getAuthData('token'), (response) => {
      const pword = {
        ...passwordData,
        password1: {
          ...passwordData.password1,
          value: response.suggestion,
          type: 'text' // makes password suggestion visible.
        }
      }
      setPasswordData(pword);
    });
  }

  const onShowPassword = (domEvent) => {
    if(domEvent.target.nodeName !== 'BUTTON') return;

    const {controls} = domEvent.target.dataset;
    const pwData = setStatePasswordResetShowHide(passwordData, controls)
    setPasswordData( pwData );
  }

  const checkPassStrength = () => {
    if(!Object.hasOwn(pw1ref, 'current')) return;

    const fd = new FormData();
    fd.set('tok', getAuthData('token'));
    fd.set('password1', pw1ref.current.value);

    apirequest(API_USER_TEST_PASS, {body: fd}, (response) => {
      const pwData = {
        ...passwordData,
        ...response,
      }
      setPasswordData(pwData);
    });
  }

  const checkPassMatch = () => {
    let doesMatch = false;

    if ( Object.hasOwn(pw1ref, 'current') && Object.hasOwn(pw2ref, 'current') ) {
      doesMatch = (pw1ref.current.value === pw2ref.current.value);
    }
    setPasswordData({...passwordData, canSave: doesMatch});
    return doesMatch;
  }

  const showAction = () => {
    const {update_status, action_mode} = updateStatus;

    return (<ActionMessageDisplay
      mode={action_mode}
      hidden={action_mode === null}
      message={update_status} />);
  }

  const isStrong = () => {
    let strong = false;
    if(passwordData && Object.hasOwn(passwordData, 'strength')) {
      strong = passwordData.strength >= 3;
    }
    return strong;
  }

  const isApproved = () => {
    let approved = false;
    if(isStrong()) {
      approved = true;
    }
    return approved;
  }

  const hasPass1 = () => {
    return Object.hasOwn(pw1ref, 'current') && !isNull(pw1ref.current) && pw1ref.current.value;
  }

  const hasPass2 = () => {
    return Object.hasOwn(pw2ref, 'current') && !isNull(pw2ref.current) && pw2ref.current.value;
  }

  const isApproved2 = () => {
    let doesMatch = false;

    if(hasPass1() && hasPass2()) {
      doesMatch = (pw1ref.current.value === pw2ref.current.value);
    }
    return doesMatch;
  }

  const saveEnabled = () => {
    let ok = passwordData.canSave;

    if(hasPass1() || hasPass2() ) {
      ok = isApproved2();
    }
    return ok;
  }


  const {
    user,
    uuid,
    email,
  } = userData;


  return (
    <div className="update__account">
      {showAction()}
      <form
        action={API_USERS_UPDATEUSER}
        method="POST"
        onSubmit={updateAccount}
        onFocus={() => setUpdateStatus(formFocusInitState)}
      >
        <p>
          <input type="hidden" name="uuid" id="uuid" value={uuid} />
          <label htmlFor="name">User Name:</label>
          <input
            className="form-control"
            type="text"
            name="name"
            id="name"
            value={user}
            disabled
          />
        </p>

        <p>
          <label htmlFor="email">User Email:</label>
          <input
            type="text"
            className="form-control"
            name="email"
            id="email"
            defaultValue={email}
          />
        </p>

        <p>
          <label htmlFor="password1">Enter new password</label>
          <button
            type="button"
            className="btn--request"
            onClick={onSuggestionRequest}>Get a password suggestion</button>
          <span className="set__pass__approvable">
            <PasswordResetShowHide
              ref={pw1ref}
              type={passwordData.password1.type}
              name="password1"
              id="password1"
              minLength="12"
              defaultValue={passwordData.password1.value}
              onChange={onChangePass}
              onClick={onShowPassword}
              aria-describedby="password1-note"
              autoComplete="new-password"
            />
            <b
              className="set__pass__approved"
              role="img"
              aria-label={isStrong() ? 'Unacceptable' : 'Approved'}
              hidden={!pw1ref?.current?.value.length > 0}
            ><IconApprovedRejected approved={ isApproved() } />
            </b>
          </span>
          <span
            id="password1-note"
            className="set__pass__note"
            dangerouslySetInnerHTML={SET_PASS_RULES} />
        </p>

        <p className="set__pass__passwordindic">
          Strength:
          <Meter
            className="password__strength"
            max="4"
            low="3"
            optimum="3.999"
            value={passwordData.strength}
          />
        </p>

        <p>
          <label htmlFor="password2">Confirm new password</label>
          <span className="set__pass__approvable">
            <PasswordResetShowHide
              ref={pw2ref}
              type={passwordData.password2.type}
              name="password2"
              id="password2"
              minLength="12"
              defaultValue={passwordData.password2.value}
              onChange={onChangePass}
              onClick={onShowPassword}
              ariaDescribedBy="password2-note"
              autoComplete="new-password"
              disabled={!isStrong()}
          />
          <b
            className="set__pass__approved"
            role="img"
            aria-label={isStrong() ? 'Unacceptable' : 'Approved'}
            hidden={!pw2ref?.current?.value.length > 0}
          >
            <IconApprovedRejected approved={ isApproved2() } />
          </b>
          </span>
          <span
            id="password2-note"
            className="set__pass__note">{SET_PASS_RULES2}</span>
        </p>

        <fieldset>
          <legend>Display Name</legend>
          <p>
            <label htmlFor="first_name">First Name:</label>
            <input
              type="text"
              className="form-control"
              name="first_name"
              id="first_name"
              defaultValue={userData.first_name}
              onInput={onChange}
            />
          </p>
          <p>
            <label htmlFor="second_name">Second Name:</label>
            <input
              type="text"
              className="form-control"
              name="second_name"
              id="second_name"
              defaultValue={userData.second_name}
              onInput={onChange}
            />
          </p>
        </fieldset>

        <p>
          <button
            type="submit"
            className="btn btn--action"
            disabled={!saveEnabled()}>Update Account Info</button>
        </p>
      </form>
    </div>
  );
}

export default UpdateAccountPage;