/**
 * ChannelEditTags.js
 */
import React from 'react';
import './css/ChannelEditTags.css';
import ActionMessageDisplay from '../subcomponents/ActionMessageDisplay';
import PropTypes from 'prop-types';

const ChannelEditTags = ({
  onSubmit,
  onChange,
  onMessageClose,
  success,
  channel,
  tags,
}) => {

  return (
    <details id="ChannelsEditTags" className="channel__singlepg__panel" open>
      <summary>
        <h2>Edit Tags</h2>
      </summary>
      <form className="form__channel__edit__tags" onSubmit={onSubmit}>
        <ActionMessageDisplay mode='success' hidden={!success} message="Tags updated." onClose={onMessageClose} />
        <p>
          <input type="hidden" name="channelID" value={channel} />
          <label htmlFor="channel_tags">Add or remove tags from the list</label>
          <textarea id="channel_tags" name="channel_tags" defaultValue={tags} onChange={onChange}></textarea>
        </p>
        <button type="submit" className="btn btn--action">Save Tags</button>
      </form>
    </details>  
  );
}

ChannelEditTags.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onMessageClose: PropTypes.func,
  success: PropTypes.bool,
  channel: PropTypes.string,
  tags: PropTypes.string,
  fullSize: PropTypes.bool
};

export default ChannelEditTags;
