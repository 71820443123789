/**
 * DateInput.jsx
 * A no-framework date input 
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DateInput = ({
  onChangeHandler,
  cssClass=null,
  id=null,
  name=null,
  value='',
  labelText='Add label text',
  isRequired=true
}) => {

  const showOptionalMessage = () => {
    let msg = '';
    msg = isRequired ? '(Required)' : '(Optional)';
    return msg;
  }

  const cssClasses = classNames('input-date destination-date', cssClass);

  return (
    <div className={cssClasses}>
      <label htmlFor={id}>{labelText}</label>
      <input type="date"
        defaultValue={value}
        name={name}
        id={id}
        required={isRequired}
        onChange={onChangeHandler} /><br />
        <span>{showOptionalMessage()}</span>
    </div>
  );
}

DateInput.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  cssClass: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  onChangeHandler: PropTypes.func,
};

export default DateInput;
