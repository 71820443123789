/**
 * RequestPasswordNoToken.js
 * Displayed when there is no password token in the URL.
 */

import React from 'react';
import { PASSWORD_REQUEST_NO_TOKEN } from '../js/Configuration';

export const ResetPasswordNoToken = () => {
  return <h1 className="page__set__password">{PASSWORD_REQUEST_NO_TOKEN}</h1>;
}
