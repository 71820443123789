/**
 * AmzClustersAddNew.jsx
 */
import React, { forwardRef } from 'react';
import { CloseButton } from '../subcomponents/CloseButton';
import ActionMessageDisplay from '../subcomponents/ActionMessageDisplay';
import uniqueId from 'lodash.uniqueid';
import { getAuthData } from '../../js/Utilities';

import './css/AmzClustersAddNew.css';

const MakeMenu = (options, value, onChangeHandler) => {
  /*
   Since the option keys function as their values, we need to keep the key/value
   association intact. But since 0 is not a value we want to allow in the menu
   or the database, we'll exclude it by only grabbing a portion of the array,
   beginning with index = 1
  */
  const optz = new Map(options.slice(1).map((o, idx) => {
    const descript = o.description ? `(${o.description})` : '';
    return [idx + 1, `${o.long} ${descript}`];
  }));

  let opts = []; // Initialize array because forEach doesn't do return values.
  optz.forEach((o, key) => {
    opts.push(<option value={key} key={uniqueId()}>{o}</option>);
  });

  return (
    <select id="size" name="size" className="form-control" onChange={onChangeHandler} value={value} required>
      <option value="">&mdash;</option>
      {opts}
    </select>
  )
};

const AddForm = (props) => {
  const ttl = <b>Add New Cluster to <q>{props.cluster.name}</q></b>;
  const err = props.error ? 'failed' : '';

  const onClose = () => {
    if(Object.hasOwn(props, 'onClose')) {
      props.onClose(props.modal);
    }

    if(Object.hasOwn(props.modal, 'current') && props.modal.current.open) {
      props.modal.current.close();
    }
  }

  return (
    <dialog id="AmzClustersAddNewModal" ref={props.modal} title={ttl}>
      <CloseButton color="#CBCBCB" className="dialog__body__close" onClick={onClose}/>
      <h2>{ttl}</h2>
      <ActionMessageDisplay mode="failed" hidden={!props.error} message={props.message} onClose={props.onErrorClose} />
      <form id="AmzClustersAddNewForm" style={{'margin': '3rem 0'}} onSubmit={props.onSubmit}>
        <input type="hidden" id="parent" name="parent" onChange={props.onChange} value={props.cluster.id || ''} />
        <p>
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" className="form-control" required value={props.name || ''} onChange={props.onChange} />
        </p>
        <p>
          <label htmlFor="size">Size</label>
          {MakeMenu(props.menu, props.size, props.onChange)}
        </p>
        <p>
          <label htmlFor="meta">Short description</label>
          <input type="text" id="meta" name="meta" className="form-control" value={props.meta || ''} onChange={props.onChange} />
        </p>
        <p>
          <label htmlFor="examples">Example songs</label>
          <input type="text" id="examples" name="examples" className="form-control" value={props.examples || ''} onChange={props.onChange} />
        </p>
        <p>
          <label htmlFor="notes">Notes</label>
          <textarea id="notes" name="notes" className="form-control" value={props.notes || ''} onChange={props.onChange}></textarea>
        </p>
        <p className="button__group" style={{'textAlign':'right'}}>
          <button type="submit" className="btn btn--action">Save</button>
          <button type="button" className="btn" onClick={onClose}>Cancel</button>
        </p>
      </form>
    </dialog>
  )
};

export const AmzClustersAddNew = forwardRef((props, ref) => {
  return (
    <div>
      <div style={{'textAlign':'right'}}>
        <button type="button" className="btn btn--action" onClick={props.onOpen}>Add New Cluster</button>
      </div>
      <AddForm {...props} modal={ref} />
    </div>
  );
});
