import React from 'react';

export const ButtonEditUser = (props) => {
  return <button type="button" className="btn btn--action" {...props}>Edit User</button>
}

export const ButtonResetPassword = (props) => {
  return (
    <form method="post" onSubmit={props.onClick}>
      <input type="hidden"  name="uuid" id="user_uid" value={props.uuid || ''} />
      <button type="submit" className="btn btn--action">Reset Password</button>
    </form>
  )
}

export const ButtonRemoveUser = (props) => {
  return (
    <form method="post" onSubmit={props.onClick}>
      <input type="hidden"  name="uuid" id="user_uid" value={props.uuid || ''} />
      <button type="submit" className="btn btn--action">Remove User</button>
    </form>
  )
}

