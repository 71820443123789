/**
 * DeleteSetModal
 * Modal called when there's a request to check for video updates.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../subcomponents/ModalDialog';

import {
  API_STATIONS_EPISODE_EDIT
} from '../../js/Configuration';

import {
  apirequest,
  csvrequest,
  getAuthData,
  forceDownload,
} from '../../js/Utilities';

// import './css/CheckForUpdatesProgress.css';
/* {
  "result": "success",
  "message": "Episode 13833 was updated",
  "series": null
} */

const DeleteSetModal = ({
  modalRef,
  blockId,
  setId,
  stationId,
  title
}) => {

  const onClose = (domEvent) => {
    let {returnValue} = domEvent.target;
    returnValue = !!+returnValue; // Convert to boolean true/false.

    if(returnValue) {
      const fd = new FormData();
      fd.set('id', blockId);
      fd.set('series', -1);
      fd.set('token', getAuthData('token'));
      const callback = (response) => {
        if (response.result === 'success') {
          window.location = `/destinations/rundates/${setId}`;
        }
      };
      apirequest(API_STATIONS_EPISODE_EDIT, { body: fd }, callback);
    }
  }

  return (
    <ModalDialog
      ref={modalRef}
      onClose={onClose}
      className="DeleteSetModal"
      id="DeleteSetModal"
    >
      <form method="dialog">
        <h2>Are you sure you want to delete <q>{title}</q>?</h2>
        <p style={{'display': 'flex', gap: '1rem'}}>
          <button
            type="submit"
            className="btn btn-sm btn--action"
            name="delete_playlist"
            value="1"
          >Yes</button>
          <button
            type="submit"
            className="btn btn-sm btn--action"
            name="delete_playlist"
            value="0"
          >No</button>
        </p>
      </form>
    </ModalDialog>
  )
}

export default DeleteSetModal;