/**
 * VideoModal.js
 * Shows data in a modal when requested.
 */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

import {
  format,
  isValid,
} from 'date-fns';

import {
  apirequest,
  numberFormat,
  getAuthData,
  formatTime
} from '../../../js/Utilities';

import { addVideo } from '../../../actions/SearchUtils';

import DestinationsSelect from '../../Destinations/DestinationsSelect';
import ModalDialog from '../ModalDialog';
import RatingsMenu from '../RatingsMenu';
import CRTApprovalsMenu from './CRTApprovalsMenu';
import MeowMenu from './MeowMenu';
import { VideoNote } from './VideoNote';
import { BadgeMEOWCRT } from '../../Videos/BadgeMEOWCRT';
import { PleaseAddToAva } from '../../Videos/PleaseAddToAva';
import { CloseButton } from '../CloseButton';

import {
  VIDEO_OPTIONS,
  NULL_DATE_TEXT,
  API_VIDEO_UPDATETAGS,
  API_CRTAPPROVALS_OPTIONS,
  API_CRTAPPROVALS_SETSTATUS,
  API_VIDEO_DELETE,
  API_VIDEO_ADDNOTE,
  API_VIDEO_INFO,
  API_GROUPS_ALLGROUPS,
  API_RATINGS_ALL,
  API_GROUPS_ADD_CHANNEL_TO_GROUP,
  API_MEOW_OPTIONS,
  API_MEOW_SETSTATUS,
  API_RATINGS_VIDEOUPDATE,
  MESSAGE_NOT_SUBMITTED,
  MESSAGE_VIDEO_NOTE_SAVED,
  MESSAGE_VIDEO_NOTRATED,
  API_VIDEO_REMOVEFROMSET,
  API_VIDEO_ADDTOSET
} from '../../../js/Configuration';

import './css/VideoModal.css';

const initInfoState = {
  video_yt_id: '',
  video_alreadyprocessed: '',
  video_channel_title: 'No title found',
  video_channel_yt_id: '',
};

const VideoModal = ({
  isAdmin=false,
  modalRef,
  youtubeId,
  id,
  params,
  onClose,
  onCRTSave,
  onRatingSave,
  onMeowSave
}) => {
    
  const [ytid, setYtid] = useState(youtubeId);
  const [info, setInfo] = useState(initInfoState);
  const [note, setNote] = useState('');
  const [channelAdded, setChannelAdded] = useState(false);
  const [videoAdded, setVideoAdded] = useState(false);
  const [addVideoButtonDisabled, setAddVideoButtonDisabled] = useState(false);
  const [chanGroups, setChanGroups] = useState({
    options: [],
    selected: null,
  });
  const [ratingsMenu, setRatingsMenu] = useState({
    options: [],
    selected: null,
  });
  const [meowMenu, setMeowMenu] = useState({
    options: [],
    selected: null,
  });
  const [crtMenu, setCrtMenu] = useState({
    options: [],
    selected: null,
  });

  useEffect(() => {
    if (youtubeId && ytid != youtubeId) {
      setInfo(initInfoState);
      setYtid(youtubeId);
    }

    if (info.video_yt_id == '') {
      getVideo(youtubeId);
    }

    if (!crtMenu.options.length) {
      getCrtOptions();
    }

    if (!meowMenu.options.length) {
      getMeowOptions();
    }

    if (!ratingsMenu.options.length) {
      getRatings();
    }

    if (!chanGroups.options.length) {
      getGroupOptions();
    }
  }, [info, ratingsMenu, crtMenu, meowMenu, youtubeId, ytid]);

  const oOnClose = () => {
    setInfo(initInfoState);

    if(onClose) {
      onClose(youtubeId);
    }
  }

  const getVideo = (id) => {
    const fd = new FormData();
    fd.set('id', id);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_INFO, { body: fd }, (data) => {
      setInfo(data);
    });
  }

  const getCrtOptions = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_CRTAPPROVALS_OPTIONS, { body: fd }, (data) => {
      const crtData = data.map((item, index) => ({
        value: index,
        label: item,
      }));

      setCrtMenu({ ...crtMenu, options: crtData });
    });
  }

  const getMeowOptions = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_MEOW_OPTIONS, { body: fd }, (data) => {
      setMeowMenu({...meowMenu, options: data});
    });
  }

  const getGroupOptions = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ALLGROUPS, { body: fd }, (data) => {
      setChanGroups({
        ...chanGroups,
        options: data
      });
    });
  }

  const onCRTSubmit = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('ytid', ytid)
    fd.set('status', crtMenu.selected);
    fd.set('user_id', getAuthData('uuid'));
    fd.set('token', getAuthData('token'));

    apirequest(API_CRTAPPROVALS_SETSTATUS, { body: fd }, (data) => {
      window.alert(data.message);
      if(data.status === 'success' && onCRTSave) {
        onCRTSave(data);
      }
    });
  }

  const onCRTChange = (domEvent) => {
    const { value } = domEvent.target;

    if (value) {
      setCrtMenu({
        ...crtMenu,
        selected: value,
      });
    }
  }

  const getCrtMenu = () => {
    let val = '';

    if (info.hasOwnProperty('crt') && !crtMenu.selected) {
      val = info.crt.id;
    } else if (crtMenu.selected) {
      val = crtMenu.selected;
    }

    return (
      <CRTApprovalsMenu
        onSubmit={onCRTSubmit}
        onChange={onCRTChange}
        options={crtMenu.options}
        value={val}
        disabled={!info.video_alreadyprocessed}
      />
    );
  }

  const getMeowMenu = () => {
    return (
      <MeowMenu
        onSubmit={onMeowSubmit}
        onChange={onMeowChange}
        options={meowMenu.options}
        value={meowMenu.selected}
        disabled={!info.video_alreadyprocessed}
      />
    );
  }

  const onMeowChange = (domEvent) => {
    if (domEvent.target) {
      const {value} = domEvent.target;
      setMeowMenu({...meowMenu, selected: value});
    }
  }
  
  const onMeowSubmit = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('ytid', info.video_yt_id)
    fd.set('status', meowMenu.selected);
    fd.set('user_id', getAuthData('uuid'));
    fd.set('token', getAuthData('token'));

    apirequest(API_MEOW_SETSTATUS, { body: fd }, (data) => {
      window.alert(data.message);
      if(data.status === 'success' && onMeowSave) {
        onMeowSave(data);
      }
    });
  }

  const getRatings = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_ALL, { body: fd }, (data) => {
      const ratingsData = data.map((item, index) => ({
        value: index,
        label: item.label,
      }));

      setRatingsMenu({...ratingsMenu, options: ratingsData});
    });
  }

  const onAddVideo = (domEvent) => {
    addVideo(ytid, (response) => {
      const {success, addedVideos} = response;
      if(success) {
        setInfo(initInfoState);
        setYtid(addedVideos[0])
      }
    });
  }

  const getAddBtn = () => {
    let action;
    
    if (info.video_alreadyprocessed === 'y' || info.video_alreadyprocessed === true || videoAdded) {
      action = <b>Video Added to System</b>;
    } else {
      action = <button type="button" className="video__info__in btn btn--action" onClick={onAddVideo}>Add to System</button>
    }

    return (
      <div className="video__info__in_sys">
        {action}
      </div>
    )
  }
  
  const getDeleteVideo = () => {
    if (!isAdmin) return;

    const setblocklist = () => {
      let response = [];

      if (
        Object.hasOwn(info, 'dest_set_block_assignments')
        && info?.dest_set_block_assignments.length
      ) {
        response = info.dest_set_block_assignments
          .filter((vset) => vset !== null)
          .map((st, index) => {
            const rundate = st.episode_run_date ? format(new Date(st.episode_run_date), 'yyyy-MM-dd') : NULL_DATE_TEXT;

            const isStation = st.isStation ? '(station)' : '';

            return (
              <option key={index.toString()} value={st.block_id}>
                {st.destination_name}
                {' '}
                { st.vs_name }
                {' '}
                {rundate}
                {' '}
                { isStation }
              </option>
            )
          });
      }
      return response;
    };

    return (
      <form className="video__info_field video__info__field" onSubmit={deleteVideo}>
        <label htmlFor="video_delete_value">Delete Video from Block or System:</label>
        <span className="form__input_button_group">
          <select name="video_delete_value" id="video_delete_value">
            <option value="all">Delete Video From System</option>
            {setblocklist()}
          </select>
          <button className="btn btn--action">Delete Video</button>
        </span>
      </form>
    )
  }

  const makeVideoSets = () => {
    let video_sets = null;

    if (Object.hasOwn(info, 'dest_set_block_assignments')) {
  
      const {dest_set_block_assignments} = info;
      
      video_sets = dest_set_block_assignments
        .filter((vset) => vset !== null)
        .map((vset, index) => {
          if (!vset) return;
          const rundate = vset.episode_run_date ? format(new Date(vset.episode_run_date), 'yyyy-MM-dd') : NULL_DATE_TEXT;
          const eptitle = vset.episode_title ? `: ${vset.episode_title}` : '';
          return (
            <li key={index.toString()} data-videosetblock={vset.video_set_block_id} className="video__info__setname">
              <a className="video__info__vs_badge" href={`/destinations/playlist/${vset.block_id}`} rel="nofollow noreferrer">
                <b>
                  {vset.destination_name}
                  :
                  {' '}
                  {vset.vs_name}
                  {eptitle}
                  {' '}
                  (
                  {rundate}
                  )
                </b>
              </a>
            </li>
          );
        });
    }
    return (
      <li className="video__stats__list_vs">
        <b>Video Sets:</b>
        <div className="resizable--vert">
          <ul className="video__info__eplist">{video_sets}</ul>
        </div>
      </li>
    );
  }

  const onRatingsChange = (domEvent) => {
    const rating = ratingsMenu.options.find((rating) => domEvent.target.value == rating.value);
    setRatingsMenu({...ratingsMenu, selected: rating});
  }

  const onRatingsSubmit = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('userid', getAuthData('uuid'));
    fd.set('videoid', info.video_yt_id);
    fd.set('rating', ratingsMenu.selected.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_VIDEOUPDATE, { body: fd }, (data) => {
      window.alert(data.message);

      if ((data.error === false ) && onRatingSave) {
        onRatingSave(data);
      }
    });
  }
  

  const getRatingsMenu = () => {
    let val = '';

    if (ratingsMenu.selected) {
      val = ratingsMenu.selected.value;
    } else if (info.rating) {
      val = ratingsMenu.options.findIndex((opt) => info.rating.value === opt.value);
    }

    return (
      <RatingsMenu
        actionURL="api/ratings"
        options={ratingsMenu.options}
        onChangeHandler={onRatingsChange}
        onSubmitHandler={onRatingsSubmit}
        value={val}
        disabled={!info.video_alreadyprocessed}
      />
    );
  }


  const deleteVideo = (domEvent) => {
    domEvent.preventDefault();

    const blockid = domEvent.target.video_delete_value.value;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    
   /**
     * Until we can standardize the API end points to spit out the same property names.
     */
    let vid;
    if (Object.hasOwn(info, 'video_yt_id')) {
      vid = info.video_yt_id;
    }

    if (Object.hasOwn(info, 'video_youtubeID')) {
      vid = info.video_youtubeID;
    }

    if (blockid === 'all') {
      const yes = window.confirm('Are you sure you want to delete this video from AVA?');

      if (yes) {
        fd.set('videoid', vid);
        apirequest(API_VIDEO_DELETE, { body: fd }, (response) => {
          if(response.videosDeleted.length) {
            alert('Video deleted.');
          }
        });
      } else {
        alert('Video not deleted.');
      }
    } else {
      const yes = window.confirm('Are you sure you want to remove this video from the selected playlist?');
      fd.set('video', vid);
      fd.set('playlist', blockid);

      apirequest(API_VIDEO_REMOVEFROMSET, { body: fd }, (response) => {
        if (this.props.hasOwnProperty('onDeleteFromBlock')) {
          this.props.onDeleteFromBlock(response);
        }
      });
    }
  }

  const addTagsToVideo = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_UPDATETAGS, { body: fd }, (data) => {
      if(data.message === 'success') {
        window.alert('Custom tags saved!')
      }
    });
  }

  const onChannelGroupChange = (domEvent) => {
    setChanGroups({
       ...chanGroups,
       selected: domEvent.target.value
    });
  }

  const addChannelToGroup = (domEvent) => {
    domEvent.preventDefault();

    const channel = info.video_channel_yt_id;

    const fd = new FormData();
    fd.set('group', chanGroups.selected);
    fd.set('channels', channel);
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ADD_CHANNEL_TO_GROUP, { body: fd }, addedToGroup);
  }

  const addedToGroup = (data) => {
    const sel = chanGroups.selected;
    const grp = chanGroups.options.find((g) => +g.group_id === +sel);

    if (data.success) {
      alert(`Added "${info.video_channel_title}" to "${grp.group_name}."`);
    } else {
      alert(`"${info.video_channel_title}" is already in "${grp.group_name}."`);
    }
  }

  const getAddChannel = () => {
    if (!chanGroups.options) return;

    return (
      <form
        className="video__info__field video__info_changroup"
        onSubmit={() => {}}
        onChange={onChannelGroupChange}
      >
        <label htmlFor="modal_group_select">Add Channel to Group:</label>
        <PleaseAddToAva hidden={info.video_alreadyprocessed} />
        {!video_channel_yt_id &&
          <p className="itemsBadge">
            <i>Can't Modify Channel Group. Missing Channel ID.</i>
          </p>
        }
        <span className="form__input_button_group">
          <select
            name="modal_group_select"
            id="modal_group_select"
            disabled={!info.video_alreadyprocessed || !video_channel_yt_id}
          >
            <option value="">Select Group</option>
            {chanGroups.options.map((st, k) => {
              return <option key={st.group_id} value={st.group_id}>{st.group_name}</option>
            })}
          </select>
          <button
            type="submit"
            className="btn btn--action"
            onClick={addChannelToGroup}
            disabled={!video_channel_yt_id}
          >Add Channel</button>
        </span>
      </form>
    )
  }

  const onNoteChange = (domEvent) => {
    const {value} =  domEvent.target;
    setNote(value);
  }

  const onNoteSubmit = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('ytid', info.video_yt_id);
    fd.set('note', note);

    apirequest(API_VIDEO_ADDNOTE, { body: fd }, (data) => {
      if (data.error === false) {
        alert(MESSAGE_VIDEO_NOTE_SAVED);
      }
    });
  }

  const {
    video_channel_title,
    video_channel_yt_id,
    video_yt_id,
    video_duration,
    video_viewcount,
    video_likecount,
    video_dislikecount,
    video_description,
    video_favoriteCount,
    meow,
    crt,
    pubdate,
    avadate,
    videoTags,
    video_rating,
  } = info

  let pDate = '';
  if(isValid(pubdate)) {
    pDate = format(pubdate, 'PP');
  }

  let aDate = '';
  if(isValid(avadate)) {
    pDate = format(avadate, 'PP');
  }

  let duration = '';
  if(isValid(video_duration)) {
    duration = formatTime(video_duration)
  }

  const getSysContent = () => {
  const vid = info;
  const tags = vid.hasOwnProperty('video_customtags') ? vid.video_customtags.replace(/,/, '') : '';
  const vid_id = vid.video_yt_id || '';

  let pubDate;
  // Comes in as a PHP timestamp.
  if (info.hasOwnProperty('video_publishdate')) {
    pubDate = isValid(info.video_publishdate) ? format(new Date(info.video_publishdate * 1000), 'PP') : null;
  }

  return (
    <div>
      <DestinationsSelect
        mode="video_info"
        disabled={!vid.video_alreadyprocessed}
        showEdit={false}
        videoId={ytid}
      />

      { getRatingsMenu() }
      { getMeowMenu() }
      { getCrtMenu() }

      <form className="video__info__custom_tags video__info__field" onSubmit={addTagsToVideo}>
        <input type="hidden" name="videoid" value={vid_id} onChange={() => {}} />
        <label htmlFor="tags">Add/Edit Custom Video Tags:</label>
        <PleaseAddToAva hidden={info.video_alreadyprocessed} />
        <span className="form__input_button_group">
          <input
            type="text"
            id="tags"
            name="tags"
            defaultValue={tags}
            placeholder="Add Comma Separated Tags"
            disabled={!info.video_alreadyprocessed}
          />
          <button className="btn btn--action" type="submit">Update Video Tags</button>
        </span>
      </form>

      {getDeleteVideo()}

      <section className="video__info__field">
        <h5>Channel Info:</h5>
        <ul className="video__info__channel">
          <li>
            <b className="listText">Name:</b>
            { video_channel_title ?
            <a rel="noopener noreferrer" target="_blank" href={`https://www.youtube.com/channel/${video_channel_yt_id}/videos`}>
              <span className="itemsBadge textLink divTransition">{ video_channel_title }</span>
            </a>
            :
            <i className="itemsBadge">Missing Channel Title</i>
          }
          </li>
          <li>
            <b className="listText">Date:</b>

            <span className="itemsBadge">{ pubDate }</span>
          </li>
        </ul>
        {getAddChannel()}

        <VideoNote
          onChange={onNoteChange}
          onSubmit={onNoteSubmit}
          value={info.video_note}
          disabled={!info.video_alreadyprocessed}
        />

      </section>
    </div>
  );
}

  return (
    <ModalDialog
      ref={modalRef}
      onClose={oOnClose}
      id={id}
    >
      <div className="video__info__container">
        <YouTube
          id={ytid}
          videoId={ytid}
          opts={VIDEO_OPTIONS}
          className="video__info__vid"
        />
        {getAddBtn()}

        <section className="video__stats video__info__field">
          <h5>Video Stats:</h5>
          <ul className="list__meta__data video__stats__list">
            <li>
              <b>Channel Name:</b>
              <span>{ video_channel_title }</span>
            </li>
            <li>
              <b>Publish Date:</b>
              <span>{ pDate }</span>
            </li>
            <li>
              <b>Added to AVA:</b>
              <span>{ aDate }</span>
            </li>
            <li>
              <b>Duration:</b>
              <span>{ duration }</span>
            </li>
            <li>
              <b>Views:</b>
              <span>{numberFormat(video_viewcount)}</span>
            </li>
            <li>
              <b>Likes:</b>
              <span>{numberFormat(video_likecount)}</span>
            </li>
            <li>
              <b>Dislikes:</b>
              <span>{numberFormat(video_dislikecount)}</span>
            </li>
            <li>
              <b>Favorites:</b>
              <span>{numberFormat(video_favoriteCount)}</span>
            </li>
            <li className="video__stats__list_vt">
              <b>Video Tags:</b>
              <div className="resizable--vert">
                <div>{ videoTags }</div>
              </div>
            </li>

            { makeVideoSets() }

            <li>
              <b>Video Rating:</b>
              <span>{video_rating}</span>
            </li>
            <li>
              <abbr>MEOW:</abbr>
              <span>
                <BadgeMEOWCRT mode="meow" {...meowMenu} fullSize />
              </span>
            </li>

            <li>
              <abbr title="Content Review Team">CRT:</abbr>
              <span>
                <BadgeMEOWCRT mode="crt" {...crtMenu} fullSize />
              </span>
            </li>
          </ul>
        </section>

        <section className="video__info__description video__info__field">
          <h5>Video Description:</h5>
          <div className="description" contentEditable="true" dangerouslySetInnerHTML={{ __html: video_description }} />
        </section>

        { getSysContent() }
      </div>
    </ModalDialog>
  )
}

export default VideoModal;
