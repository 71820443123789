/**
 * UserAdminActivate.js
 * A control for activating or deactivating a user.
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import { MESSAGE_USER_NOPASS } from '../../../js/Configuration';

export const UserAdminActivate = (props) => {
  const prefix = props.enabled ? 'Dea' : 'A';
  const title = props.missing_pass ? null : MESSAGE_USER_NOPASS;
  
  const submitter = props.enabled ? props.onDeactivate : props.onReactivate;

  return (
    <form onSubmit={submitter}>
      <input type="hidden"  name="uuid" id="uuid" value={props.uuid || ''} onChange={()=>{}}/>
      <button
        type="submit"
        className="btn btn--action btn__user--activate"
        disabled={props.missing_pass}
        title={title}>{prefix}ctivate</button>
    </form>
  );
}
