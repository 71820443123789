/**
 * AddChannelsByURL.js
 * Used to add channels by URL. In terms of layout, it's the same as AddVideosByURL.
 */
import React from 'react';
import PropTypes from 'prop-types';

import SelectMenuControlled from '../subcomponents/SelectMenuControlled';
import { IconApprovedRejected } from '../subcomponents/IconApprovedRejected';
import ModalDialog from '../subcomponents/ModalDialog';

import uniqueId from 'lodash.uniqueid';

import {
  MESSAGE_ERROR_TOO_MANY_URLS
} from '../../js/Configuration';

import '../Destinations/css/AddVideosByURL.css';

const AddChannelsByURL = ({
  channels,
  group,
  open,
  options,
  modalRef,
  response,
  working,
  max,
  onClose,
  onChange,
  onSubmit
}) => {

  const urlField = React.useRef(null);

  if(
    Object.hasOwn(urlField,'current') &&
    urlField.current &&
    open
  ) {
    urlField.current.focus();
  }

  const makeListItem = ( info = [] ) => {
    let title;
    switch( info[1].message ) {
      case 'exists':
        title = 'Already in AVA';
        break;
      default:
        title = 'Added successfully';
    }

    /*
      In makeDialogBody, we convert response to an array from a Map.
      Each item that we receive here is an array in the form [channelid, statusobject]
     */
    return (
      <li key= { uniqueId() }>
        { info[1].title }
        <i className={`add_channels_message__${info[1].message}`}>{ title }</i>
        <b className={`add_channels_indicator__${info[1].message}`}>
          <IconApprovedRejected approved={ info[1].message === 'success' } title="" />
        </b>
      </li>
    );
  }
  
  const makeAddForm = () => {
    return (
      <div>
        <form method="dialog" id="add_channels_by_url_form" onSubmit={onSubmit}>
          <p>
            <label htmlFor="add_channels_by_url_urls">Enter URLs</label> <span className="form__instructions" id="add_channels_by_url_note">(Maximum 50 URLs or YouTube identifiers.)</span><br />
            <textarea className="form-control" id="add_channels_by_url_urls" aria-describedby="add_channels_by_url_note" name="channels" value={channels} onChange={onChange} rows="15" ref={urlField} required disabled={working}></textarea>
            <span className="form__instructions aria__description">Use valid YouTube URLs or channel IDs. URLs or IDs should be separated either by a comma (,) or new line. If using URLs, include the protocol for each, e.g.: <code>http://</code> or <code>https://</code>. Limit 50.</span>
          </p>
          <div>
            <SelectMenuControlled options={options} labelText="Select a group" firstEmpty={true} name="group" cssClass="channels__group_menu" value={group} onChange={onChange} />
            <div id="group__labelled" className="form__instructions">(Optional)</div>
          </div>
        </form>
        <div>
          <i><span hidden={!max} className="form__instructions form__error"><b>{MESSAGE_ERROR_TOO_MANY_URLS[0]}</b> {MESSAGE_ERROR_TOO_MANY_URLS[1]}</span></i>
          <button type="submit" form="add_channels_by_url_form" disabled={max} className="btn btn--action">Add Channels</button>
        </div>
      </div>
    )
  }

  const makeDialogBody = () => {
    let display = makeAddForm();

    /* Convert the map to an array */
    /*if( [...response].length ) {
      display = <ul className="multi_success multi_success--alt">{ Array.from( response ).map( makeListItem ) }</ul>;
    } */

    return (
      <div id="add_channels_by_url">
        { display }
      </div>
    )
  }

  return (
    <ModalDialog ref={modalRef} id="AddChannelsByURL" open={open} onClose={onClose}>
      <h2>Add Channels By URL</h2>
      {makeDialogBody()}
    </ModalDialog>
  );
}

AddChannelsByURL.propTypes = {
  open: PropTypes.bool.isRequired,
  cssClass: PropTypes.string,
  channels: PropTypes.string,
  group: PropTypes.string,
  options: PropTypes.array,
  max: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  response: PropTypes.object,
  working: PropTypes.bool
};

export default AddChannelsByURL;