/**
 * RuntimeRatings.jsx
 * Display runtime and video set ratings on the Destinations page.
 * See PropTypes definitions at the end of this file for parameters
 */  
import React from 'react';
import './css/RuntimeRatings.css';

const RuntimeRatings = ({
  runtime=0,
  rating='—',
  videos=0,
  isRequired=false
}) => {

  const showOptionalMessage = () => {
    let msg = '(Optional)';

    if(isRequired) {
       msg = '(Required)';
    }
  }

  return (
    <div className="ratings__runtime">
      <span className="ratings__runtime_rt">Runtime {runtime} </span>
      | <span className="ratings__runtime_vc">{videos} videos </span>
      | <span className="ratings__runtime_r">Rating: {rating}</span>
    </div>
  );
}

export default RuntimeRatings;
