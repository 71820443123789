export const setPageState = (pagination, value) => {
  let process;

  switch(value) {
    case 'first':
      process = {
        ...pagination,
        page: 1
      }
      break;
    case 'previous':
      process = {
        ...pagination,
        page: +pagination.page - 1
      }
      break;
    case 'next':
      process = {
        ...pagination,
        page: pagination.page + 1
      }   
      break;
    case 'last':
      process = {
        ...pagination,
        page: pagination.pages
      }
      break;        
    default:
      process = {
        ...pagination,
        page: +value
      }
  }
  return process;
}

