/**
 * ChannelListTable.jsx
 * A no-framework table component.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
  
const ChannelListTable = ({
  cssClass=null,
  headers=[],
  id='',
  rows=[],
  onCheckChange
}) => {

  const makeHeaders = () => {
    let retval;

    if(headers) {
      let hdrs = [];

      hdrs = headers.map((h, idx) => {
        return <th key={idx.toString()}>{h}</th>
      });

      retval = (
        <thead>
          <tr>
            {hdrs}
          </tr>
        </thead>
      );
      
    } else {
      retval = null
    }
    return retval;
  }

  const makeRows = () => {
    let retval;
    
    if(rows) {
      let rws = [];

      rws = rows.map((h,idx) => {
        return makeOneRow(h, idx);
      });

      retval = (
        <tbody>
         {rws}
        </tbody>
      );
      
    } else {
      retval = null
    } 
    return retval;
  }

  const makeOneRow = (obj, index) => {
    let tds = [];

    tds[0] = (
      <td key={obj.channel_youtubeID}>
        <input
          name="channels_to_add"
          type="checkbox"
          defaultChecked={obj.channel_selected}
          id={obj.channel_youtubeID}
          value={obj.channel_youtubeID}
          onChange={onCheckChange} />
      </td>
    );

    tds[1] = (
      <td key={`${obj.channel_youtubeID}_1`}>
        <label htmlFor={`${obj.channel_youtubeID}`}>
          {obj.channel_title}
        </label>
      </td>
    );

    tds[2] = <td key={`${obj.channel_youtubeID}_count`}>{obj.video_count}</td>;

    const tr = (
      <tr key={index.toString()}>
        {tds}
      </tr>
    );

    return tr;
  }

  const cssClasses = classNames('table-list', cssClass);
  return (
    <table className={cssClasses} id={id}>
      {makeHeaders()}
      {makeRows()}
    </table>
  );
}

ChannelListTable.propTypes = {
  id: PropTypes.string,
  cssClass: PropTypes.string,
  headers: PropTypes.array,
  rows: PropTypes.array,
  onCheckChange: PropTypes.func
};

export default ChannelListTable;
