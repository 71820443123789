/*
LoginUtils.js
*/

import {
  apirequest,
  setAuthData
} from '../js/Utilities';

import {
  API_USER_LOGIN,
  API_USER_PW_RESET,
  ERROR_MISSING_USERNAME,
  ERROR_LOGIN_FAILED,
  API_USER_SUGGEST_PASSWORD
} from '../js/Configuration';

export const login = (formdata, callback) => {
  const options = {
    method: 'POST',
    body: formdata
  };
  apirequest(API_USER_LOGIN, options, callback);
}

export const setStatePostLogin = (state, data) => {
  let response;

  if(data.result === 'success') {
    setAuthData(data);
    response = {user: data};
  } else {
    response = {error: ERROR_LOGIN_FAILED};
  }

  return response;
}

export const requestPassword = (domEvent) => {
  if(!domEvent.target.elements.uname.value) {
    alert(ERROR_MISSING_USERNAME);
    return;
  }

  domEvent.preventDefault();
  domEvent.persist();

  const fd = new FormData();
  fd.append('id', domEvent.target.elements.uname.value);
  fd.append('tok', domEvent.target.elements.tok.value);

  const options = {
    method: 'POST',
    body: fd
  };

  apirequest(API_USER_PW_RESET, options, (response) => {
    if(response.result === 'success') {
      window.location = '/emailsent';
    }
  });
}

export const requestPasswordSuggestion = (reqtok, callback) => {
  const fd = new FormData();
  fd.set('tok', reqtok);

  const options = {
    method: 'POST',
    body: fd
  };

  apirequest(API_USER_SUGGEST_PASSWORD, options, callback);
}

export const updateOnPasswordSuggestion = (state, suggestionObj) => {
  return {
    password1: {
      value: suggestionObj.suggestion,
      type: 'text'
    }
  };
}

export const setStatePasswordResetShowHide = (state, fieldId) => {
  const opts = ['password', 'text']; // Options we can use.
  const index = +(state[fieldId].type === 'password'); // + converts this boolean to an integer
  return {
    ...state,
    [fieldId]: {
      ...state[fieldId],
      type: opts[index]
    }
  }
}

export const resetPasswordState = {
  password1: {
    value: '',
    type: 'password'
  },
  password2: {
    value: '',
    type: 'password'
  },
  strength: 0,
  length: 0,
  canSave: true,
  token: '',
  reqtok: '',
  expired: false,
  message: null,
  mode: null
}
