/**
 * GroupsPageTable component 
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import uniqueId from 'lodash.uniqueid';

import { getUTCNormalizedDate } from '../../js/Utilities';

import '../../css/GroupsPageTable.css';

const GroupsPageTable = ({
  groupList,
  onDelete
}) => {

  if(!groupList.length) return null;

  let groups = groupList.map(( item, i ) => {
    const cCount = item.channelList.length || 0;
    /* Object ordered by where they appear in each row */
    return {
      title: item.group_name,
      dateAdded: item.group_dateadded,
      dateUpdated: item.group_datelastupdated,
      channelCount: cCount,
      id: item.group_id,
    }
  });

  const rows = groups.map((item, index) => {
    let tds;
    if(Object.keys(item).length)  {
      tds = Object.keys(item).map((r) => {
        let cell;

        switch(r) {
          case 'dateAdded':
          case 'dateUpdated':
            cell = <td key={uniqueId('gptc_')}>{ format(new Date(getUTCNormalizedDate(item[r])), 'd MMMM yyyy') }</td>;
            break;

          case 'id':
            cell = (
              <td key={uniqueId('gptc_')}>
                <span className="button__group">
                  <Link to={`/group/${item[r]}`} className='btn btn btn-sm btn--action'>Edit Group</Link>
                  <button onClick={onDelete} type="button" data-groupid={item[r]} className='btn btn btn-sm btn--action'>Remove Group</button>
                </span>
              </td>
            )
            break;

          default:
            cell = <td key={uniqueId('gptc_')}>{item[r]}</td>;
        }

        return cell;
      })
    }

    return <tr key={uniqueId('group_')}>{tds}</tr>
  });

  return (
    <table id="GroupsPageTable" className="groups__table">
      <thead>
        <tr>
          <th>Group Name</th>
          <th>Date Added</th>
          <th>Date Updated</th>
          <th>Channel Count</th>
          <th>Remove Group</th>
        </tr>
      </thead>

      <tbody>
        {rows}
      </tbody>
    </table>
  )
}

export default GroupsPageTable;
