export const initAllChannels = {
  open: false,
  loading: true,
  page: 1,
  perPage: 10,
  pageCount: 1,
  list: [],
  sortBy: 'video_addeddate',
  ascending: true,
  keyword: '',
  filterOn: '',
  toAdd: [],
} 

export const setStateAfterGroupDelete = (state, data) => {
  const groups = [...state];
  return groups.filter((g) => +g.group_id !== +data.deleted);
}

export const deleteGroupConfirmation = (group_name) => {
  window.alert(`Group "${group_name}" was removed.`)
}

