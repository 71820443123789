/**
 * AmzGenreAddNew.jsx
 */
import React, { forwardRef } from 'react';
import ActionMessageDisplay from '../subcomponents/ActionMessageDisplay';
import uniqueId from 'lodash.uniqueid';
import { getAuthData } from '../../js/Utilities';
import ModalDialog from '../subcomponents/ModalDialog';

import './css/AmzGenreAddNew.css';

export const AmzGenreAddNew = ({
  id,
  modalRef,
  error,
  message,
  name,
  notes,
  onChange,
  onClose,
  onOpen,
  onSubmit,
  onErrorClose

}) => {

  const openModal = () => {
    if(modalRef.current) {
      modalRef.current.showModal();
    }
  }

  const closeModal = () => {
    if(modalRef.current) {
      modalRef.current.close();
    }
  }

  return (
    <div>
      <div style={{'textAlign':'right'}}>
        <button type="button" onClick={openModal} className="btn btn--action">Add New Genre</button>
      </div>
      <ModalDialog ref={modalRef} id="AmzGenreAddNewModal">
        <h2>Add New Genre</h2>

        <ActionMessageDisplay mode="failed" hidden={!error} message={message} onClose={onErrorClose} />
        <form id="AmzGenreAddNewForm" style={{'margin': '3rem 0'}} onSubmit={onSubmit}>
          <input type="hidden" id="parent" name="parent" onChange={onChange} value="0" />
          <p>
            <label htmlFor="name">Name</label>
            <input
              autoComplete="off"
              type="text"
              id="name"
              name="name"
              className="form-control"
              required value={name || ''}
              onChange={onChange}
            />
          </p>

          <p>
            <label htmlFor="notes">Notes&nbsp;</label>
            <span className="form-note">(optional)</span>
            <textarea
              id="notes"
              name="notes"
              rows="6"
              className="form-control"
              value={notes || ''}
              onChange={onChange}
            ></textarea>
          </p>

          <p className="button__group" style={{'textAlign':'right'}}>
            <button type="submit" className="btn btn--action">Save</button>
            <button type="button" className="btn" onClick={closeModal}>Cancel</button>
          </p>
        </form>
      </ModalDialog>
    </div>
  );
});

