/**
 * CRTStatus.js
 * Check for updates button.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LoaderSimple from '../subcomponents/LoaderSimple';

import './css/CRTStatus.css';

const CRTStatus = ({
  approved = false,
  status = 'Not Submitted',
  loading = false
}) => {
  const cssClass = classNames(
    {'destinations__crt_status': true},
    {'destinations__crt_status--approved': approved},
    {'destinations__crt_status--unsubmitted': /Not Submitted/.test(status)},
    {'destinations__crt_status--rejected': /Rejected/.test(status)}
  );

  const showLoader = loading ? <LoaderSimple open={true} cssClass="destinations__crt_status__loader" /> : status;

  return (
    <div className={cssClass}>
      <b><abbr title="Content Review Team">CRT</abbr> Status:</b> <span className="destinations__crt_status__message">{showLoader}</span>
    </div>
  );
}

CRTStatus.propTypes = {
  approved: PropTypes.bool,
  status: PropTypes.string
};

export default CRTStatus;
