/**
 * ReportsChannelsDestinations
 * Displays reports/display/channels
 */

import React from 'react';
import {
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import ReportSearch from './ReportSearch';
import ReportsResults from './ReportsResults';
import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  API_DESTINATIONS_LIST,
  API_REPORTS_CHANNELSBYDEST
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

const ReportsChannelsDestinations = ({params}) => {
  let display;
  const limit = '500';

  const [ destination, setDestination ] = useState('');
  const [ destinations, setDestinations ] = useState([]);
  const [ end, setEndDate ] = useState('');
  const [ channels, setChannels ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ results, setResults ] = useState(null);
  const [ start, setStartDate ] = useState('');

  useEffect(() => {
    if( destinations.length ) return;

    const fetchCancel = new AbortController();
    const signal = fetchCancel.signal;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_DESTINATIONS_LIST, {body: fd, signal}, (data) => setDestinations( data ));

    return () => {
      if( destinations.length ) {
        fetchCancel.abort();
      }
    }
  });

  const massageResults = (results) => {
    const res = results.map( (result) => {
      return {
        title: result['channel_title'],
        url: result['youtubeID'],
        frequency: result['frequency']
      }
    });
    return res;
  }

  const onSearchSubmit = (domEvent) => {
    domEvent.preventDefault();

    setLoading(true);
    setDestination(domEvent.target.destination.value);
    setStartDate(domEvent.target.start.value)
    setEndDate(domEvent.target.end.value);

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_REPORTS_CHANNELSBYDEST, {body: fd}, (data) => {
      setChannels(data.data);
      setResults(data.data.length);
      setLoading(false);
    });
  }

  if(loading) {
    display = <LoaderSimple open={true} />
  } else {

   display = (
    <ReportsResults
      columnHeadings={['Channel Title', 'Frequency']}
      results={massageResults(channels)}
      count={results}
      start={start}
      end={end}
      destination={destination}
      downloadURL={`${API_REPORTS_CHANNELSBYDEST}/csv`}
      limit={limit} />
    );
  }

  return (
    <div className="reports__page">
      <h1>{params.state.title}</h1>
      <ReportSearch
        apiURL={API_REPORTS_CHANNELSBYDEST}
        destinations={destinations}
        id="destination"
        labelText="Select a destination:"
        limit={limit}
        name="destination"
        onSubmitHandler={onSearchSubmit} />
      {display}
    </div>
  );
}

export default () => ( <ReportsChannelsDestinations params={useLocation()} /> );