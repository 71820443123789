/**
 * AmzSongAddSongsSave.js
 */
import React from 'react';
import uniqueId from 'lodash.uniqueid';

import { MESSAGE_ERROR_TOO_MANY_URLS } from '../../js/Configuration';
import { NoImageImg } from '../subcomponents/NoImageImg';

import '../subcomponents/css/fancy-checkboxes.css';

const AmzSongAddSongsSave = ({
  data,
  onOrderChange,
  onExcludeToggle,
  onClose,
  onSave
}) => {

  const makeHeader = () => {
    return (
      <thead>
        <tr>
          <th scope="col" className="asin__table_order">Order</th>
          <th colSpan="2" scope="col">Title</th>
          <th scope="col">ASIN</th>
          <th scope="col">Artist</th>
          <th scope="col">Album</th>
          <th scope="col">
            Confirm<br />
            <span className="table__note">Uncheck to exclude</span>
          </th>
        </tr>
      </thead>
    );      
  }

  const makeRow = (obj) => {
    let artist_name, album_title, cover_img;

    // Property can exist, but be empty, so we need to check for both.
    if(!Object.hasOwn(obj, 'artist') ) {
      artist_name = 'No Artist Info';
    } else {
      artist_name = obj.artist.name ? obj.artist.name : 'No Artist Info';
    }

    if(!Object.hasOwn(obj, 'album')) {
      album_title = obj.album.title ? obj.album.title : 'No Album Info';
    }

    if(!Object.hasOwn(obj, 'imgpath')) {
      cover_img = obj.imgpath ? <img src={obj.imgpath} alt={`"${obj.title}"" cover`} className="asin__thumb" /> : <NoImageImg height="75px" width="75px" />;
    } else {
      cover_img = <NoImageImg height="75px" width="75px" />;
    }

    return (
      <tr key={uniqueId()}>
        <td>
          <input
            size="4"
            type="number"
            id={`order[${obj.asin}]`}
            name={`order[${obj.asin}]`}
            onChange={onOrderChange}
            defaultValue={obj.ordinal}
            min="-1"
            max="125"
            step="0.5"
          />
        </td>
        <td>{cover_img}</td>
        <td><label htmlFor={`order[${obj.asin}]`}>{obj.title || 'No Title Info'}</label></td>
        <td>{obj.asin}</td>
        <td>{artist_name}</td>
        <td>{album_title}</td>
        <td>
          <span className="fancy__chex">
            <input type="checkbox"
              id={obj.asin}
              name={obj.asin}
              defaultChecked={true}
            />
            <label htmlFor={obj.asin} className="asin__label">
              <span className="form__access__label">{obj.asin}</span>
            </label>
          </span>
        </td>
      </tr>
    );
  }

  const sty = {'textAlign': 'right'};

  return (
    <form id="AmzSongAddSongs__save" onSubmit={onSave}>
      <div className="button__group" style={sty}>
        <button
          type="submit"
          className="btn btn--action"
        >Save</button>
        <button type="button"
          className="btn btn--light"
          onClick={onClose}
        >Cancel</button>
      </div>
      <table className="asin__table_add">
        {makeHeader()}
        <tbody>
        {data.map(makeRow)}
        </tbody>
      </table>
      <div className="button__group" style={sty}>
        <button
          type="submit"
          className="btn btn--action"
        >Save</button>
        <button type="button"
          className="btn btn--light"
          onClick={onClose}
        >Cancel</button>
      </div>
    </form>
  );
}

export default AmzSongAddSongsSave;
