/**
 * DestinationsRestoreSet.jsx
 */

import React from 'react';
import uniqueId from 'lodash.uniqueid';

export const DestinationsRestoreSet = (props) => {
  let uniq = uniqueId('DestinationsRestoreSet_');
  return (
    <form onSubmit={props.onSubmit}>
      <input type="hidden" id={uniq} name="id" value={props.id} />
      <button type="submit" className="btn btn-sm btn--restore">Restore</button>
    </form>
  );
}
