/**
 * DestinationsAddNew.jsx
 */

import React from 'react';

const DestinationsAddNew = ({dID, dName, onClick}) => {
  return (
    <button
      type="button"
      className="btn btn--action"
      data-action="videoset"
      data-destinationid={dID}
      onClick={onClick}
    >Add New Video Set
    <span className="form__access__label">to <q>{dName}</q></span>
    </button>
  );
}

export default DestinationsAddNew;