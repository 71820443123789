/*
ChannelsUtils.js

Functions that are shared between multiple views in Channels and Groups
*/
import {
  MESSAGE_ERROR_NOTACHANNEL,
  MESSAGE_ERROR_ADDCHANNELTOSYSTEM
} from '../js/Configuration';

import {
  tooManyURLs,
} from './DestinationsUtils';

export const filterCriteria = {
  filterOn: 'channel_title',
  hideCategories: true,
  itemsName: 'Channels',
  searchValue: '',
}

export const addChannelState = {
  open: false,
  channels: '', // Input string
  group: '',
  options: [],
  mode: 'adding', // Should be either "adding", "working", "error," or "response"
  max: false,
  error: false,
  response: new Map()
}

export const stringToArray = ( string ) => {
  return string.split(/[,\s]/);
}

export const getGroupNamesFromData = (channelData) => {
  /* If there's no property, return an empty array */
  if(!channelData.hasOwnProperty('groupList')) return [];

  const getGroupName = (item) => item.group_name;

  let gl = [];
  if(channelData.groupList && channelData.groupList.length) {
    gl = channelData.groupList.map(getGroupName)
  }

  return gl.join(', ');
}

export const stateOnPerPageSelect = (state, value) => {
  // Calculate a new page count
  const newpagecount = Math.ceil(state.videos.total / value);
  let pg = state.videos.page;

  // If the current page value is greater than the new page count, reset the
  // new page count too. Otherwise, we'll use the existing count.
  if(state.videos.page > newpagecount) { 
    pg = newpagecount;
  }

  return { videos: {...state.videos, perPage: +value, page: pg }};
}

export const setStateOnGroupListUpdate = (state, response) => {
  const channelID = Object.keys(response.list)[0];
  return {
    data: {
      ...state.data, 
      groupList: response.list[channelID]
    }
  }
}

export const setStateOnShowAddChannel = ( state ) => {
  return {
    ...state,
    open: true,
    channels: '',
    group: ''
  }
}

export const setStateOnSubmitAddChannel = ( state ) => {
  return {
    addChannels: {
      ...state.addChannels,
      mode: 'working',
    }
  }
}

export const setStateOnCloseAddChannel = ( state ) => {
  return {
    ...state,
    open: false,
  }
}

export const setStateOnAddChannelResponse = ( state, response ) => {
  if( !response.channelsAdded ) {
    window.alert( MESSAGE_ERROR_ADDCHANNELTOSYSTEM );
    return;
  }
  const status =  new Map( state.response );

  /*
   Creates a map of items in the form { <KEY:CHANNEL_ID>: { status: <object> }}
   where <object> contains { message: '', error: true/false, title: ''}
  */
  response.channelsAdded.forEach(( ch ) => {
    status.set(
      ch.channel_youtubechannelid,
      {
        ...ch.status,
        title: ch.channel_title
      }
    );
  });

  return {
    ...state,
    response: status,
    mode: 'response',
    open: false
  }
}

export const setStateOnAddChannelChange = (state, name, value) => {
  return {
    ...state,
    [name]: value,
    max: tooManyURLs( value )
  }
}
