/**
 * Destinations.js
 */
import React, {
  useRef,
  useState,
  useEffect
} from 'react';

import { Link } from 'react-router-dom';

import {
  API_DESTINATIONS_LIST,
  API_DESTINATIONS_ADDNEW,
  API_DESTINATIONS_RENAME,
  API_VIDEOSET_ADDNEW,
  API_DESTINATIONS_SEARCH,
  GENERIC_NO_RESULTS,
  MESSAGE_ERROR_GENERAL,
  MESSAGE_DESTINATION_DELETE_CONFIRM,
  MESSAGE_DESTINATION_DELETED,
  MESSAGE_DESTINATION_DELETE_UNNAMED
} from '../js/Configuration';

import ActionMessageDisplay from './subcomponents/ActionMessageDisplay';
import DestinationsSearchResults from './Destinations/DestinationsSearchResults';
import DestinationsSearch from './Destinations/DestinationsSearch';
import LoaderSimple from './subcomponents/LoaderSimple';
import CreateDestination from './Destinations/CreateDestination';
import CreateVideoSet from './Destinations/CreateVideoSet';
import DestinationsAddNew from './Destinations/DestinationsAddNew';
import DestinationsRenameTrigger from './Destinations/DestinationsRenameTrigger';
import DestinationsRename from './Destinations/DestinationsRename';

import {
  apirequest,
  asyncapirequest,
  formatString,
  getAuthData,
  isAdmin,
} from '../js/Utilities';

import {
  duplicatePlaylist,
  getDestinations,
  deleteDestination,
  findDestinationById,
  restoreVideoSet
} from '../actions/DestinationsUtils';

import './Destinations/css/Destinations.css';

const Destinations = ({project, history}) => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({
    loading: false,
    active: false,
    results: [],
    keyword: ''
  });

  const [destinations, setDestinations] = useState({
    list: [],
    withinactives: false,
    renaming: {
      destination: '',
      value: ''
    }
  });

  const [create, setCreate] = useState(null);

  const [createVidset, setCreateVidSet] = useState({
    create: null,
    value: '',
    destination: null
  });

  const [videoset, setVideoset] = useState({
    value: '',
    destination: {
      dID: '',
      dName: ''
    },
  });

  const [remove, setRemove] = useState({
    mode: 'success',
    message: '',
    hidden: true
  });
  const [episodes, setEpisodes] = useState([]);

  const canAdmin = isAdmin(project);

  const cdRef = useRef(null);
  const cvsRef = useRef(null);
  const renameRef = useRef(null);

  const onPopState = (domEvent) => {
    domEvent.preventDefault();

    var pars = new URLSearchParams(window.locationsearch);
    if(!pars.get('q')) return;

    /*
     * If the length of the q parameter is less than or equal to the length of
     * the current keyword being searched, assume this is a back button operation
     * and replace the last item in the browser history.
     */
    if( pars.get('q').length <= search.keyword.length ) {
      window.history.replaceState({}, 'Destinations', '/destinations');
      // Reset search object.
      onResetSearch();
    }
  }

  useEffect(() => {
    if(!destinations.list.length) {
      getDestinations(onDestinationsLoaded);
    }
  
  }, [destinations]);

  const onDestinationsLoaded = (response) => {
    setDestinations({
      ...destinations,
      list: response,
    });
    setLoading(false);
  }

  const onInactivesChange = (domEvent) => {
    const {checked} = domEvent.target;

    setDestinations({
      ...destinations,
      withinactives: +checked,
      list: []
    });
  }

  const onRestore = (domEvent) => {
    domEvent.preventDefault();
    const setId = domEvent.target.elements.id.value;
    restoreVideoSet(setId, onRestoreAfter);
  }

  const onRestoreAfter = (value) => {
    if(search.keyword) {
      onSearchDestinations();
    } else {
      getDestinations(onDestinationsLoaded, false, value);
    }
  }

  const showCreate = () => {
    let button = null;
    if(canAdmin) {
      button = (
        <button
          type="button"
          className="btn btn--action"
          onClick={onCreateOpen}
          data-action="create">Create New Destination</button>
      )
    }
    return button;
  }

  const showDelete = (item) => {
    let button = null;
    if(canAdmin) {
      button = (
        <button
          type="button"
          className="btn btn--destructive"
          data-destinationid={item.dID}
          onClick={onDelete}>Delete
          <span className="form__access__label">to <q>{item.dName}</q></span>
        </button>
      );
    }
    return button;
  }

  const showRename = (item) => {
    let button = null;
    if(canAdmin) {
      button = <DestinationsRenameTrigger onClick={onRenameOpen} {...item} />
    }
    return button;
  }

  const buildLinks = (resp) => {
    // If there's something in the search field, assume this is a search and clear
    // links.
    if(search.keyword) { return; }
    if(loading) {
      return <LoaderSimple open={loading} />;
    }

    const dests = destinations.list.map((item) => {
      const css = item.hasOwnProperty('isNew') ? 'destinations__list__new' : '';

      return (
        <tr key={`destination_${item.dID}`}  className={css}>
          <td>
            <Link to={{ pathname: `/destinations/videosets/${item.dID}`, state: { destination: item.dName} }}>{item.dName}</Link>
          </td>
          <td className="button__group">
            <DestinationsAddNew
              {...item}
              onClick={onCreateVidsetOpen}
            />
            {showRename(item)}
            {showDelete(item)}
          </td>
        </tr>
      )
    });

    return (
      <table className="destinations__list">
        <thead>
          <tr>
            <td>Name</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>{dests}</tbody>
      </table>
    );
  }

  const makeSearchResults = () => {
    let results;
    if(!search.keyword) return;

    if(search.loading) {
      return <LoaderSimple open={search.loading} />;
    }

    if(!!search.results.length) {
      results = (
        <DestinationsSearchResults
          keyword={search.keyword}
          results={search.results}
          onRestore={onRestore} isAdmin={canAdmin} />
      );
    } else {
      results = <h3>{GENERIC_NO_RESULTS}</h3>;
    }
    return results;
  }

  const handleSearchResponse = (response) => {
    let res = [false];
    if(response.length) {
      res = response;
    }

    setSearch({
      ...search,
      loading: false,
      results: res
    });
  }

  const onResetSearch = () => {
    setSearch({
      loading: false,
      active: false,
      results: [],
      keyword: ''
    });
  }

  const duplicate = (id) => {
    duplicatePlaylist(id, (data) => {
      props.history.push(`/destinations/playlist/${data}`, {is_dupe: true});
    });
  }

  const onSearchChange = (domEvent) => {
    const {value} = domEvent.target;
    const {history} = window;
    history.pushState({'activeKeyword': value}, 'search', `?q=${value}`);

    setSearch({
      ...search,
      loading: true,
      keyword: value
    });
  }

  const getSearchResults = () => {
    const fd = new FormData();
    fd.set('q', search.keyword.trim());
    fd.set('token', getAuthData('token'));

    if(destinations.withinactives) {
      fd.set('inactives', 1);
    }

    apirequest(API_DESTINATIONS_SEARCH, {body: fd}, handleSearchResponse);
  }

  const onSearchDestinations = (domEvent = null) => {
    if(domEvent) {
      domEvent.preventDefault();
    }
    window.history.pushState({'activeKeyword': search.keyword}, 'search', `?q=${search.keyword}`);
    getSearchResults();
  }

  const onSearchFocus = () => {
    window.history.pushState({}, 'search', '?q=');
  }

  const onResetEpisodes = (domEvent) => {
    setEpisodes([]);
  }

  const onCreateChange = (domEvent) => {
    const {value} = domEvent.target;
    const {form} = domEvent.target;

    let key;
    let current;

    switch (form) {
      case 'destination_create_vidset':
        key = 'videoset';
        setVideoset({...videoset, value: value});
        break;
      default:
        key = 'create';
        setCreate(value);
    }
  }

  const onCreateSave = (domEvent) => {
    domEvent.preventDefault();

    let callback;
    let endpoint;

    switch(domEvent.target.id) {
      case 'destination_create_vidset':
        callback = (response) => { onCreateVidsetResponse(response) };
        endpoint = API_VIDEOSET_ADDNEW;
        break;
      default:
        callback = (response) => { onCreateDestinationResponse(response )};
        endpoint = API_DESTINATIONS_ADDNEW;
    }
    const fd = new FormData(domEvent.target);
    apirequest(endpoint, {body: fd}, callback);
  }

  const onCreateDestinationSave = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    apirequest(API_DESTINATIONS_ADDNEW, {body: fd}, onCreateDestinationResponse);
  }

  const onCreateDestinationClose = (domEvent) => {
    setCreate('');

    if(cdRef.current) {
      cdRef.current.close();
    }
  }

  const onCreateDestinationResponse = (response) => {
    const new_destination = [{
      dID: response.d_id,
      dName: response.d_name,
      isNew: true
    }];

    setCreate('');
    setDestinations({
      ...destinations,
      list: new_destination.concat([...destinations.list]),
    });

    if(cdRef.current) {
      cdRef.current.close();
    }
  }

  const onCreateVidsetResponse = (response) => {
    if(response.result !== 'success') {
      setRemove({
        ...remove,
        mode: 'failed',
        message: MESSAGE_ERROR_GENERAL,
        hidden: false
      })
      return;
    }

    setVideoset({ ...videoset, value: ''});
    setRemove({
      ...remove,
      mode: 'success',
      message: ( <span><q>{response.setName}</q> was added to <q>{response.destination}</q>
                 <Link to={{ pathname: `/destinations/rundates/${response.setID}`, state: { destination: response.setName} }}>View</Link>
                 </span>),
      hidden: false
    });
  }

  const onCreateVidsetClose = (dialogRef) => {
    setVideoset({
      value: '',
      destination: {
        dID: '',
        dName: ''
      }
    });

    if(cvsRef.current) {
      cvsRef.current.close();
    }
  }

  const onCreateOpen = (domEvent = null) => {
    if(!domEvent) return;
    if(cdRef && cdRef.current) {
      cdRef.current.showModal();
    }
  }

  const onCreateVidsetOpen = (domEvent) => {
    const {action, destinationid} = domEvent.target.dataset;
    let destination = null;

    if(destinationid) {
      destination = findDestinationById(destinations.list, destinationid);
    }

    setVideoset({
      ...videoset,
      destination: destination
    });

    if(cvsRef && cvsRef.current) {
      cvsRef.current.showModal();
    }
  }

  const onDelete = (domEvent) => {
    const {destinationid} = domEvent.target.dataset;
    const toDelete = findDestinationById(destinations.list, destinationid);

    let destName = MESSAGE_DESTINATION_DELETE_UNNAMED;
    if( toDelete.hasOwnProperty('dName') && toDelete?.dName ) {
      destName = toDelete.dName.toUpperCase();
    }

    const confirm = window.confirm(
      formatString(
        MESSAGE_DESTINATION_DELETE_CONFIRM,
        [destName]
      )
    );
    if(!confirm) return;
    setLoading(true);

    onDeleteSubmit(toDelete);
  }

  const onDeleteSubmit = (destObj) => {
    const { dID, dName } = destObj;

    deleteDestination(destObj.dID, (response) => {
      let msg = MESSAGE_ERROR_GENERAL;
      if(response.result === 'success') {
        msg = (
          <span>
            <q>{destObj.dName}</q> {MESSAGE_DESTINATION_DELETED.replace(/%s\s/,' ')}
          </span>
        )
      }

      setLoading(false);
      setRemove({
        ...remove,
        mode: response.result,
        message: msg,
        hidden: false
      });
      setDestinations({...destinations, list: []});
    });
  }

  const onDeleteComplete = () => {
    getDestinations(onDestinationsLoaded);
  }

  const onCloseMessage = () => {
    setRemove({
      ...remove,
      hidden: true,
      message: '',
      mode: 'success'
    });
  }

  const onRenameOpen = (domEvent = null) => {
    if(!domEvent) return;
    const {destinationid} = domEvent.target.dataset;

    const dest = findDestinationById(destinations.list, destinationid);

    setDestinations({
      ...destinations,
      renaming: {
        ...destinations,
        destination: dest
      }
    });

    if(renameRef.current) {
      renameRef.current.showModal();
    }
  }

  const onRenameClose = () => {
    if(renameRef.current) {
      renameRef.current.close();
    }
    setDestinations({
      ...destinations,
      renaming: {
        ...destinations.renaming,
        value: '',
        destination: ''
      }
    });
  }

  const onRenameChange = (domEvent = null) => {
    if(!domEvent) return;
    const {value} = domEvent.target;
    setDestinations({
      ...destinations,
      renaming: {
        ...destinations.renaming,
         value: value
      }
    });
  }

  const onRenameSave = (domEvent, dialogRef=null) => {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', destinations.renaming.destination.dID);
    fd.set('name', destinations.renaming.value);
    apirequest(API_DESTINATIONS_RENAME, {body: fd}, (response) => {
      if(!response.result) return;

      let { list } = destinations;
      dlist = [...list];

      const {id, destination} = response;

      const index = dlist.findIndex((d) => +d.dID === +id);

      dlist[index]['dName'] = destination;

      setDestinations({
        ...destinations,
        list: dlist,
      });

      onRenameClose();
    });
  }

  return (
    <article className="stations__view">
      <header id="destination__header">
        <h1>Destinations</h1>
        {showCreate()}
      </header>

      <DestinationsSearch
        isAdmin={canAdmin}
        value={search.keyword}
        onSubmit={onSearchDestinations}
        onChange={onSearchChange}
        onFocus={onSearchFocus}
        onReset={onResetSearch}
        onInactiveChange={onInactivesChange} />

      <ActionMessageDisplay
        hidden={remove.hidden}
        message={remove.message}
        mode={remove.mode}
        onClose={onCloseMessage}
      />

      {buildLinks()}
      {makeSearchResults()}

      <CreateDestination
        {...create}
        modalRef={cdRef}
        onSubmit={onCreateSave}
        onChange={onCreateChange}
        onClose={onCreateDestinationClose}
      />

      <CreateVideoSet
        {...videoset}
        modalRef={cvsRef}
        onClose={onCreateVidsetClose}
        onSubmit={onCreateSave}
        onChange={onCreateChange}
      />

      <DestinationsRename
        {...destinations.renaming}
        modalRef={renameRef}
        onClose={onRenameClose}
        onSubmit={onRenameSave}
        onChange={onRenameChange}
      />
    </article>
  );
}

export default Destinations;