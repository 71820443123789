/**
 * ChannelsList.js
 * Displays the channel search modal.
 */

import React, { useState, useEffect } from 'react';

import {closeModal} from '../js/Utilities';

import ModalDialog from './subcomponents/ModalDialog';
import LoaderSimple from './subcomponents/LoaderSimple';
import ChannelListTable from './subcomponents/ChannelListTable';
import ChannelListSearch from './subcomponents/ChannelListSearch';
import Pagination from './subcomponents/Pagination';
import SelectPerPage from './subcomponents/SelectPerPage';

import {
  API_CHANNEL_BATCH,
} from '../js/Configuration';

import {
  apirequest,
  getAuthData
} from '../js/Utilities';

const ChannelsList = ({
  groupsInChannel,
  onClose,
  onCheckChange,
  onSubmit,
  modalRef=null
}) => {

  const [channels, setChannels] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(true); // Do we need this?

  const onPaginationClick = (domEvent) => {
    setPage((pg) => pg + (+domEvent.target.value));
    setLoading(!loading);
    setChannels([]);
  }
  
  const onChannelFilterChange = (domEvent) => {
    setKeyword(domEvent.target.value);
    setLoading(true);
    setChannels([]);
  } 

  const getChannelBatch = () => {
    const fd = new FormData();
    fd.set('perpage', perPage);
    fd.set('page', page);
    fd.set('sort_by', null);
    fd.set('filter_by', keyword);
    fd.set('filter_on', '');
    fd.set('order', 'ascending');
    fd.set('token', getAuthData('token'));

    apirequest(API_CHANNEL_BATCH, {body: fd}, (response) => {
      setChannels(response.channels);
      setLoading(false);
      setPages(response.pages);
    });
  }
 
  const onPerPageChange = (domEvent) => {
    setPerPage(+domEvent.target.value)
    setChannels([])   
  }
 
  useEffect(() => {
    if(!channels.length) {
      getChannelBatch();
    }
  }, [channels])
  
  const makeTableData = () => {
    let data = [];
    let curSet = groupsInChannel || [];

    if(channels.length) {
      data = channels.map((item) => {
        const d = {};
        
        d.channel_id = item.channel_id;
        d.channel_title = item.channel_title;
        d.video_count = item.channel_videocount;
        d.channel = item.channel_dateaddedtosystem;
        d.channel_youtubeID = item.channel_youtubechannelid;

        d.channel_selected = !!curSet.find((cs) => {
          return cs.channel_youtubechannelid === item.channel_youtubechannelid;
        });

        return d;
      });
    } 
    return data; 
  }       

  const getModalBody = () => {
    if( loading ) {
      return <LoaderSimple open={true} />;
    }

    return (
      <ChannelListTable
        cssClass="channel__list__table"
        headers={[null, 'Channel Title', 'Video count']}
        rows={makeTableData()}
        onCheckChange={onCheckChange} />
    );
  }

  return ( 
    <ModalDialog ref={modalRef} id="SearchChannelsList" onClose={onClose}>
      <div className="modalHeaderBtns button__group">
        <button type="submit" className='btn btn-sm btn--action' form="add_to_videoset">Submit</button>
      </div>
      <h2>Select Channels</h2>
      <div className="table__list__controls">
        <SelectPerPage
          name="video_list_pages" cssClass="table__list__perpage"
          value={perPage}
          onChangeHandler={onPerPageChange}
        />
        <Pagination
          currentPage={+page}
          totalPages={+pages}
          onClickHandler={onPaginationClick}
          disablePrevious={page <= 1}
          disableNext={channels.length < perPage} />
      </div>

      <ChannelListSearch
        apiURL="api/getchannelbatch"
        cssClass="search__channel__list__searchform"
        limit={50}
        datalist={null}
        isRequired={false}
        onInputHandler={onChannelFilterChange}
        labelText="Filter channels"
        value={keyword} />

      <form action="api/getchannelbatch" method="post" onSubmit={onSubmit} id="add_to_videoset">
       { getModalBody() }
        <div className="table__list__controls">
          <SelectPerPage name="video_list_pages" cssClass="table__list__perpage" value={perPage} onChangeHandler={onPerPageChange} />
          <Pagination
            currentPage={+page}
            totalPages={+pages}
            onClickHandler={onPaginationClick}
            disablePrevious={+page <= 1}
            disableNext={channels.length < perPage} />
        </div>

        <div className="button__group">
          <button type="submit" className='btn btn-sm btn--action'>Submit</button>
        </div>       
      </form>
    </ModalDialog>
  )
}

export default ChannelsList