/*
  eslint react/destructuring-assignment: 0

*/

/**
 * AmzSongs.js
 */
import React, {
  useState,
  useRef,
  useEffect
} from 'react';
import { useParams } from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { AmzSongSearch } from './AmzSongSearch';
import AmzSongAddSongs from './AmzSongAddSongs';
import { AmzSongsReorder } from './AmzSongsReorder';
import { AmzSongsRemove } from './AmzSongsRemove';
import { AmzSongsReorderTrigger } from './AmzSongsReorderTrigger';

import {
  API_CLUSTERS_SONGS,
  API_CLUSTERS_SINGLEGENRE,
  API_CLUSTERS_SONG_GET_ASINDATA,
  API_CLUSTERS_SONGSREORDER,
  API_CLUSTERS_SONG_REMOVE,
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  isEmptyObject
} from '../../js/Utilities';

import {
  tooManyURLs,
} from '../../actions/DestinationsUtils';

import {
  download,
} from '../../actions/ReportsUtils';

import {
  amzUrl,
  makeSaveRequest
} from '../../actions/AmzUtils';

import './css/AmzSongs.css';

const withParams = (Component) => ((props) => <Component {...props} params={useParams()} />);

const AmzSongs = ({params}) => {
  const [loading, setLoading] = useState(true);
  const [songs, setSongs] = useState([]);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({ page: 1, count: 0, pages: 0 });
  const [meta, setMeta] = useState({});
  const [ascending, setAscending] = useState(1);
  const [_sortfield, set_Sortfield] = useState('artist');
  const [search, setSearch] = useState({
    value: '',
    loading: false,
  });
  const [addSongs, setAddSongs] = useState({
    open: false,
    value: '',
    maxIds: false,
    working: false,
    data: [],
    mode: 'add', // Should be one of ['add','save']
  });
  const [mode, setMode] = useState('display'); // One of ['reorder', 'display']

  const addSongsRef = useRef(null);

  useEffect(() => {
    if(!meta.name) {
      getClusterTitle();
    }

    if(!songs.length) {
      getSongs();
    }

  }, [meta, songs]);

  const onSearchChange = (domEvent) => {
    const {value} = domEvent.target;
    setSearch({value: value, loading: true});
    setSongs([]);
  }

  const onSearchSubmit = (domEvent = null) => {
    if (domEvent) {
      domEvent.preventDefault();
    }
    setSearch({value: value, loading: true});
    setSongs([]);
  }

  const onAddSongsRequest = () => {
    if(addSongsRef.current) {
      addSongsRef.current.showModal();
    }
  }

  const onAddSongsClose = () => {
    setAddSongs({
      ...addSongs,
      data: [],
      mode: 'add',
      working: false,
      value: '',
    });

    if(addSongsRef.current) {
      addSongsRef.current.close();
    }
  }

  const onAddSongsChange = (domEvent) => {
    const { value } = domEvent.target;

    setAddSongs({
      value: value,
      maxIds: tooManyURLs(value, 10)
    });
  }

  const onAddSongsSubmit = (domEvent = null) => {
    if (domEvent) {
      domEvent.preventDefault();
    }
    getScrapedAsin();
  }

  const onAddSongsSave = (domEvent = null) => {
    if (domEvent) {
      domEvent.preventDefault();
    }

    const {elements} = domEvent.target;

    /*
      Filter form elements to:
      
      1. Return checkboxes only;
      2. Narrow that down to checked checkboxes; then
      3. Return the id attribute values of the checked boxes.
    */
    const submits = Array.from(elements)
                .filter((i) => i.type === 'checkbox')
                .filter((i) => i.checked)
                .map((i) => i.id);

    const send = [...addSongs.data].filter((s) => submits.indexOf(s.asin) > -1)

    if(send.length === 0) {
      alert('No songs to save. Please try again.');
    } else {
      makeSaveRequest(params.id, send, (response) => {
        setLoading(true);
        setAddSongs({
          open: false,
          value: '',
          maxIds: false,
          working: false,
          data: [],
          mode: 'add', // Should be one of ['add','save']
        });
        setSongs([]);
      }); 
    }
  }

  /* Change when adding a song */
  const onAsinOrderChange = (domEvent = null) => {
    const {value, id} = domEvent.target;

    const asin = id.replace(/order\[/, '').replace(/\]/,'');
    const songs = [...addSongs.data];

    const index = songs.findIndex((s) => asin === s.asin);
    songs[index]['ordinal'] = +value;

    setAddSongs({
      ...addSongs,
      data: songs
    });
  }

  const onRemoveSubmit = (domEvent = null) => {
    if (!domEvent) return;
    domEvent.preventDefault();

    const {asin} = domEvent.target;
    const todelete = songs.find((s) => asin.value === s.ASIN);

    // eslint-disable-next-line no-alert
    const confirm = window.confirm(`Are you sure that you want to remove “${todelete['Song Title']}” from this cluster?`);
    if (confirm) {
      setLoading(true);

      const fd = new FormData(domEvent.target);
      fd.set('token', getAuthData('token'));

      apirequest(API_CLUSTERS_SONG_REMOVE, { body: fd }, (response) => {
        if(response.success) {
          const newsongs = [...songs].filter((s) => response.asin !== s.ASIN);
          setSongs(newsongs);
          setLoading(false);
        }
      });
    }
  }

  /* Change in reordering mode */
  const onChangeOrder = (domEvent) => {
    let {id, value} = domEvent.target;

    const oldSongs = [...songs]

    // Find which item to change
    const update = oldSongs.findIndex((song) => id === song.ASIN);
    oldSongs[update].ordinal = value;

    setSongs(oldSongs);
  }

  const onAddSongsSubmitResponse = (response) => {
    const songs = response.map((r) => {
      const x = { ...r };
      x.ordinal = 0;
      return x;
    });

    setAddSongs({
      ...addSongs,
      working: false,
      data: songs,
      mode: 'save',
    })
  }

  const onSortFieldChange = (domEvent) => {
    const {sortfield} = domEvent.target.dataset;

    if (_sortfield === sortfield) {
      setAscending(+!ascending);
    }

    set_Sortfield(sortfield);
    setSearch({...search, loading: true});
    setSongs([]);
  }

  const getClusterTitle = () => {
    if (Object.hasOwn(meta, 'name')) return;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', params.id);

    apirequest(API_CLUSTERS_SINGLEGENRE, { body: fd }, (response) => {
      if(Object.hasOwn(response, 'id')) {
        setMeta({...response});
      } else {
        setMeta({error: true});
      }
    });
  }

  const getSongs = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', params.id);
    fd.set('sortfield', _sortfield);
    fd.set('ascending', ascending);

    if (search.value && search.value.length > 2) {
      fd.set('keyword', search.value);
    }

    apirequest(API_CLUSTERS_SONGS, { body: fd }, (response) => {
      setLoading(false);

      if(response.results.length) {
        setSongs(response.results);
      } else {
        setSongs([false]);
      }

      setCount(response.count);
      setPagination(pagination);
      setSearch({...search, loading: false});
      setMode('display');
    });
  }

  const getScrapedAsin = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('asin', addSongs.value);
    apirequest(API_CLUSTERS_SONG_GET_ASINDATA, { body: fd }, onAddSongsSubmitResponse)
  }

  const changeMode = () => {
    if (mode === 'display') {
      const current = ['reorder', 'display'].find((m) => m === mode);
      setMode('reorder');
    } else {

      const updated = songs.map((song) => ({
        asin: song.ASIN,
        ordinal: song.ordinal,
      }));

      const fd = new FormData();
      fd.set('genre', params.id);
      fd.set('order', JSON.stringify(updated));
      fd.set('token', getAuthData('token'));

      apirequest(API_CLUSTERS_SONGSREORDER, { body: fd }, (response) => {
        setLoading(false);
        setSearch({...search, loading: false});
        setMode('display');
        setPagination({...response.pagination});
        setCount(response.pagination.count)
      });
    }
  }

  const goBack = (domEvent) => {
    domEvent.preventDefault();
    window.history.go(-1);
  }

  const makeDisplay = () => {
    let body = <LoaderSimple open />;
    if (Object.hasOwn(meta, 'error')) {
      body = (
        <div>
          <h2>No such cluster</h2>
          <p><a href="/music/clusters/" onClick={goBack}>Back to Clusters</a></p>
        </div>
      )
    } else if (loading === false) {
      if (songs.length || search.value) {
        body = makeTheResults()
      } else {
        body = makeNoResults();
      }
    }
    return body;
  }

  const makeTable = () => {
    return (
      <table className="amz__songs__tbl">
        {makeTableHeader()}
        {makeTableRows()}
      </table>
    );
  }

  const makeTableRows = () => {
    let rows = null;

    if(!songs[0]) {
      return rows;
    }

    rows = songs.map((song) => {
      const tds = Object.values(song).map((f, idx) => {
          /* eslint-disable no-param-reassign */
          if (idx === 0 && mode === 'reorder') {
            f = <AmzSongsReorder {...song} onChange={onChangeOrder} />
          }

          // Add exception for ASINs
          if (idx === 1) {
            f = <a href={amzUrl(f)} target="_blank" rel="noopener noreferrer">{f}</a>;
          }
          return <td key={uniqueId()}>{f}</td>;
          /* eslint-enable */
      });

      return (
        <tr key={uniqueId()}>
          {tds}
          <td>
            <AmzSongsRemove
              {...song}
              cluster={params.id}
              onSubmit={onRemoveSubmit}
            />
          </td>
        </tr>
      );
    });

    return (
      <tbody>
        {rows}
      </tbody>
    );
  }

  const makeTableHeader = () => {
    if (songs[0] === false) return null;

    const ths = Object.keys(songs[0])
      .map((h) => {
        const txt = (h === 'ordinal') ? 'Order' : h;
        const th = (
          <th key={uniqueId()} scope="col">
            <button
              type="button"
              data-sortfield={txt.toLowerCase().replace(/\s/g, '')}
              className="tbl__sort_trigger"
              onClick={onSortFieldChange}
            >
              {txt}
            </button>
          </th>
        );
        return th;
      });

    return (
      <thead>
        <tr>
          {ths}
          <th>Remove</th>
        </tr>
      </thead>
    )
  }

  const makeNoResults = () => {
    return (
      <div>
        <h2>There are no songs in this cluster.</h2>
        <p><a href="/music/clusters/" onClick={goBack}>Back to Clusters</a></p>

        <p>
          <button
            type="button"
            className="btn btn--action"
            onClick={onAddSongsRequest}
          >Add songs</button>
        </p>

        <AmzSongAddSongs
          {...addSongs}
          cluster={params.id}
          onClose={onAddSongsClose}
          onChange={onAddSongsChange}
          onSubmit={onAddSongsSubmit}
          onSave={onAddSongsSave}
          onOrderChange={onAsinOrderChange}
        />
      </div>
    );
  }

  const makeTheResults = () => {
    const { loading } = search;
    const body = loading ? <LoaderSimple open /> : makeTable();

    let songCount = 0;
    if(songs[0] !== false) {
      songCount = songs.length;
    }

    /* eslint-disable react/jsx-one-expression-per-line */
    return (
      <div>
        <header className="amz__songs__head">
          <h1>
            Songs in <q>{meta.name}</q>
          </h1>
          <h2>{meta?.meta?.trim()}</h2>
          <p>{meta?.notes?.trim()}</p>
          <p style={{ textAlign: 'right' }}>
            <button type="button" className="btn btn--action" onClick={onAddSongsRequest}>Add songs</button>
          </p>
          <p className="l__flex__sb">
            <span style={{ fontSize: '2rem' }}>
              { songCount } songs
            </span>
            <span>
              <b>Curated by: </b>
              {meta.curator}
            </span>
          </p>
        </header>

        <div>
          <AmzSongSearch
            {...search}
            onChange={onSearchChange}
            onSubmit={onSearchSubmit}
          />
        </div>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <AmzSongsReorderTrigger
              mode={mode}
              disabled={!songs[0]}
              onClick={changeMode}
            />
          </span>
          <button
            type="button"
            onClick={makeCSV}
            disabled={!songs[0]}
            className="btn btn--action"
          >Export as CSV</button>
        </p>
        {body}
        <AmzSongAddSongs
          {...addSongs}
          modalRef={addSongsRef}
          cluster={params.id}
          onClose={onAddSongsClose}
          onChange={onAddSongsChange}
          onSubmit={onAddSongsSubmit}
          onSave={onAddSongsSave}
          onOrderChange={onAsinOrderChange}
        />
      </div>
    );
    /* eslint-enable */
  }

  const makeCSV = () => {
    if (!songs.length) return;

    const headers = [
      [
        'ASIN',
        'Artist',
        'Song Name',
        'Album Name',
        'Genre',
        'Sub-genre',
        'Curator',
        'Entry #',
      ],
    ];

    const rows = songs.map((song) => ([
      `"${song.ASIN}"`,
      `"${song.Artist}"`,
      `"${song['Song Title']}"`,
      `"${song.Album}"`,
      `"${meta.parent.name}"`,
      `"${meta.name}"`,
      '',
      song.ordinal,
    ]));

    const csv = headers.concat(rows);
    download(csv, meta.name.replace(/\s/g, '_'), 'Music', '');
  }

  let body = <LoaderSimple open />;
  if (loading === false) {
    body = (
      <article id="AmzGenres">
        {makeDisplay()}
      </article>
    );
  }
  return body;
}

export default withParams(AmzSongs);
