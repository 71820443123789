import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import YTPlayIcon from './YTPlayIcon';

import '../../css/VideoItem.css';

import {
  formatTime,
  isNumeric
} from '../../js/Utilities';

import {
  MESSAGE_NOT_SUBMITTED
} from '../../js/Configuration';

const VideoDragItem = ({
  deleteVideo,
  playVideo,
  data={},
  ordinal=0,
  disabled=false,
  deleteText='Delete video'
}) => {

  const {
    active,
    is_interstitial,
    duration,
    ytID,
    thumb,
    name,
    position,
    meow,
    crt
  } = data;

  const playHandler = (data) => {
    if(playVideo){
      playVideo(data)
    };
  }

  const deleteHandler = (data) => {
    if(deleteVideo){
      deleteVideo(data);
    }
  }

  const makeBadge = (obj = {}, isMeow = false) => {
    const label = obj.label || MESSAGE_NOT_SUBMITTED;
    let css;

    if(isMeow) {
      css = classNames({
        'video__meow--okay': meowOK() === true,
        'video__meow--notok': meowOK() === false,
        'video__meow--escalate': meowOK() === 'escalate'
      });
    } else {
      css = classNames({
        'video__crt--okay': crtOK(),
        'video__crt--notok': crtOK() === false,
        'video__crt--escalate': crtOK() === 'escalate'
      });
    }

    return (
      <b className={ `video__badge itemsBadge ${css}`} >{label}</b>
    )
  }

  const meowOK = () => {

    // Return this if it's not submitted.
    let okay;
    if(meow && isNumeric(meow?.id)) {
      switch(+meow.id) {
        case 0:
          okay = true;
          break;
        case 16:
          okay = 'escalate';
          break;
        default:
          okay = false;
      }
    } else {
      okay = null;
    }
    return okay;
  }

  const crtOK = () => {
    if(!crt) return;
    const {id} = crt;

    let approved = false;

    if(id === 0) {
      approved = null;
    }

    if(id === 1) {
      approved = true;
    }

    if(id >= 10) {
      approved = 'escalate';
    }
    return approved;
  }

  let cssClasses = classNames({
    'video__draggable' : true,
    'results__list__item--interstitial': is_interstitial,
    'video__inactive' : active === 'n'
  });

  return (
    <div className={cssClasses}>
      <div className="video__draggable__ordinal">{ordinal + 1}</div>
      <h5 data-video={ytID} onClick={playVideo.bind(null, ytID)} className="resultItemLink videoResultItemHeader results__list__item__title divTransition">{ name }</h5>
      <button data-video={ytID} onClick={playVideo.bind(null, ytID)} className="video__item__thumb videoThumb">
        <img src={thumb} alt={`Watch ${name}`} />
        <YTPlayIcon />
      </button>

      <p className="video__drag__item__duration">Duration: {formatTime(duration)}</p>
      <ul className="video__drag__item__data">
        <li><span><abbr>MEOW</abbr>:</span> <span>{makeBadge(meow, true)}</span></li>
        <li><span><abbr title="Content Review Team">CRT</abbr>:</span><span>{makeBadge(crt)}</span></li>
      </ul>
      <span className="video__item__delete">
        <button
          data-video={ytID}
          type='button'
          disabled={disabled}
          className='btn btn-sm btn--action'
          onClick={deleteVideo.bind(null, {id: ytID, position: position})}
        >{deleteText}</button>
      </span>
    </div>
  )
}

export default VideoDragItem;