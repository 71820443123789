import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom'

import './css/Breadcrumb.css';

const Breadcrumb = ({
  items=[],
  id='breadcrumb-navigation',
  cssClass='',
  ariaLabel='Breadcrumb'
}) => {

  const makeCrumb = (item, index) => {
    let link;
    let linkState = {};

    const defaultObj = {
      pathname: null,
      state: {}
    };

    if(!item.hasOwnProperty('path') || !item.path) {
      link = <b>{item.label}</b>;
    } else {

      if( item.hasOwnProperty('state') ) {
        linkState = { state: item.state };
      }

      link = (
        <NavLink
          to={item.path}
          state={linkState}
          className={ ({isActive}) => isActive ? 'active' : ''}
          end
        >
          {item.label}
        </NavLink>
      );
    }

    return <li key={index.toString()}>{link}</li>;
  }
  const links = items.map(makeCrumb);

  return  (
    <nav id={id} aria-label={ariaLabel}>
      <ol className={`breadcrumb__menu ${cssClass}`}>
        {links}
      </ol>
    </nav>
  );
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string.isRequired,
      state: PropTypes.object
    }
  )).isRequired,
  cssClass: PropTypes.string,
  id: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired
};

export default Breadcrumb;