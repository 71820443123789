import React, {useEffect, useState} from 'react';
import logo from '../media/AVALogoREV.png';
import { Navigate } from 'react-router-dom'

import '../css/LoginPage.css';

import {
  getAuthData,
  setAuthData,
  apirequest
} from '../js/Utilities';

import {
  login,
  requestPassword,
  setStatePostLogin
} from '../actions/LoginUtils';


import {
  ERROR_LOGIN_FAILED,
  ERROR_LOGIN_CAPSLOCK
} from '../js/Configuration';

const LoginPage = () => {

  const [reqtok, setReqTok] = useState('');
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const [capslock, setCapsLock] = useState(false)
  const abortcontroller = new AbortController();
  const {signal} = abortcontroller;
  
  useEffect(() => {
    apirequest('api/requesttoken', {signal}, (response) => {
      setReqTok(response.request_token);
    });
  
  }, []);

  const onFocusHandler = (domEvent) => {
    if(error == true) {
      setError(null);
    }
  }

  const isCapsLocked = (keyboardEvent) => {
    if (keyboardEvent.target.value && keyboardEvent.getModifierState && keyboardEvent.getModifierState('CapsLock')) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  }

  const clearIsCapsLocked = (keyboardEvent) => {
    if(keyboardEvent.target.value.length === 0) {
      setCapsLock(false);
    }
  }


  const redirectTo = () => {
    let redirect = '';

    const user = getAuthData();

    if(Object.hasOwn(user, 'permissions')) {
      const projectCount = Object.keys(user.permissions);

      switch(projectCount.length) {
        case 0:
          redirect = `/notassigned`;
          break;
        case 1:
          redirect = `/${user.permissions[projectCount[0]].path}`;
          break;
        default:
          redirect = `/projects`;
      }
    }
    
    if(window.location.search) {
      const params = new URLSearchParams(window.location.search);
      redirect = params.get('redirect');
    }

    return <Navigate to={redirect} />;
  }

  const authenticate = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    const callback = (response) => { 
      if(response.result === 'success') {
        setAuthData(response);
        setUser(response);
      } else {
        setError(ERROR_LOGIN_FAILED)
      }
    }
    login(fd, callback);
  }

  if(getAuthData('token')) {
    return redirectTo();
  }

  return(
    <form className="login" action="/ava/api/api/api/login" method="post" onSubmit={authenticate} onReset={requestPassword}>
      <p id="form_error" className="alert alert-danger" hidden={!error}>{ERROR_LOGIN_FAILED}</p>
      <p>
        <input type="hidden" id="tok" name="tok" value={reqtok} />
        <label htmlFor="login_user_name">User Name:</label>
        <input type="text" id="login_user_name" name="uname" required onFocus={onFocusHandler} autoComplete="username" />
      </p>

      <p>
        <label htmlFor="login_user_pass">Password:</label>
        <input
          onKeyDown={(domEvent) => {isCapsLocked(domEvent)}}
          id="login_user_pass"
          type="password"
          name="pword"
          aria-labelledby="login_user_pass_error"
          onFocus={onFocusHandler}
          onChange={ (domEvent) => { clearIsCapsLocked(domEvent)} }
          autoComplete="current-password"
          />
        <span id="login_user_pass_error" className="alert alert-warning" hidden={!capslock}>{ERROR_LOGIN_CAPSLOCK}</span>
      </p>

      <p className="login__buttons">
        <button type="submit" className="btn btn-success login-btn">Login</button>
        <button type="reset" className="btn">Forgot Password</button>
      </p>
      <img src={logo} className="login-logo" alt="logo" />
    </form>
  );
}

export default LoginPage;