/**
 * CheckForDuplicatesExclude.jsx
 */

import React from 'react';

export const CheckForDuplicatesExclude = ({
  checked,
  name,
  id,
  youtubeid,
  onChange
}) => {

  return (<input
    type="checkbox"
    defaultChecked={!checked}
    name={name}
    id={id}
    value={youtubeid}
    onChange={onChange}
  />);
}
