/**
 * DestinationsIncludeInactive.jsx
 */

import React from 'react';
import './css/DestinationsIncludeInactive.css';

export const DestinationsIncludeInactive = ({
  checked=false,
  onChange=null
}) => {
  return (
    <span id="DestinationsIncludeInactive">
      <input type="checkbox" defaultChecked={checked} name="DestinationsIncludeInactive__toggle" id="DestinationsIncludeInactive__toggle" value="1" onChange={onChange} />
      <label htmlFor="DestinationsIncludeInactive__toggle">Include deleted video sets?</label>
    </span>
  )
}
