/**
 * EditModal.js
 * Allows for renaming of series. Mostly the same as VideoSetEdit.
 */
import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CloseButton } from './CloseButton';
import ModalDialog from './ModalDialog';

import '../../css/VideoSetEdit.css';
import './css/EditModal.css';

const EditModal = React.forwardRef(function EditModal(props, ref) {
  const {
    apiURL,
    cssClass,
    error,
    id,
    labelText,
    modalRef,
    onInput,
    onSubmit,
    title,
    value
  } = props;

  const onClose = () => {
    if(modalRef?.current) {
      modalRef.current.close();
    }
  }

  const onReset = (domEvent) => {
    if(modalRef?.current) {
      modalRef.current.close();
    }
  }

  const cssClasses = classNames({
    'edit__modal': true,
  }, cssClass);

  return (
    <ModalDialog className={cssClasses} ref={modalRef} id="EditModal" title="Add Songs" onClose={onClose}>
      <h1>{title}</h1>
      <form action={apiURL} method="dialog" onSubmit={onSubmit} onReset={onReset} className="video__set__edit__form">
        <div>
          <label htmlFor="destinations_new_set_name">{labelText}</label>
          <div>
            <input type="text" ref={ref} onChange={onInput} name={id} id={id} defaultValue={value} required aria-describedby="video_set_edit_error" />
            <button type="submit" className="btn btn--action">Save</button>
            <button type="reset" className='btn'>Cancel</button>
          </div>
          <p id="video_set_edit_error" className="video__set__edit__error" hidden={!error}>{error}</p>
        </div>
      </form>
    </ModalDialog>
  );
});

EditModal.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  apiURL: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired
};

export default EditModal;
