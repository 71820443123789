/**
 * Pagination.jsx
 * A no-framework pagination component
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';

import '../../css/pagination.css';

const Pagination = ({onClickHandler, disablePrevious, disableNext}) => {
  const getPreviousPage = (domEvent) => {
    onClickHandler(domEvent);
  }

  const getNextPage = (domEvent) => {
    onClickHandler(domEvent);
  }

  return (
    <div className="pagination__component">
      <button
        className="btn btn-light pagination__previous"
        type="button"
        onClick={getPreviousPage}
        value="-1"
        disabled={disablePrevious}>Previous</button>
    
      <button
        className="btn btn-light pagination__next"
        type="button"
        onClick={getNextPage}
        value="+1"
        disabled={disableNext}>Next</button>
    </div>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  disablePrevious: PropTypes.bool,
  disableNext: PropTypes.bool,
};

export default Pagination;
