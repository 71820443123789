/**
 * ImportSpreadsheetToPlaylist
 * Enables users to import data into an AVA playlist from Google Sheet.
 */

import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { CloseButton } from '../subcomponents/CloseButton';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { ImportSpreadsheetFields } from './ImportSpreadsheetFields';
import ModalDialog from '../subcomponents/ModalDialog';
import ToggleSwitch from '../subcomponents/ToggleSwitch';

import {
  format,
  isValid
} from 'date-fns';

import {
  API_IMPORT_EMAIL,
  API_IMPORT_GSHEET_TO_PLAYLIST,
  NULL_DATE_TEXT_STATIONS,
} from '../../js/Configuration';

import {
  getAuthData,
  apirequest
}  from '../../js/Utilities';

import {
  initGState
} from '../../actions/ImportUtils';


const ImportSpreadsheetToPlaylist = (props) => {
  const {
    onSubmit,
    className,
    prepend,
    Video_Set,
    vsb_datetimetorun,
    sheet,
    vsb_id,
    modalRef,
  } = props;

  const [email, setEmail] = useState('');
  const [gSheet, setGSheet] = useState(initGState);

  useEffect(() => {
    if(email == '') {
      const fd = new FormData();
      fd.append('token', getAuthData('token'));

      apirequest(API_IMPORT_EMAIL, {body: fd}, (response) => {
        setEmail(response.email);
      });
    }
  }, [email]);

  const makeTitle = () => {
    let dte = `(${NULL_DATE_TEXT_STATIONS})`;

    if( vsb_datetimetorun && isValid(new Date(vsb_datetimetorun)) ) {
      dte = format(new Date(vsb_datetimetorun), 'PP');
    }

    return <span>Importing videos to <q>{Video_Set}</q> {dte.replace(/[[|\]]/g, '')}</span>;
  }

  let body;
  const cssClasses = classNames({
    'spreadsheet__destinations': true,
    'spreadsheet__import__disabled': !email
  }, className);

  const onChange = (domEvent) => {
    const {name, value} = domEvent.target;
    let field = name;
    if(name.indexOf('prepend') === 0) {
      field = 'prepend';
    }
    setGSheet({
      ...gSheet,
      [field]: value
    });
  }

  const oOnSubmit = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('url', gSheet.url);
    fd.set('sheet', gSheet.sheet);
    fd.set('playlist', vsb_id);
    fd.set('prepend', gSheet.prepend);
    fd.set('userUID', getAuthData('uuid'));
    fd.set('userID', getAuthData('uid'));
    fd.set('userName', getAuthData('user'));
    fd.set('token', getAuthData('token'));

    apirequest(API_IMPORT_GSHEET_TO_PLAYLIST, { body: fd }, (response) => {
      if(response.success && onSubmit) {
        onSubmit();

        if(modalRef.current) {
          modalRef.current.close();
        }
        setGSheet(initGState);
      }
    });
  }

  const onClose = (domEvent) => {
    if(modalRef.current) {
      modalRef.current.close()
    }
    setGSheet(initGState);
  }

  if(gSheet.loading) {
    body = <LoaderSimple open />;
  } else {

    /*
    This markup is weird because ToggleSwitch is also a form and you can't nest forms.
    You can, however, associate a form control such as a button with a form by setting
    the `form` attribute as we've done here.
    */
    body = (
        <>
          
          <form id="tools_import_sheet" onSubmit={oOnSubmit} className={cssClasses}>
            <input type="hidden"
              name="playlist"
              id="playlist"
              value={vsb_id}
              onChange={onChange}
            />
            <ImportSpreadsheetFields
              email={email}
              {...gSheet}
              onChange={onChange}
            />
          </form>
          <ToggleSwitch
            disabled={!email || !gSheet.url }
            onChange={onChange}
            label="Add to beginning?"
            options={['Yes','No']}
            name="prepend"
            selectedIndex={+gSheet.prepend}
          />
          <p className="text__right button__group">
            <button
              disabled={!email || !gSheet.url }
              type="submit"
              className="btn btn--action"
              form="tools_import_sheet"
            >Import</button>
            <button
              type="button"
              onClick={onClose}
              className="btn btn-light"
              form="tools_import_sheet"
            >Cancel</button>
          </p>
        </>
    );
  }

  return (
    <ModalDialog
      ref={modalRef}
      id="ImportSpreadsheetToPlaylist"
      onClose={onClose}
    >
      <h2>{makeTitle()}</h2>
      {body}
    </ModalDialog>
  );
}

export default ImportSpreadsheetToPlaylist;
