/* 
* SelectPerPage.jsx
* Creates a select menu for selecting the number of results to display 
* per screen. Used on Destinations, Search, and other pages.
* 
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNumeric } from '../../js/Utilities';

const SelectPerPage = ({
  id,
  name,
  onChangeHandler,
  cssClass,
  value,
  isRequired,
  options,
  replace,
  disabled
}) => {

  const makeOptions = () => {
    const defaults = [10,25,30,50,100];
    let opts = defaults;

    if(replace) {
      opts = options;
    } else {
      opts = defaults.concat(options);
    }

    return opts.sort((a,b) => {return a - b;}).map((o = null,idx) => {
      if(o === null) return null;
      if(isNumeric(o)) {
        return <option value={o} key={idx}>{o}</option>
      } else {
        // if a string was passed in, split it on a pipe.
        // Use first part for label, second for value.
        const v = o.split('|');
        return <option value={v[1]} key={idx}>{v[0]}</option>
      }
    });
  }

  const cssClasses = classNames('input-select', cssClass);
  const nme = name || id;

  return (
    <div className={cssClasses}>
      <label htmlFor={name}>Per page</label>
      <select name={nme} id={id} value={value} onChange={onChangeHandler} required={isRequired} disabled={disabled}>
        {makeOptions()}
      </select>
    </div>
  );
};

SelectPerPage.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChangeHandler: PropTypes.func,
  cssClass: PropTypes.string,
  value: PropTypes.number,
  isRequired: PropTypes.bool,
  options: PropTypes.array,
  replace: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SelectPerPage;
