/**
 * AmzReports.js
 */
import React, {useState, useEffect} from 'react';
import {
  useParams,
  Link,
  Route
} from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { AmzSongSearch } from './AmzSongSearch';
import { AmzSongAddSongs } from './AmzSongAddSongs';
import { NoSuchReport } from './NoSuchReport';

import {
  API_GENRES_REPORTS,
  API_CLUSTERS_SINGLEGENRE
} from '../../js/Configuration';

import {
  apirequest,
  capitalize,
  getAuthData
} from '../../js/Utilities';

import {
  download
} from '../../actions/ReportsUtils';

import './css/AmzReports.css';

const AmzReports = (props) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [sortby, setSortby]   = useState('frequency');
  const [ascending, setAscending] = useState(0);
  const [meta, setMeta] = useState({});

  const {
    params,
  } = props;
  
  useEffect(() => {
    if(!Object.hasOwn(meta, 'name')) {
      getGenreTitle();
    }
    
    if(!results.length) {
      getDuplicates();
    }
  
  }, [meta]);

  const getGenreTitle = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', params.id);

    apirequest(API_CLUSTERS_SINGLEGENRE, {body: fd}, (response) => {
      if (Object.hasOwn(response, 'id')) {
        setMeta(response);
      } else {
        setMeta({error: true});
      }
    });
  }

  const getDuplicates = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('report', params.report);
    fd.set('genre', params.id);
    fd.set('ascending', ascending);
    fd.set('sort', sortby);

    apirequest(API_GENRES_REPORTS, {body: fd}, (response) => {
      setLoading(false);
      setResults(response.results);
    });
  }

  const makeTable = () => { 
    let body = <LoaderSimple open={true} />;
    if(results.length) {
      body = (
        <table className="reports__table__results">
          {makeTableHeader()}
          {makeTableRows()}
        </table>
      )
    }
    return body;
  }

  const makeTableHeader = () => {
    if(!results.length) return null;
    const fields = Object.keys(results[0]);
    const th = fields
              .filter((f) => ['sASIN','aASIN'].indexOf(f) < 0)
              .map((f) => <th key={uniqueId()}>{capitalize(f)}</th>);

    return (
      <thead>
        <tr>
          {th}
        </tr>
      </thead>
    );
  }

  const makeTableRows = () => {
   


    return (
      <tbody>
       
      </tbody>
    );
  }

  const makeTitle = () => {
    return (
      <header>
        <h1 style={{'textTransform': 'capitalize'}}>
          Duplicate {params.report} Report for <q>{meta.name}</q>
        </h1>

        <p>
          <a
            className="amz__reports_backlink"
            href={`/music/clusters/${params.id}/`}
          >Return to {meta.name}</a>
        </p>

        <p className="button__group" style={{'textAlign': 'right'}}>
          <button type="button"
            className="btn btn--action"
            onClick={makeCSV}
          >Export CSV</button>
        </p>
      </header>
    );
  }

  const makeCSV = () => {
    if(!results.length) return;

    const exclude = ['ASIN','sASIN','aASIN','sgid','gid'];
    let csv = [];

    const headers = Object.keys(results[0]);
    csv[0] = headers
            .filter((h) => exclude.indexOf(h) < 0)
            .map((h) => `"${capitalize(h)}"`);

    const rws = results.map((r) => {
      const fields = Object.keys(r).filter((r) => exclude.indexOf(r) < 0);
      return fields.map((f) => `"${r[f]}"`);
    });

    const filename = `${capitalize(params.report)}_Report_for_${meta.name}`;
    download(csv.concat(rws), filename, 'Duplicate', '');
  }
  
  let body = <NoSuchReport />;
  if(['artists','songs'].indexOf(params.report) > -1) {
    body = (
      <div>
        {makeTitle()}
        {makeTable()}
      </div>
    );
  }
  return body;

}

export default (props) => (
  <AmzReports
    {...props}
    params={useParams()}
  />
);
