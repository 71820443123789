/**
 * PlaylistMeta.jsx
 * Displays playlist URL and comments form fields.
 */
import React from 'react';
import PropTypes from 'prop-types';

const PlaylistMeta = ({
  url,
  copy,
  onChange,
  form,
  playlist,
  disabled=false
}) => {

  return (
    <div className="destinations__playlist_copy">
      <label
        htmlFor="playlist_url"
        className="form__access__label"
      >Enter Playlist URL:</label>
      <input
        form={form}
        onChange={onChange}
        className="blockInfoTxt form-control"
        type="text"
        id="playlist_url"
        name="vsb_playlistURL"
        defaultValue={url}
        placeholder="Enter Playlist URL"
        disabled={disabled}
      />
      <label
        htmlFor="playlist_comments"
        className="form__access__label"
      >Enter comments for this play list:</label>
      <textarea
        form={form}
        className='blockInfoTxt copy-txt form-control'
        name='vsb_proofCopy'
        id='playlist_comments'
        defaultValue={copy}
        onChange={onChange}
        placeholder="Playlist Copy" disabled={disabled}
      ></textarea>
    </div>
  );
}

export default PlaylistMeta;