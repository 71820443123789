/**
 * EpisodesList.jsx
 * Displays modal list of episodes within a video set.
 */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from '../subcomponents/CloseButton';
import {
  format,
  isValid
} from 'date-fns';
import { Link } from 'react-router-dom';
import LoaderSimple from '../subcomponents/LoaderSimple';
import SeasonsEpsInput from './SeasonsEpsInput';
import EpisodeListCount from './EpisodeListCount';
import ModalDialog from '../subcomponents/ModalDialog';

import {
  API_VIDEOSET_ASSIGNMENTS,
  NULL_SET_TITLE,
  NULL_EPISODE_TITLE,
  NULL_DATE_TEXT_STATIONS
} from '../../js/Configuration';

import {
  apirequest,
  formatTime,
  getAuthData
} from '../../js/Utilities';

import './css/EpisodesList.css';

const EpisodesList = ({
  breadcrumbs,
  meta,
  modalRef,
  mode,
  onClose,
  onBatchEdit,
  onBatchSave,
  onDuplicateEpisode
}) => {

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(+meta.id) {
      getEpisodes();
    }
  }, [meta]);

  const getEpisodes = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', meta.id);

    apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd}, (data) => {
      // Add place holder properties for season and episode
      const episodes = data.map((ep) => {
        let s_e = {
          season: +ep.season || 0,
          episode: +ep.episode || 0
        };
        return Object.assign(ep, s_e);
      });

      setList(episodes);
      setLoading(false)
    });
  }

  const onBatchSaver = (domEvent) => {
    domEvent.preventDefault();
    if(onBatchSave) {
      onBatchSave(list)
    };
  }

  const onSeasonEpChange = (domEvent) => {
    const episodes = [...list];
    /*
    Seasons and episodes follow the format y:nnnn where y is either
    's' for 'season' or 'e' for 'episode.'
    
    line tells us whether we need to set the season or the
    episode property, and gives us the episode ID.
    */
    const sEandID = domEvent.target.name.split(':');

    let which = episodes.findIndex(item => +sEandID[1] === +item.vsb_id);

    if(sEandID[0] === 's') {
      episodes[which].season = +domEvent.target.value;
    }

    if(sEandID[0] === 'e') {
      episodes[which].episode = +domEvent.target.value;
    }

    setList(episodes);
  }

  const makeEpisodesView = () => {
    return (
      <div>
        <p className="episodes__display__batch">
          <button type="button" className="btn btn--action" onClick={onBatchEdit} hidden={mode === 'page'}>Batch Edit</button>
        </p>
        {makeEpisodesTable()}
      </div>
    );
  }

  const makeBatchView = () => {
    return (
      <div>
        <form id="EpisodesViewBatchSave" method="post" onSubmit={onBatchSaver}></form>
        <p className="episodes__display__batch">
          <button type="submit" form="EpisodesViewBatchSave" className="btn btn--action">Save Changes</button>
        </p>
        {makeEpisodesTable()}
      </div>
    );
  }

  const makeBody = () => {
    let body;

    if(loading) {
      body = <LoaderSimple open={loading} />
    } else {
      body = mode === 'edit' ? makeBatchView() :  makeEpisodesView();
    }
    return body;
  }

  const makeEpisodesTable = () => {
    let table;
    if(!list.length) {
      table = <h2>No episodes in set</h2>;
    } else {

      const season_count = countSeasons();

      const rows = list.map((item, idx) => {
        const runtime = item.duration ? (item.duration) : 0;
        const rundate = isValid( item.vsb_datetimetorun ) ? format( new Date(item.vsb_datetimetorun), 'D MMM yyyy' ) : NULL_DATE_TEXT_STATIONS;

        let season, episode;

        if(mode === 'edit') {
          season  = <SeasonsEpsInput value={item.season || 0} id={`s:${item.vsb_id}`} onChange={onSeasonEpChange} form="EpisodesViewBatchSave" label="Season Number" />;
          episode = <SeasonsEpsInput value={item.episode || 0} id={`e:${item.vsb_id}`} onChange={onSeasonEpChange} form="EpisodesViewBatchSave" label="Episode Number" />;
        } else {
          season  = item.season || '―';
          episode = item.episode || '―';
        }

        const fruntime = (runtime === 0) ? '00:00:00' : formatTime(runtime);

        return (
          <tr key={item.vsb_id}>
            <td>{item.title || NULL_EPISODE_TITLE}</td>
            <td>{rundate}</td>
            <td>{item.video_count}</td>
            <td>{fruntime}</td>
            <td>{makeControls(item.vsb_id)}</td>
            <td>{season}</td>
            <td>{episode}</td>
          </tr>
        );
      });

      table = (
        <div>
          <EpisodeListCount
            episodes={list.length}
            seasons={season_count.seasons}
            unassigned={season_count.unassigned} />   
          <table className="episodes__display__table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Run date</th>
                <th>Video count</th>
                <th>Run time</th>
                <th>Actions</th>
                <th>Season</th>
                <th>Episode</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      );
    }
    return table;
  }

  const makeControls = (episodeid) => {
    return (
      <div>
        <Link to={{
          pathname: `/stations/episode/${episodeid}`,
          state: {
            set: meta,
            list: list,
            breadcrumbs: [...breadcrumbs]
          }
        }}>View / Edit</Link>

        <form className="episodes__list__dup" method="post" onSubmit={onDuplicateEpisode}>
          <input type="hidden" name="id" value={episodeid} />
          <button type="submit" className="btn__link">Duplicate</button>
        </form>
      </div>
    );
  }

  const countSeasons = () => {
    const eps = list;
    if(!eps.length) return;

    // Extract the season number from every episode
    const season_numbers = eps.map((item) => { return item.season; });
   
    // Find unique season values that aren't NULL
    const unique = season_numbers.filter((item, index, arr) => {
      return item && arr.indexOf(item) === index;
    });

    // Find unique season values that are falsy (0 or NULL)
    const unassigned = season_numbers.filter(item => item == false);

    // Return the number of values
    return {seasons: unique.length, unassigned: unassigned.length};
  }

  if(Object.hasOwn(meta, 'title')) {
    title = meta.title ? meta.title.trim() : NULL_SET_TITLE;
  }

  if(mode === 'page') {
    mode = makeBody();
  } else {
    mode = (
      <ModalDialog
        ref={modalRef}
        setid={meta.id}
        id="EpisodesView"
        onClose={onClose}
      >
        <h2>Episodes for {`\u201c${title}\u201d`}</h2>
        {makeBody()}
      </ModalDialog>
    );
  }
  return mode;
}

EpisodesList.propTypes = {
  onDuplicateEpisode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  meta: PropTypes.object, // Should probably be a shape, but fine for now.
}

export default EpisodesList;