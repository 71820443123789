import React, {
  useState,
  useEffect,
  useRef,
} from 'react';

import { useParams } from 'react-router-dom';
import { Meter } from './subcomponents/Meter';
import ActionMessageDisplay from './subcomponents/ActionMessageDisplay';
import { IconApprovedRejected } from './subcomponents/IconApprovedRejected';

import PasswordResetShowHide from './UpdateAccount/PasswordResetShowHide';

import {
  apirequest,
  meetsMinLength,
  isSameString
} from '../js/Utilities';

import {
  requestPasswordSuggestion,
  updateOnPasswordSuggestion,
  setStatePasswordResetShowHide,
  resetPasswordState
} from '../actions/LoginUtils';

import {
  API_USER_CHECK_SET_PASS_ID,
  API_USER_SET_PASS,
  API_USER_TEST_PASS,
  SET_PASS_RULES,
  SET_PASS_RULES2,
  PASSWORDS_MISMATCH,
  PASSWORD_REQUEST_EXPIRED
} from '../js/Configuration';

import '../css/ResetPassword.css';

const ResetPassword = (props) => {
  const [state, setState] = useState(resetPasswordState);
  const {params} = props;
  const {pwordReqID} = params;

  /* Loads hooks JSON file from a URL. */
  useEffect(() => {
    if (!state.reqtok) {
      const fd = new FormData();
      fd.set('guid', pwordReqID);
  
      apirequest(API_USER_CHECK_SET_PASS_ID, {body: fd}, (response) => {
        const newState = {
          ...state,
          expired: response.expired,
          reqtok: response.request_token || null
        };      
        setState(newState);
      });
    }    
  },[]);

  const checkPass = () => {
    if(!state.password1.value) return;
    
    const fd = new FormData();
    fd.set('tok', state.reqtok);
    fd.set('password_token', pwordReqID);
    fd.set('password1', state.password1.value);

    apirequest(API_USER_TEST_PASS, {body: fd}, (response) => {
      // response is an object {length: xxx, strength: xxx}
      setState({...state, ...response});
    });
  }

  const disableSave = () => {
    return (state.password1.value === '') || (state.password2.value === '') || (state.password1.value !== state.password2.value) || state.strength < 3;
  }

  const postSaveMessage = (message, mode) => {
    let msg = <ActionMessageDisplay hidden={!message} message={message} mode={mode} />
    return msg;
  }

  const savePass = () => {
    const fd = new FormData();
    fd.set('tok', state.reqtok);
    fd.set('password_token', pwordReqID);
    fd.set('password1', state.password1.value);
    fd.set('password2', state.password2.value);

    apirequest(API_USER_SET_PASS, {body: fd}, (response) => {
      let newstate = {
        message: 'Could not save your password. Tell an administrator.',
        mode: 'failed'
      };

      if(Object.hasOwn(response, 'status') && response.status === 'success') {
        newstate.message = 'Your new password has been saved. <a href="/">Log in now</a>';
        newstate.mode = response.status;
      }

      setState({
        ...state,
        ...newstate
      });

    });
  }

  const onChange = (domEvent) => {
    let newstate = {
      [domEvent.target.name]: {
        ...state[domEvent.target.name],
        value: domEvent.target.value
      }
    };
  
    setState(Object.assign(state, newstate));

    checkPass()
  }

  const onSubmit = (domEvent) => {
    domEvent.preventDefault();
    // Send a request to the server containing the change request GUID and the password,
    // but only if the passwords match

    if( !isSameString(state.password1.value, state.password2.value) ) {
      alert(PASSWORDS_MISMATCH);
    }

    else if(!meetsMinLength(state.password2.value)) {
      alert(`${SET_PASS_RULES} Please try again.`);
    }

    else {
      // send the request to the server.
      savePass();
    }
  }

  const onSuggestionRequest = () => {
    requestPasswordSuggestion(state.reqtok, (response) => {
      const suggested = updateOnPasswordSuggestion(state, response);
      setState({...state, ...suggested});
    });
    
    checkPass();
  }

  const onShowPassword = (domEvent) => {
    if(domEvent.target.nodeName !== 'BUTTON') return;
    const fieldId = domEvent.target.dataset.controls;

    const newState = setStatePasswordResetShowHide(state, fieldId);
    setState({...state, ...newState});
  }

  
  let body = null;

  const isStrong = state.strength < 3;
  const isApproved = isStrong ? false : true;

  const p1ref = useRef(null);
  
  body = (
    <section className="page__set__password" >
      <header>
        <h1>Set a new password</h1>
        {postSaveMessage(state.message, state.mode)}
      </header>

      <form method="post" onSubmit={onSubmit}>
        <p>
          <input type="hidden" name="password_token" value={pwordReqID} />
          <label htmlFor="password1">Enter new password</label>
          <button type="button" className="btn--request" onClick={onSuggestionRequest}>Get a password suggestion</button>
          <span className="set__pass__approvable">
            <PasswordResetShowHide
              ref={p1ref}
              type={state.password1.type}
              name="password1"
              id="password1"
              minLength="12"
              onChange={onChange}
              onClick={onShowPassword}
              aria-describedby="password1-note"
              autoComplete="new-password"
              required
            />
            <b className="set__pass__approved" role="img" aria-label={isStrong ? 'Unacceptable' : 'Approved'} hidden={!state.password1.value.length > 0}><IconApprovedRejected approved={ isApproved } /></b>
          </span>
          <span id="password1-note" className="set__pass__note" dangerouslySetInnerHTML={SET_PASS_RULES} />
        </p>

        <p className="set__pass__passwordindic">
          Strength:
          <Meter className="password__strength" max="4" low="3" optimum="3.999" value={state.strength} />
        </p>

        <p>
          <label htmlFor="password2">Confirm new password</label>
          <span className="set__pass__approvable">
            <PasswordResetShowHide
              type={state.password2.type}
              name="password2"
              id="password2"
              minLength="12"
              onChange={onChange}
              onClick={onShowPassword}
              aria-describedby="password2-note"
              autoComplete="new-password"
              required
              disabled={ isStrong }
            />
            <b className="set__pass__approved" role="img" aria-label={isStrong ? 'Unacceptable' : 'Approved'} hidden={disableSave()}><IconApprovedRejected approved={ isApproved } /></b>
          </span>
          <span id="password2-note" className="set__pass__note">{SET_PASS_RULES2}</span>
        </p>

        <p>
          <button type="submit" className="btn btn--action" disabled={disableSave()}>Submit</button>
        </p>
      </form>
    </section>
  )

  if(state.expired === true) {
    body = <h1 className="page__set__password">{PASSWORD_REQUEST_EXPIRED}</h1>;
  }
  
  if(Object.hasOwn(p1ref, 'current') && p1ref.current !== null) {
    p1ref.current.focus();
  }
  return body;
}

export default (props) => (
  <ResetPassword
    {...props}
    params={useParams()}
  />
);
