/**
 * AmzSongsReorderTrigger.js
 * Button to enter and exit reordering mode.
 */
import React from 'react';
import uniqueId from 'lodash.uniqueid';

export const AmzSongsReorderTrigger = ({onClick, mode, disabled}) => {  
  return (
    <span className="AmzSongsReorderTrigger">
      <button
        type="button"
        className="btn btn--action"
        onClick={onClick}
        disabled={disabled}
        hidden={mode !== 'display'}
      >Re-order list</button>
      <button
        type="button"
        className="btn btn--action"
        onClick={onClick}
        hidden={mode !== 'reorder'}
      >Save changes</button>
    </span>
  );
}
