/*
ReportsUtils.js

Functions that are shared between multiple views in Reports
*/

import {
  apirequest
} from '../js/Utilities';

import {
  convertArrayToCSV
} from './ToolsUtils';

import {
  REPORTS_CHANNEL_USAGE_PER_PAGE
} from '../js/Configuration';

export const download = (data, channel, prefix='Channel', suffix='Usage_Report') => {
  const blob = new Blob([convertArrayToCSV(data)], {type: 'text/csv'});
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");

  a.style = "display: none";
  document.body.appendChild(a);
  a.href = url;
  a.download = `${prefix}_${channel}_${suffix}.csv`;
  a.click(); // programatically click the link to trigger the download

  //release the reference to the file by revoking the Object URL
  window.URL.revokeObjectURL(url);

  // Delete the link.
  document.body.removeChild(a);
}

export const prepareDestinationsMenu = (response = []) => {
  if(!response.length) return response;
   
  return response.map((destination) => {
    const station = +destination.isStation ? ` (Station)` : '';   
    return {
      label: `${destination.dName}${station}`,
      value: destination.dID
    };
  });
}

export const setStateForDest = (state, value) => {
  return {
    forDestination: +value
  };
}

export const setStateSearchPre = (state, value) => {
  return {
    isLoading: true,
    channel: value,
    count: 0
  };
}

export const setStateSearchPost = (state, data) => {
  return {
    results: data.data.results,
    isLoading: false,
    count: data.data.results.length,
    csv: data.data.csv
  }
}

/* DONT EXPORT. */
const pageState = (destinationObj, value) => {
  let deets;
  let current;

  const defaultToggle = {
    selected: 0,
    ascending: 1
  }

  if(Object.hasOwn(destinationObj, 'detail')) {
    current = {
      ...destinationObj.detail,
      loading: true
    };
  } else {
    current = {
      loading: true,
      page: 1,
      perPage: REPORTS_CHANNEL_USAGE_PER_PAGE,
      results : [],
      toggle: defaultToggle
    };
  }

  switch(value) {
    case 'previous':
      deets = {
        ...current,
        page: destinationObj.detail.page - 1
      }
      break;
    case 'next':
      deets = {
        ...current,
        page: destinationObj.detail.page + 1
      }
      break;
    case 'first':
    case Number.isNaN(value):
      deets = {
        ...current,
        page: 1
      }
      break;
    case 'last':
      deets = {
        ...current,
        page: destinationObj.detail.pages
      }
      break;
    default:
      deets = {
        ...current,
        page: +value
      }
  }
  return deets;
}

export const setStateRequestDetails = (state, destinationId, pg = 1) => {
  const destinations = [...state.results];
  const update = destinations.findIndex(item => +item.destination === +destinationId);

  if(update === -1) return;
  destinations[update].detail = pageState(destinations[update], pg);

  return {
    results: destinations
  }
}

export const setLoading = (state) => {
  return {
    isLoading: true
  }
}

/* When you drill down into a Channel Usage panel */
export const setStateAddVideoResults = (state, data) => {
  if(!data.hasOwnProperty('destination')) return;

  const ind = state.results.findIndex((item) => +item.destination === +data.destination);
  const ste = [...state.results];

  const deets = {
    pages: data.pages,
    loading: false,
    results: data.results
  };

  ste[ind]['detail'] = Object.assign({...state.results[ind].detail}, deets);

  return {
    results: ste
  };
}

export const setStateReset = (state) => {
  return {
    ascending: 0,
    channel: '',
    destinations: [],
    forDestination: 0,
    groupBy: 'destination',
    isLoading: false,
    results: [],
    count: null // Number of results returned
  }
}

export const channelUseDetailSetView = (state, destinationId, view) => {
  const which = state.results.findIndex((item) => +item.destination === destinationId);
  const toUpdate = {...state};

  const changes = {
    ...toUpdate.results[which].detail,
    loading: true,
    toggle: {
      ...toUpdate.results[which].detail.toggle,
      selected: +view,
      ascending: !(+view)
    },
    page: 1
  }

  toUpdate.results[which].detail = changes;

  return toUpdate;
}

/*
 Accepts a destination object and adds this default state as a
 detail property
*/
const setDetailDefault = (resultObject) => {
  const resultState = {
    loading: true,
    open: false,
    page: 1,
    perPage: REPORTS_CHANNEL_USAGE_PER_PAGE,
    results : [],
    toggle: {
      selected: 0,
      ascending: 1
    }
  };

  return {
    ...resultObject,
    detail: resultState
  }
}

export const reportsChannelUseDetailDefaultState = (resultArray) => {
  return resultArray.map(setDetailDefault);
}

export const setChannelUsePanelPage = (destinationObj, value) => {
  switch(value) {
    case 'previous':
      pg = +destinationObj.detail.page - 1;
      break;
    case 'next':
      console.log(value)
      pg = destinationObj.detail.page + 1;
      break;
    case 'first':
    case Number.isNaN(value):
      pg = 1;
      break;
    case 'last':
      pg = +destinationObj.detail.pages
      break;
    default:
      pg = +value;
  }

  const update = {...destinationObj};
  update.detail = {
    ...update.detail,
    page: pg
  }

  return update;
}