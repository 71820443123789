/**
 * Channel.js
 * /channel/:channelID single channel, page-level component
 */
import React, { useEffect, useState} from 'react';
import { format } from 'date-fns';

import { useParams } from 'react-router-dom';

import Breadcrumb from '../subcomponents/Breadcrumb';
import ChannelsManageGroups from './ChannelsManageGroups';
import ChannelVideos from './ChannelVideos';
import ChannelEditTags from './ChannelEditTags';

import {
  API_CHANNEL_INFO,
  API_CHANNEL_REMOVE,
  API_GROUPS_ALLGROUPS,
  API_GROUPS_ASSIGN_CHANNEL_TO_GROUP,
  API_CHANNEL_GET_VIDEOS,
  API_CHANNEL_SETTAGS,
  API_REMOVE_CHANNEL_FROM_GROUP,
  MESSAGE_NO_TAGS
} from '../../js/Configuration';

import './css/Channel.css';

import {
  apirequest,
  getAuthData,
  numberFormat,
  isEmptyObject
} from '../../js/Utilities';

const Channel = (props) => {
  const { params } = props;
  const { channelID } = params;

  const [data, setData] = useState({});
  const [videos, setVideos] = useState({
    list: [],
    page: 1,
    perPage: 25,
    total: 1,
    sort: 0,
    ascending: 1,
    loading: true,
    rangelimit: 10, // End of the pagination display.
    start: 1        // Start of the pagination display.
  });

  const [groups, setGroups] = useState({
    options: [],
    selected: ''
  });

  const [crumbs] = useState([{
    path: `/channels/`,
    label: 'Channels',
    state: {}
  }]);

  const [editTags, setEditTags] = useState({tags: '', success: false});

  const DATE_FORMAT_TEXT = 'PP';

  useEffect(() => {
    if(isEmptyObject(data)) {
      getChannel(channelID);
    }

    if(!videos.list.length) {
      getVideos();
    }

    if(!groups.options.length) {
      getGroups();
    }

  }, [channelID, videos]);

  const getChannel = (id = null) => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', id);

    apirequest(API_CHANNEL_INFO, {body: fd}, (data) => {
      setData(data);
    });
  }

  const getVideos = (page=1, perPage=25) => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', channelID);
    fd.set('page', page);
    fd.set('perPage', perPage);

    apirequest(API_CHANNEL_GET_VIDEOS, {body: fd}, (response) => {
      setVideos((previous) => {
        return {
          ...previous,
          list: response.list,
          total: response.total,
          pages: response.pages,
          loading: false
        };
      });
    });
  }

  const getGroups = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, (data) => {
      setGroups({...groups, options: [...data]});
    });
  }

  const makeBreadcrumbs = () => {
    let slice = [...crumbs];
    let lastCrumb = [];

    if (Object.hasOwn(data, 'channel_title')) {
      lastCrumb = [{
        label: data.channel_title,
        state: {}
      }];
    };

    return <Breadcrumb ariaLabel="Navigate to Channels index page" items={slice.concat(lastCrumb)} />;
  }

  const makeCategories = () => {
    if(!data.channel_category_names) return;
    return data.channel_category_names.join(', ');
  }

  const onGroupSubmit = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ASSIGN_CHANNEL_TO_GROUP, {body: fd}, (response) => {
      if (!response.success) return false;
      setData((previous) => {
        return {
          ...previous,
          groupList: [...response.list[channelID]]
        }
      });
    });
  }

  const onGroupDelete = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));
    apirequest(API_REMOVE_CHANNEL_FROM_GROUP, {body: fd}, (response) => {
      setData((previous) => {
        return {
          ...previous,
          groupList: [...response[channelID]]
        };
      });
    });
  }

  const onChannelDeleteRequest = (id = null) => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', id);

    apirequest(API_CHANNEL_REMOVE, {body: fd}, (data) => {
      if(data.status === 'success') {
        window.location.href = '/channels';
      }
    });
  }

  const onChannelDelete = () => {
    const confirmed = window.confirm(`Are you sure that you want to delete ${data.channel_title}`);
    if(confirmed) {
      onChannelDeleteRequest(channelID);
    }
  }

  const onCustomTagsChange = (domEvent) => {
    const {value} = domEvent.target;
    setEditTags((previous) => {
      return {
        ...previous,
        tags: value
      }
    });
  }

  const onCustomTagsSubmit = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', data.channel_id); // using a numeric ID for now.
    fd.set('tags', editTags.tags);

    apirequest(API_CHANNEL_SETTAGS, {body: fd}, (response) => {
      const { channelTags } = response;
      setEditTags((previous) => {
        return {
          ...previous,
          tags: channelTags,
          success: true
        }
      });

      setData((previous) => {
        return {
          ...previous,
          channel_customtags: channelTags
        }
      })
    });
  }

  const onPerPageSelect = (domEvent) => {
    const {value} = domEvent.target;
    setVideos((previous) => {
      return {
        ...previous,
        perPage: +value,
        pages: Math.ceil(previous.total / +value)
      }
    });
    getVideos(videos.page, +value);
  }

  const onPaginationClick = (domEvent) => {
    const {value} = domEvent.target;
    setVideos({
      ...videos,
      page: +value
    });
    getVideos(+value, videos.perPage);
  }

  const onCustomTagsMessageClose = () => {
    setEditTags((previous) => {
      return {
        ...previous,
        success: false
      }
    });
  }

  const formatDate = (dateString) => {
    let date = '';
    if(dateString) {
      const d = new Date(dateString);
      date = format(d, DATE_FORMAT_TEXT);
    }
    return date;
  }

  return (
    <div className="ava__view__wrap">
      {makeBreadcrumbs()}
      <div id="channel_single_page_header">
        <h1>{data.channel_title} <a className="channel__external" href={`https://youtube.com/channel/${data.channel_youtubechannelid}`} target="_blank" rel="noreferrer">View channel on YouTube</a></h1>
        <img className="channel__single__thumb" src={data.channel_thumbnail} />
        <div className="channel__single__description">
          {data.channel_description}
        </div>

        <h2>Channel Stats:</h2>
        <ul className="channel__single__meta">
          <li><span className="listText">Added to System:</span><span className="itemsBadge">{ formatDate(data.channel_dateaddedtosystem) }</span></li>
          <li><span className="listText">Date Updated in System:</span><span className="itemsBadge">{ formatDate(data.channel_datelastupdated) }</span></li>
          <li><span className="listText">Publication Date:</span><span className="itemsBadge">{ formatDate(data.channel_dateadded) }</span></li>
          <li><span className="listText">Videos In AVA:</span><span className="itemsBadge">{numberFormat( videos.total )}</span></li>
          <li><span className="listText">Subscriber Count:</span><span className="itemsBadge">{numberFormat( data.channel_subscribercount )}</span></li>
          <li><span className="listText">Videos in Channel:</span><span className="itemsBadge">{numberFormat( data.channel_videocount )}</span></li>
          <li><span className="listText">Categories / Topics:</span><output className="itemsBadge textNormal listItemScrollingTextBox resizable--vert">{makeCategories()}</output></li>
          <li><span className="listText">Keywords:</span><span className="itemsBadge textNormal listItemScrollingTextBox resizable--vert">{ data.channel_keywords }</span></li>
          <li><span className="listText">Tags:</span><span className="itemsBadge textNormal listItemScrollingTextBox resizable--vert">{ data.channel_customtags || MESSAGE_NO_TAGS}</span></li>
        </ul>
      </div>

      <ChannelsManageGroups
        channel={channelID}
        groups={groups.options}
        inGroups={data.groupList}
        onSubmit={onGroupSubmit}
        onChannelDelete={onChannelDelete}
        onGroupDelete={onGroupDelete} />

      <ChannelEditTags
        channel={data.channel_youtubechannelid}
        tags={data.channel_customtags}
        onSubmit={onCustomTagsSubmit}
        onChange={onCustomTagsChange}
        success={editTags.success}
        onMessageClose={onCustomTagsMessageClose}
      />

      <ChannelVideos
        {...videos}
        onPerPageSelect={onPerPageSelect}
        onPaginationClick={onPaginationClick}
      />

    </div>
  )
}

export default (props) => (
    <Channel
        {...props}
        params={useParams()}
    />
);