/**
 * Header.js
 * Manages the navigation display
 */

import React from 'react';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import Logo1021 from './subcomponents/Logo1021';

import {
  canAdminProject,
  getAuthData
} from '../js/Utilities';

import '../css/Header.css';

const Header = ({displayname_first}) => {
  if(!getAuthData()) return null;

  const canAdmin = () => {
    let links = null;

    if(canAdminProject('User Admin')) {
      links = (
        <span>
          <b>•</b><NavLink className={({isActive}) => {
            isActive ? 'mainNavSelected divTransition' : 'divTransition';
          }} to="/admin" key="admin">Admin</NavLink>
        </span>
      )
    }
    return links;
  }

  let display_name = 'Friend';
  if(displayname_first) {
    display_name = displayname_first;
  }

  return (
    <header id="SiteHeader">
      <div id="Logo1021">
        <Logo1021 />
      </div>
      <div id="account">
        <div>
          { `Hello, ${ display_name }` }<br/>
          <Link to="/update_account" key="update_account">Update Account Info</Link><b>•</b>
          <Link to="/logout" key="logout">Log Out</Link>
          {canAdmin()}
        </div>
      </div>
    </header>
  );
}

export default Header;
