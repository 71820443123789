/**
 * AmzSongsRemove.jsx
 * A form for removing songs from a cluster.
 */
import React from 'react';
import uniqueId from 'lodash.uniqueid';

export const AmzSongsRemove = ({
  onSubmit,
  ASIN,
  cluster
}) => {
  const emptyOnChange = ()=>{};

  return (
    <form className="AmzSongsRemove" id={uniqueId('AmzSongsRemove_')} onSubmit={onSubmit}>
      <input type="hidden" name="asin" value={ASIN} onChange={emptyOnChange} />
      <input type="hidden" name="cluster" value={cluster} onChange={emptyOnChange} />
      <button type="submit" className="btn btn--action">Remove from Cluster</button>
    </form>
  );
}
