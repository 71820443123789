/**
 * ShuffleVideos.jsx
 * Shuffle videos in a playlist
 */
import React from 'react';
import PropTypes from 'prop-types';

const ShuffleVideos = (props) => {
  return <button type="button" {...props} onClick={props.onClick} className="btn btn--action btn-sm">Shuffle Videos</button>;
}

ShuffleVideos.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ShuffleVideos