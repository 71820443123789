/*
ModalDialog
Use this instead of DialogModal. Uses the HTML dialog element.
*/

import React, { useEffect, useRef } from 'react';
import { CloseButton } from './CloseButton';

import './css/ModalDialog.css';

const ModalDialog = React.forwardRef(function ModalDialog(props, ref) {
  const {
    open,
    children,
    id,
    onClose,
  } = props;

  useEffect(() => {
    if (!open) {
      return;
    }
    
    if(Object.hasOwn(ref, 'current')) {
      ref.current?.showModal();
    }
  }, [open]);

  /* onClose event on the dialog element supports confirmation and alert-style dialogs. */
  return (
    <dialog ref={ref} id={id} aria-labelledby={`${id}_title`} onClose={onClose}>
      <CloseButton
        color="#CBCBCB"
        className="dialog__body__close"
        onClick={onClose}
      />

      <div className="dialog__body" role="document">
        {children}
      </div>
    </dialog>
  )
});

export default ModalDialog;
