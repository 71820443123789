import React from 'react';
import { Link } from 'react-router-dom';

const ListDisplay = ({items}) => {
  const reports = items.map((item, index) => {
    return (
      <li key={index.toString()}>
        <Link
          className="reports__link"
          to={`/reports/display/${item.api_path.split('/')[1]}`}
          state={{ title: item.title }}>{item.title}</Link>
        <br />{item.description}
      </li>
    );
  })

  return (
    <ul>
      {reports}
    </ul>
  );
}

export default ListDisplay;
