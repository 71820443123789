/**
 * SeriesView.js
 * /stations page-level component
 */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../subcomponents/Breadcrumb';
import EpisodesList from './EpisodesList';
import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  API_SERIES,
  API_VIDEOSET_ASSIGNMENTS,
  API_VIDEOSET_DUPLICATE,
  NULL_SET_TITLE
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

const SeriesView = ({
  params,
}) => {

  const [breadcrumbs, setBreadcrumbs] = useState([{
    path: '/stations',
    label: 'Stations'
  }]);

  const initEpState = {
    open: false,
    list: [],
    meta: {
      id: 0,
      title: null
    },
    mode: 'view'
  };
    
  const [seriesMeta, setSeriesMeta] = useState({ title: null, id: null });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true)
  const [episodes, setEpisodes] = useState(initEpState)

  useEffect(() => {
    if(!list.length) {
      getSeriesMeta();
      getSeries();
    }
  }, []);

  const getSeries = () => {
    const fd = new FormData();

    fd.set('id', params.seriesID);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd}, (data) => {
      setList(data);
      setLoading(false);
    });
  }

  const getSeriesMeta = () => {
    const fd = new FormData();
    fd.set('id', params.seriesID);
    fd.set('token', getAuthData('token'));

    apirequest(API_SERIES, {body: fd}, (data) => {      
      const slice = [{
        path: `/stations/${data.station_id}`,
        label: data.Station
      },
      {
        path: `/stations/series/${params.seriesID}`,
        label: data.Video_Set || NULL_SET_TITLE
      }];

      setBreadcrumbs([...breadcrumbs].concat(slice));
      setSeriesMeta({
        title: data.Video_Set || NULL_SET_TITLE,
        id: data.vs_id
      });
    });
  }

  const onEpisodesClose = (domEvent) => {
    setEpisodes(initEpState);
  }

  const onBatchSave = () => {}
  const onBatchEdit = () => {}

  const onEpisodeDuplicate = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('id', domEvent.target.elements.id.value)
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSET_DUPLICATE, {body: fd}, (data) => {
      if( Number.isNaN(+data) ) {
        throw new TypeError('Invalid response. Please notify an administrator.');
      } else {
         window.location = `/stations/episode/${data}`;
      }
    });
  }

  const makeEpisodesList = () => {
    let body;

    if(loading) {
      body = <LoaderSimple open={true} />;
    } else {
      body = (
        <EpisodesList
          meta={{id: params.seriesID}}
          mode="page"
          {...list}
          onClose={onEpisodesClose}
          breadcrumbs={breadcrumbs.slice(0, 2)}
          onDuplicateEpisode={onEpisodeDuplicate}
          onBatchEdit={onBatchEdit}
          onBatchSave={onBatchSave} />
      );
    }
    return body;
  }

  return (
    <div className="stations__view">
      <Breadcrumb
        items={breadcrumbs}
        ariaLabel="Stations navigation"/>
      <h1>Episodes for <q>{seriesMeta.title}</q></h1>
      {makeEpisodesList()}
    </div>
  ); 
}

export default (props) => (
  <SeriesView
    {...props}
    params={useParams()}
  />
);


