/**
 * ToolsAverages.js
 * Calculate and display averages for a list of videos.
 */

import React, { useState, useEffect } from 'react';
import  { useParams } from 'react-router-dom';
import ToggleSwitch from '../subcomponents/ToggleSwitch';
import { ToolsAddToGoogleSheet } from './ToolsAddToGoogleSheet';
import { ToolsImportAnother } from './ToolsImportAnother';
import { ToolsSpreadsheetUpdated } from './ToolsSpreadsheetUpdated';
import { ToolsSpreadsheetFailed } from './ToolsSpreadsheetFailed';
import { ToolsSheetLoading } from './ToolsSheetLoading';

import {
  API_IMPORT_SPREADSHEET,
  MESSAGE_ERROR_GENERAL
} from '../../js/Configuration';

import {
  getAuthData,
  apirequest,
  numberFormat,
  formatTime  
}  from '../../js/Utilities';

import {
  download,
  setSpreadsheetData
} from '../../actions/ToolsUtils';

import '../subcomponents/css/fancy-radiobuttons.css';

const ToolsAverages = (props) => {
  const {params} = props;

  const fetchCancel = new AbortController();

  const [loading, setLoading] = useState(true);
  const [calcs, setCalcs] = useState({
    total: 0,
    median: 0,
    mean: 0,
    mode: [],
    min: 0,
    max: 0
  });

  const [mode, setMode] = useState('importing'); // 'importing', 'imported', or 'pushed'

  const [timedisplay, setTimeDisplay] = useState({
    options: ['Hours','Days'],
    useDays: true // Convert to Boolean.
  });
  
  const [exporting, setExporting] = useState({loading: false});

  useEffect(() => {
    if(!calcs.mode.length) {
      requestModeRanges();
    }

    return () => fetchCancel.abort();

  }, [calcs]);

  const ddownload = () => {
    const dta = prepareData();
    download(dta, params.spreadsheetID, params.sheetName);
  }

  const onToggleTimeDisplay = (domEvent) => {
    const td = {...timedisplay};
    const {value} = domEvent.target;

    setTimeDisplay({
      ...timedisplay,
      useDays: !(+value) // + converts to integer / boolean. ! inverts it.
    });
  }

  const prepareData = () => {
    const {
      count,
      mean,
      min,
      max,
      total
    } = calcs;

    const data = [
      [`Statistics for ${params.spreadsheetID} (${params.sheetName})`],
      ['Number of Videos', +count],
      ['Total Duration', `${formatTime(total, false)} (${formatTime(total, true)})`],
      ['Average Length', formatTime(mean)],
      ['Shortest', formatTime(min)],
      ['Longest', formatTime(max)],
    ];
    return data;
  }

  const setData = () => {
    const data = prepareData();

    const cb = (response) => {
      if(response.spreadsheetId) {
        setExporting({...exporting, loading: false});
        setMode('pushed');
      } else {
        alert(MESSAGE_ERROR_GENERAL);
      }
    };
    setSpreadsheetData(data, cb, params.spreadsheetID, params.sheetName, null, 'durations');
  }

  const makeAverages = () => {
    const {
      count,
      mean,
      min,
      max
    } = calcs;

    return (
      <div className="tools__imported">
        <h1>Statistics for {params.sheetName}</h1>
        <ul>
          <li><b>Videos in sheet:</b> {numberFormat(calcs.count)}</li>
          <li>
            <b>Total:</b> {formatTime(total, !timedisplay.useDays)}
            <ToggleSwitch
              onChange={onToggleTimeDisplay}
              label="Show in:"
              className="tools--toggle"
              options={timedisplay.options}
              name="PlaylistCalcTimeDisplay"
              selectedIndex={+(!timedisplay.useDays)}
            />
          </li>
          <li><b>Average Length:</b> {formatTime(mean)}</li>
          <li><b>Shortest:</b> {formatTime(min)}</li>
          <li><b>Longest:</b> {formatTime(max)}</li>
        </ul>
        <p className="tools__imported__controls">
          <button
            type="button"
            onClick={ddownload}
            className="btn btn--action"
          >Download Statistics</button>
          <ToolsAddToGoogleSheet onClick={setData} loading={exporting.loading} />
          <ToolsImportAnother />
        </p>
      </div>
    );
  }

  const requestModeRanges = () => {
    const signal = fetchCancel.signal;

    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('url', `https://docs.google.com/spreadsheets/d/${params.spreadsheetID}/`);
    fd.set('sheet', params.sheetName);

    apirequest(API_IMPORT_SPREADSHEET, {body: fd, signal}, (response) => {
      if(+response.status != 200) {
        setMode('failed');
        return;
      }
      setCalcs(response.results);
      setMode('imported');
      setLoading(false);
    });
  }

  let body;
  switch (mode) {
    case 'importing':
      body = <ToolsSheetLoading />;
      break;
    case 'failed':
      body = <ToolsSpreadsheetFailed />;
      break;
    case 'imported':
      body = makeAverages();
      break;
    case 'pushed':
      body = <ToolsSpreadsheetUpdated spreadsheetID={params.spreadsheetID} />;
      break;
  }
  return body;
}

export default (props) => (
  <ToolsAverages
      {...props}
      params={useParams()}
  />
);
