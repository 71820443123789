/**
 * CheckForDuplicatesModal.js
 * Displays a list of available videos to add. 
 * Used in DestinationsPlaylist.js
 */

import React, { createRef } from 'react';

import LoaderSimple from '../subcomponents/LoaderSimple';
import uniqueId from 'lodash.uniqueid';
import { CheckForDuplicatesRemove } from './CheckForDuplicatesRemove';
import { CheckForDuplicatesExclude } from './CheckForDuplicatesExclude';
import ActionMessageDisplay from '../subcomponents/ActionMessageDisplay';
import ModalDialog from '../subcomponents/ModalDialog';


import {
  indicesOfDupes,
  checkUrlsForDupes
} from '../../actions/DestinationsPlaylistUtils';

import {
  extractYouTubeIdFromUrl
} from '../../js/Utilities';

import './css/CheckForDuplicatesModal.css';

const CheckForDuplicatesModal = ({
  dupes=[],
  inSet,
  message,
  modalRef,
  mode,
  loading,
  onChange,
  onClose,
  onErrorClose,
  onRemove,
  onSubmit,
  playlist,
  response,
  working
}) => {

  /*
    Finds the indices of the original and the duplicate.
    returns an object in the form {youtubeid: [array,of,indices]}
  */
  const findDupesIndices = () => {
    if(!dupes.length) return;
    const callback = (item) => {
      return indicesOfDupes(inSet, item)
    };
    return dupes.map(callback);
  }

  const makeBody = () => {
    let body = null;
    switch(mode){
      case 'checking':
      case 'checked':
        body = checkingModeDisplay();
        break;

      default:
        body = addingModeDisplay();
    }
    return body;
  };

  const sortByVideoName = (a, b) => {
    const c = a.name.toLowerCase();
    const d = b.name.toLowerCase();
    if (c < d) { return -1; }
    if (c > d) { return 1; }

    // names must be equal
    return 0;
  }

  const checkingModeDisplay = () => {
    const dupes = findDupesIndices() || [];
    let table = null;

    if(!dupes.length) {
      table = <div><h2>Check for Duplicates</h2><p>No duplicates found.</p></div>;
    } else {
      /* For each array of indices in each row return a row...*/
      const rows = dupes
        .map((r) => r.indexes.map((index) => inSet[index]))
        .reduce((accumulator, value) => accumulator.concat(value), []) // flattens the array
        .sort(sortByVideoName) // alphabetize
        .map((instance, index) => {
          const ky = uniqueId(`${instance.ytID}__`);
          return (
            <tr key={ky}>
              <td>{instance.name}</td>
              <td>{+instance.ordinal + 1}</td>
              <td>
                <CheckForDuplicatesRemove id={instance.ytID} playlist={playlist} order={instance.position} onSubmit={onRemove} />
              </td>
            </tr>
          );
        });

       table = (
        <div>
          <h2>Found duplicates</h2>
          <ActionMessageDisplay
            mode={response.itemsDeleted ? 'success' : 'failed'}
            message={response.itemsDeleted ? message : 'Error: Something went wrong. Please tell an administrator.'}
            hidden={mode !== 'checked'}
            onClose={onErrorClose} />
          <LoaderSimple open={working} />
          <p>The following videos appear more than once in this play list. Please select which instance(s) you'd like to remove.</p>
          <table className="CheckForDuplicatesModal__table__checking">
            <thead>
              <tr>
                <th>Video Name</th>
                <th>Order</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </table>
         </div>
      );
    }
    return table;
  }

  const addingModeDisplay = () => {
    if(!dupes.length) {
      return <h2>No duplicates found.</h2>
    }

    const du = dupes
                .map(extractYouTubeIdFromUrl)
                .map((d) => inSet.find((i) => i.ytID == d))
                .flat();

    // Filter undefined.
    const rows = du.filter(r => r).map((r) => {
      const ky = uniqueId(`${r.ytID}__`);
      const formId = uniqueId(`${r.ytID}--`);

      return (
        <tr key={ky}>
          <td><label htmlFor={formId}>{r.name}</label></td>
          <td>
            <CheckForDuplicatesExclude
              onChange={onChange}
              youtubeid={r.ytID}
              id={formId}
              name="duplicates"
            />
          </td>
        </tr>
      );
    });

    return (
      <form onSubmit={onSubmit}>
        <h2>Found duplicates</h2>
        <p>The following videos are already part of this list. Do you still want to add them?</p>
        <input id="PostDupeCheck" name="PostDupeCheck" value="1" type="hidden" />
        <table className="CheckForDuplicatesModal__table__adding">
          <thead>
            <tr>
              <th>Video Name</th>
              <th>
                Yes
                <span className="note">(uncheck to exclude)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        <div className="button__group">
          <button type="submit" className="btn btn--action">Save</button>
          <button type="button" className="btn" onClick={onClose}>Cancel</button>
        </div>
      </form>
    );
  }

  let body;
  if(loading) {
    body = (
      <div>
        <h2>Check for Duplicates</h2>
        <LoaderSimple open={true} />
      </div>
    )
  } else {
    body = makeBody();
  }

  return (
    <ModalDialog onClose={onClose} ref={modalRef} id="CheckForDuplicatesModal" className="check_dupes__modal">
      {body}
    </ModalDialog>
  )
}

export default CheckForDuplicatesModal;