/**
 * DestinationsSearch
 * Displays playlist URL and comments form fields.
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/DestinationsSearch.css';
import { DestinationsIncludeInactive } from './DestinationsIncludeInactive';

const DestinationsSearch = ({
  value,
  onChange,
  onFocus,
  onSubmit,
  onReset,
  onInactiveChange,
  isAdmin = false
}) => {

  let showIncludeInactive = null;
  if(isAdmin) {
    showIncludeInactive = <DestinationsIncludeInactive onChange={onInactiveChange} />;
  }
  return(
    <form
      method="dialog"
      id="DestinationsSearch"
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <label htmlFor="q">Search for a Video Set</label>
      <div className="form__input_button_group">
        <span>
          <input
            type="search"
            id="q"
            name="q"
            value={value}
            onChange={onChange}
            aria-describedby="DestinationsSearch__hint"
            autoComplete="off" onFocus={onFocus}
          />
          <button type="submit" className="btn btn--action">Search</button>
        </span>
      </div>
      <p>
        <span
          id="DestinationsSearch__hint"
          className="aria__description"
        >
          Add quotes around phrases for better results,
          (e.g. <code>"world cup"</code> instead of <code>world cup</code>.)
        </span>
        {showIncludeInactive}
      </p>
      <button
        type="reset"
        className="trigger__fauxlink"
        disabled={!value.length}
      >Return to Destinations list</button>
    </form>
  )
}

export default DestinationsSearch;