/**
 * EpisodeListCount.js
 * Simple number input component
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/EpisodeListCount.css';

const EpisodeListCount = (props) => {
  const {
    episodes,
    seasons,
    unassigned,
  } = props;

  return (
    <p className="episodes__list__count">
      <b className="episodes__list__count_num">{episodes}</b>
      {' '}
      total episodes in
      <b className="episodes__list__count_num">
        {' '}
        {seasons}
      </b>
      {' '}
      seasons
      (
      <b>{unassigned}</b>
      {' '}
      unsorted)
    </p>
  );
}

EpisodeListCount.propTypes = {
  episodes: PropTypes.number.isRequired,
  seasons: PropTypes.number.isRequired,
  unassigned: PropTypes.number.isRequired,
}

export default EpisodeListCount
