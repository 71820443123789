/**
 * Reviewed.jsx
 * Component used for Proofed, Curated, and Approved features.
 * Author: Webinista, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/Reviewed.css';

const Reviewed = ({
  date=null,
  cssClass='',
  name=null,
  id=null,
  labelText='YOU FORGOT YOUR LABEL!',
  by='\u0020',
  onChange = (domEvent) => {}
}) => {

  const [checked, setChecked] = React.useState(false);

  const cssClasses = classNames(
    cssClass,
    {'destinations__reviewed': true}
  );
  
  const fieldName = name || id;
  
  return (
    <div className={cssClasses}>
        <div>
          <label htmlFor={id}>{labelText} </label>
          <input type="checkbox"
            onChange={onChange}
            name={fieldName}
            id={id}
          />
        </div>
        <div><i>by:</i> {by}</div>
        <div><i>date:</i> {date}</div>
    </div>
  )
}

Reviewed.propTypes = {
  cssClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  by: PropTypes.string,
  date: PropTypes.string,
  labelText: PropTypes.string.isRequired
};

export default Reviewed;