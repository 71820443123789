import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';

import NextPrevious from './NextPrevious';
import SearchResultItem from './SearchResultItem';
import LoaderSimple from '../subcomponents/LoaderSimple';

import './css/SearchResultsPanel.css';

import {
  SEARCH_NO_RESULTS
} from '../../js/Configuration';

const SearchResultsPanel = (props) => {
  const {
    channel_ytid,
    channel_title,
    results,
    onPlayVideo,
    onRequestPage,
    loading,
    next_page = null,
    prev_page = null,
  } = props;

  const makeResultItems = () => {
    if(results.length) {
      videos = results.map((r) => {
        const {
          video_yt_id
        } = r;

        return (
          <SearchResultItem
            key={uniqueId(video_yt_id)}
            id={video_yt_id}
            {...r}
            onPlay={onPlayVideo}
          />
        )
      });

    } else {
      videos = (
        <h3
          style={{
            fontStyle: 'italic',
            fontWeight: 400
          }}
        >No results in this channel.</h3>
      )
    }
    return videos;
  }

  return (
    <details
      className="search__results__panel"
      id={channel_ytid}
      open
    >
      <summary>
        <h2>{channel_title || 'All Channels'}</h2>
      </summary>

      <NextPrevious
        next={onRequestPage}
        prev={onRequestPage}
        nextpage={next_page}
        prevpage={prev_page}
        className="search__channel__pagination"
        form="search_form"
        channel={channel_ytid}
      />

      <div className="video__display video__display--grid search__results__results">
        {loading ?
          <LoaderSimple open /> : (
            <div className="List">
              {makeResultItems()}
            </div>
          )
        }
      </div>

    </details>
  )

}

export default SearchResultsPanel;