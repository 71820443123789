/**
 * ChannelGroupDelete.js
 */
import React from 'react';
import './css/ChannelGroupDelete.css';

const ChannelGroupDelete = ({
  onSubmit,
  group,
  channel,
  name
}) => {
  return (
    <form className="badge__delete" onSubmit={onSubmit} key={group}>
      <input type="hidden" name="groupID" value={group} />
      <input type="hidden" name="channelID" value={channel} />
      <span>
        {name}
        <button type="submit" className="badge__delete__btn">
          <b className="form__access__label">Remove channel from {name}</b>
          ✕
        </button>
      </span>
    </form>
  );
}

export default ChannelGroupDelete;