import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectMenuControlled from '../subcomponents/SelectMenuControlled';

import './css/FilterComponent.css';

const FilterComponent = ({
  onFilterChange,
  onFilterClear,
  onSortChange,
  onDirectionChange,
  cssClass,
  sortData,
  sortDefault='video_setscount',
  filterData,
  showHint,
  itemsName,
  searchValue,
  hideCategories,
  filterOn,
  category,
  ascending = true,
  categories = [],
}) => {

  const sortFields = [{
    value:'none',
    name:'Select property to sort by',
    type:'none'
  }].concat(sortData);

  const filterFields = [{
    isDisabled: false,
    value: 'none',
    name:'Select property to filter by',
    type:'none'
  }].concat(filterData)

  const makeMenuItems = (arrayOfItems=[]) => {
    let menu = null;
    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        let disabledOpt;

        disabledOpt = item.hasOwnProperty('isDisabled') ? item.isDisabled : false;
        return <option key={index.toString()} value={`${item.value}`} disabled={disabledOpt}>{item.name}</option>
      });
    }
    return menu;
  }

  const makeSortIndicator = () => {
    const arrow = ascending ? '↑' : '↓';
    const label = ascending ? 'Ascending' : 'Descending';

    return (
      <b className="sort__order__icon" aria-label={label}>{arrow}</b>
    );
  }

  const css = classNames(
    cssClass,
    {'filter__component': true}
  );

  return (
    <div className={css}>
      <form id="filter_videos" method="dialog" onChange={ onFilterChange }>
        <div className="filter__vid">
          <label htmlFor="searchValue">{`Filter ${itemsName}:`}</label>
          <input
            autoFocus
            className="form-control"
            type='text'
            name="searchValue"
            id="searchValue"
            disabled={!hideCategories}
          />
        </div>

        <p hidden={!showHint} id="filter__component__hint">
          For best results, use quotes for filters containing multiple words. <abbr title="example">ex:</abbr> <code><q>room makeover</q></code> instead of <code>room makeover</code>.
        </p>
        <p className="ava-input-select">
          <select name="filterOn" id="filterOn" value={ filterOn } onChange={()=>{}}>
            { makeMenuItems( filterFields )}
          </select>
        </p>

        <div className="filter__categories" hidden={hideCategories}>
          <SelectMenuControlled
            options={categories}
            labelText="Category"
            firstEmpty={false}
            name="category"
            value={category}
            disabled={ !categories.length } />
        </div>

        <p>
        <button
          type="reset"
          className="btn btn-sm btn--action filterBtn"
        >Clear Filter</button></p>
      </form>

      <form id="sort_videos">
        <div className="filter__sort">
          <label htmlFor="sortBy">{`Sort ${itemsName} by Property:`}</label>
          <div className="ava-input-select">
            <select name='sortBy' id='sortBy' onChange={onSortChange}>
              {makeMenuItems(sortFields)}
            </select>
          </div>
        </div>
        <div style={{'display': 'flex', 'alignItems': 'center'}}>
          <button type='button' className='btn btn-sm btn--action filterBtn' onClick={onDirectionChange}>Change Direction</button>
          <span className="sort__order"><span className="form__access__label">Sort Direction is now:</span>  {makeSortIndicator()}</span>
        </div>
      </form>
    </div>
  );
}

export default FilterComponent;