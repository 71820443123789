import React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';

import YTPlayIcon from './YTPlayIcon';
import { BadgeMEOWCRT } from '../Videos/BadgeMEOWCRT';

import {
  truncateString,
  numberFormat,
  formatTime,
  isNumeric
} from '../../js/Utilities';

import { MESSAGE_NOT_SUBMITTED } from '../../js/Configuration';

import '../../css/VideoItem.css';

const VideoItem = ({
  videoData = {},
  cssClass='',
  onPlayVideo=null,
}) => {
  if( !videoData ) return null;

  const {
    category,
    channel_title,
    is_interstitial,
    meow,
    rating,
    video_crt,
    video_dateadded,
    videoext_duration,
    videoext_publishdatetime,
    video_setcount,
    videoext_thumbnail,
    video_title,
    videoext_viewcount,
    video_youtubeID,
  } = videoData;

  const playVideo = () => {
    if(onPlayVideo){
      onPlayVideo(video_youtubeID);
    }
  }

  const makeBadge = (obj = {}, isMeow = false) => {
    return (
      <BadgeMEOWCRT {...obj} mode={ isMeow ? 'meow' : 'crt' } />
    )
  }


  const cssClasses = classNames({
    'videoListItem': true,
    'results__list__item': true,
    'results__list__item--interstitial': is_interstitial
  }, cssClass);

  const channelTitle = channel_title ? truncateString(channel_title, 20) : "No Channel Info";
  const videoRating = rating ? rating.label : "⸻";
  const ratingCSS = rating ? '' : '__unrated';

  return (
    <div className={cssClasses}>
      <h5 onClick={playVideo} className="results__list__item__title videoResultItemHeader divTransition">{video_title}</h5>

      <button onClick={playVideo}  className="videoThumb video__item__thumb">
        <img lazy="true" width="480" height="360" src={videoext_thumbnail} alt={`Watch ${video_title}`} />
        <YTPlayIcon />
      </button>

      <ul className="video__item__metadata">
        <li><b className="listText">Channel Title:</b> <span className="itemsBadge">{channelTitle}</span></li>
        <li><b className="listText">Video Publish Date:</b><span className="itemsBadge">{ format( videoext_publishdatetime * 1000, 'PP' )  }</span></li>
        <li><b className="listText">Added to AVA:</b><span className="itemsBadge">{ format( video_dateadded * 1000, 'PP' )  }</span></li>
        <li><b className="listText">Duration:</b><span className="itemsBadge">{ formatTime(videoext_duration) }</span></li>
        <li><b className="listText">Views:</b><span className="itemsBadge">{ numberFormat( videoext_viewcount )}</span></li>
        <li><b className="listText"><abbr title="number">No.</abbr> of Video Sets:</b><span className="itemsBadge">{ video_setcount }</span></li>
        <li><b className="listText">Video Rating:</b><span className={`itemsBadge video__item__rating${ratingCSS}`}>{ videoRating }</span></li>
        <li><abbr className="listText">MEOW:</abbr> <span>{makeBadge(meow, true)}</span></li>
        <li><abbr title="Content Review Team" className="listText">CRT:</abbr> <span>{makeBadge(video_crt)}</span></li>
        <li><b className="listText">Category:</b> <span>{category}</span></li>
      </ul>
    </div>
  );
};

export default VideoItem;
