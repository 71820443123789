/**
 * AmzClusters.jsx
 */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';

import LoaderSimple from '../subcomponents/LoaderSimple';
import PaginationBar from '../subcomponents/PaginationBar';

import AmzClustersSearch from './AmzClustersSearch';
import { AmzClustersAddNew } from './AmzClustersAddNew';
import AmzGenreRemove from './AmzGenreRemove';
import ToggleSwitch from '../subcomponents/ToggleSwitch';
import AmzGenreImport from './AmzGenreImport';

import {
  API_CLUSTERS_GENRES,
  API_CLUSTERS_SINGLEGENRE,
  API_CLUSTERS_GENRESIZES,
  API_CLUSTERS_GENREADD,
  MESSAGE_ERROR_GENERAL,
  API_GENRES_EXPORTCSV,
  API_IMPORT_EMAIL,
  API_CLUSTERS_GENREIMPORT,
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  pushDownload,
} from '../../js/Utilities';

import './css/AmzClusters.css';
import './css/AmzGenres.css';

import {
  filterGenres,
  setStateOnAddNewChange,
  setStateOnAddNewChange,
  setStateOnAddNewSubmitResponse,
  setStateAfterGenreRemoval,
  genreRemove
} from '../../actions/AmzUtils';

const AmzClusters = () => {
  const initState = {
    loading: true,
    genres: [],
    filtered: [],
    meta: {},
    pagination: {
      perPage: 15,
      page: 1,
      pages: 1,
      ascending: 1,
    },
    search: {
      value: '',
    },
    addcluster: {
      open: false,
      menu: [],
      size: 0,
      error: false,
      message: '',
    },
  };
  const [state, setState] = useState({ ...initState })

  const params = useParams();
  const addNewCluster = useRef();

  useEffect(() => {
    if (!Object.hasOwn(state.meta).length) {
      getClusterTitle();
    }

    /**
     * If state.genres is a strict false value, assume that we haven't requested
     * the genres yet and do that now.
     */
    if (!state.genres.length) {
      getGenres();
    }
  });


  const fd = new FormData();

  const resetFormKeys = () => {
    const keys = Array.from(fd.keys()).filter((f) => f !== 'token');
    keys.forEach((k) => fd.delete(k));
  }

  const getGenres = () => {
    resetFormKeys();
    fd.set('token', getAuthData('token'));
    fd.set('perPage', state.pagination.perPage);
    fd.set('page', state.pagination.page);
    fd.set('parent', params.id);
    fd.set('ascending', state.pagination.ascending);
    fd.set('keyword', state.search.value);

    apirequest(API_CLUSTERS_GENRES, { body: fd }, (response) => {
      let update = [false];
      if(response.results.length) {
        update = [...response.results];
      }

      setState({
        ...state,
        loading: false,
        genres: update,
        pagination: {
          ...state.pagination,
          ...response.paginaiton
        }
      });
    });
  }

  const getClusterTitle = () => {
    if (state.meta.name) return;
    resetFormKeys();
    fd.set('token', getAuthData('token'));
    fd.set('id', params.id);

    apirequest(API_CLUSTERS_SINGLEGENRE, { body: fd }, (response) => {
      setState({
        ...state,
        meta: {
          ...state.meta,
          ...response,
        },
      });
    });
  }

  const getPage = (domEvent) => {
    let pg = 1;

    if (domEvent && Object.hasOwn(domEvent, 'target')) {
      const { value } = domEvent.target;

      switch (value) {
        case 'first':
          pg = 1;
          break;
        case 'last':
          pg = +state.pagination.pages;
          break;
        case 'next':
          pg = +state.pagination.page + 1;
          break;
        case 'previous':
          pg = +state.pagination.page - 1;
          break;
        case (value > state.pagination.pages):
          pg = +state.pagination.page;
          break;
        default:
          pg = +value;
      }
    }

    setState({
      ...state,
      pagination: {
        ...state.pagination,
        page: pg
      },
      genres: []
    })
  }

  const getSizesOnAddNewResponse = (response) => {
    setState(() => setStateOnAddNew(state, response));
  }

  const getSizesOnAddNew = (formObj) => {
    if (!state.addcluster.menu.length) {
      apirequest(API_CLUSTERS_GENRESIZES, { body: formObj }, getSizesOnAddNewResponse);
    } else {
      setState({
        ...state,
        addcluster: {
          ...state.addcluster,
          open: true,
        },
      });
    }
  }

  const onSearchChange = (domEvent) => {
    const filtered = filterGenres(
      domEvent.target.value,
      state.genres,
    );

    setState({
      ...state,
      filtered,
    });
  }

  const onSearchReset = () => {
    setState({
      ...state,
      filtered: [],
      search: {
        ...state.search,
        value: '',
      },
    });
  }

  const onSortChange = (domEvent) => {
    domEvent.preventDefault();
    const { value } = domEvent.target;

    setState({
      ...state,
      pagination: {
        ...state.pagination,
        ascending: +(!state.pagination.ascending)
      },
      genres: []
    });
  }

  const onAddNew = (domEvent) => {
    domEvent.preventDefault();
    resetFormKeys();
    fd.set('token', getAuthData('token'));
    getSizesOnAddNew(fd);

    if (Object.hasOwn(addNewCluster, 'current') && addNewCluster.current) {
      addNewCluster.current.showModal();
    }
  }

  const onAddNewClose = (ref = null) => {
    if (Object.hasOwn(ref, 'current') && ref.current) {
      ref.current.close();
    }
  }

  const onAddNewChange = (domEvent) => {
    const field = domEvent.target.name;
    const { value } = domEvent.target;
    setState(() => setStateOnAddNewChange(state, field, value))
  }



  const onAddNewSubmit = (domEvent) => {
    domEvent.preventDefault()
    const submission = new FormData(domEvent.target);
    submission.set('token', getAuthData('token'));

    apirequest(API_CLUSTERS_GENREADD, { body: submission }, (response) => {
      const cb = () => setStateOnAddNewSubmitResponse(state, response);
      setState(cb);
      getGenres()
      onAddNewClose(addNewCluster)
    });
  }

  const afterGenreRemove = (response) => {
    if (!response.success) {
      setState({ ...state, loading: false });
      // eslint-disable-next-line no-alert
      alert(MESSAGE_ERROR_GENERAL);
    } else {
      setState(() => setStateAfterGenreRemoval(state, response))
    }
  }

  const onGenreRemove = (domEvent) => {
    domEvent.preventDefault();

    const form = domEvent.target;
    setState({ ...state, loading: true });
    genreRemove(form, afterGenreRemove)
  }

  const exportGenreCsv = () => {
    const exportRequest = new FormData();
    exportRequest.set('id', state.meta.id);
    exportRequest.set('token', getAuthData('token'));

    apirequest(API_GENRES_EXPORTCSV, { body: exportRequest }, (response) => {
      const fixSingleQuote = response.map((row) => row.map((r) => r.replace("\\'", "'")));

      const fNameDate = Intl.DateTimeFormat('en-US').format(Date.now());
      pushDownload(fixSingleQuote, state.meta.name.replace(/\W/g, '_'), fNameDate.replace(/\//g, '-'));
    });
  }

  const onImportSubmit = () => {
    setState({
      ...state,
      genres: []
    })
  }

  const makeListItem = (l) => {
    let link = null;
    if (l) {
      link = (
        <li key={`cluster_${uniqueId()}`}>
          <Link
            to={`/music/songs/${l.id}`}
          >{l.name}</Link>
          <AmzGenreRemove id={l.id} onSubmit={onGenreRemove} />
        </li>
      );
    }
    return link;
  }

  const makeLinks = () => {
    let body = <LoaderSimple open />

    if (
      state.loading === false &&
      !state.genres[0]
    ) {
      return (
        <div>
          <h2>This genre doesn't have any clusters</h2>
          <p><Link to="/music/">Back to Genres</Link></p>
        </div>
      );
    }

    const genres = (state.search.value || state.filtered.length) ? state.filtered : state.genres;

    if (state.loading === false && Array.isArray(genres) && genres.length) {
      const links = genres.map(makeListItem);
      body = (
        <div>
          <div className="l__flex__sb l-shelf-flex50">
            <ToggleSwitch
              label="Sort alphabetically: "
              selectedIndex={+(!state.pagination.ascending)}
              options={['A - Z', 'Z - A']}
              disabled={!Array.isArray(state.genres)}
              onChange={onSortChange}
              name={`AmzClustersSort_${params.id}`}
            />
            <PaginationBar {...state.pagination} onClickHandler={getPage} />
          </div>
          <AmzClustersSearch {...state.search} onChange={onSearchChange} onReset={onSearchReset} />
          <ul className="genres__list">
            {links}
          </ul>
          <PaginationBar {...state.pagination} onClickHandler={getPage} />
        </div>
      );
    }
    return body;
  }

  let body = <LoaderSimple open />

  body = (
    <article id="AmzGenres">
      <header>
        <div className="l-shelf-flex50" style={{ justifyContent: 'space-between' }}>
          <span className="button__group">
            <Link disabled={!state.genres.length} to={`/music/reports/songs/${state.meta.id}`} className="btn btn--action">Duplicate Songs Report</Link>
            <Link disabled={!state.genres.length} to={`/music/reports/artists/${state.meta.id}`} className="btn btn--action" style={{ marginRight: '.5rem' }}>Duplicate Artists Report</Link>
            <button disabled={!state.genres.length} className="btn btn--action" type="button" style={{ margin: '0' }} onClick={exportGenreCsv}>Export CSV</button>
          </span>

          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <AmzClustersAddNew
              ref={addNewCluster}
              onOpen={onAddNew}
              onSubmit={onAddNewSubmit}
              onClose={onAddNewClose}
              onChange={onAddNewChange}
              cluster={{ ...state.meta }}
              {...state.addcluster}
            />
            <AmzGenreImport
              id="AmzGenreImport"
              {...state.import}
              parent={params.id}
              onClose={onImportSubmit}
            />
          </div>
        </div>

        <h1>
          Clusters in
          {' '}
          <q>{state.meta.name}</q>
        </h1>
        <p>{state.meta.meta}</p>
      </header>
      {makeLinks()}
    </article>
  )

  return body;
}

export default AmzClusters;
