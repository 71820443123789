import React from 'react';
import { Navigate } from 'react-router-dom';
import Header from './Header';
import YouTubeHeader from './subcomponents/YouTubeHeader';
import AmzHeader from './subcomponents/AmzHeader';
import UserAdminHeader from './subcomponents/UserAdminHeader';
import UpdateAccountHeader from './subcomponents/UpdateAccountHeader';
import { NoProjectHeader } from './subcomponents/NoProjectHeader';

import {
  getAuthData
} from '../js/Utilities';

import {
  setStateProjectMenuToggle
} from '../actions/ProjectUtils';

const Projects = {
  'd157f6f8-43b7-11ea-b50b-1902bdae': YouTubeHeader,
  'ea9298b7-6ddd-11ea-b7f4-008cfa070708': UserAdminHeader,
  'dec4be2a-43b7-11ea-b50b-1902bdae': AmzHeader,
  '999999': UpdateAccountHeader,
  '000000': NoProjectHeader
}

const ProjectWrapper = (projectId, WrappedComponent, props) => {
  return class extends React.Component {
     constructor(props) {
        super(props);
        this.state = {
          menu: {
            open: false
          }
        }
        
      this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(domEvent) {
      this.setState(setStateProjectMenuToggle);
    }
    
    render() {
      const ProjectHeader = Projects[projectId];
      
      let body;
      const user = getAuthData();
      const requestedURL = window.location;
      let destination = {
        pathname: '/',
      };
      
      if(user && user.hasOwnProperty('token')) {
        body = (
          <div>
            <Header {...getAuthData()} />
            <ProjectHeader onSwitch={this.toggleMenu} canswitch={getAuthData('permissions').length} menuopen={this.state.menu.open} />
            <WrappedComponent project={projectId} {...this.state} {...this.props} />
          </div>
        );
      // If the user is not authenticated...
      } else {
        if(requestedURL.pathname !== '/') {
          destination.search = `?redirect=${requestedURL.pathname}`
        }
        body = <Navigate to={destination} />
      }
      return body;
    }
  }
}

export default ProjectWrapper;

