/**
 * DestinationVideoSets.js
 * View of videosets for an individual destination
 */
import {
  useState,
  useEffect,
  useRef
} from 'react';
import { format } from 'date-fns';
import { Link, useParams } from 'react-router-dom';

import ActionMessageDisplay from '../subcomponents/ActionMessageDisplay';
import Breadcrumb from '../subcomponents/Breadcrumb';
import LoaderSimple from '../subcomponents/LoaderSimple';
import CreateVideoSet from './CreateVideoSet';
import EditVideoSet from './EditVideoSet';
import PaginationBar from '../subcomponents/PaginationBar';
import SelectPerPage from '../subcomponents/SelectPerPage';
import { DestinationsVideoSetsToggleSort } from './DestinationsVideoSetsToggleSort';
import { DestinationsIncludeInactive } from './DestinationsIncludeInactive';
import { DestinationsRestoreSet } from './DestinationsRestoreSet';

import {
  INTERSTITIAL_NOTE,
  API_VIDEOSET_ADDNEW,
  API_VIDEOSET_ASSIGNMENTS,
  API_VIDEOSET_RENAME,
  API_DESTINATIONS_INFO,
  MESSAGE_DESTINATION_NOVIDEOSETS,
  MESSAGE_DESTINATIONS_ADDVIDEOSET,
  NULL_DATE_TEXT_STATIONS
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  formatString,
  getDuration
} from '../../js/Utilities';

import {
  duplicatePlaylist,
  setStateOnEditVidsetClose,
  restoreVideoSet,
  deleteVideoSet
} from '../../actions/DestinationsUtils';

import {
  initPaginationState
} from '../../actions/DestinationVideoSetsUtils';

import {
  setPageState
} from '../../actions/PaginationBarUtils';

import './css/DestinationVideoSets.css';

const DestinationVideoSets = ({params, history}) => {

  const [pagination, setPagination] = useState(initPaginationState);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [create, setCreate] = useState({
    open: false,
    value: '',
    destination: {
      dName: '',
      dID: ''
    }
  });
  const [postCreate, setPostCreate] = useState({
    hidden: true,
    message: '',
    mode: 'failed'
  });
  const [destinations, setDestinations] = useState({
    setList: [],
    withinactives: false
  });
  const [edit, setEdit] = useState({
    item: '',
    value: ''
  });
  
  const fetchcancelqueue = [];
  const createVSRef = useRef(null);
  const editModalRef = useRef(null);
  
  useEffect(() => {
  
    if(!destinations.setList.length) {
      getDestinationInfo();
    }

    return () => {
      fetchcancelqueue.forEach((ac) => {
        ac.abort();
      })
    }
  }, [destinations]);

  const findCurrent = (id) => {
    return destinations.setList.findIndex((item) => item.setID == id);
  }

  const getDestinationInfo = () => {
    const fd = new FormData();
    fd.set('id', params.id);
    fd.set('token', getAuthData('token'));

    if(pagination.perPage) {
      fd.set('perPage', pagination.perPage);
    }

    if(pagination.page) {
      fd.set('page', pagination.page);
    }

    fd.set('inactives', +destinations.withinactives);
    fd.set('sort', _getSortValue(+pagination.sort));
    fd.set('ascending', pagination.ascending);

    apirequest(API_DESTINATIONS_INFO, {body: fd}, (response) => {
      setDestinations({
        ...destinations,
        ...response
      });

      setLoading(false);

      setPagination({
        ...pagination,
        ...response.sets
      });
    });
  }

  const getRunDateForSet = (videosetID, callback = null) => {
    if(!videosetID) {
      throw new Error('No video set ID was passed.');
    }

    // Check if we've already fetched a list of blocks. If so, just show what we have.
    const videoSet = {...destinations}.setList.find((vs) => vs.setID == videosetID);
    if(videoSet.hasOwnProperty('blockList')) {
      callback([...videoSet.blockList]);
      return;
    }
    fetchcancelqueue[videosetID] = new AbortController();
    const signal = fetchcancelqueue[videosetID].signal;

    const fd = new FormData();
    fd.set('id', videosetID);
    fd.set('token', getAuthData('token'));
    apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd, signal}, callback);
  }

  const onCloseMessage = (domEvent) => {
    setPostCreate({
      hidden: true,
      message: '',
      mode: 'failed'
    });
  }

  const onTogglePanel = (domEvent) => {
    const {id} = domEvent.currentTarget.dataset;
    
    // Finds the index of the toggled-open panel.
    const index = findCurrent(id);
    
    const {setList} = destinations;
    const sl = [...setList];


    getRunDateForSet(id, (response) => {
      // console.log(response)
      
      sl[index] = {
        ...sl[index],
        loading: false,
        blockList: response
      }

      setDestinations({
        ...destinations,
        setList: sl
      })    
    });
  }

  const onInactivesChange = (domEvent) => {
    const {checked} = domEvent.target;

    setDestinations({
      ...destinations,
      withinactives: +checked,
      setList: [],
    });
  }

  const makeASet = (videoset) => {
    let plural = videoset.count > 1 ? 's' : '';
    let body = null;
    let action;

    if(videoset.hasOwnProperty('blockList') && !videoset.loading) {
      const dates = videoset.blockList.map(makeASetDates);
      body = <ul className="videosets__list">{dates}</ul>
    } else {
      body = <LoaderSimple open={true} className="videoset__rundates__loader" />
    }

    let vs;
    // videoset.activecomes in as a string. Use + to convert it to an integer.
    if(videoset.hasOwnProperty('active') && +videoset.active) {
      vs = (
        <details key={`vs_${videoset.setID}`} className="videoset__rundates" onClick={onTogglePanel} data-id={videoset.setID}>
          <summary>
            <h4 className="videoset__rundates__title">
              <b>{`${videoset.setName} `}
                <span
                  className="videoset__rundates__count">({videoset.count} playlist{plural})</span>
              </b>
              <button
                type="button"
                className="videoset__edittitle trigger__fauxlink"
                data-videoset={videoset.setID}
                onClick={onEditVidsetOpen}
              >Edit title</button>
              <button
                type="button"
                className="btn btn-sm btn--destructive"
                data-videoset={videoset.setID}
                onClick={onDeleteSet}>Delete video set</button>
            </h4>
          </summary>
          {body}
        </details>
      );
    } else {
      vs = (
        <div key={`vs_${videoset.setID}`} className="videoset__rundates">
          <div>
            <b className="videoset__rundates__title">{videoset.setName}</b>
            <DestinationsRestoreSet id={videoset.setID} onSubmit={onRestore} />
          </div>
        </div>
      );
    }

    return vs;
  }

  const makeASetDates = (item) => {
    let datetxt = (!item.vsb_datetimetorun || item.vsb_datetimetorun === '0000-00-00') ? NULL_DATE_TEXT_STATIONS : format(new Date(`${item.vsb_datetimetorun}T00:00:00`), 'PP' );
    return <li key={item.vsb_id}><Link to={`/destinations/playlist/${item.vsb_id}`}>{datetxt}</Link> {getDuration(item.duration)}</li>;
  }

  const makeSets = () => {
    const vsets = Object.values(destinations.setList);
    let rundates;

    if(loading === false && !vsets.length) {
      rundates = <h3>{MESSAGE_DESTINATION_NOVIDEOSETS}</h3>;
    } else {
      rundates = vsets.map(makeASet)
    }
    return rundates;
  }

  const makeBreadcrumbs = () => {
    let crumbs = [{
      path: '/destinations',
      label: 'Destinations',
      state: {}
    }];

    let lastCrumb =  [{label: ''}];

    if(destinations.hasOwnProperty('dName')) {
      lastCrumb = [{
        label: destinations.dName,
        state: {}
      }];
    };

    return (
    <Breadcrumb
      items={crumbs.concat(lastCrumb)}
      ariaLabel="Destinations navigation"
    />);
  }

  const makePagination = () => {
    return (
      <div className="videosets__pagination">
        <SelectPerPage id="VideoSetsPerPage" value={pagination.perPage} onChangeHandler={onPerPageSelect} options={['View All|-1', 15, 30, 50, 75, 100]} replace={true} cssClass="VideoSetsPerPage__select" />
        <PaginationBar {...pagination} onClickHandler={getPage} />
      </div>
    );
  }

  const onPerPageSelect = (domEvent) => {
    const {value} = domEvent.target;
        
    setDestinations({
      ...destinations,
      setList: []
    });

    setPagination({
      ...pagination,
      perPage: +value
    });
  }

  const getPage = (domEvent) => {
    const {value} = domEvent.target;
    
    setDestinations({...destinations, setList: []});
    setPagination(setPageState(pagination, value));
  }
  const onCreateVidsetClose = () => {
    if(createVSRef.current) {
      createVSRef.current.close();
    }
    
    setCreate({
      ...create,
      destination: {
        dID: '',
        dName: ''
      },
      value: ''
    });
  }

  const onCreateVidsetOpen = () => {   
    setCreate({
      ...create,
      destination: {
        ...create.destination,
        dID: params.id,
        dName: destinations.dName
      }
    });

    if(createVSRef.current) {
      createVSRef.current.showModal();
    }
  }

  const onCreateChange = (domEvent) => {
    const {
      id,
      value
    } = domEvent.target;

    setCreate({
      ...create,
      value: value
    });
  }

  const onCreateSave = (domEvent) => {
    domEvent.preventDefault();

    setDestinations({...destinations, setList: []});

    const fd = new FormData(domEvent.target);
    apirequest(
      API_VIDEOSET_ADDNEW,
      {body: fd},
      (response) => { 
        setPostCreate({
          ...postCreate,
          hidden: false,
          message: response.message,
          mode: response.result
        });

        if(createVSRef.current) {
          createVSRef.current.close();
        }
    });
  }

  const onEditVidsetOpen = (domEvent) => {
    const { setList } = destinations;
    const { videoset } = domEvent.target.dataset
    
    const which = setList.find((vs) => videoset == vs.setID);

    setEdit({
      ...edit,
      item: videoset,
      value: which.setName
    })  

    if(editModalRef.current) {
      editModalRef.current.showModal();
    }
  }

  const onEditChange = (domEvent) => {
    const {
      value
    } = domEvent.target;

    setEdit({
      ...edit,
      value: value
    });
  }

  const onEditVidsetClose = (dialogRef=null) => {
    setEdit({
      item: '',
      value: ''
    });

    if(editModalRef.current) {
      editModalRef.current.showModal();
    }
  }

  const onEditVidsetSave = (domEvent) => {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    fd.set('title', edit.value);
    apirequest(API_VIDEOSET_RENAME, {body: fd}, (response) => {
      setPostCreate({
        ...postCreate,
        hidden: false,
        message: response.message,
        mode: response.result === 'fail' ? 'failed' : response.result
      });
      
      setEdit({item: '', value: ''});
      setDestinations({...destinations, setList: []});

      if(editModalRef.current) {
        editModalRef.current.close();
      }
    });
  }

  const _updateTitle = (id) => {
    const changed = findCurrent(id);
    const setlist = [...destinations.setList];
    setlist[changed].setName = edit.value;
    return setlist;
  }

  const duplicate = (id) => {
    duplicatePlaylist(id, (data) => {
      history.push(`/destinations/playlist/${data}`, {is_dupe: true});
    });
  }

  const onSortChange = (domEvent) => {
    const {value} = domEvent.target;
    setDestinations({...destinations, setList: []});
    setPagination({
      ...pagination,
      sort: +value,
      // Value == 0 will be true/false. + converts it to a boolean
      ascending: +(value == 0)
    });
  }

  const _getSortValue = (index) => {
    const options = ['alpha', 'alpha', 'newest'];
    return options[index];
  }

  const onDeleteSet = (domEvent) => {
    domEvent.preventDefault();
    const setId = domEvent.target.dataset.videoset;
    const title = destinations.setList[findCurrent(setId)].setName;
    const confirm = window.confirm(`Are you sure that you want to delete “${title}”?`);

    if(confirm) {
      deleteVideoSet(setId, getDestinationInfo);
    }
  }

  const onRestore = (domEvent) => {
    domEvent.preventDefault();
    const setId = domEvent.target.elements.id.value;
    restoreVideoSet(setId, getDestinationInfo);
  }

  let body;

  if(loading) {
    body = <LoaderSimple open={loading} />
  }  else {
    body = (
      <article className="stations__view">
        {makeBreadcrumbs()}
        <header id="destination__header">
          <h1>Video Sets for <q>{destinations.dName}</q></h1>
          <button
            type="button"
            className="btn btn--action"
            onClick={onCreateVidsetOpen}
          >Create New Video Set</button>
        </header>
        <p className="destinations__component__note">{INTERSTITIAL_NOTE}</p>
        <ActionMessageDisplay
          {...postCreate}
          onClose={onCloseMessage}
          className="destinations__videosets__msg"
        />
        <div className='l-shelf-flex50'>
          <DestinationsVideoSetsToggleSort
            onChange={onSortChange}
            selectedIndex={pagination.sort}
            disabled={!destinations.setList.length}
          />
          <DestinationsIncludeInactive onChange={onInactivesChange} />
        </div>
        {makePagination()}
        {makeSets()}
        <CreateVideoSet
          {...create}
          modalRef={createVSRef}
          onClose={onCreateVidsetClose}
          onSubmit={onCreateSave}
          onChange={onCreateChange}
        />
        <EditVideoSet
          {...edit}
          modalRef={editModalRef}
          onClose={onEditVidsetClose}
          onSubmit={onEditVidsetSave}
          onChange={onEditChange}
        />
        {makePagination()}
      </article>
    )
  }
  return body;
}

export default (props) => (
    <DestinationVideoSets
        {...props}
        params={useParams()}
    />
);