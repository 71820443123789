/**
 * VideoList
 * Displays a list of available videos to add.
 */

import React, { useState, useEffect } from 'react';
import { CloseButton } from './subcomponents/CloseButton';

import VideoListTable from './subcomponents/VideoListTable';
import LoaderSimple from './subcomponents/LoaderSimple';
import Pagination from './subcomponents/Pagination';
import SelectPerPage from './subcomponents/SelectPerPage';
import FilterComponent from './Videos/FilterComponent';
import ModalDialog from './subcomponents/ModalDialog';

import {
  apirequest,
  getAuthData,
} from '../js/Utilities';

import {
  API_VIDEO_ADDBATCH,
  API_VIDEO_GETBATCH,
  API_VIDEOSET_REINIT,
  DESTINATIONS_VIDEOS_PER_PAGE,
} from '../js/Configuration';

import {
  normalizeVids,
} from '../actions/DestinationsPlaylistUtils';


const VideosList = ({
  blockId,
  currentSet,
  modalRef,
  selected,
  onSubmit
}) => {


  const [perPage, setPerPage] = useState(DESTINATIONS_VIDEOS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState('video_addeddate');
  const [keyword, setKeyword] = useState('');
  const [filterOn, setFilterOn] =  useState('');
  const [ascending, setAscending] =  useState(true);
  const [list, setList] =  useState([]);
  const [numResults, setNumResults] =  useState(0);
  const [loading, setLoading] =  useState(true);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [prepend, setPrepend] = useState(false);

  const [tableHeaders, setTableHeaders] = useState([
    { name: '', sortable: false },
    { name: 'Occurrences', sortable: false },
    { name: 'Video Name', sortable: false },
    { name: 'Channel Name', sortable: false },
    { name: 'Duration', sortable: false },
    { name: 'Video Date', sortable: false },
  ]);

  const getVideoListBatchSortDir = () => {
    const direction = ['descending', 'ascending'];
    const dirKey = +ascending;
    return direction[dirKey];
  }

  useEffect(() => {
    if (!list.length) {
      requestVideoListBatch();
    }
  }, [list]);

  const requestVideoListBatch = () => {
    const fd = new FormData();
    fd.set('perpage', perPage);
    fd.set('page', currentPage);
    fd.set('sort_by', sortBy);
    fd.set('filter_by', keyword);
    fd.set('filter_on', filterOn);
    fd.set('order', getVideoListBatchSortDir());
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_GETBATCH, { body: fd }, (response) => {
      const {
        videos,
        current_page,
        pages,
        num_results
      } = response;

      if(videos.length) {
        setList(videos.map((v) => { v.checked = false; return v; }));
        setCurrentPage(current_page);
        setTotalPages(pages);
        setNumResults(num_results);
        setLoading(false);
      }
    });
  }

  const onPaginationClick = (domEvent) => {
    const {value} = domEvent.target;
    setCurrentPage(currentPage + (+value));
    setLoading(true);
    setList([]);
  }

  const onPerPageChange = (domEvent) => {
    domEvent.persist();
    const {value} = domEvent.target;
    setPerPage(+value);
    setList([]);
  }

  const onSearchFilterChange = (domEvent) => {
    const {form} = domEvent.target;

    setList([]);
    setKeyword(form.searchValue.value);
    setFilterOn(form.filterOn.value.split('|')[0]);
    setCurrentPage(1);
    setTotalPages(1);
  }

  const onCheckBoxChange = (domEvent) => {
    const {target} = domEvent;
    const {checked, value} = target;

    setList((prev) => {
      const updates = [...prev];
      const index = updates.findIndex((video) => (video.video_youtubeID === value));
      updates[index].checked = checked;
      return updates;
    });

    if(checked) {
      setSelectedVideos((prev) => {
        return [...prev, value];
      });
    } else {

      setSelectedVideos((prev) => {
        return [...prev].filter((f) => f !== value);
      });
    }
  }

  const oOnSubmit = (domEvent) => {
    domEvent.preventDefault();

    if(!selectedVideos.length) {
      alert('Nothing to submit!');
      return;
    }

    const fd = new FormData();
    fd.set('blockid', blockId);
    fd.set('userID', getAuthData('uuid'));
    fd.set('token', getAuthData('token'));
    fd.set('prepend', 0);
    fd.set('videoids', selectedVideos.join(','));

    apirequest(API_VIDEO_ADDBATCH, { body: fd }, (response) => {
      if(response.error) {
        alert(response.message);
        return;
      }

      const { returnData } = response.videoList;
      if(modalRef.current) {
        modalRef.current.close();
      }

      setSelectedVideos([]);
      setList([]);

      /* Resets the parent component's video list. */
      if(onSubmit) {
        onSubmit();
      }
    });
  }

  const isSelected = (id) => {
    const videos = Array.isArray(selected) ? selected : [];
    const selectedVideo = videos.find((sv) => sv.video_youtubeID === id)
    return !!selectedVideo;
  }

  const makeTableData = () => {
    let data = [];

    if (list.length) {
      data = list.map((item, index) => ({
        video_id: item.video_id,
        video_title: item.video_title,
        video_channel: item.channel_title,
        video_duration: +item.videoext_duration,
        video_pubdate: item.videoext_publishdatetime,
        video_youtubeID: item.video_youtubeID,
        occurrences: currentSet.filter((v) => item.video_youtubeID === v.ytID).length,
        checked: item.checked,
      }));
    }
    return data;
  }

  const getModalBody = () => {
    let body = <LoaderSimple open cssClass="destinations__video__list__loader" />;
    if(!loading) {
      body = (
        <VideoListTable
          headers={tableHeaders}
          rows={makeTableData()}
          currentSet={currentSet}
          cssClass="video__list__table"
          onCheckBoxChange={onCheckBoxChange}
        />
      );
    }
    return body;
  }


  const makeVideoSearch = () => {
    return (
      <FilterComponent
        ascending={ascending}
        cssClass="reports__vidset__search"
        itemsName="Videos"
        filterOn="video_title"
        filterData={[
          { value: 'video_title', name: 'Video Title', type: 'string' },
          { value: 'video_channel_title', name: 'Channel Name', type: 'string' },
          { value: 'video_addedbyname', name: 'User name', type: 'string' },
        ]}
        sortData={[
          { value: 'video_title', name: 'Video Title', type: 'string' },
          { value: 'video_updateddate', name: 'Video Last Updated', type: 'num' },
          { value: 'video_addeddate', name: 'Date Video Added to System', type: 'num' },
          { value: 'video_channel_title', name: 'Channel Name', type: 'string' },
          { value: 'video_duration_seconds', name: 'Video Duration', type: 'num' },
          { value: 'video_viewcount', name: 'Video View Count', type: 'num' },
          { value: 'video_likecount', name: 'Video Likes Count', type: 'string' },
          { value: 'video_setscount', name: 'Number of Video Sets', type: 'num' },
          { value: 'video_publishdate', name: 'Publish Date', type: 'num' },
          { value: 'video_channel_publishdate', name: 'Channel Date', type: 'date' },
          { value: 'video_rating', name: 'Rating', type: 'string' },
          { value: 'meow', name: 'MEOW Status' },
        ]}
        onSortChange={onSortChange}
        onFilterChange={onSearchFilterChange}
        onFilterClear={onFilterClear}
        onDirectionChange={onDirectionChange}
        hideCategories
      />
    );
  }

  const onDirectionChange = (domEvent) => {
    setAscending( !ascending );
    setList([]);
  }

  const onSortChange = (domEvent) => {
    const {value} = domEvent.target;
    setSortBy(value);
    setList([]);
  }

  const onFilterClear = (domEvent) => {
    setPerPage(DESTINATIONS_VIDEOS_PER_PAGE);
    setCurrentPage(1);
    setTotalPages(1);
    setSortBy('video_addeddate');
    setKeyword('');
    setFilterOn('');
    setAscending(true);
    setList([]);
    setNumResults(0);
    setLoading(true);
    setSelectedVideos([]);
    setSelectAll(false);
  }

  const onClose = () => {
    if(modalRef.current) {
      modalRef.current.close();
    }
  }

  return (
    <ModalDialog ref={modalRef} id="VideosListModal" className="video__list__modal" onClose={onClose}>
      <h2>Select Videos</h2>
      {makeVideoSearch()}
      <form action={API_VIDEOSET_REINIT} method="post" onSubmit={oOnSubmit} id="add_to_videoset">
        <div className="table__list__controls">
          <SelectPerPage
            name="video_list_pages"
            cssClass="table__list__perpage"
            value={perPage}
            onChangeHandler={onPerPageChange}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onClickHandler={onPaginationClick}
            disablePrevious={currentPage <= 1}
            disableNext={currentPage >= totalPages}
          />
        </div>
        { getModalBody() }
        <div className="table__list__controls">
          {'  '}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onClickHandler={onPaginationClick}
            disablePrevious={currentPage <= 1}
            disableNext={currentPage >= totalPages}
          />
        </div>
      </form>
      <div className="vlm__controls">
        <button
          type="submit"
          form="add_to_videoset"
          className="btn btn--action"
        >Submit</button>
      </div>
    </ModalDialog>
  );

}

export default VideosList;