import {
  apirequest,
  getAuthData,
  hideLoader,
  setAuthData,
  showLoader,
  removeAuthData,
} from '../js/Utilities';

import {
  API_CHANNEL_BATCH,
  API_GROUPS_ALLGROUPS,
  API_SEARCH,
  API_VIDEO_ADDNEW,
  API_LANGUAGES,
  API_REGIONS,
  VIDEOS_PER_PAGE,
  MESSAGE_GENERIC_ERROR,
  MESSAGE_VIDEO_ADDED_TO_SET
} from '../js/Configuration';

import { REGION_FLAGS } from '../js/RegionFlagsConfiguration';

export const initLanguage = {
  code: 'YTE',
  name: 'All Languages'
}

export const initCriteria = {
  order: 'viewCount',
  channelsearch: [],
  search_group: 0
}

export const initGroups = [{label: 'Select Group', value: '0'}];

export const initChannelsList = {
  channels: [],
  currentPage: 1,
  perPage: 10,
  totalPages: 0,
  sortBy: 'channel_title',
  ascending: true,
  filterKeyword: '',
  filterOn: '',
  loading: false
}

export const getRegions = (callback=()=>{}) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  return apirequest(API_REGIONS, {body: fd}, callback);
}

export const getLanguages = (callback) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  return apirequest(API_LANGUAGES, {body: fd}, callback);
}

export const getGroups = (callback) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  return apirequest(API_GROUPS_ALLGROUPS, {body: fd}, callback);
}

export const setStateAfterGetRegions = (state, response) => {
  const regionShapes = response.map((region) => {
    return {
      label: region.name,
      value: region.code
    };
  });

  return {
    regions: regionShapes
  }
}

export const setStateAfterGetLangs = (state, response) => {
  const langShapes = response.map((language) => {
    return {
      label: language.name,
      value: language.code
    };
  });

  return {
    languages: langShapes
  }
}

export const search = (options, callback) => {
  return apirequest(API_SEARCH, options, callback);
}

export const getChannels = (options, callback) => {
  return apirequest(API_CHANNEL_BATCH, options, callback);
}

export const addVideo = (youtubeID, callback) => {
  const ac = new AbortController();
  const {signal} = ac;
  
  const fd = new FormData();
  fd.set('videoids', youtubeID);
  fd.set('setID', '-1');
  fd.set('blockID', '-1');
  fd.set('uuid', getAuthData('uuid'));
  fd.set('userName', getAuthData('user'));
  fd.set('token', getAuthData('token'));

  const options = {
    method: 'POST',
    body: fd,
    signal
  };

  apirequest(API_VIDEO_ADDNEW, options, callback);
}

export const setStateOnGetGroups = (state, groups) => {
  return {
    groups: {
      ...state.groups,
      data: groups
    }
  }
}

export const setStateGetChannelBatch = (state) => {
  const loading = showLoader();

  return {
    channelslist: {
      ...state.channelslist,
      ...loading,
      open: true,
    }
  }
}

export const setStateAfterChannelBatch = (state, data) => {
  const notloading = hideLoader();

  return {
    channelslist: {
      ...state.channelslist,
      ...notloading,
      channels: data.channels,
      currentPage: data.current_page,
      totalPages: data.pages,
    }
  }
}

export const setStateOnChannelList = (state, value) => {
  return {
    channelslist: {
      ...state.channelslist,
      currentPage: (+state.channelslist.currentPage) + (+value)
    }
  }
}

export const setStateOnChannelSearchSubmit = (state, value) => {
  return {
    channelslist: {
      ...state.channelslist,
      open: false,
      filterKeyword: ''
    }
  }
}

export const setStateOnPerPageChange = (state, value) => {
  return {
    channelslist: {
      ...state.channelslist,
      perPage: value
    }
  }
}

export const setStateOnChanFilterChange = (state, value) => {
  return {
    channelslist: {
      ...state.channelslist,
      filterKeyword: value.toString(),
      filterOn: 'channel_title'
    }
  }
}

export const setStateOnPanelToggle = (state, channel) => {
  return {
    results:{
      ...state.results,

      [channel]: {
        ...state.results[channel],
        open: !state.results[channel].open
      }
    }
  }
}

export const setStateOnHideChannels = (state) => {
  const notloading = hideLoader();

  return {
    ...notloading,
    channelslist: {
      ...state.channelslist,
      channels: [],
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      sortBy: 'channel_title',
      ascending: true,
      filterKeyword: '',
      filterOn: '',
      open: false,
      loading: false
    }
  }
}

export const setStateOnReset = (state) => {
  return {
    channelslist: {
      channels: [],
      currentPage: 1,
      perPage: VIDEOS_PER_PAGE,
      totalPages: 0,
      sortBy: 'channel_title',
      ascending: true,
      filterKeyword: '',
      filterOn: ''
    },
    results: {},
    channelsearch: [],
    criteria: {
      order: 'viewCount'
    }
  }
}

export const setStateOnCloseVideo = (state) => {
  return {
    videoinfo: {
      open: false,
      videoData: {}
    }
  }
}

export const setStateOnSetChannelGroup = (state, value) => {

  const notloading = hideLoader();

  // Returns the group whose channels we want to search.
  const group = state.groups.data.find((item) => item.group_id == value);

  let channels = [];
  if(group && Object.hasOwn(group, 'channelList')) {
    channels = group.channelList;
  }

  return {
    channelsearch: channels,
    ...notloading
  }
}
