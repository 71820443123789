/**
 * SearchText
 * Search form for Top Channels by Destination, Top Videos by Destination reports
 * Author: Webinista, Inc 3/24/18.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SearchText = ({
  apiURL=null,
  cssClass={},
  id=null,
  name=null,
  labelText='NEEDS A LABEL',
  limit=50,
  onInput=null,
  onSubmit=null,
  datalist=[],
  value=''
}) => {

  const makeDataList = (arrayOfOptions) => {
    return arrayOfOptions.map( (item, index) => {
      return <option value={item.title} key={index.toString()} />
    });
  }

  const cssClasses = classNames(
    cssClass,
    {'reports__vidset__search': true}
  );

  let dataList = null;
  let dataListAttr = null;

  if(this.props.datalist) {
    dataListAttr = `${id}_dl`;
    dataList = <datalist id={dataListAttr}>{makeDataList(datalist)}</datalist>;
  }

  return (
    <form id="reports_videoset_search" action={apiURL} method="POST" className={cssClasses} onSubmit={onSubmit}>
      <input type="hidden" id="limit" name="limit" value={limit} />
      <label htmlFor={id}>{labelText}</label>
      <div>
        <input
          type="text"
          id={id || name}
          name={name}
          onChange={onInput}
          list={dataListAttr}
          required={true}
          autoComplete="off"
          defaultValue={value}
        />
          {dataList}
        <button type="submit" className="btn btn--action">Search</button>
      </div>
    </form>
  );
}

SearchText.propTypes = {
  apiURL: PropTypes.string.isRequired,
  id: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  limit: PropTypes.number,
  name: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onInput: PropTypes.func,
  onSubmit: PropTypes.func,
  destinations: PropTypes.arrayOf(PropTypes.shape({
    dID: PropTypes.string,
    dName: PropTypes.string
  })),
  value: PropTypes.string.isRequired,
  datalist: PropTypes.array
};

export default SearchText;
