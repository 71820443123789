/**
 * AddVideosByURL.jsx
 * Displays playlist URL and comments form fields.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CloseButton } from '../subcomponents/CloseButton';

import ModalDialog from '../subcomponents/ModalDialog';
import LoaderSimple from '../subcomponents/LoaderSimple';
import ToggleSwitch from '../subcomponents/ToggleSwitch';

import {
  getAuthData
} from '../../js/Utilities';

import {
  MESSAGE_ERROR_TOO_MANY_URLS
} from '../../js/Configuration';

import './css/AddVideosByURL.css';

const AddVideosByURL = ({
  onChange,
  onClose,
  onSubmit,
  toggle,
  modalRef,
  working,
  block,
  value,
  maxIds,
  onPrependChange,
  cssClass=''
}) => {

  const makeToggle = () => {
    let toggleSwitch = null;
    if(toggle) {
      toggleSwitch = <ToggleSwitch onChange={onPrependChange} label="Add to beginning?" className="addvideos__toggle" options={toggle.options} name="prepend" selectedIndex={+toggle.selected} />;
    }
    return toggleSwitch;
  }

  const makeDialogBody = () => {

    const blockid = (block && Object.hasOwn(block, 'vsb_id')) ? block.vsb_id : '';
    const setid = (block && Object.hasOwn('block', 'vsb_setID')) ? block.vsb_setID : '';

    return (
      <div id="add_videos_by_url">
        <form method="dialog" id="add_videos_by_url_form" onSubmit={onSubmit}>
          <LoaderSimple cssClass="add__by__url__loading" open={working} />
          <input type="hidden" name="setid" id="add_videos_by_url_videoset" value={setid} onChange={()=>{}} />
          <input type="hidden" name="blockid" id="add_videos_by_url_blockid" value={blockid} onChange={()=>{}} />
          <input type="hidden" name="userID" id="add_videos_by_url_userid" value={getAuthData('uid')} onChange={()=>{}} />
          <input type="hidden" name="userUID" id="add_videos_by_url_uid" value={getAuthData('uuid')} onChange={()=>{}} />
          <input type="hidden" name="userName" id="add_videos_by_url_username" value={getAuthData('user')} onChange={()=>{}} />

          <p>
            <label htmlFor="add_videos_by_url_urls">Enter URLs</label> <span className="form__instructions" id="add_videos_by_url_note">(Maximum 50 URLs or YouTube identifiers.)</span><br />
            <textarea
              autoFocus
              className="form-control"
              id="add_videos_by_url_urls"
              aria-describedby="add_videos_by_url_note"
              name="videoids"
              onChange={onChange}
              rows="20"
              required
              disabled={working}
              value={value}
            ></textarea>
            <span className="form__instructions aria__description">Use valid YouTube URLs or video IDs. URLs or IDs should be separated either by a comma (,) or new line. If using URLs, include the protocol for each, e.g.: <code>http://</code> or <code>https://</code>. Limit 50.</span>
          </p>
        </form>

        <div>
          <i><span hidden={!maxIds} className="form__instructions form__error"><b>{MESSAGE_ERROR_TOO_MANY_URLS[0]}</b> {MESSAGE_ERROR_TOO_MANY_URLS[1]}</span></i>
          {makeToggle()}
          <button type="submit" form="add_videos_by_url_form" disabled={maxIds} className="btn btn--action">Add Videos</button>
        </div>
      </div>
    )
  }

  const cssClasses = classNames({
    'add__videos__by__url': true,
  }, cssClass);

  return (
    <ModalDialog ref={modalRef} id="AddVideoByURL" onClose={onClose} className={cssClasses}>
      <h2>Add Videos By URL</h2>
      {makeDialogBody()}
    </ModalDialog>
  );
}

export default AddVideosByURL;