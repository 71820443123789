/*
DestinationsUtils.js

Functions that are shared between multiple views in Destinations and/or Stations
*/

import {
  apirequest,
  asyncapirequest,
  getAuthData,
  getUTCNormalizedDate
} from '../js/Utilities';

import { format } from 'date-fns';

import {
  API_DESTINATIONS_LIST,
  API_DESTINATIONS_ASSIGNMENTS,
  API_DESTINATION_DELETE,
  API_RATINGS_ALL,
  API_SERIES,
  API_VIDEOSET_DUPLICATE,
  API_VIDEOSET_ASSIGNMENTS,
  API_VIDEOSET_DELETE,
  API_VIDEOSET_RESTORE,
  MESSAGE_CLIPBOARD_OK,
  MESSAGE_CLIPBOARD_FAILED,
  MESSAGE_ERROR_GENERAL
} from '../js/Configuration';


export const playAllVideos = (videolist = []) => {
  const videoIds = videolist.map(( video ) => {
    return video.ytID;
  });
  window.open(`https://www.youtube.com/watch_videos?video_ids=${videoIds.join( ',' )}`, '_blank');
}

export const exportVideoData = (videolist = []) => {
  let items = videolist.map(item => item.ytID);

  let textArea = document.createElement( "textarea" );
  textArea.style.position = 'absolute';
  textArea.style.left = '-9999em';
  textArea.style.top = '0px';
  textArea.value = items; 

  document.body.appendChild( textArea );
  textArea.select();

  try {
    let successful = document.execCommand( 'copy' );

    if(successful) {
      window.alert(MESSAGE_CLIPBOARD_OK);
    } else {
      window.alert(MESSAGE_CLIPBOARD_FAILED);
    }
  } catch (err) {
    window.alert(MESSAGE_CLIPBOARD_FAILED);
  }

  document.body.removeChild(textArea);
  textArea = null;
}

export const duplicatePlaylist = (setblockid, callback = null) => {
  const fd = new FormData();
  fd.set('id', +setblockid);
  fd.set('token', getAuthData('token'));
  apirequest(API_VIDEOSET_DUPLICATE, {body: fd}, callback);
}

export const tooManyURLs = (string, max=50) => {
  return string.split(/[,\s]/).length > max;
}

export const getDestinations = (callback = null, withstations = false, inactives = false) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  if(withstations) {
    fd.set('withstations', 1);
  }
  if(inactives) {
    fd.set('inactives', 1);
  }
  asyncapirequest(API_DESTINATIONS_LIST, {body: fd}, callback);
}

export const getRatings = (callback = null) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  apirequest(API_RATINGS_ALL, {body: fd}, callback);
}

export const getVideoSets = (destid, callback = null) => {
  const fd = new FormData();
  fd.set('id', destid);
  fd.set('token', getAuthData('token'));
  apirequest(API_DESTINATIONS_ASSIGNMENTS, {body: fd}, callback);
}

export const getRunDates = (setid, callback = null) => {
  const fd = new FormData();
  fd.set('id', setid);
  fd.set('token', getAuthData('token'));
  apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd}, callback);
}

export const deleteDestination = (destinationId, callback = null) => {
  const fd = new FormData();
  fd.set('id', destinationId);
  fd.set('token', getAuthData('token'));
  apirequest(API_DESTINATION_DELETE, {body: fd}, callback);
}

export const getVideoSetBreadcrumbs = (videoSetId, callback = null) => {
  const fd = new FormData();
  fd.set('id', videoSetId);
  fd.set('token', getAuthData('token'));
  apirequest(API_SERIES, {body: fd}, callback);
}

export const restoreVideoSet = (setId, callback = null) => {
  const fd = new FormData();
  fd.set('id', setId);
  fd.set('token', getAuthData('token'));
  apirequest(API_VIDEOSET_RESTORE, {body: fd}, callback);
}

export const deleteVideoSet = (setId, callback = null) => {
  const fd = new FormData();
  fd.set('id', setId);
  fd.set('token', getAuthData('token'));
  apirequest(API_VIDEOSET_DELETE, {body: fd}, callback);
}

export const findDestinationById = (destinationsList = [], id = null) => {
  if(!id) return false;

  return destinationsList.find((dest) => {
    return +dest.dID === +id;
  });
}
