/**
 * ToolsDurationMode
 * Enables users to update their own account page.
 */

import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { ToolsAddToGoogleSheet } from './ToolsAddToGoogleSheet';
import { ToolsImportAnother } from './ToolsImportAnother';
import { ToolsSpreadsheetUpdated } from './ToolsSpreadsheetUpdated';
import { ToolsSpreadsheetFailed } from './ToolsSpreadsheetFailed';
import { ToolsSheetLoading } from './ToolsSheetLoading';


import {
  API_IMPORT_SPREADSHEET_FINDRANGES,
  MESSAGE_ERROR_GENERAL
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  numberFormat,
  percentage,
  precision
}  from '../../js/Utilities';

import {
  download,
  setSpreadsheetData
}  from '../../actions/ToolsUtils';

// import '../css/ToolsDurationMode.css';
import '../subcomponents/css/fancy-radiobuttons.css';

const ToolsDurationMode = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState({count: 0});
  const [mode, setMode] = React.useState('importing'); // 'importing', 'imported', or 'pushed'
  const [exporting, setExporting] = React.useState({ loading: false });

  const fetchCancel = new AbortController();
  const {params} = props;

  useEffect(() => {
    if(Object.keys(results).length == 1) {
      requestModeRanges();
    }
    return () => fetchCancel.abort()
  }, []);

  const requestModeRanges = () => {
    const signal = fetchCancel.signal;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('url', `https://docs.google.com/spreadsheets/d/${params.spreadsheetID}/`);
    fd.set('sheet', params.sheetName);

    apirequest(API_IMPORT_SPREADSHEET_FINDRANGES, {body: fd, signal}, (response) => {
      if(response.status != 200) { 
        setMode('failed');
        return;
      }

      setResults({...results, ...response.results});
      setMode('imported');
      setLoading(true);
    });
  }

  const prepareData = () => {
    const bands = Object.keys(results)
    .filter(b => b !== 'count')
    .map((b) => [`${b} minutes`, results[b], `${ precision(percentage(+results.count, +results[b]), 2)}%`]);

    let data = [
      [`Statistics for ${params.spreadsheetID} (${params.sheetName})`],
      [''], // empty line
      ['Number of videos', results.count],
      [''], // empty line
      ['Range','Number of videos','Percentage']
    ];
    return data.concat(bands)
  }

  const ddownload = () => {
    const dta = prepareData();
    download(dta, params.spreadsheetID, params.sheetName);
  }

  const makeResponseDisplay = () => {
    const bands = Object.keys(results).filter(key => key !== 'count');
    const results = bands.map((b, ind) => {
      let pctg = percentage(+results.count, +results[b]);
      return <li key={ind}><b>{b} minutes:</b> {results[b]} videos or {precision(pctg, 2)}%</li>;
    });

    return (
      <div className="tools__imported">
        <h1>Video count by duration for {params.sheetName}</h1>

        <ul>
          <li><b>Videos in sheet:</b> {numberFormat(results.count)}</li>
          {results}
        </ul>

        <p className="tools__imported__controls">
          <button type="button" onClick={ddownload} className="btn btn--action">Download Statistics</button>
          <ToolsAddToGoogleSheet onClick={setData} loading={exporting.loading} />
          <ToolsImportAnother />
        </p>
      </div>
    );
  }

  const setData = () => {
    const data = prepareData();

    const cb = (response) => {
      if(response.spreadsheetId) {
        setExporting({loading: false});
        setMode('pushed')
      } else {
        alert(MESSAGE_ERROR_GENERAL);
      }
    };
    setSpreadsheetData(data, cb, params.spreadsheetID, params.sheetName, null, 'durations');
  }

  let body;
  switch(mode) {
    case 'importing':
      body = <ToolsSheetLoading />;
      break;
    case 'failed':
      body = <ToolsSpreadsheetFailed />;
      break;
    case 'imported':
      body = makeResponseDisplay();
      break;
    case 'pushed':
      body = <ToolsSpreadsheetUpdated spreadsheetID={params.spreadsheetID} />
      break;
  }
  return body;
}

export default (props) => (
  <ToolsDurationMode
    {...props}
    params={useParams()}
  />
);
