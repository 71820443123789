/**
 * EditUserModal.jsx
 * Form for adding a new user
 * See PropTypes definitions at the end of this file for parameters
 */

import React from 'react';
import PropTypes from 'prop-types';
import AddUserForm from './AddUserForm';
import ModalDialog from '../../subcomponents/ModalDialog';

const EditUserModal = (props) => {
  return (
    <ModalDialog id="UserAdminEditUser" title="Add Songs" open={props.open} onClose={props.onClose}>
      <AddUserForm
        edit={true}
        cssClass="user__admin__edit__user"
        title={`Editing user: ${props.form.user_name || props.form.username}`}
        onToggle={null}
        {...props} />
    </ModalDialog>
  )
};

EditUserModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onChange: PropTypes.func
};

export default EditUserModal;
