/**
 * ChannelItem.js
 * Displays individual channels. Used on Channels page.
 */

import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import {
  truncateString,
  numberFormat,
  formatDate
} from '../../js/Utilities';

import { getGroupNamesFromData } from '../../actions/ChannelsUtils';

import './css/ChannelItem.css';

const ChannelItem = ({
  channelData
}) => {

  const {
    channel_title,
    channel_youtubechannelid,
    channel_thumbnail,
    channel_dateaddedtosystem,
    channel_datelastupdated,
    channel_dateadded,
    videos_in_ava,
    channel_subscribercount,
    channel_videocount,
    channel_customtags
  } = channelData;
  
  let title = truncateString(channel_title);
  const group_select_id = `${channel_youtubechannelid}_group_select`;

  return (
      <Link to={{ pathname: `/channels/${channel_youtubechannelid}`}} className="channel__list__item results__list__item">
        <h5 className="results__list__item__title videoResultItemHeader divTransition">{title}</h5>
        <img src={ channel_thumbnail} alt={`View ${channel_title}`} className="channel__thumb" />
        <ul>
          <li><span className="listText">Added to System:</span><span className="itemsBadge">{ formatDate(channel_dateaddedtosystem, 'PP') }</span></li>
          <li><span className="listText">Date Updated in System:</span><span className="itemsBadge">{ formatDate(channel_datelastupdated, 'PP') }</span></li>
          <li><span className="listText">Publication Date:</span><span className="itemsBadge">{ formatDate(channel_dateadded, 'PP') }</span></li>
          <li><span className="listText">Videos In AVA:</span><span className="itemsBadge">{numberFormat( videos_in_ava )}</span></li>
          <li><span className="listText">Subscriber Count:</span><span className="itemsBadge">{numberFormat( channel_subscribercount )}</span></li>
          <li><span className="listText">Video Count:</span><span className="itemsBadge">{numberFormat( channel_videocount )}</span></li>
          <li className="nogrid"><span className="listText">Groups:</span><span className="itemsBadge textNormal listItemScrollingTextBox">{ getGroupNamesFromData(channelData) }</span></li>
          <li className="nogrid"><span className="listText">Tags:</span><span className="itemsBadge textNormal listItemScrollingTextBox">{ channel_customtags }</span></li>
        </ul>
    </Link>
  )
};

export default ChannelItem;