/**
 * DestinationsPlaylistRunDateModal.jsx
 * Modal for adding a run date after duplicating a playlist
 */
import React from 'react';

import {
  format,
  isValid
} from 'date-fns';

import {
  NULL_DATE_TEXT_STATIONS,
  INTERSTITIAL_INDICATOR,
  INTERSTITIAL_NOTE
} from '../../js/Configuration';

import ModalDialog from '../subcomponents/ModalDialog';

const DestinationsPlaylistRunDateModal = (props) => {
  const {
    open,
    modalRef,
    rundate,
    onClose,
    onSubmit,
    onChange,
    onDuplicateSkip
  } = props;

  return (
    <ModalDialog ref={modalRef} id="DestinationsPlaylistRunDateModal" onClose={onClose} open={open}>
      <h2>Duplicate this list</h2>
      <form action="api/videosetblockupdate" onSubmit={onSubmit}>
        <h3>Add a run date?</h3>
        <p>
          <label htmlFor="destination_rundate">Set run date: </label>
          <span className="playlist__dupemod_btns">
            <input type="date" id="destination_rundate" name="destination_rundate" value={rundate} onChange={onChange} />
            <button type="submit" className="btn btn--action" disabled={!rundate}>Save</button>
          </span>
        </p>
        <p><button type="button" className="btn btn-light" onClick={onDuplicateSkip}>Skip</button></p>
      </form>
    </ModalDialog>
  )
}

export default DestinationsPlaylistRunDateModal;
