import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/NextPrevious.css';

const NextPrevious = ({
  className,
  next,
  prev,
  prevpage=null,
  nextpage=null,
  per_page,
  result_count,
  form,
  channel = 'All Channels'
}) => {

  const cssClass = classNames(className, {
    'pagination__component': true
  });

  return(
    <div className={cssClass}>
      <button
        disabled={!prevpage}
        type="button"
        className="pagination__btn btn btn-light pagination__previous"
        onClick={prev}
        form={form}
        data-page={prevpage}
        data-channel={channel}
      >Previous</button>
      <button
        disabled={result_count < per_page || !nextpage}
        type="button"
        className="pagination__btn btn btn-light pagination__next"
        onClick={next}
        form={form}
        data-page={nextpage}
        data-channel={channel}
      >Next</button>
    </div>
  );
}

export default NextPrevious;