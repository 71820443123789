/**
 * CheckForUpdatesProgress.js
 * Modal called when there's a request to check for video updates.
 */

import React from 'react';
import PropTypes from 'prop-types';
import LoaderSimple from '../subcomponents/LoaderSimple';
import ModalDialog from '../subcomponents/ModalDialog';

import {
  API_DESTINATIONS_CHECKFORUPDATES_CSV,
  API_DESTINATIONS_CHECKFORUPDATES
} from '../../js/Configuration';

import {
  apirequest,
  csvrequest,
  getAuthData,
  forceDownload,
} from '../../js/Utilities';

import './css/CheckForUpdatesProgress.css';

const CheckForUpdatesProgress = (props) => {
  const {
    loading,
    onClose,
    updates,
    videoset,
    modalRef,
    onServerResponse
  } = props; 
  
  const getUpdatesCSV = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData();
    const vidIds = updates.map((vid) => vid.YouTube_ID).join('|');

    fd.set('videoset_id', videoset);
    fd.set('ids', vidIds);
    fd.set('token', getAuthData('token'));

    csvrequest(API_DESTINATIONS_CHECKFORUPDATES_CSV, {body: fd}, (response) => {
      forceDownload(response);
    });
  }

  const makeList = () => {
    if(loading) {
      return <LoaderSimple open />
    }
    
    if(updates?.length) {
      const items = updates.map((item) => {

        let url;
        if(!item.URL) {
          url = <td className="text__center">–</td>;
        } else {
          url = <td className="text__nowrap"><a href={item.URL} target="_blank" rel="noopener noreferrer">{item.URL}</a></td>
        }

        const deleted = item.deleted ? 'Yes' : '';
        const title = (item.Current_Title === item.Previous_Title) ? '–' : item.Current_Title;

        return (
          <tr key={item.YouTube_ID}>
            {item.Previous_Title ? <td>{item.Previous_Title}</td> : <td>–</td>}
            {item.Current_Title ? <td>{title}</td> : <td>–</td>}
            {url}
            <td>{deleted}</td>
          </tr>
        );
      });

      const ids = updates.map((item) => {
        return item.history_id;
      });

      return (
        <div className="destinations__checkupdated__results">
          <h2>Results</h2>
          <table>
            <thead>
              <tr>
                <th>Previous Title</th>
                <th>Current Title</th>
                <th>Video URL</th>
                <th>Deleted from YouTube</th>
              </tr>
            </thead>
            <tbody>
              {items}
            </tbody>
          </table>
          <form action={API_DESTINATIONS_CHECKFORUPDATES_CSV} method="POST" target="_blank" onSubmit={getUpdatesCSV}>
            <input type="hidden" name="videoset_id" id="videoset_id" value={videoset} onChange={()=>{}} />
            <input type="hidden" name="ids" id="ids" value={ids.join('|')} onChange={()=>{}} />
            <button type="submit" className="btn btn--action">Export updates as CSV</button>
          </form>
        </div>
      )
    } else {
      return <p className="destinations__checkupdated__noupdates">No updates</p>;
    }
  }

  return (
    <ModalDialog
      ref={modalRef}
      onClose={onClose}
      className="CheckForUpdatesProgress"
      id="CheckForUpdatesProgress"
    >
     <h2>Checking for Title Updates</h2>
      {makeList()}
    </ModalDialog>
  )
}

export default CheckForUpdatesProgress;