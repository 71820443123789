/**
 * EpisodeViewTitle.jsx
 * Title and change title component
 */
import React from 'react';
import { NULL_EPISODE_TITLE } from '../../js/Configuration';

import './css/EpisodeViewTitle.css';

const EpisodeViewTitle = ({
  onEpisodeDelete,
  onEpisodeEdit,
  onEpisodeMove,
  title=NULL_EPISODE_TITLE,
  isAdmin=false
}) => {
  let del = null;

  if(isAdmin) {
    del = <button type="button" onClick={onEpisodeDelete} className="btn btn-sm btn-light">Delete Episode</button>;
  }

  return (
    <header className="episode__view__title">
      <h1>{title}</h1>
      <button type="button" onClick={onEpisodeEdit} className="btn btn-sm btn--action">Edit Episode Name</button>
      <button type="button" onClick={onEpisodeMove} className="btn btn-sm btn--action">Move Episode</button>
      {del}
    </header>
  );
}

export default EpisodeViewTitle;
