import React from 'react';

import '../css/UserProjects.css';

const UserProjects = () => {
  return (
    <div className="UserNoProjects">
      <h1>No projects 😞</h1>
      <p>You haven&apos;t been assigned to any projects yet. If this sounds wrong, talk to your project lead.</p>
    </div>
  );
}

export default UserProjects

