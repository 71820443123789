/*
ImportUtils.js
*/

import {
  apirequest,
  getAuthData
} from '../js/Utilities';

import {
  API_IMPORT_GSHEET_TO_PLAYLIST
} from '../js/Configuration';

export const initGState = {
  url: '',
  sheet: 'Sheet1',
  loading: false,
  prepend: 1
}

export const setStateImportOnOpen = (state) => {
  return {
    importGSheet: {
      ...state.importGSheet,
      open: true,
    }
  }
}

export const setStateImportOnClose = (state, showloader=false) => {
  return {
    ...state,
    importGSheet: {
      ...state.importGSheet,
      loading: false,
      open: false,
      url: '',
      sheet: 'Sheet1'
    },
    loading: showloader
  }
}

const setStateImportOnError = (state) => {
  return {
    importGSheet: {
      ...state.importGSheet,
      loading: false
    }
  }
}
