import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from './CloseButton';

import './css/ModalAlert.css';

const ModalAlert = ({
  open,
  children,
  id,
  onClose,
  title=''
}) => {
  const ref = useRef();

  useEffect(() => {
    if (!open) {
      return;
    }
    const dialog = ref.current;

    dialog.showModal();

    // eslint-disable-next-line consistent-return
    return () => (dialog.close());
  }, [open]);

  const makeTitle = () => {
    let titleEl = null;
    if(title) {
      titleEl = <h2 id={`${id}_title`}>{title}</h2>;
    }
    return titleEl;
  }

  return (
    <dialog ref={ref} id={id} aria-labelledby={`${id}_title`}>
      <CloseButton
        color="#CBCBCB"
        className="dialog__body__close"
        onClick={onClose}
      />

      <div className="dialog__body" role="document">
        {makeTitle()}
        {children}
      </div>
    </dialog>
  )
}

ModalAlert.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.any,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired
};

export default ModalAlert;
