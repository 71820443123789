import React from 'react';
import PropTypes from 'prop-types';

import DateInput from '../subcomponents/DateInput';
import './css/SearchDateRange.css';

const SearchDateInput = ({onChange, afterDate, beforeDate}) => {
  return (
    <fieldset className="search__form__daterange">
      <div>
        <legend>Published Between:</legend>
        <div>
          <DateInput
            cssClass="search__form__date__label"
            name="publishedAfter"
            isRequired={false}
            value={afterDate}
            onChangeHandler={onChange} />
            <span>and</span>
          <DateInput
            cssClass="search__form__date__label"
            name="publishedBefore"
            isRequired={false}
            labelText="End of range"
            value={beforeDate}
            onChangeHandler={onChange} />
        </div>
      </div>
    </fieldset>
  );
}

SearchDateInput.propTypes = {
  date: PropTypes.string
};

export default SearchDateInput;
