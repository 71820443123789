/**
* Utilities.js
* Utility functions.
*/

import 'moment-duration-format';
import moment from 'moment';
import { isValid, format } from 'date-fns';

import {
  USER_PERMISSIONS_LEVELS,
  API_VIDEO_INFO,
  STRIP_TAGS_REGEX
} from '../js/Configuration';

const SECONDS_HOUR = 60 * 60;
const SECONDS_MINUTE = 60;
const SECONDS_DAY = 60 * 60 * 24;

/*
Debounce function.
Prevents a function from being called too many times. From:
https://codeburst.io/throttling-and-debouncing-in-javascript-646d076d0a44
*/

export const debounce = (delay, fn)  => {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  }
}

/*
Get auth token from window.sessionStorage
*/
export const getAuthData = (item = null) => {
  const authd_string = window.sessionStorage.getItem('ava_auth');
  let authd_json;

  if(!authd_string) return;

  authd_json = JSON.parse(authd_string);
  if(!! item) {
    return authd_json[item];
  } else {
    return authd_json;
  }
}

export const setAuthData = (auth_data) => {
  try {
    window.sessionStorage.setItem('ava_auth', JSON.stringify(auth_data));
  } catch ( error ) {
    console.log( error );
  }
}

export const updateAuthData = (data_obj) => {
  const authd_string = window.sessionStorage.getItem('ava_auth');
  if(!authd_string) return;

  const authd_json = JSON.parse(authd_string);
  const updated = Object.assign(authd_json, data_obj);
  setAuthData(updated);
}

export const removeAuthData = (item = null) => {
  if( item ) {
    let data = window.sessionStorage.getItem('ava_auth');

    if( data ) {
      data = JSON.parse( data )
      delete data[item];
      setAuthData( data );
    }

  } else {
    window.sessionStorage.removeItem('ava_auth');
  }

}

export const numberFormat = (number, separator = ',') => {
  const num = Number.isNaN(+number) ? 0 : +number;
  let x = 0;
  let fnum = [];

  const digits = (num + '').split('');
  const seg = digits.length / 3;

  while(x < seg){
    fnum[x] = digits.splice(-3).join('');
    x++;
  }
  return fnum.reverse().join(separator);
}

const apiError = (error) => {
  console.log(error);
  alert("Oh dear. Something went wrong. This is probably an issue with the server. Please tell an administrator.");
}

export const apirequest = (path, options = {}, callback) => {
  const defaultOptions = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  };

  const url = `${process.env.REACT_APP_API}api/${path}`;
  return fetch(url, Object.assign(defaultOptions, options))
  .then(function(response) {
    if(response.ok) {
      return response.json();
    }
    else if(+response.status === 401) {
      window.alert('You need to log in again');
      removeAuthData();
      window.location.href = '/';
    }
    else {
      let error = '';

      switch(+response.status) {
        case 504:
          error = `You asked AVA to process a lot of videos, and it's struggling. Try using two separate sheets. [ERROR: ${response.status}]`;
          break;
        // When importing spreadsheets.
        case 403:
          error = `${response.statusText} [ERROR: ${response.status}]`;
          break;
        case 460:
          error = `${response.statusText} [ERROR: ${response.status}]`;
          break;
        default:
          error = `Whoops! Look like we missed that bug in testing. [ERROR: ${response.status}]`;
      };
      window.alert(error);
    }
   })
  .then(callback)
  .catch(apiError);
}

export const csvrequest = (path, options = {}, callback) => {
  const defaultOptions = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  };

  const url = `${process.env.REACT_APP_API}api/${path}`;
  return fetch(url, Object.assign(defaultOptions, options))
  .then(callback)
  .catch(apiError);
}

export const truncateString = (str = '', length = 50) => {
  let shortened = str.length > length ? `${str.substr(0, length).trim()}...` : str;
  return shortened;
}

export const rfc3339Date = (timeStringOrStamp) => {
  const output = new Date(timeStringOrStamp);

  const month = (output.getUTCMonth() + 1).toString();
  const ddate = output.getUTCDate().toString();

  const m = month.length > 1 ? month : padTimePart(month);
  const d = ddate.length > 1 ? ddate : padTimePart(ddate);

  return `${output.getFullYear()}-${m}-${d}T00:00:00Z`;
}

export const yyyymmdd = (timeStringOrStamp) => {
  const ts = new Date(timeStringOrStamp);

  const month = ts.getUTCMonth() + 1;
  const ddate = ts.getUTCDate();

  const m = month.toString().length > 1 ? month : padTimePart(month);
  const d = ddate.toString().length > 1 ? ddate : padTimePart(ddate);

  return `${ts.getFullYear()}-${m}-${d}`;
}

export const mysqlTimeStamp = (timestamp) => {
  // 2020-06-29 14:08:13
  return format(new Date(timestamp), 'yyyy-LL-dd k:mm:ss');
}
/*
 * Sort direction is either DESCENDING/ASCENDING
 * It's a true/false 0/1 boolean situation.
 *
 * In this case:
 *
 * descending = 0
 * ascending  = 1
 *
 * We'll convert the value of this.state.ascending to a
 * number. Then we can use our number as an index to select
 * the correct value from the array.
 */

export const getSortDirection = (sortdir) => {
  const direction = ['descending', 'ascending'];
  const dirKey = +sortdir;
  return direction[dirKey];
}

export const totalRunTime = (arrayOfVideos = []) => {

  if(arrayOfVideos.length === 0) return;

  const totalSeconds = arrayOfVideos
    .map((vid) => {return +vid.duration || +vid.Duration || 0}) // Get the durations.
    .filter((duration) => !window.isNaN(duration)) // Filter out potential NaN values
    .reduce((accumulator, currentValue) => currentValue + accumulator); // Total it all up.
  return totalSeconds;
}

export const isAdmin = (projectId) => {
  if(!projectId) {
    throw 'isAdmin needs a project identifier.';
  }
  let returnValue = false;
  const permissions = getAuthData('permissions') || [];
  const hasAccess = permissions.find((p) => p.pid === projectId);

  if(hasAccess && hasAccess.hasOwnProperty('role')) {
    returnValue = hasAccess.role > USER_PERMISSIONS_LEVELS[0].value;
  }

  return returnValue;
}

export const canAdminProject = (projectName = '') => {
  const perms = getAuthData('permissions');
  if(!perms) return;
  if(!perms.length) return;

  const role = perms.find((p) => p.name === projectName);

  let returnValue = false;
  if(role && role.hasOwnProperty('role')) {
    returnValue = role.role >= USER_PERMISSIONS_LEVELS[1].value;
  }

  return returnValue;
}

const padTimePart = (timepart) => {
  const tp = timepart.toString();
  return tp.padStart(2, '0');
}

/*
 YouTube rounds fractional seconds down on the site, so API times
 are sometimes off by one. This compensates for that discrepancy a
 bit better than moment.js does.
*/

export const formatTime = (seconds, usedays = false) => {
  let timestring = [];
  let days;
  let secs;

  if(usedays && seconds > SECONDS_DAY) {
    days = Math.floor(seconds / SECONDS_DAY);
    timestring.concat([`${days} days, `]);
    secs = seconds - (days * SECONDS_DAY);
  } else {
    secs = seconds;
  }

  if(!secs) {
    timestring = ['00',':00'];
  }

  else if(secs < SECONDS_MINUTE) {
    timestring = ['00', `:${padTimePart(secs)}`];
  }

  else {
    const hours = secs / SECONDS_HOUR;
    const hh = Math.floor(hours);

    const minutes = (hh >= 1) ? ((hours % hh) * SECONDS_MINUTE) : (hours * SECONDS_MINUTE);
    const mm = Math.floor(minutes);

    const sseconds = +Number.parseFloat(minutes % mm).toPrecision(5);
    const ss = Number.isNaN(sseconds) ? 0 : Math.floor(sseconds * SECONDS_MINUTE);

    const hpart = hh === 0 ? null : `${padTimePart(hh)}`;

    timestring.push(`:${padTimePart(mm)}`);
    timestring.push(`:${padTimePart(ss)}`);

    if(hh > 0) {
      timestring.unshift(hpart);
    }

    if(days) {
      timestring.unshift(`${days}d `);
    }
  }
  // Remove : when it's the first character.
  return timestring.join('').replace(/^:/, '');
}



// Tests whether the object has any properties of its own.
export const isEmptyObject = (obj) => {
  return !Object.entries(obj).length;
};

// Requests video data for a single ID.
export const getVideoData = (id, callback=null) => {
  const fd = new FormData();
  fd.set('id', id);
  fd.set('token', getAuthData('token'));

  const options = {
    method: 'POST',
    body: fd
  };

  apirequest(API_VIDEO_INFO, options, callback);
}

export const meetsMinLength = (string, length = 12) => {
  return string.length >= length;
}

export const isSameString = (string1, string2) => {
  return string1 === string2;
}

// Handles a response from the server directly
export const forceDownload = (response) => {
  const cdheader = response.headers.get('Content-Disposition');
  const filename = cdheader.replace('attachment; filename="', '').replace('"', '');

  response.blob().then((csvblob) => {
    let a = document.createElement("a");
    a.style = "display: none";
    document.body.appendChild(a);

    let url = window.URL.createObjectURL(csvblob);
    a.href = url;
    a.download = filename;

    //programatically click the link to trigger the download
    a.click();

    //release the reference to the file by revoking the Object URL
    window.URL.revokeObjectURL(url);
  })
}

// This was added after forceDownload, and takes an array that was generated on
// the client side and makes it into a download.
export const pushDownload = (data, sheetID = '', sheetName = '') => {
  const blob = new Blob([convertArrayToCSV(data)], {type: 'text/csv;charset=utf-8'});
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");

  // Filter out empty segments.
  const fn = [sheetID, sheetName].filter((s) => s != '').join('_');

  a.style = "display: none";
  document.body.appendChild(a);
  a.href = url;
  a.download = `${fn}.csv`;
  a.click(); // programatically click the link to trigger the download

  //release the reference to the file by revoking the Object URL
  window.URL.revokeObjectURL(url);

  // Delete the link.
  document.body.removeChild(a);
}


export const isNumeric = (value) => {
  return !Number.isNaN(parseFloat(value,10));
}

/* Use %{X} as a placeholder where {X} is a number*/
export const formatString = (template, replacements = []) => {
  const findRegExp = /%s/g;

  if(!template) throw new Error('formatString: Argument `template` can not be null');
  if(!replacements.length) throw new Error('formatString: Argument `replacements` can not be null');

  // Find placeholders in the template
  const finds = template.match(findRegExp);
  if(finds.length != replacements.length) throw new Error('formatString: There should be an equal number of placeholders and replacements.');

  let tpl = template;
  for (let i = 0; i < finds.length; i++) {
    tpl = tpl.replace(finds[i], replacements[i]);
  }
  return tpl;
}

/*
Creates an array of values with a start an end. Similar to PHP's range() function
*/
export const range = (min, max) => {
  const diff = max - min;
  const spread = [...Array(diff).keys()].map(n => {return n + min});
  spread.push(max);
  return spread;
}

/* floatlen should be an integer. */
export const percentage = (total, portion) => {
  return (portion / total) * 100;
}

export const precision = (floatValue, precision = 0) => {
  let mult = Math.pow(10, precision);
  let prec;

  if(precision < 0) {
    throw new Error('Argument `precision` must be greater than or equal to 0');
  }

  if(precision == 1) {
    prec = Math.trunc(floatValue * mult) / mult;
  } else {
    prec = Math.round(floatValue * mult) / mult;
  }

  return prec;
}

export const convertArrayToCSV = (data) => {
  return data.join("\n");
}

export const shuffle = (arrayToShuffle) => {
  var tmp, current, top = arrayToShuffle.length;
  if(top) {
    while(--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = arrayToShuffle[current];
      arrayToShuffle[current] = arrayToShuffle[top];
      arrayToShuffle[top] = tmp;
    }
  }
  return arrayToShuffle;
}

export const youtubeURL = (id = '') => {
  const ytid = id.trim();
  return `https://youtube.com/watch?v=${ytid}`;
}

export const youtubeThumbnailURL = (id = '') => {
  const ytid = id.trim();
  return `https://i.ytimg.com/vi/${ytid}/hqdefault.jpg`;
}

export const arraychunk = (array, groupsize) => {
  let sets = [];
  let chunks = array.length / groupsize;

  for (let i = 0, x = 0; i < chunks; i++, x += groupsize) {
    sets[i] = array.slice(x, x + groupsize);
  }

  return sets;
};

// Moved here from Utils.js so that we can delete that file.
// converts youtube duration value to time code
export const getDuration = function( _duration ) {
  if( !_duration ) return '00:00';

  // temp hack fix until the database is cleared of the old dates.
  if( _duration.toString().indexOf( ':' ) > -1 )
  {
      return _duration;
  }

  let duration = moment.duration( _duration, "seconds").format("hh:mm:ss");
  if( duration.indexOf( ':' ) === -1 )
  {
      duration = '00:' + duration;
  }

  return duration;
};

export const expandShortYouTubeUrl = (shortUrl) => {
  return shortUrl.replace(/http(|s):\/\/youtu\.be\//, 'https://www.youtube.com/watch?v=');
}

export const extractYouTubeIdFromUrl = (url) => {
  let ytid = '';
  try {
    const urlObj = new URL(url);
    ytid = urlObj.searchParams.get('v');
  } catch (err) {
    ytid = url;
  }
  return ytid;
}

export const makeYouTubeUrl = (identifier) => {
  return `https://www.youtube.com/watch?v=${identifier}`;
}

export const checkAccess = (projectId) => {
  if(!getAuthData('permissions')) return false;
  const permissions = Object.values(getAuthData('permissions'));
  return permissions.find((pm) => projectId === pm.pid);
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const randomString = (length = 16) => {
  // Ripped and adapted https://gist.github.com/6174/6062387#gistcomment-2742945
  return [...Array(length)].map(_=>(Math.random()*36|0).toString(36)).join``;
}

/*
  Convert a Map object to a generic Object since we can't use JSON.stringify
  with Map().
*/
export const mapToObject = (mapObject) => {
  let returnValue = {};
  if(mapObject && (Object.prototype.toString.call(mapObject) == "[object Map]")) {
    returnValue = Object.fromEntries(mapObject);
  }
  return returnValue;
}

export const getSheetId = (url) => {
  if(!url) return;
  // Uses the RegEx found here: https://developers.google.com/sheets/api/guides/concepts
  const id = url.toString().match(/\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/);
  return id[1]; // Return the alpha-numeric sheet ID.
}

export const pathBase = (locationString, segments = 2) => {
  return locationString
          .split('/')
          .filter((f) => f.length)
          .slice(0, segments)
          .join('/');
}

export const prepareFileName = (input) => {
  return input.replace(/\s/g,'_').replace(/\W/g,'');
}

/* Strips most HTML tags, and trims leading/trailing spaces */
export const stripTags = (input) => {
  return input.replace(STRIP_TAGS_REGEX, '').trim();
}

export const saveCurrentPath = ( path = '' ) => {
  window.sessionStorage.setItem( 'ava_last_path', path );
}

export const getTZOffsetHours = ( offsetSeconds ) => {
  if( !offsetSeconds ) return;
  return Math.floor(offsetSeconds / 60);
}

export const getUTCNormalizedDate = ( date ) => {
  if( !date ) return;

  const getTZoffset = new Date( date ).getTimezoneOffset();
  const tzOffset = getTZOffsetHours( getTZoffset );

  const nDate = new Date( date ).setUTCHours( tzOffset );

  return nDate;
}

export const showLoader = (state={}) => {
  return {
    ...state,
    loading: true
  };
}

export const hideLoader = () => {
  return {
    loading: false
  };
}

export const setPageNumber = (pageValue, detailObj) => {
    let pg;
    switch(pageValue) {
    case 'previous':
      pg = +detailObj.page - 1;
      break;
    case 'next':
      pg = +detailObj.page + 1;
      break;
    case 'first':
    case Number.isNaN(pageValue):
      pg = 1;
      break;
    case 'last':
      pg = +detailObj.pages
      break;
    default:
      pg = +pageValue;
  }
  return pg;
}

export const formatDate = (date, dformat) => {
  let d = date;
  if(isValid(new Date(date))) {
    d = format( new Date(date), dformat)
  }
  return d;
}

export const unique = (inputArray, returnAsArray=true) => {
  let uniq = new Set(inputArray);
  if(returnAsArray) {
    uniq = Array.from(uniq)
  }
  return uniq;
}

/* Duplicated in ChannelUtils. Use this one instead. */
export const stringToArray = ( string ) => {
  return string.split(/[,\s]/);
}

export async function asyncapirequest(path, options = {}, callback) {
  const defaultOptions = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  };

  const url = `${process.env.REACT_APP_API}api/${path}`;

  try {
    response = await fetch(url, {...defaultOptions, ...options});
    if (!response.ok) {
      let error;

      switch(+response.status) {
        case 504:
          error = `You asked AVA to process a lot of videos, and it's struggling. Try using two separate sheets. [ERROR: ${response.status}]`;
          break;
        // When importing spreadsheets.
        case 403:
          error = `${response.statusText} [ERROR: ${response.status}]`;
          break;
        case 460:
          error = `${response.statusText} [ERROR: ${response.status}]`;
          break;
        default:
          error = `Whoops! Look like we missed that bug in testing. [ERROR: ${response.status}]`;
      };
      throw new Error(error);
    }

    const json = await response.json();
    callback(json);
    
  } catch (error) {
    console.error(error.message);
  }
}

export const slugify = (input) => {
  return input.replace(/\s/g,'_');
}